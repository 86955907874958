import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Tab } from './Tab';

import { Props } from './LightTabs.types';

import styles from './lightTabs.module.scss';

export const LightTabs: FC<Props> = ({
  tabs,
  tabsComponents,
  className,
  children,
  activeTab,
  setActiveTab,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const handleClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={className}>
      <div
        className={classNames(styles.tabs_header, {
          [styles.tabs_header_mobile]: isMobile,
        })}
      >
        {children}
        <div
          className={classNames(styles.tabs, {
            [styles.tabs_mobile]: isMobile,
          })}
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                activeTab={activeTab}
                onClick={handleClick}
                index={index}
                key={index}
              >
                {item}
              </Tab>
            );
          })}
        </div>
      </div>
      <div className={styles.main}>{tabsComponents[activeTab]}</div>
    </div>
  );
};
