import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: loginReset } = actions;

export * from './thunks';
export * as loginSelectors from './selectors';
export * from './actions';

export default reducer;
