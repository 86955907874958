import { combineReducers } from 'redux';

import userReducer from './user';
import tasksReducer from './tasks';
import chartReducer from './chart';

const reducer = combineReducers({
  user: userReducer,
  tasks: tasksReducer,
  chart: chartReducer,
});

export * from './user';
export * from './tasks';
export * from './chart';

export default reducer;
