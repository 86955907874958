import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Dropdown } from '../Dropdown';

import { Props } from './Instruction.types';

import styles from './instruction.module.scss';

export const Instruction: FC<Props> = ({ condition }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
      })}
    >
      {condition && (
        <div className={styles.dropdowns}>
          <Dropdown
            className={styles.dropdown}
            title={t('bonuses.about_deposit_bonus')}
          >
            <p>{condition}</p>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
