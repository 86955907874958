import { createSlice } from '@reduxjs/toolkit';

import { SlotGames, SlotGamesInitialState } from './types';
import { fetchSlotGames } from './thunks';
import { resetSlotsList } from './actions';

const defaultList = {
  list: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 40,
    total: 40,
  },
  ready: false,
  loading: false,
  error: '',
} as SlotGames;

const initialState = {} as SlotGamesInitialState;

export const slice = createSlice({
  name: 'slots/games',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlotGames.pending, (state, action) => {
        const { listId: listName } = action.meta.arg;

        if (!(listName in state)) {
          state[listName] = defaultList;
        } else {
          state[listName].loading = true;
        }
      })
      .addCase(fetchSlotGames.fulfilled, (state, action) => {
        const { listId: listName } = action.meta.arg;
        const list = state[listName];

        const currentList = list.list;
        const newList = action.payload.list;

        list.list = list.list.length
          ? [
              ...currentList,
              ...newList.filter((object1) => {
                return !currentList.some(
                  (object2) => object1.id === object2.id
                );
              }),
            ]
          : action.payload.list;
        list.meta = action.payload.meta;
        list.loading = false;
        list.ready = true;
      })
      .addCase(fetchSlotGames.rejected, (state, action) => {
        const { listId: listName } = action.meta.arg;
        const list = state[listName];
        list.error = action.payload;
        list.loading = false;
      })
      .addCase(resetSlotsList, (state, action) => {
        state[action.payload] = {
          ...defaultList,
          ready: true,
          loading: false,
        };
      });
  },
});
