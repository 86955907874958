import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@box/redux/hooks';
import { hideModal, openInfoPopup } from '@box/redux/app';
import { userSelectors } from '@box/redux/user';
import { setChatOpen } from '@box/redux/support';
import { fetchPermissions, userInitSelectors } from '@box/redux/authentication';
import {
  fetchWithdrawalMerchants,
  withdrawalMerchantsSelectors,
} from '@box/redux/finance';

import { BlockWithLoading } from '../../../baseComponents';

import { MerchantForm } from './MerchantForm';
import { MerchantTabs } from './MerchantTabs';

export const Content = () => {
  const [activeTab, setActiveTab] = useState(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const userId = useSelector(userSelectors.id);
  const merchants = useSelector(withdrawalMerchantsSelectors.list);
  const loading = useSelector(withdrawalMerchantsSelectors.loading);
  const emptyData = useSelector(withdrawalMerchantsSelectors.emptyData);
  const isWageredReady = useSelector(userInitSelectors.isWageredReady);

  useEffectOnce(() => {
    dispatch(fetchWithdrawalMerchants());
  });

  const handleInfoPopup = () => {
    dispatch(setChatOpen(true));
    dispatch(hideModal('funds'));
  };

  useEffect(() => {
    if (emptyData) {
      dispatch(
        openInfoPopup({
          text: t('withdrawalRequest.contact_support_chat'),
          type: 'chat',
          callback: handleInfoPopup,
        })
      );
    }
  }, [emptyData]);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  return (
    <div className='funds__merchants'>
      <BlockWithLoading loading={loading || !isWageredReady}>
        {merchants && merchants.length > 0 ? (
          <>
            <div className='funds__tabs'>
              <MerchantTabs
                className='funds__merchants-tabs'
                onTabClick={setActiveTab}
                activeTab={activeTab?.id}
              />
            </div>
            <div className='funds__merchants-form'>
              {activeTab && (
                <MerchantForm activeTab={activeTab} userId={userId} />
              )}
            </div>
          </>
        ) : (
          <div className='funds__noData'>No data</div>
        )}
      </BlockWithLoading>
    </div>
  );
};
