import dayjs from 'dayjs';

const converToDayJsFormat = (date: string) => dayjs(date, 'DD-MM-YYYY', true);

export const birthdateValidation = {
  correct: (v: string) => {
    const currentDate = converToDayJsFormat(v);
    return currentDate.isValid() && dayjs().year() >= currentDate.year();
  },
  age: (v: string) => dayjs().diff(converToDayJsFormat(v), 'year') >= 18,
};
