import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';
import { InitJackpotReturn, GETInitJackpot } from './types';

export const initJackpot = createAsyncThunk<
  InitJackpotReturn,
  void,
  AppThunkApi
>('app/jackpot/init', async (_payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { status, data } = await api.get<GETInitJackpot>('/jackpot');

    if (status === 200) {
      return {
        id: data.data.id,
        amount: data.data.amount,
        currencyId: data.data.currency_id,
      };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
