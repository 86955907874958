import React, { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Modal as BaseModal, Window } from '@box/ui';
import { useAppSelector } from '@box/redux/hooks';
import { hideModal, modalSelectors } from '@box/redux/app';
import { useApp, useLockScroll } from '@box/shared/hooks';

import { AnimationDelayWrapper } from '../../baseComponents';

import { Props } from './Modal.types';

export const Modal: FC<Props> = ({
  children,
  id,
  title,
  backTitle,
  onClose,
  customHead,
  headColor,
  backToMain,
  isSecondStep,
  secondChildren,
  headWithSvg,
  heightAuto,
  hasCloseClick = true,
  ...props
}) => {
  const dispatch = useDispatch();

  const {
    userEnv: { isJune },
  } = useApp();

  const isOpen = useAppSelector((state) => modalSelectors.openById(state, id));

  const prevIsOpen = useRef<boolean>(isOpen);

  useLockScroll(isOpen);

  const close = () => {
    if (hasCloseClick) {
      dispatch(hideModal(id));
    }
  };

  useEffect(() => {
    if (onClose && prevIsOpen.current && !isOpen) {
      onClose();
    }

    prevIsOpen.current = isOpen;
  }, [isOpen]);

  return (
    <AnimationDelayWrapper isOpen={isOpen}>
      <BaseModal heightAuto={heightAuto} isOpen={isOpen} close={close}>
        <Window.Container fullHeight={isJune && !heightAuto} {...props}>
          {!customHead && !isSecondStep && (
            <Window.Head isSvg={headWithSvg} color={headColor}>
              <Window.Title>{title}</Window.Title>
              <Window.Close onClick={close} />
            </Window.Head>
          )}
          {isSecondStep && (
            <Window.Head color={headColor}>
              <Window.BackTitle onClick={backToMain}>
                {backTitle}
              </Window.BackTitle>
              <Window.Close onClick={close} />
            </Window.Head>
          )}
          {!isSecondStep ? children : secondChildren}
        </Window.Container>
      </BaseModal>
    </AnimationDelayWrapper>
  );
};
