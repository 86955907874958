import { createSlice } from '@reduxjs/toolkit';

import { fetchWithdrawalRequests, withdrawalRequest } from './thunks';
import {
  addWithdrawal,
  resetWithdrawalError,
  setWithdrawalLoading,
} from './actions';
import { Withdrawal, WithdrawalRequestList } from './types';

const defaultList = {
  loading: true,
  list: [],
  currentPage: 1,
  lastPage: 1,
  ready: false,
};

const initialState = {
  lists: {} as {
    withdrawal: WithdrawalRequestList;
  },
  loading: false,
  error: '',
  confirmError: false,
};

export const slice = createSlice({
  name: 'withdrawalRequests',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWithdrawalRequests.pending, (state, { meta }) => {
        const currentList = state.lists[meta.arg.id];
        if (!currentList) {
          state.lists[meta.arg.id] = defaultList;
        } else {
          currentList.loading = true;
        }
      })
      .addCase(
        fetchWithdrawalRequests.fulfilled,
        (state, { payload, meta }) => {
          const currentList = state.lists[meta.arg.id];

          currentList.loading = false;
          currentList.list = payload.list;
          currentList.currentPage = payload.currentPage;
          currentList.lastPage = payload.lastPage;
          currentList.ready = true;
        }
      )
      .addCase(fetchWithdrawalRequests.rejected, (state, { meta }) => {
        const currentList = state.lists[meta.arg.id];

        currentList.ready = true;
        currentList.loading = false;
      })
      .addCase(addWithdrawal, (state, { payload }) => {
        const newWithdrawal: Withdrawal = {
          ...payload,
          status: 'pending',
        } as Withdrawal;

        state.lists = Object.keys(state.lists).reduce((acc, key) => {
          const updatedList = {
            ...acc,
            [key]: {
              ...state.lists[key],
              list: [newWithdrawal.withdrawal, ...state.lists[key].list],
            },
          };

          return updatedList;
        }, {});
      })

      .addCase(withdrawalRequest.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(withdrawalRequest.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(withdrawalRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(resetWithdrawalError, (state) => {
        state.error = '';
      })
      .addCase(setWithdrawalLoading, (state) => {
        state.loading = true;
      });
  },
});
