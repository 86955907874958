import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Props } from './MobileChart.types';

import styles from './mobileChart.module.scss';

export const MobileChart: FC<Props> = ({ historyList }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.chart}>
      {historyList.map((item, index) => {
        return (
          <div key={index} className={styles.item}>
            <div className={styles.row}>
              <div className={styles.column}>
                <p className={classNames(styles.text, styles.text_bold)}>
                  {item.date}
                </p>
                <p className={classNames(styles.text, styles.text_shadow)}>
                  {item.time}
                </p>
              </div>
              <div className={styles.column}>
                <p className={classNames(styles.text, styles.text_shadow)}>
                  {t('loyalty.chart.status_of_exchange')}
                </p>
                <p className={styles.text}>{item.status}</p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <p className={classNames(styles.text, styles.text_shadow)}>
                  {t('loyalty.chart.given_away_vc')}
                </p>
                <p className={styles.text}>{item.vc}</p>
              </div>
              <div className={styles.column}>
                <p className={classNames(styles.text, styles.text_shadow)}>
                  {t('loyalty.chart.received')}
                </p>
                <p className={styles.text}>{item.getMoney}</p>
              </div>
              <div className={styles.column}>
                <p className={classNames(styles.text, styles.text_shadow)}>
                  {t('loyalty.chart.exchange_rate')}
                </p>
                <p className={styles.text}>{item.exchangeRate}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
