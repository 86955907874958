import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useApp } from '@box/shared/hooks';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  passwordUpdateReset,
  passwordUpdateSelectors,
  updatePassword,
} from '@box/redux/authentication';
import { passwordRegex } from '@box/shared/utils/validation';

import { DecemberInput } from '@box/ui';
import { Button, Control } from '../../../../baseComponents';

import './passwordChange.scss';

const required = { required_error: 'fields.required' };
const schema = z
  .object({
    old_password: z
      .string(required)
      .min(6, 'fields.password_length_error')
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
    new_password: z
      .string(required)
      .min(6, 'fields.password_length_error')
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
    password_confirmation: z.string(required),
  })
  .refine((v) => v.new_password !== v.old_password, {
    message: 'fields.old_password',
    path: ['new_password'],
  })
  .refine((v) => v.new_password === v.password_confirmation, {
    message: 'fields.password_confirmation',
    path: ['password_confirmation'],
  });

export const PasswordChange = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    userEnv: { isMobile },
  } = useApp();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const error = useSelector(passwordUpdateSelectors.error);
  const loading = useSelector(passwordUpdateSelectors.loading);

  const onSubmit = (data) => {
    dispatch(updatePassword(data));
  };

  useEffect(() => {
    return () => {
      dispatch(passwordUpdateReset());
    };
  }, []);

  return (
    <div className='settings__block'>
      <h3 className='settings__block-title'>{t('password_change.change')}</h3>
      <form
        className={classNames('password-change-form', {
          'password-change-form_mobile': isMobile,
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DecemberInput
          color='outlined'
          placeholder={t('settings.old_pass')}
          label={t('settings.old_pass')}
          type='password'
          {...register('old_password')}
          error={t(errors.old_password?.message)}
        />
        <DecemberInput
          color='outlined'
          placeholder={t('settings.new_pass')}
          label={t('settings.new_pass')}
          type='password'
          {...register('new_password')}
          error={t(errors.new_password?.message)}
        />
        <DecemberInput
          color='outlined'
          placeholder={t('settings.confirm_pass')}
          label={t('settings.confirm_pass')}
          type='password'
          {...register('password_confirmation')}
          error={t(errors.password_confirmation?.message)}
        />
        {error && (
          <span className='password-change-form__error'>{t(error)}</span>
        )}
        <Button
          className='password-change-form__submit'
          type='submit'
          color='secondary'
          hoverType='opacity'
          loading={loading}
        >
          {t('common.submit')}
        </Button>
      </form>
    </div>
  );
};
