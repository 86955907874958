import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './ScrollBlock.types';

import styles from './scrollBlock.module.scss';

export const ScrollBlock: FC<Props> = ({
  children,
  height,
  color = 'dark',
  trackColor = 'none',
  size,
  className,
  orientation = 'vertical',
  isContentPadding,
}) => {
  return (
    <div
      style={{ height }}
      className={classNames(
        styles.scrollBlock,
        styles[`orientation_${orientation}`],
        className
      )}
    >
      <div
        className={classNames(
          styles.content,
          styles[`content_color_${color}`],
          styles[`content_trackColor_${trackColor}`],
          {
            [styles[`content_size_${size}`]]: size,
            [styles.content_padding]: isContentPadding,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};
