import uniqBy from 'lodash/uniqBy';
import { Session, Game } from '../types';

export type GetGameFromGamesSession = (session: Session) => Game;
export type PrepareGamesHistory = (list: Session[]) => Game[];

export const getGameFromGamesSession: GetGameFromGamesSession = (session) => ({
  ...session.game,
  thumbnail: session.game.icon,
});

export const prepareGamesHistory: PrepareGamesHistory = (list) =>
  uniqBy(list, 'game.id').map((item) => getGameFromGamesSession(item));
