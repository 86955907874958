import React from 'react';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';
import 'swiper/css/navigation';

import { Icon } from '../Icon';
import { SlideButtons } from './SlideButtons';

import './navigationTabs.scss';

export const NavigationTabs = ({ list, isIconHidden }) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <Swiper
      className={classNames('navigation-tabs', {
        'navigation-tabs_june': isJune,
      })}
      slidesPerView='auto'
      speed={10}
      spaceBetween={8}
      freeMode
      slidesOffsetAfter={130}
      draggable
      slideToClickedSlide
      mousewheel={{
        releaseOnEdges: true,
      }}
    >
      {list.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <NavLink to={item.url} className='navigation-tabs__item'>
              {!isIconHidden && (
                <Icon className='navigation-tabs__item-icon' icon={item.icon} />
              )}
              <span className='navigation-tabs__item-name'>{item.name}</span>
            </NavLink>
          </SwiperSlide>
        );
      })}
      <SlideButtons />
    </Swiper>
  );
};
