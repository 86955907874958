import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@box/redux/hooks';
import { userInitSelectors } from '@box/redux/authentication';

export const useNavigateForUnlogged = () => {
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLogged) {
      navigate('/');
    }
  }, [isLogged]);
};
