import { createSlice } from '@reduxjs/toolkit';
import { resetPromocodeStatus } from './action';
import { checkUserPromocode, fetchUserPromocodes } from './thunks';

import { InitialState, UserPromocodes } from './types';

const initialState: InitialState = {
  promocodes: [] as UserPromocodes[],
  error: '',
  loading: false,
  checkLoading: false,
  ready: false,
  checked: false,
};

export const slice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPromocodes.pending, (state) => {
        state.loading = true;
        state.ready = false;
      })
      .addCase(fetchUserPromocodes.fulfilled, (state, { payload }) => {
        state.promocodes = payload;
        state.loading = false;
        state.ready = true;
      })
      .addCase(fetchUserPromocodes.rejected, (state) => {
        state.loading = false;
        state.ready = false;
      })
      .addCase(checkUserPromocode.pending, (state) => {
        state.checkLoading = true;
        state.checked = false;
        state.error = '';
      })
      .addCase(checkUserPromocode.fulfilled, (state) => {
        state.checkLoading = false;
        state.checked = true;
      })
      .addCase(checkUserPromocode.rejected, (state, { payload }) => {
        state.checkLoading = false;
        state.error = payload as string;
      })
      .addCase(resetPromocodeStatus, (state) => {
        state.error = '';
      });
  },
});
