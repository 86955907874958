import React, { FC } from 'react';
import classNames from 'classnames';
import { LightButton } from '@box/ui';
import { useTranslation } from 'react-i18next';
import { useApp } from '@box/shared/hooks';

import { Props } from './Header.types';

import styles from './header.module.scss';
import { ReactComponent as IconSvg } from './assets/images/icon.svg';
import { ReactComponent as HistorySvg } from './assets/images/history.svg';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';

export const Header: FC<Props> = ({
  points,
  exchangeRate,
  handleToggleHidden,
  isHistoryHidden,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.header, {
        [styles.header_mobile]: isMobile,
      })}
    >
      <div className={styles.mobileRow}>
        <div className={classNames(styles.column, styles.column_withBorder)}>
          <p className={styles.label}>{t('loyalty.chart.my_balance')}:</p>
          <div className={styles.row}>
            <IconSvg className={styles.svg_yellow} />
            <p className={styles.value}>{points}</p>
          </div>
        </div>
        <div
          className={classNames(styles.column, {
            [styles.column_withBorder]: !isMobile,
          })}
        >
          <p className={styles.label}>{t('loyalty.chart.exchange_rate')}:</p>
          <div className={styles.row}>
            <IconSvg className={styles.svg_white} />
            <p className={styles.value}>{exchangeRate}</p>
          </div>
        </div>
        {!isMobile ? (
          <>
            <div className={styles.column}>
              <div className={styles.row}>
                <LightButton className={styles.exchange} color='green'>
                  {t('loyalty.chart.exchange')}
                </LightButton>
                <button
                  type='button'
                  onClick={handleToggleHidden}
                  className={classNames(styles.hide, {
                    [styles.hide_active]: isHistoryHidden,
                  })}
                >
                  <HistorySvg />
                  {t('loyalty.chart.hide_history')}
                  <div className={styles.arrow}>
                    <ArrowSvg />
                  </div>
                </button>
              </div>
            </div>
            <LightButton className={styles.start}>
              {t('loyalty.chart.start_earning')}
            </LightButton>
          </>
        ) : (
          <LightButton className={styles.exchange} color='green'>
            {t('loyalty.chart.exchange')}
          </LightButton>
        )}
      </div>
      {isMobile && (
        <button
          type='button'
          onClick={handleToggleHidden}
          className={classNames(styles.hide, {
            [styles.hide_active]: isHistoryHidden,
          })}
        >
          <HistorySvg />
          {t('loyalty.chart.hide_history')}
          <div className={styles.arrow}>
            <ArrowSvg />
          </div>
        </button>
      )}
    </div>
  );
};
