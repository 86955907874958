import React, { FC } from 'react';
import { useAppSelector } from '@box/redux/hooks';
import { bonusesSelectors } from '@box/redux/bonuses';
import { BonusImages } from '@box/shared/utils';
import { useApp, useLockScroll } from '@box/shared/hooks';

import { BonusModal } from '../Modals';
import { DecemberBonusModal } from '../BonusModal';

export const BonusForBanner: FC = () => {
  const bonus = useAppSelector(bonusesSelectors.detail);
  const {
    userEnv: { isJune },
  } = useApp();

  useLockScroll(!!bonus);

  if (bonus) {
    if (isJune) {
      return (
        <BonusModal
          wager_params={bonus?.getting_params}
          getting_params={bonus?.getting_params}
          name={bonus?.name}
          id={bonus?.id}
          condition={bonus?.condition}
          isAbsolute
          isOpen={!!bonus}
        />
      );
    }
    return (
      <DecemberBonusModal
        name={bonus?.name}
        isOpen={!!bonus}
        isAbsolute
        bonusId={bonus?.id}
        img={BonusImages[bonus?.type] || ''}
        wager_params={bonus?.getting_params}
        getting_params={bonus?.getting_params}
      />
    );
  }

  return null;
};
