import { z } from 'zod';
import { resolveSafeParse } from './helper';

export const passwordRegex =
  /^(?=.*[A-Za-z\d])[A-Za-z\d*.!@$%^&():;,.?~_+-=]{6,}$/;

export const validatePassword = (value: string) => {
  const result = z
    .string()
    .min(6, 'fields.password_length_error')
    .max(30, 'fields.password_max_length_error')
    .regex(passwordRegex, 'fields.invalid_field')
    .safeParse(value);

  return resolveSafeParse(result);
};

const required = { required_error: 'fields.required' };

export const changePasswordScheme = z
  .object({
    old_password: z
      .string(required)
      .min(6, 'fields.password_length_error')
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
    new_password: z
      .string(required)
      .min(6, 'fields.password_length_error')
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
    password_confirmation: z.string(required),
  })
  .refine((v) => v.new_password !== v.old_password, {
    message: 'fields.old_password',
    path: ['new_password'],
  })
  .refine((v) => v.new_password === v.password_confirmation, {
    message: 'fields.password_confirmation',
    path: ['password_confirmation'],
  });
