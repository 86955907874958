import { useEffect } from 'react';
import { userInitSelectors } from '@box/redux/authentication';
import { commonSelectors, sendPartnersTokens } from '@box/redux/common';
import {
  useAppDispatch,
  useAppSelector,
  useAuthTokens,
} from '@box/redux/hooks';

export const useSendTokenForPartners = () => {
  const dispatch = useAppDispatch();

  const isUserReady = useAppSelector(userInitSelectors.isReady);
  const isCommonsReady = useAppSelector(commonSelectors.commonsReady);
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const { partner_mirror } = useAppSelector(commonSelectors.clientData);
  const { accessToken, refreshToken, expiresIn } = useAuthTokens();

  useEffect(() => {
    if (isCommonsReady && isUserReady && isLogged && partner_mirror) {
      dispatch(
        sendPartnersTokens({
          token: accessToken,
          refresh_token: refreshToken,
          expires: expiresIn,
          partner_mirror,
        })
      );
    }
  }, [isCommonsReady, isUserReady, partner_mirror, isLogged]);
};
