import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './types';

const initialState: InitialState = {
  loading: false,
  error: '',
};

export const slice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    reset: () => initialState,
  },
});
