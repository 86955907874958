import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: paymentReset } = actions;

export * as paymentSelectors from './selectors';
export * from './thunks';
export * from './actions';

export default reducer;
