import { UserBonus, UserTransformBonus } from '@box/redux/types';
import { images } from './images';

type PrepareSingleUserBonus = (b: UserBonus) => UserTransformBonus;
type PrepareUserBonuses = (list: UserBonus[]) => UserTransformBonus[];

export const prepareSingleUserBonus: PrepareSingleUserBonus = (b) => {
  delete b.bonus.id;
  delete b.user;

  b = {
    ...b,
    ...b.bonus,
  };

  delete b.bonus;

  return b;
};

export const prepareUserBonuses: PrepareUserBonuses = (list) =>
  list?.map((item) => prepareSingleUserBonus(item));

export const prepareCommonBonuses = (list) =>
  list?.map((item) => ({
    ...item,
    img: images[item.type] || '',
    bonus_id: item.id,
  }));

export const prepareUserBonusesWithImage = (list) =>
  list?.map((item) => ({
    ...item,
    img:
      {
        png: images[item.type].png,
        webp: images[item.type].webp,
        avif: images[item.type].avif,
      } || '',
    id: item.bonus_id,
  }));
