import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './blockWithLoader.scss';
import { LineLoader } from '@box/ui';
import { useApp } from '@box/shared/hooks';

export const BlockWithLoading = ({
  loading,
  children,
  className,
  color = 'yellow',
  loaderClassName,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return loading ? (
    <div className={classNames('block-loader', className)}>
      {isJune ? (
        <LineLoader color={color} />
      ) : (
        <div
          className={classNames(
            loaderClassName,
            'loader-wrapper loader-wrapper--button'
          )}
        />
      )}
    </div>
  ) : (
    children
  );
};

BlockWithLoading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  color: 'yellow' || 'white',
  loaderClassName: PropTypes.string,
};

BlockWithLoading.defaultProps = {
  loading: false,
  children: null,
  className: '',
  color: 'yellow',
};
