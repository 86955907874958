import React, { FC } from 'react';

import { Props } from './Arrows.types';

import { ReactComponent as ArrowsSvg } from './assets/images/arrow.svg';

export const Arrows: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <ArrowsSvg />
    </div>
  );
};
