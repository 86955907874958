import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkApi } from '@box/redux/types';
import { useAuthTokens } from '@box/redux/hooks';

import { getPureErrorMessage } from '../../utils';

import { FetchChatsRegisterReturn, POSTChatsRegister } from './types';
import { fetchInitChatsUser } from '../init';

export const fetchChatsRegister = createAsyncThunk<
  FetchChatsRegisterReturn,
  void,
  AppThunkApi
>(
  'register/fetchChatsRegister',
  async (_payload, { extra, dispatch, rejectWithValue }) => {
    const { api, crmUrl } = extra;

    const config = {
      baseURL: crmUrl,
    };

    const { setCrmAccessToken, setCrmUuid, setCrmRefreshToken } =
      useAuthTokens();

    try {
      const { data, status } = await api.post<POSTChatsRegister>(
        'v1/lite-users?&auth=1',
        {},
        config
      );

      if (status === 200) {
        setCrmAccessToken(data.access_token);
        setCrmRefreshToken(data.refresh_token);
        setCrmUuid(data.uuid);
        dispatch(fetchInitChatsUser({}));
        return data.id;
      }

      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(data);

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(e);
      return rejectWithValue('error');
    }
  }
);
