// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Faqs-Faqs-module__container{padding:16px 32px}.\\------shared-components-Faqs-Faqs-module__container,.\\------shared-components-Faqs-Faqs-module__container .\\------shared-components-Faqs-Faqs-module__main{display:flex;flex-direction:column}.\\------shared-components-Faqs-Faqs-module__container .\\------shared-components-Faqs-Faqs-module__main{align-items:center}.\\------shared-components-Faqs-Faqs-module__container .\\------shared-components-Faqs-Faqs-module__main .\\------shared-components-Faqs-Faqs-module__list{display:flex;flex-direction:column;list-style-type:none;margin:0;padding:0;width:100%}.\\------shared-components-Faqs-Faqs-module__container .\\------shared-components-Faqs-Faqs-module__main .\\------shared-components-Faqs-Faqs-module__list .\\------shared-components-Faqs-Faqs-module__item{margin:0 0 12px;width:100%}", "",{"version":3,"sources":["webpack://./../../shared/components/Faqs/Faqs.module.scss"],"names":[],"mappings":"AACA,sDAGE,iBAAF,CAEE,6JAJA,YAAA,CACA,qBAMF,CAHE,uGAGE,kBAAJ,CAEI,wJAEE,YAAA,CACA,qBAAA,CAGA,oBAAA,CAFA,QAAA,CACA,SAAA,CAJA,UAKN,CAEM,yMAEE,eAAA,CADA,UACR","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.container {\n  display: flex;\n  flex-direction: column;\n  padding: 16px 32px;\n\n  .main {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    \n    .list {\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      margin: 0;\n      padding: 0;\n      list-style-type: none;\n\n      .item {\n        width: 100%;\n        margin: 0 0 12px 0;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "------shared-components-Faqs-Faqs-module__container",
	"main": "------shared-components-Faqs-Faqs-module__main",
	"list": "------shared-components-Faqs-Faqs-module__list",
	"item": "------shared-components-Faqs-Faqs-module__item"
};
module.exports = ___CSS_LOADER_EXPORT___;
