import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';
import { userSelectors } from '@box/redux/user';

import { VerifyBadge } from '../Settings';

import { Modal } from '../../Modal';
import { UserInfo } from '../Settings/UserInfo';

export const Verification = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const isUserVerify = useAppSelector(userSelectors.isVerify);
  return (
    <Modal
      id='verification'
      className={classNames('settings', { settings_mobile: isMobile })}
      headExtraComponent={isUserVerify && <VerifyBadge />}
      title={t('settings.verification')}
    >
      <UserInfo />
    </Modal>
  );
};
