import React, { FC } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Collapsed } from '@box/shared/baseComponents';
import { appConfig } from '@box/shared/config';
import { FaqsItemProps } from './FaqsItem.types';

import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';

import styles from './FaqsItem.module.scss';

export const FaqsItem: FC<FaqsItemProps> = ({
  className,
  icon: Icon,
  title,
  text,
  image,
  link,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useToggle(false);

  return (
    <div className={classNames(styles.item, className)}>
      <button
        onClick={setOpen}
        type='button'
        className={classNames(styles.button, {
          [styles.button_active]: isOpen,
        })}
      >
        <div className={styles.column}>
          <Icon />
          {title && <p className={styles.title}>{title}</p>}
        </div>
        <div className={styles.circle}>
          <ArrowSvg />
        </div>
      </button>
      <Collapsed isOpen={isOpen}>
        <div className={styles.content}>
          {text &&
            text.map((item, index) => (
              <p
                key={index}
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: t(item, { company: appConfig.companyName }),
                }}
              />
            ))}
          {image && (
            <img className={styles.image} src={image.link} alt={image.alt} />
          )}
          {link && (
            <a href={link} className={styles.link}>
              {t('faqs.watch')}
            </a>
          )}
        </div>
      </Collapsed>
    </div>
  );
};
