import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { userSelectors } from '@box/redux/user';
import { currentGameSelectors, initDemoGame, initGame } from '@box/redux/games';
import { openModal } from '@box/redux/app';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppSelector, useAppDispatch } from '@box/redux/hooks';
import { fixGameId } from '@box/shared/utils';

import { useNavigateToDeposit } from '../useNavigateToDeposit';
import { useApp } from '../useApp';

type OpenGameProps = {
  id: number | string;
  hasDemo: boolean;
  withRedirect?: boolean;
  demo?: boolean;
};

type UseOpenGames = (returnUrl?: string) => {
  openGame: (args: OpenGameProps) => void;
  loadingId: string;
};

export const useOpenGames: UseOpenGames = (returnUrl) => {
  const dispatch = useAppDispatch();

  const {
    userEnv: { isJune },
  } = useApp();

  const isDemo = useAppSelector(userSelectors.isDemoGames);
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const isAlreadyLoading = useAppSelector(currentGameSelectors.loading);

  const { openDeposit } = useNavigateToDeposit();

  const loadingId: string = useMemo(() => uuidv4(), []);

  const openGame = ({
    id,
    hasDemo,
    withRedirect,
    demo,
  }: OpenGameProps): void => {
    if (isAlreadyLoading) return;

    if (isDemo || demo) {
      if (!hasDemo) {
        if (isJune) {
          if (isLogged) {
            openDeposit();
          } else {
            dispatch(openModal('register'));
          }
        } else {
          dispatch(openModal(isLogged ? 'deposit' : 'register'));
        }
      } else {
        dispatch(
          initDemoGame({
            gameId: fixGameId(id),
            loadingId,
            withRedirect,
            returnUrl,
          })
        );
      }
    } else {
      dispatch(
        initGame({
          gameId: fixGameId(id),
          loadingId,
          withRedirect,
          returnUrl,
        })
      );
    }
  };

  return {
    openGame,
    loadingId,
  };
};
