import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Hyphenated from 'react-hyphen';
import { hideModal } from '@box/redux/app';
import { balanceSelectors, userSelectors } from '@box/redux/user';
import {
  fetchPaymentMethods,
  paymentMethodsSelectors,
  paymentResetError,
  paymentSelectors,
  walletsSelectors,
} from '@box/redux/finance';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { validateEmail } from '@box/shared/utils';
import { DecemberInput, DecemberMaskedInput } from '@box/ui';
import { useApp } from '../../../../hooks';

import {
  Button,
  FormSelect,
  PlateWithCollapsed,
} from '../../../../baseComponents';
import { BlockWithLoading } from '../../../../baseComponents/BlockWithLoading/BlockWithLoading';
import { merchantIcons } from '../../../../utils/merchants';
import { appConfig } from '../../../../config';

import { ReactComponent as InstructionsIcon } from '../assets/instructions.svg';

import './currencyTabs.scss';

const CurrencyForm = ({
  fields,
  merchantId,
  submit,
  minDep,
  maxDep,
  paymentMethodId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { route } = useApp();

  const currentUserWallet = useAppSelector(walletsSelectors.current);
  const loading = useAppSelector(paymentSelectors.loading);
  const error = useAppSelector(paymentSelectors.error);
  const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
  const userId = useAppSelector(userSelectors.id);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const onSubmit = (data) => {
    const mainInfo = {
      amount: data.sum,
      merchantId,
      paymentMethodId,
      currencyCode: currentUserWallet.name,
    };

    delete data.sum;

    submit({ ...mainInfo, userId, fields: data });
  };

  const getCustomValidate = (rules) => (v) => {
    if (rules.email) {
      return validateEmail(v, 'fields.payment_rules.email_format');
    }

    return true;
  };

  const getRulesObj = (rules) =>
    Object.keys(rules).reduce(
      (acc, item) => ({
        ...acc,
        [item]: {
          value: rules[item],
          message: t(`fields.payment_rules.${item}`, { extra: rules[item] }),
        },
      }),
      {}
    );

  const fieldsArr = useMemo(
    () =>
      fields.map((item) => {
        if (item.type === 'select') {
          const options = Object.keys(item.options).map((key) => ({
            value: key,
            label: item.options[key],
          }));

          const onChange = (v, field) => field.onChange(v.value);

          return {
            ...item,
            options,
            onChange,
            label: item.label,
            rules: getRulesObj(item.rules),
            validate: getCustomValidate(item.rules),
          };
        }

        return item;
      }),
    [fields]
  );

  useEffect(() => {
    return () => {
      dispatch(paymentResetError());
    };
  }, []);

  const openInstruction = () => {
    navigate(route('politics.funds'));
    dispatch(hideModal('top-up'));
  };

  const defaults = useMemo(
    () =>
      Array(4)
        .fill(minDep)
        .map((item, idx) => (item * (idx + 1) <= maxDep ? item * (idx + 1) : 0))
        .filter(Boolean),
    [minDep, maxDep]
  );

  return (
    <form className='currency-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='currency-form__head'>
        <span className='currency-form__deposit'>
          {minDep}-{maxDep} <span>{currencySymbol}</span>{' '}
          {t('top_up.single_deposit')}
        </span>
        <span
          className='currency-form__instructions currency-form__instructions--top'
          onClick={openInstruction}
          aria-hidden='true'
        >
          <InstructionsIcon /> {t('top_up.instruction')}
        </span>
      </div>
      {error && <span className='currency-form__error'>{error}</span>}
      <div className='currency-form__fields'>
        <div className='currency-form__sum currency-form__fields-item'>
          <DecemberInput
            className='currency-form__sum-control'
            label={t('top_up.sum')}
            color='outlined'
            error={errors.sum.message}
            type='number'
            {...register('sum', {
              required: t('fields.required'),
              min: {
                value: minDep,
                message: t('fields.payment_rules.minSum', { sum: minDep }),
              },
              max: {
                value: maxDep,
                message: t('fields.payment_rules.maxSum', { sum: maxDep }),
              },
            })}
          />
          <span className='currency-form__sum-badge'>{currencySymbol}</span>
        </div>
        {fieldsArr?.map((item, idx) =>
          item.type === 'select' ? (
            <FormSelect
              className={classNames('currency-form__fields-item', {
                'currency-form__fields-item--hidden': item.hidden,
              })}
              control={control}
              defaultValue={item.default_value}
              {...item}
              error={!!errors[item.name]}
              errorMessage={t(errors[item.name]?.message)}
              key={idx}
            />
          ) : (
            <DecemberMaskedInput
              className={classNames('currency-form__fields-item', {
                'currency-form__fields-item--hidden': item.hidden,
              })}
              as='input'
              label={item.label}
              color='outlined'
              error={!!errors[item.name]}
              errorMessage={t(errors[item.name]?.message)}
              defaultValue={item.default_value}
              placeholder={item.placeholder}
              {...register(item.name, {
                ...getRulesObj(item.rules),
                validate: getCustomValidate(item.rules),
              })}
              type={item.type}
              mask={item.mask}
              key={idx}
            />
          )
        )}
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='currency-form__defaults'>
            {defaults?.map((item, index) => (
              <div
                className='currency-form__defaults-item'
                aria-hidden='true'
                onClick={() => setValue('sum', item)}
                key={index}
              >
                {item} <span>{currencySymbol}</span>
              </div>
            ))}
          </div>
        </div>
        <div className='col-sm-6'>
          <Button color='secondary' loading={loading} type='submit'>
            {t('common.submit')}
          </Button>
        </div>
      </div>

      <span
        className='currency-form__instructions'
        onClick={openInstruction}
        aria-hidden='true'
      >
        <InstructionsIcon /> {t('top_up.instruction')}
      </span>
    </form>
  );
};

const NoData = () => {
  const { t } = useTranslation();

  return <h2 className='currencies-no-data'>{t('common.no_data')}</h2>;
};

const CurrencyTabs = ({ currencies, onPay, withInstruction }) => {
  const dispatch = useAppDispatch();

  const isPaymentsInit = useAppSelector(paymentMethodsSelectors.isInit);

  useEffect(() => {
    if (!isPaymentsInit) {
      dispatch(fetchPaymentMethods());
    }
  }, []);

  const getIcon = (alias) => {
    const Component = merchantIcons[alias] || merchantIcons.defaultIcon;

    return <Component className='currency-item__icon' />;
  };

  return (
    <BlockWithLoading className='currencies-loader' loading={!isPaymentsInit}>
      {currencies.length ? (
        <PlateWithCollapsed.Content
          className={classNames('currencies', {
            'currencies--hide-instructions': !withInstruction,
          })}
          breakPoints={{ 700: 7, 650: 6, 575: 5, 475: 4, 400: 3 }}
          defaultPerRow={7}
        >
          {currencies.map((item, idx) => (
            <PlateWithCollapsed.Item key={idx}>
              <PlateWithCollapsed.Card
                className='currency-item'
                defaultActive={currencies.length === 1}
                data-label={appConfig.env === 'development' ? item.name : ''}
              >
                {getIcon(item.alias)}
                <span className='currency-item__name'>
                  <Hyphenated>{item.name}</Hyphenated>
                </span>
              </PlateWithCollapsed.Card>
              <PlateWithCollapsed.Collapse>
                <CurrencyForm
                  currencyName={item.name}
                  defaults={item.defaults}
                  fields={item.fields}
                  merchantId={item.merchant.id}
                  submit={onPay}
                  withInstruction={withInstruction}
                  minDep={item.limits.min}
                  maxDep={item.limits.max}
                  paymentMethodId={item.id}
                  key={idx}
                />
              </PlateWithCollapsed.Collapse>
            </PlateWithCollapsed.Item>
          ))}
        </PlateWithCollapsed.Content>
      ) : (
        <NoData />
      )}
    </BlockWithLoading>
  );
};

export default CurrencyTabs;
