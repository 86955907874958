import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: resetGamesLists } = actions;

export * from './thunks';
export * from './actions';
export * as gamesListsSelectors from './selectors';

export default reducer;
