// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-SupportChat-MessageButton-MessageButton-module__button-wrapper{margin:10px 0}.\\------shared-components-SupportChat-MessageButton-MessageButton-module__button-wrapper .\\------shared-components-SupportChat-MessageButton-MessageButton-module__button{margin-left:0}.\\------shared-components-SupportChat-MessageButton-MessageButton-module__button-wrapper .\\------shared-components-SupportChat-MessageButton-MessageButton-module__text{font-size:12px;margin-top:10px}.\\------shared-components-SupportChat-MessageButton-MessageButton-module__button-wrapper .\\------shared-components-SupportChat-MessageButton-MessageButton-module__text_june{color:#fff}", "",{"version":3,"sources":["webpack://./../../shared/components/SupportChat/MessageButton/MessageButton.module.scss"],"names":[],"mappings":"AACA,yFACE,aAAF,CAEE,0KACE,aAAJ,CAGE,wKAEE,cAAA,CADA,eAAJ,CAGI,6KACE,UADN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.button-wrapper {\n  margin: 10px 0;\n\n  .button {\n    margin-left: 0;\n  }\n\n  .text {\n    margin-top: 10px;\n    font-size: 12px;\n\n    &_june {\n      color: $color-white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-wrapper": "------shared-components-SupportChat-MessageButton-MessageButton-module__button-wrapper",
	"button": "------shared-components-SupportChat-MessageButton-MessageButton-module__button",
	"text": "------shared-components-SupportChat-MessageButton-MessageButton-module__text",
	"text_june": "------shared-components-SupportChat-MessageButton-MessageButton-module__text_june"
};
module.exports = ___CSS_LOADER_EXPORT___;
