import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { openErrorPopup, openInfoPopup } from '../app';

import { AppThunkApi } from '../types';
import { refetchRoles } from '../user';
import { getPureErrorMessage } from '../utils';
import {
  EmailResendReturn,
  EmailVerifyPayload,
  EmailVerifyReturn,
  POSTEmailResend,
  POSTEmailVerify,
} from './types';
import { useAuthTokens } from '../hooks';
import { authUser, setModalRegisterOpen } from '../authentication';

export const emailResend = createAsyncThunk<
  EmailResendReturn,
  void,
  AppThunkApi
>(
  'email/emailResend',
  async (_payload, { extra, rejectWithValue, dispatch }) => {
    const { api } = extra;
    try {
      const { data, status } = await api.post<POSTEmailResend>('email/resend');

      if (status === 200) {
        dispatch(
          openInfoPopup({
            text: i18next.t('settings.mail_was_sent'),
            type: 'success',
          })
        );
        return data;
      }

      dispatch(
        openErrorPopup({
          title: i18next.t('settings.email_resend'),
          text: getPureErrorMessage(data) || 'Error',
        })
      );

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      return rejectWithValue('error');
    }
  }
);

export const emailVerify = createAsyncThunk<
  EmailVerifyReturn,
  EmailVerifyPayload,
  AppThunkApi
>(
  'email/emailVerify',
  async (payload, { extra, rejectWithValue, dispatch }) => {
    const { api, history } = extra;

    const { user_id, hash } = payload;

    const { setAccessToken, setExpires, setRefreshToken } = useAuthTokens();

    try {
      const { data, status } = await api.post<POSTEmailVerify>(
        `email/verify/${user_id}/${hash}`
      );

      if (status === 200) {
        history.push('/');
        dispatch(
          openInfoPopup({
            text: i18next.t('settings.email_verified'),
            type: 'success',
          })
        );

        dispatch(refetchRoles());
        setAccessToken(data.data.access_token);
        setExpires(data.data.expires_in);
        setRefreshToken(data.data.refresh_token);
        dispatch(authUser());
        return data;
      }

      history.push('/');
      dispatch(setModalRegisterOpen(false));
      dispatch(
        openErrorPopup({
          title: i18next.t('user_actions.email_verify'),
          text: getPureErrorMessage(data) || 'Error',
        })
      );

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      return rejectWithValue('error');
    }
  }
);
