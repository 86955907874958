import React, { useMemo, FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { userBonusesSelectors, initUserBonuses } from '@box/redux/user';
import { sortUserBonuses } from '@box/redux/utils';
import { BlockWithLoading } from '@box/shared/baseComponents';

import { useBonusesListState } from './useBonusesListState';

import { Props } from './BonusesContent.types';

export const withBonusState = (Component: FC<Props>) => {
  const wrapper = ({ ...props }) => {
    const [params] = useSearchParams();
    const bonusParam = params.get('bonus');
    const activeBonusParam = params.get('active-bonus');
    const bonusFilter = params.get('bonuses-filter');
    const { list, loading, userBonusesLoading } = useBonusesListState();

    const dispatch = useAppDispatch();

    const userBonuses = useAppSelector(userBonusesSelectors.list);

    const isLogged = useAppSelector(userInitSelectors.isLogged);
    const isLoading = useAppSelector(userBonusesSelectors.loading);

    const currentBonus = useMemo(() => {
      if (list) {
        return list.find((item) => item.id === Number(bonusParam));
      }

      return false;
    }, [list, bonusParam]);

    const currentActiveBonus = useMemo(() => {
      if (userBonuses) {
        return userBonuses.find(
          (item) => item.bonus_id === Number(activeBonusParam)
        );
      }
      return false;
    }, [userBonuses, activeBonusParam]);

    const ended = useAppSelector((state) =>
      userBonusesSelectors.filteredByStatuses(state, 'ended')
    );

    const created = useAppSelector((state) =>
      userBonusesSelectors.filteredByStatuses(state, 'created')
    );

    const activated = useAppSelector((state) =>
      userBonusesSelectors.filteredByStatuses(state, 'activated')
    );

    useEffect(() => {
      dispatch(initUserBonuses());
    }, []);

    if (isLoading) {
      return <BlockWithLoading />;
    }

    return (
      <Component
        activeBonusParam={activeBonusParam}
        bonusFilter={bonusFilter}
        bonusParam={bonusParam}
        loading={loading}
        isLogged={isLogged}
        userBonusesLoading={userBonusesLoading}
        currentActiveBonus={currentActiveBonus}
        ended={sortUserBonuses(ended)}
        created={sortUserBonuses(created)}
        activated={sortUserBonuses(activated)}
        currentBonus={currentBonus}
        list={list}
        {...props}
      />
    );
  };

  return wrapper;
};
