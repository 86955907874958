import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Text.types';

import styles from './text.module.scss';

export const Text: FC<Props> = ({ className, text, isShadow, isBold }) => {
  return (
    <p
      className={classNames(
        styles.text,
        { [styles.text_shadow]: isShadow, [styles.text_bold]: isBold },
        className
      )}
    >
      {text}
    </p>
  );
};
