import { createSlice } from '@reduxjs/toolkit';
import { closeImageViewer, openImageViewer } from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  isOpen: false,
  image: '',
};

export const slice = createSlice({
  name: 'app/imageViewer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openImageViewer, (state, { payload }) => {
        state.isOpen = true;
        state.image = payload;
      })
      .addCase(closeImageViewer, (state) => {
        state.isOpen = false;
        state.image = '';
      });
  },
});
