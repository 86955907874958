export const statusesList = [
  {
    level: 'II',
    name: 'Джекейрис Веларион',
    rating: 200,
    rate: '600 VC = $1',
    wager: 'x60',
    icon: '1',
  },
  {
    level: 'III',
    name: 'Эймонд Таргариен',
    rating: 1000,
    rate: '540 VC = $1',
    wager: 'x60',
    icon: '2',
  },
  {
    level: 'IV',
    name: 'Эйгон Таргариен',
    rating: 2000,
    rate: '480 VC = $1',
    wager: 'x60',
    icon: '3',
  },
  {
    level: 'V',
    name: 'Визерис Таргариен',
    rating: 3500,
    rate: '400 VC = $1',
    wager: 'x60',
    icon: '4',
  },
];
