// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Footer-Warning-Warning-module__warning{justify-content:center;margin:12px 0}.\\------shared-components-Footer-Warning-Warning-module__warning,.\\------shared-components-Footer-Warning-Warning-module__warning .\\------shared-components-Footer-Warning-Warning-module__row{align-items:center;display:flex}.\\------shared-components-Footer-Warning-Warning-module__warning .\\------shared-components-Footer-Warning-Warning-module__row span{color:#756885;font-size:12px;text-align:left}.\\------shared-components-Footer-Warning-Warning-module__warning .\\------shared-components-Footer-Warning-Warning-module__row+.\\------shared-components-Footer-Warning-Warning-module__row{margin-left:12px}.\\------shared-components-Footer-Warning-Warning-module__warning .\\------shared-components-Footer-Warning-Warning-module__row>:not(:first-child){margin-left:5px}", "",{"version":3,"sources":["webpack://./../../shared/components/Footer/Warning/Warning.module.scss"],"names":[],"mappings":"AACA,iEAGE,sBAAA,CACA,aAAF,CAEE,+LAJA,kBAAA,CADA,YAOF,CAEI,mIAEE,aAAA,CADA,cAAA,CAEA,eAAN,CAGI,2LACE,gBADN,CAKM,iJACE,eAHR","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.warning {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 12px 0;\n\n  .row {\n    display: flex;\n    align-items: center;\n\n    span {\n      font-size: 12px;\n      color: #756885;\n      text-align: left;\n    }\n\n    & + .row {\n      margin-left: 12px;\n    }\n\n    & > * {\n      &:not(:first-child) {\n        margin-left: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "------shared-components-Footer-Warning-Warning-module__warning",
	"row": "------shared-components-Footer-Warning-Warning-module__row"
};
module.exports = ___CSS_LOADER_EXPORT___;
