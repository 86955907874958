import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Text } from '@box/ui';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import { useApp } from '@box/shared/hooks';

import { userSelectors } from '@box/redux/user';
import { Inputs } from './PersonalData.types';
import styles from './personalData.module.scss';
import { NamesFields } from './Fields/Names';
import { CountryField } from './Fields/Country';
import { CityField } from './Fields/City';
import { BirthdateField } from './Fields/Birthdate';
import { AddressField } from './Fields/Address';
import { PhoneField } from './Fields/Phone';
import { EmailField } from './Fields/Email';
import { GenderField } from './Fields/Gender';
import { DocumentFields } from './Document';
import { usePersonalData } from './PersonalData.hook';

export const PersonalDataForm = memo(() => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const disabled = useAppSelector(selectIsDisabled);
  const isVerify = useAppSelector(userSelectors.isVerify);
  const isVerifyPending = useAppSelector(userSelectors.isPendingVerification);

  const hideDocs = isVerifyPending || isVerify;

  const { handleSubmit } = useFormContext<Inputs>();
  const onSubmit = usePersonalData(setIsUploadOpen);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={classNames({ [styles.form_desktop]: !isMobile })}>
        <Text
          size={isMobile ? 'lg' : 'xxl'}
          as='span'
          color='violet'
          weight='800'
        >
          {t('user_actions.personal_data')}
        </Text>
        <div className={styles.plate}>
          <NamesFields />
          <BirthdateField />
          <GenderField />
        </div>
        <div className={styles.location}>
          <CountryField />
          <CityField />
          <AddressField />
        </div>
        <div className={styles.social}>
          <PhoneField />
          <EmailField />
        </div>
      </div>
      {!hideDocs && (
        <DocumentFields
          isUploadOpen={isUploadOpen}
          setIsUploadOpen={setIsUploadOpen}
        />
      )}
      {disabled && (
        <p className={styles.disabled}>{t('settings.disabled_text')}</p>
      )}
    </form>
  );
});
