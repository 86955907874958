import React, { useCallback, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'debounce';

import {
  currentGameSelectors,
  favoritesSelectors,
  removeFavorite,
  removeFavoriteRequest,
  setFavorite,
  setFavoriteRequest,
} from '@box/redux/games';
import { userInitSelectors } from '@box/redux/authentication';
import { useApp } from '@box/shared/hooks';
import { getImageUrl } from '@box/shared/utils';

import { getStoragePath } from '../../utils/getStoragePath';
import { useOpenGames } from '../../hooks/useOpenGames';

export const withState = (Component) => {
  const wrapper = ({ ...props }) => {
    const {
      id,
      hasDemo,
      withFavorite,
      disabled,
      thumbnail,
      isJackpot,
      isProvider,
      externalId,
    } = props;

    const {
      userEnv: { isJune, isDesktop },
    } = useApp();

    const { openGame, loadingId } = useOpenGames();
    const loading = useSelector(currentGameSelectors.loading);
    const loadedId = useSelector(currentGameSelectors.loadedId);
    const isLogged = useSelector(userInitSelectors.isLogged);

    const handleClick = () => openGame({ id, hasDemo, withRedirect: true });

    const handleDemoClick = () =>
      openGame({ id, hasDemo, withRedirect: true, demo: true });

    const type = () => {
      if (isDesktop) {
        if (isJackpot) {
          return 'jackpotDesktop';
        }
        return 'defaultDesktop';
      } else {
        if (isProvider) {
          return 'providerMobile';
        }
        return 'defaultMobile';
      }
    };

    return (
      <Component
        {...props}
        thumbnail={
          isJune
            ? getImageUrl(externalId, type())
            : getStoragePath(thumbnail, 'main')
        }
        loading={loading && loadingId === loadedId}
        disabled={disabled || loading}
        showFavorite={isLogged && withFavorite}
        handleClick={handleClick}
        handleDemoClick={handleDemoClick}
      />
    );
  };

  return wrapper;
};

export const withFavorite = (Component) => {
  const wrapper = ({ ...props }) => {
    const { id, thumbnail, name } = props;

    const dispatch = useDispatch();
    const favorites = useSelector(favoritesSelectors.list);
    const loading = useSelector(favoritesSelectors.loading);
    const isFavoriteInitialRef = useRef(false);

    useEffect(() => {
      isFavoriteInitialRef.current = !!favorites.find((item) => item.id === id);
    }, [loading]);

    const isFavorite = !!favorites.find((item) => item.id === id);

    const debounceChangeFavorite = useCallback(
      debounce((favorite, gameId) => {
        const isFavoriteInitial = isFavoriteInitialRef.current;

        if (isFavoriteInitial !== favorite) {
          if (favorite) {
            dispatch(setFavoriteRequest({ gameId }));
            isFavoriteInitialRef.current = true;
          } else {
            dispatch(removeFavoriteRequest({ gameId }));
            isFavoriteInitialRef.current = false;
          }
        }
      }, 500),
      []
    );

    const onSetFavorites = () => {
      if (!isFavorite) {
        dispatch(setFavorite({ thumbnail, name, id }));
      } else {
        dispatch(removeFavorite(id));
      }

      debounceChangeFavorite(!isFavorite, id);
    };

    return (
      <Component
        {...props}
        isFavorite={isFavorite}
        onSetFavorites={onSetFavorites}
      />
    );
  };

  return wrapper;
};
