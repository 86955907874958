import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Props } from './DefaultButton.types';

import styles from './defaultButton.module.scss';

export const DefaultButton: FC<Props> = ({
  className,
  onClick,
  percent,
  active,
  item,
  currencySymbol,
  defaultValue,
  isActive,
  minBonuses,
}) => {
  const handleClick = () => {
    onClick(item);
  };

  const { t } = useTranslation();

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        styles.item,
        {
          // Фикс бага с undefined default amount.
          // До активации кнопок активность определяется полем defaultValue,
          // после смотрит на поле active
          // Активация лежит в пропсе onClick
          [styles.item_active]: isActive
            ? active === item
            : defaultValue === item,
        },
        className
      )}
    >
      <span className={styles.value}>
        {item} {currencySymbol}
      </span>
      {percent && minBonuses <= item && (
        <span className={styles.bonus}>
          {t('bonuses.bonus')} {percent}%
        </span>
      )}
    </button>
  );
};
