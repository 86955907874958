import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { LightButton } from '@box/ui';
import { ratesSelectors } from '@box/redux/partner/rates';
import { userInitSelectors } from '@box/redux/authentication';
import { useApp } from '@box/shared/hooks';

import styles from './referralJune.module.scss';
import { ReactComponent as IconSvg } from './assets/images/icon.svg';

export const ReferralJune: FC = () => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const currentRate = useAppSelector(ratesSelectors.currentRate);
  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const earn = useMemo(() => {
    if (currentRate) {
      return `${currentRate?.rate || ''} ${currentRate?.currency || ''} `;
    }
    return '';
  }, [currentRate]);

  const handleClick = () => {
    if (!isLogged) {
      dispatch(openModal('login'));
    } else {
      dispatch(openModal('referral'));
    }
  };

  return (
    <div
      className={classNames(styles.referral, {
        [styles.referral_desktop]: isDesktop,
        [styles.referral_show]: earn,
      })}
    >
      <IconSvg />
      {earn && (
        <p className={styles.text}>
          {t('referral.earn.refer_friend')}: {t('referral.earn.earn')} {earn}
          {t('referral.earn.invite_friend')}
        </p>
      )}
      <LightButton
        color='white'
        className={styles.button}
        onClick={handleClick}
      >
        {t('referral.refer')}
      </LightButton>
    </div>
  );
};
