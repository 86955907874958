// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__buttons{width:100%}.\\------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__buttons .\\------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__container{background:#f1f3f6;border-radius:8px;box-shadow:inset 0 4px 4px #3f3f4914;display:grid;gap:2px;grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(2,108px);padding:2px;width:100%}.\\------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__buttons_mobile .\\------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__container{grid-template-rows:repeat(2,56px)}", "",{"version":3,"sources":["webpack://./../../shared/components/Deposit/Components/DefaultsButtons/defaultsButtons.module.scss"],"names":[],"mappings":"AACA,6FACE,UAAF,CAEE,4LAME,kBAAA,CAEA,iBAAA,CADA,oCAAA,CANA,YAAA,CAGA,OAAA,CAFA,mCAAA,CACA,kCAAA,CAMA,WAAA,CAJA,UAIJ,CAII,mMACE,iCAFN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.buttons {\n  width: 100%;\n\n  .container {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(2, 108px);\n    gap: 2px;\n    width: 100%;\n    background: #f1f3f6;\n    box-shadow: inset 0px 4px 4px rgba(63, 63, 73, 0.08);\n    border-radius: 8px;\n    padding: 2px;\n  }\n\n  &_mobile {\n    .container {\n      grid-template-rows: repeat(2, 56px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__buttons",
	"container": "------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__container",
	"buttons_mobile": "------shared-components-Deposit-Components-DefaultsButtons-defaultsButtons-module__buttons_mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
