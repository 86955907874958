import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { InnerModal, Button } from '@box/ui';
import { useAppDispatch } from '@box/redux/hooks';
import { addUserBonus } from '@box/redux/user';
import { useApp } from '@box/shared/hooks';

import { Main } from './Main';
import { Steps } from './Steps';

import { Props } from './InstructionModal.types';

import styles from './instructionModal.module.scss';

export const DecemberInstructionModal: FC<Props> = ({ className, bonusId }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const [params, setParams] = useSearchParams();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleClose = () => {
    params.delete('bonus');
    params.delete('instruction');
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleBackClick = () => {
    params.delete('instruction');
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleClick = () => {
    dispatch(addUserBonus({ bonusId }));
  };

  return (
    <InnerModal
      back='BACK TO BONUS'
      onClose={handleClose}
      onBackClick={handleBackClick}
      className={className}
    >
      <div className={styles.container}>
        <Main onClick={handleClick} />
        <Steps />
        <Button
          color='secondary'
          type='button'
          size='x-large'
          bold
          onClick={handleClick}
          className={classNames(styles.button, {
            [styles.button_mobile]: isMobile,
          })}
        >
          {t('bonuses.activate')}
        </Button>
      </div>
    </InnerModal>
  );
};
