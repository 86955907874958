import React from 'react';
import classNames from 'classnames';

import './merchantTabs.scss';

export const MerchantTabs = ({ list, activeTab, className }) => {
  return (
    <div className={classNames('merchant-tabs', className)}>
      {list.map((item) => {
        const Icon = item.icon;

        return (
          <div
            className={classNames('merchant-tabs__item', {
              [`merchant-tabs__item--active`]: activeTab === item.id,
            })}
            onClick={item.onClick}
            aria-hidden='true'
            key={item.id}
          >
            {Icon && <Icon className='merchant-tabs__item-icon' />}
            <span className='merchant-tabs__item-name'>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
};
