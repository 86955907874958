/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useApp } from '@box/shared/hooks';
import { documentsSelectors, initDocuments } from '@box/redux/user';

import {
  BlockWithLoading,
  Button,
  Collapsed,
  FormSelect,
} from '../../../../baseComponents';
import { useDisabled } from '../UserInfo';
import { Photos } from './Photos';
import { Fields } from './Fields';

import './documents.scss';

export const Documents = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useDispatch();

  const init = useSelector(documentsSelectors.init);
  const types = useSelector(documentsSelectors.types);
  const currentDocument = useSelector(documentsSelectors.current);

  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const { disabled } = useDisabled();

  const {
    control,
    formState: { errors, isSubmitted },
    setValue,
    watch,
  } = useFormContext();

  const documentType = watch('document_type');

  const openUpload = () => setIsUploadOpen(true);

  useEffect(() => {
    if (!init) {
      dispatch(initDocuments());
    } else if (!isEmpty(currentDocument)) {
      setValue('document_type', currentDocument.type.alias);
      setValue('scan', currentDocument.scan);
      setValue('photo', currentDocument.photo);

      forIn(currentDocument.fields_values, (value, key) => {
        setValue(`document__${key}`, value);
      });

      openUpload();
    }
  }, [init]);

  const typesOptions = useMemo(
    () =>
      types.map((item) => ({
        label: get(item, 'name'),
        value: get(item, 'alias'),
      })),
    [types]
  );

  const selectedType = useMemo(
    () => types.find((item) => item.alias === documentType) || {},
    [types, documentType]
  );

  return (
    <div className='documents'>
      <BlockWithLoading className='documents__loader' loading={!init}>
        <FormSelect
          name='document_type'
          error={!!get(errors, 'document_type')}
          errorMessage={t(errors?.document_type?.message)}
          control={control}
          options={typesOptions}
          onChange={(v, field) => {
            field.onChange(v.value);
          }}
          label={t('settings.document_type')}
          placeholder={`${t('settings.select')}...`}
          disabled={disabled}
          rules={{ required: t('fields.required') }}
        />
        <Collapsed className='documents__step' isOpen={!!documentType}>
          <Fields fields={selectedType.fields} />
          {!isUploadOpen && !isSubmitted && (
            <div className='documents-button'>
              <Button
                className={classNames('settings__button', {
                  settings__button_mobile: isMobile,
                })}
                color='secondary'
                hoverType='opacity'
                onClick={openUpload}
                type='button'
              >
                {t('settings.open_upload')}
              </Button>
            </div>
          )}
        </Collapsed>
        <Collapsed
          className='documents__step'
          isOpen={isUploadOpen || (isSubmitted && !!documentType)}
        >
          <Photos />
        </Collapsed>
      </BlockWithLoading>
    </div>
  );
};
