import React, { FC } from 'react';
import classNames from 'classnames';
import { merchantIcons } from '@box/shared/utils/merchants';
import { getStoragePath } from '@box/shared/utils';
import { useApp } from '@box/shared/hooks';

import { Payment } from '../../../Payments';

import { Props } from './Payments.types';

import styles from './payments.module.scss';

export const Payments: FC<Props> = ({
  payments,
  selectedMethod,
  setSelectedMethod,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const getIcon = (item) => {
    if (item?.logo) {
      return <img alt={item.alias} src={getStoragePath(item.logo, 'main')} />;
    }
    const Component = merchantIcons.defaultIcon;
    return <Component />;
  };

  const handleClick = (item) => {
    setSelectedMethod(item);
  };

  return (
    <div
      className={classNames(styles.tabs, { [styles.tabs_mobile]: isMobile })}
    >
      {payments.map((item, idx) => (
        <Payment
          onClick={handleClick}
          item={item}
          selectedItem={selectedMethod}
          key={idx}
          label={item.name}
          isWithdrawal
        >
          {getIcon(item)}
        </Payment>
      ))}
    </div>
  );
};
