import React from 'react';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';

import { faqsSelectList } from './FaqsSearch.data';

import './FaqsSearch.scss';
import { FaqsInput } from './FaqsInput';
import { HandleInputChange, HandleSelectChange } from './FaqsSearch.types';

export const FaqsSearch = () => {
  const [params, setParams] = useSearchParams();

  const paramValue = params.get('faqs');

  const inputParamValue = params.get('faqsSearch');

  const handleSetParams = () => {
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleSelectChange: HandleSelectChange = (v) => {
    if (paramValue === v.value) {
      params.delete('faqs');
    } else {
      params.append('faqs', v.value);
    }

    handleSetParams();
  };

  const handleInputChange: HandleInputChange = (event) => {
    if (event.target.value === '') {
      params.delete('faqsSearch');
    } else {
      params.append('faqsSearch', event.target.value.toLowerCase());
    }

    handleSetParams();
  };
  return (
    <div className='faqs-search'>
      <FaqsInput
        value={inputParamValue}
        placeholder='Find'
        onChange={handleInputChange}
      />
      <Select
        options={faqsSelectList}
        onChange={handleSelectChange}
        placeholder='Payment Methods'
        className='faqs-select'
        classNamePrefix='faqs-select'
        value={
          paramValue
            ? faqsSelectList.find((item) => item.value === paramValue)
            : null
        }
      />
    </div>
  );
};
