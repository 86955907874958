import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './SmallLoader.types';

import styles from './smallLoader.module.scss';

export const SmallLoader: FC<Props> = ({ className, isActive, isLight }) => {
  return (
    <div
      className={classNames(styles.loader, className, {
        [styles.loader_active]: isActive,
        [styles.loader_light]: isLight,
      })}
    >
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};
