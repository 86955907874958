import get from 'lodash/get';

export const getOptions = (list, label, value) => {
  return list?.map((item) => ({
    label: get(item, label),
    value: get(item, value),
  }));
};

export const createCurrenciesOptions = (currencies) => {
  const priorityCurrencies = ['USD', 'BRL', 'INR', 'EUR', 'GBP'];

  const sortedCurrencies = [...currencies].sort((a, b) => {
    if (
      priorityCurrencies.includes(a.code) &&
      !priorityCurrencies.includes(b.code)
    ) {
      return -1;
    }
    if (
      !priorityCurrencies.includes(a.code) &&
      priorityCurrencies.includes(b.code)
    ) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  return sortedCurrencies.map((item) => ({
    label: `${item.name} (${item.code})`,
    value: item.id,
  }));
};

export const createCountriesCodeOptions = (countries) => {
  return countries.map((item) => ({
    label: item.code,
    value: item.id,
  }));
};

export const createCountriesPhoneOptions = (countries) => {
  return (
    countries?.map((item) => ({
      label: item.code,
      value: { id: item.id, code: item.phone_code },
    })) || []
  );
};

export const getCurrencyIdByCode = (list, code) =>
  list.find((item) => item.code === code)?.id;

export const getCountryCodeById = (list, id) =>
  list.find((item) => item.id === id)?.code;

export const getCountryIdByICode = (list, code) =>
  list.find((item) => item.code === code)?.id;

export const getPhoneCodeById = (list, id) =>
  list.find((item) => item.id === id)?.phone_code;
