import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import styles from './Text.module.scss';
import { Props } from './Text.types';

const Element = ({ children, as, ...props }) =>
  React.createElement(as, props, children);

export const Text: FC<Props> = ({
  children,
  className,
  color = 'white',
  size = 'md',
  style,
  weight = 'normal',
  align,
  as = 'span',
  ...props
}) => {
  const elementStyle: CSSProperties =
    typeof size === 'number'
      ? {
          ...style,
          fontSize: size,
        }
      : style;

  return (
    <Element
      as={as}
      className={classNames(
        styles.text,
        className,
        styles[`text--size-${size}`],
        styles[`text--color-${color}`],
        {
          [styles[`color--${size}`]]: typeof size === 'number',
        }
      )}
      style={{ ...elementStyle, textAlign: align, fontWeight: weight }}
      {...props}
    >
      {children}
    </Element>
  );
};
