import React, { useState } from 'react';
import { Input, DecemberInput, BaseInputPorps } from '../Base';
import { ReactComponent as HideIcon } from './assets/hide.svg';
import { ReactComponent as ShowIcon } from './assets/show.svg';

export type PasswordProps = Omit<BaseInputPorps, 'icons'>;

export const withPassword = (
  Component: React.ForwardRefExoticComponent<
    BaseInputPorps & React.RefAttributes<HTMLInputElement>
  >
) =>
  React.forwardRef<HTMLInputElement, PasswordProps>(
    (props: PasswordProps, ref) => {
      const [visible, setVisible] = useState(false);

      const inputProps = {
        ...props,
        type: visible ? 'text' : 'password',
      };

      const Icon = visible ? ShowIcon : HideIcon;

      const handleChangeVisible = () => setVisible((prev) => !prev);

      return (
        <Component
          icons={{ endIcon: <Icon onClick={handleChangeVisible} /> }}
          ref={ref}
          {...inputProps}
        />
      );
    }
  );

export const Password = withPassword(Input);
export const DecemberPassword = withPassword(DecemberInput);
