import { useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { fetchRepeatVisit } from '@box/redux/app';
import { useAppDispatch } from '@box/redux/hooks';

export const useRepeatVisit = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const repeatParam = params.get('repeat_visit');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (repeatParam === '1') {
      dispatch(fetchRepeatVisit());
      navigate(location.pathname, { replace: true });
    }
  }, [repeatParam]);
};
