import { useState, useRef, useEffect, useCallback } from 'react';

interface Props {
  hideWhenClickOutside?: boolean;
  onClose?: () => void;
}

export const useToggleActiveElement = <ElementRefType = HTMLDivElement>({
  hideWhenClickOutside,
  onClose,
}: Props) => {
  const elementRef = useRef<ElementRefType>(null);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen((prev) => !prev);

  const closeIfClickOutside = useCallback(
    (e) => {
      if (
        isOpen &&
        hideWhenClickOutside &&
        !e.composedPath().includes(elementRef.current)
      ) {
        close();

        if (onClose) {
          onClose();
        }
      }
    },
    [isOpen]
  );

  // Скрытие элемента по клику на область вне этого элемента
  useEffect(() => {
    if (hideWhenClickOutside) {
      window.addEventListener('click', closeIfClickOutside);
    }

    return () => {
      window.removeEventListener('click', closeIfClickOutside);
    };
  }, [isOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
    elementRef,
    setIsOpen,
  };
};
