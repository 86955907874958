import React, { useState, useEffect, FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import styles from './countdown.module.scss';

import { Props } from './Countdown.types';

export const Countdown: FC<Props> = ({ date, color = 'yellow', className }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = date - now;

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);
    return () => clearInterval(interval);
  }, [date]);

  return (
    <div
      className={classNames(
        styles.time,
        styles[`time_color_${color}`],
        { [styles.time_mobile]: isMobile },
        className
      )}
    >
      <div className={styles.item}>
        {countdown.days} <span>d</span>
      </div>
      <div className={styles.item}>
        {countdown.hours} <span>h</span>
      </div>
      <div className={styles.item}>
        {countdown.minutes} <span>m</span>
      </div>
      <div className={styles.item}>
        {countdown.seconds} <span>s</span>
      </div>
    </div>
  );
};
