import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@box/redux/hooks';
import { userInitSelectors } from '@box/redux/authentication';
import { useApp } from '@box/shared/hooks';

import { NavigationButton } from './NavigationButton';

import { eventsBonusesList } from './BonusesFilter.data';

import styles from './bonusesFilter.module.scss';

export const BonusesFilter: FC = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (!isLogged) {
      params.delete('bonuses-filter');
      setParams({
        ...Object.fromEntries(new URLSearchParams(params)),
      });
    }
  }, [isLogged]);

  return (
    <div
      className={classNames(styles.filter, {
        [styles.filter_mobile]: isMobile,
      })}
    >
      <div className={styles.container}>
        {eventsBonusesList.map((item, index) => {
          return (
            <NavigationButton
              disabled={!isLogged && index === 1}
              className={styles.button}
              key={index}
              isBase={index === 0}
              label={t(item.label)}
              icon={item.icon}
              searchParams='bonuses-filter'
              value={item.value}
            />
          );
        })}
      </div>
    </div>
  );
};
