import React, { FC } from 'react';
import classNames from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ImageSet } from '@box/ui';

import { useApp } from '@box/shared/hooks';
import { useCalculation } from './useCalculation';

import { Props } from './Avatar.types';

import styles from './avatar.module.scss';
import AvatarPng from './assets/images/avatar.png';
import AvatarAvif from './assets/images/avatar.webp';
import AvatarWebp from './assets/images/avatar.avif';
import { ReactComponent as LineSvg } from './assets/images/line.svg';

export const Avatar: FC<Props> = ({ value, className }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const { calculatedRotation } = useCalculation(value, isMobile);

  return (
    <div
      className={classNames(
        styles.avatar,
        {
          [styles.avatar_mobile]: isMobile,
        },
        className
      )}
    >
      <div className={styles.image}>
        <ImageSet
          src={AvatarPng}
          srcWebp={AvatarWebp}
          srcAvif={AvatarAvif}
          alt='Avatar'
        />
      </div>
      <LineSvg className={styles.line} />
      <div className={styles.decor} />
      <div
        style={{
          transform: `translate(-50%, -50%) rotate(${
            isMobile ? 198 + calculatedRotation : 68 - calculatedRotation
          }deg)`,
        }}
        className={classNames(styles.dot, {
          [styles.dot_hidden]: value > 97 || value < 3,
        })}
      >
        <div
          style={{
            transform: `rotate(${
              isMobile ? -198 - calculatedRotation : -68 + calculatedRotation
            }deg)`,
          }}
          className={styles.dotElement}
        >
          <div className={styles.percent}>{value}%</div>
        </div>
      </div>
      <div className={classNames(styles.decor, styles.decor_second)} />
      <div className={styles.circle}>
        <CircularProgressbar
          value={value}
          text={`${30}%`}
          strokeWidth={4.5}
          circleRatio={isMobile ? 0.4 : -0.38}
          styles={buildStyles({
            trailColor: '#302FA0',
            textColor: 'none',
            pathColor: '#FDA700',
          })}
        />
      </div>
    </div>
  );
};
