import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from '@box/redux/user';
import { userInitSelectors } from '@box/redux/authentication';

import { Modal } from '../../Modal/Modal';
import Tabs from '../../Tabs/Tabs';

import './detalization.scss';
import {
  DetailParams,
  PaginateDetalizationList,
  WithdrawalList,
} from './DetalizationList';

const listsMeta = {
  'all--completed': {
    confirmed: true,
    sortby: 'created_at',
    sort: 'desc',
    type: 'deposit',
  },
  'all--waiting': {
    confirmed: false,
    sortby: 'created_at',
    sort: 'desc',
    type: 'deposit',
  },
  deposit: {
    type: 'deposit',
    sortby: 'created_at',
    sort: 'desc',
  },
};

const withdrawalMeta = {
  'all--completed': {
    type: 'approved',
    sort: 'desc',
    sortby: 'created_at',
    id: 'withdrawal-completed',
  },
  'all--waiting': {
    type: 'pending',
    sort: 'desc',
    sortby: 'created_at',
    id: 'withdrawal-waiting',
  },
};

export const DetalizationModal = () => {
  const { t } = useTranslation();

  const isLogged = useSelector(userInitSelectors.isLogged);
  const userId = useSelector(userSelectors.id);

  const AllTab = React.memo(() => (
    <>
      <DetailParams
        listId='all--completed'
        title={t('detalization.completed')}
        showItems={4}
        userId={userId}
        requestMeta={listsMeta['all--completed']}
        withdrawalRequestMeta={withdrawalMeta['all--completed']}
      />
      <DetailParams
        listId='all--waiting'
        title={t('detalization.waiting')}
        showItems={4}
        userId={userId}
        requestMeta={listsMeta['all--waiting']}
        withdrawalRequestMeta={withdrawalMeta['all--waiting']}
      />
    </>
  ));

  const DepositsTab = React.memo(() => (
    <PaginateDetalizationList
      listId='deposit'
      userId={userId}
      requestMeta={listsMeta.deposit}
    />
  ));

  const WithdrawalsTab = React.memo(() => (
    <WithdrawalList listId='withdrawal' userId={userId} />
  ));

  const tabs = [
    {
      title: t('detalization.all'),
      component: AllTab,
    },
    {
      title: t('detalization.deposits'),
      component: DepositsTab,
    },
    {
      title: t('detalization.withdrawals'),
      component: WithdrawalsTab,
    },
  ];

  return (
    <Modal className='detalization' id='detalization' title='detalization'>
      {isLogged && <Tabs tabs={tabs} />}
    </Modal>
  );
};
