import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Collapsed } from '@box/shared/baseComponents';
import { Text } from '@box/ui';
import { useApp } from '@box/shared/hooks';
import { Footer } from '../Footer';

import { Message, FlexibleFields, Amount } from '../../../Payments';
import { DefaultsButtons } from '../DefaultsButtons';
import { Card } from '../Card';
import { BonusJune } from '../BonusJune';

import { Props } from './Form.types';

import styles from './form.module.scss';

export const Form: FC<Props> = ({
  onSubmit,
  isOpen,
  defaultsEnabled,
  bonuses,
  currencySymbol,
  amount,
  submitLoading,
  fields,
  isBeatifyCardForm,
  requestError,
  qrOpen,
  isModal,
  qr,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const methods = useFormContext();

  const { handleSubmit, watch } = methods;

  const { t } = useTranslation();

  const watchAmount = watch('amount');

  const valueBonuses = useMemo(() => {
    if (watchAmount !== undefined) {
      return watchAmount;
    }
    return bonuses?.defaults ? bonuses.defaults[1] : null;
  }, [bonuses, watchAmount]);

  return (
    <Collapsed isOpen={isOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames(styles.form, {
          [styles.form_mobile]: isMobile,
          [styles.form_modal]: isModal,
        })}
      >
        <div className={styles.form_data}>
          <div className={styles.form_data_bonuses}>
            {defaultsEnabled ? (
              <>
                <Text
                  size={isMobile ? 'lg' : 'xxl'}
                  color='violet'
                  weight={800}
                  as='span'
                >
                  {t('deposit.choose_bonus')}
                </Text>
                <div className={styles.form_bonuses}>
                  <DefaultsButtons
                    percent={bonuses.percent}
                    minDepSum={bonuses.minDepSum}
                    defaults={bonuses.defaults}
                    currencySymbol={currencySymbol}
                    defaultValue={
                      bonuses?.defaults ? bonuses.defaults[1] : null
                    }
                  />
                </div>
              </>
            ) : (
              <Text
                size={isMobile ? 'lg' : 'xxl'}
                color='violet'
                weight={800}
                as='span'
              >
                {t('deposit.no_bonuses')}
              </Text>
            )}
          </div>
          <div className={styles.form_data_deposit}>
            <Text
              size={isMobile ? 'lg' : 'xxl'}
              color='violet'
              weight={800}
              as='span'
            >
              {t('deposit.amount')}
            </Text>
            <div
              className={classNames(styles.column, {
                [styles.column_row]: String(watchAmount).length > 4,
              })}
            >
              <div
                className={classNames(styles.column_inner, {
                  [styles['column_inner-double']]:
                    valueBonuses < Number(bonuses.minDepSum) ||
                    !valueBonuses ||
                    String(valueBonuses).length > 4,
                })}
              >
                <Amount
                  className={styles.amount}
                  amount={amount}
                  currencySymbol={currencySymbol}
                />

                {bonuses && bonuses?.percent && defaultsEnabled && (
                  <BonusJune
                    className={styles.bonuses}
                    percent={bonuses.percent}
                    valueBonuses={valueBonuses}
                    minBonuses={bonuses.minDepSum}
                    currencySymbol={currencySymbol}
                  />
                )}
              </div>
            </div>
            {isBeatifyCardForm && <Card isOpen={!!watchAmount} />}
            {fields && (
              <FlexibleFields
                isDeposit
                className={styles.flexible}
                fields={fields}
              />
            )}
            {requestError && <Message message={requestError} />}
          </div>
        </div>

        {/* Убрали по просьбе бизнеса: LM-2423 */}
        {/* {!isDesktop && (
          <MinMax min={amount.min} max={amount.max} currency={currencySymbol} />
        )} */}
        <Footer
          submitLoading={submitLoading}
          qr={qr}
          qrOpen={qrOpen}
          isModal={isModal}
        />
      </form>
    </Collapsed>
  );
};
