import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// import { commonSelectors } from '@box/redux/common';
// import { useAppSelector } from '@box/redux/hooks';
import { appConfig } from '@box/shared/config';

import { Button } from '../../../baseComponents';
import { useApp } from '../../../hooks';
// import { getTelegramByCountryCode, socialLinks } from '../../../utils';
import { LanguageDropdown } from '../../LanguageDropdown';

import { ReactComponent as ScrollTopIcon } from '../assets/toTop.svg';
import { ReactComponent as DesktopIcon } from '../assets/desktop.svg';
import { ReactComponent as MobileIcon } from '../assets/mobile.svg';
// import { ReactComponent as TelegramIcon } from '../assets/telegram.svg';
// import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';
// import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';

import { MenuProps } from './Menu.types';
import styles from './Menu.module.scss';

export const Menu: React.FC<MenuProps> = ({ className }) => {
  const { t } = useTranslation();

  const {
    route,
    userEnv: { isMobile, toggleAppMode },
  } = useApp();

  const { companyAddress, companyName } = appConfig;

  // const clientData = useAppSelector(commonSelectors.clientData);

  const currentYear = new Date().getFullYear();

  const scrollTop = () => {
    const position =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (position > 0) {
      window.requestAnimationFrame(scrollTop);
      window.scrollTo(0, position - position / 8);
    }
  };

  return (
    <div
      className={classNames(styles.menu, className, {
        [styles.menu_mobile]: isMobile,
      })}
    >
      <div className={styles.left}>
        <ul className={styles.links}>
          <li className={styles.item}>
            <Link to={route('politics.about_us')} className={styles.link}>
              {t('footer.menu.about_us')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.contact_us')} className={styles.link}>
              {t('footer.menu.contact_us')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link className={styles.link} to={route('politics.privacy')}>
              {t('footer.menu.privacy_policy')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link
              to={route('politics.terms_and_conditions')}
              className={styles.link}
            >
              {t('footer.menu.terms_and_conditions')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.aml')} className={styles.link}>
              {t('footer.menu.aml')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.self_exclusion')} className={styles.link}>
              {t('footer.menu.self_exclusion')}
            </Link>
          </li>
        </ul>
        <ul className={styles.links}>
          <li className={styles.item}>
            <Link to={route('politics.refund')} className={styles.link}>
              {t('footer.menu.refund_policy')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.cancellation')} className={styles.link}>
              {t('footer.menu.cancellation_policy')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.fairness')} className={styles.link}>
              {t('footer.menu.fairness')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.user_auxiliary')} className={styles.link}>
              {t('footer.menu.user_auxiliary')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link to={route('politics.kyc')} className={styles.link}>
              {t('footer.menu.kyc')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link
              to={route('politics.dispute_resolution')}
              className={styles.link}
            >
              {t('footer.menu.dispute_resolution')}
            </Link>
          </li>
          <li className={styles.item}>
            <Link
              to={route('politics.responsible_gaming')}
              className={styles.link}
            >
              {t('footer.menu.responsible_gaming')}
            </Link>
          </li>
        </ul>
      </div>

      <div className={styles.right}>
        <div className={styles.address}>
          <span className={styles.copy}>
            © 2021 - {currentYear}. {companyName}.{' '}
            {t('footer.menu.rights_reserved')}
          </span>
          <span className={styles.info}>{companyAddress}</span>
        </div>
        <div className={styles.settings}>
          <button
            className={styles.mobile}
            type='button'
            onClick={toggleAppMode}
          >
            {!isMobile ? <MobileIcon /> : <DesktopIcon />}
            <span>
              {isMobile
                ? t('footer.menu.desktop_version')
                : t('footer.menu.mobile_version')}
            </span>
          </button>
          <div className={styles.row}>
            <LanguageDropdown />
            {isMobile && (
              <Button
                className={styles.scroll}
                icon={<ScrollTopIcon />}
                color='primary'
                onClick={scrollTop}
              />
            )}
          </div>
        </div>
      </div>
      {/* LM-2814 - Убрать соц. сети с футера лафы */}
      {/* {!isMobile ? (
        <div className={styles.socials}>
          <button
            className={styles.socials_item}
            type='button'
            onClick={() =>
              window.open(
                getTelegramByCountryCode(clientData?.country?.code),
                '_blank',
                'noreferrer'
              )
            }
          >
            <TelegramIcon />
          </button>
          <button
            className={styles.socials_item}
            type='button'
            onClick={() =>
              window.open(socialLinks.instagram, '_blank', 'noreferrer')
            }
          >
            <InstagramIcon />
          </button>
          <button
            className={styles.socials_item}
            type='button'
            onClick={() =>
              window.open(socialLinks.facebook, '_blank', 'noreferrer')
            }
          >
            <FacebookIcon />
          </button>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
};
