import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../Modal/Modal';
import Tabs from '../../Tabs/Tabs';

import './topUp.scss';
import { Banner } from './Banner';
import { PaymentFrame } from './PaymentFrame';

export const TopUp = ({ tabs, frame }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className='top-up'
      title={t('top_up.title')}
      id='deposit-disabled' // Не понял, почему тут такой id должен быть top-up
      size='lg'
    >
      <div className='top-up__body'>
        {frame ? <PaymentFrame {...frame} /> : <Tabs tabs={tabs} />}
        <Banner />
      </div>
    </Modal>
  );
};

export const TopUpAutoRegister = ({ tabs, frame }) => {
  return frame ? <PaymentFrame {...frame} /> : <Tabs tabs={tabs} />;
};
