export const bonusTypes = {
  free_spins: 'free_spins',
  '1st_dep_bonuses': '1st_dep_bonuses',
  '2nd_dep_bonuses': '2nd_dep_bonuses',
  '3rd_dep_bonuses': '3rd_dep_bonuses',
  '4th_dep_bonuses': '4th_dep_bonuses',
  bonuses: 'bonuses',
  '1st_dep_free_spins': '1st_dep_free_spins',
  '2nd_dep_free_spins': '2nd_dep_free_spins',
  '3rd_dep_free_spins': '3rd_dep_free_spins',
  birthday: 'birthday',
  next_dep_bonuses: 'next_dep_bonuses',
};
