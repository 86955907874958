import { createSlice } from '@reduxjs/toolkit';

import { initUserBonuses, addUserBonus, deleteUserBonus } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  loading: false,
  ready: false,
  list: [],
  add: {
    id: '',
    loading: false,
  },
};

export const slice = createSlice({
  name: 'user/bonuses',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initUserBonuses.pending, (state) => {
        state.loading = true;
        state.ready = false;
      })
      .addCase(initUserBonuses.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
        state.ready = true;
      })
      .addCase(initUserBonuses.rejected, (state) => {
        state.loading = false;
        state.ready = false;
      })

      .addCase(addUserBonus.pending, (state, { meta }) => {
        state.add.loading = true;
        state.add.id = meta.arg.bonusId;
      })
      .addCase(addUserBonus.fulfilled, (state, { payload }) => {
        const filteredList = state.list.filter(
          (item) => item.bonus_id !== payload.bonus_id
        );

        state.list = [...filteredList, payload];
        state.add.loading = false;
        state.add.id = '';
      })
      .addCase(addUserBonus.rejected, (state) => {
        state.add.loading = false;
        state.add.id = '';
      })

      .addCase(deleteUserBonus.fulfilled, (state, { meta }) => {
        state.list = state.list.filter(
          (item) => item.bonus_id !== meta.arg.bonusId
        );
      });
  },
});
