import { createSlice } from '@reduxjs/toolkit';

import { initUser } from './thunks';
import {
  setUserInfo,
  updateDepositBonus,
  updatePermissions,
  updateRoles,
} from './actions';
import { InitialState } from './types';

const initialState = {} as InitialState;

export const slice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initUser.fulfilled, (state, { payload }) => {
        const permissions = [];

        payload.permissions.forEach((item) => permissions.push(item.name));
        payload.roles.forEach((item) => {
          if (item.permissions) {
            item.permissions.forEach((i) => permissions.push(i.name));
          }
        });

        return { ...state, ...payload, permissionsArray: permissions };
      })
      .addCase(setUserInfo, (state, { payload }) => {
        const permissions = [];
        payload.permissions?.forEach((item) => permissions.push(item.name));
        payload.roles.forEach((item) => {
          if (item.permissions) {
            item.permissions.forEach((i) => permissions.push(i.name));
          }
        });
        return { ...state, ...payload, permissionsArray: permissions };
      })
      .addCase(updatePermissions, (state, { payload }) => {
        const permissions = [];
        payload.permissions.forEach((item) => permissions.push(item.name));
        payload.roles.forEach((item) => {
          if (item.permissions) {
            item.permissions.forEach((i) => permissions.push(i.name));
          }
        });

        state.permissionsArray = permissions;
      })
      .addCase(updateRoles, (state, { payload }) => {
        state.roles = payload;
      })
      .addCase(updateDepositBonus, (state, { payload }) => {
        state.deposit_bonus = payload;
      });
  },
});
