import { ReactComponent as ProfileIcon } from './assets/images/profile.svg';
import { ReactComponent as EmailIcon } from './assets/images/email.svg';
import { ReactComponent as FundIcon } from './assets/images/fund.svg';
import { ReactComponent as Bet100Icon } from './assets/images/bet100.svg';
import { ReactComponent as Bet200Icon } from './assets/images/bet200.svg';
import { ReactComponent as Bet300Icon } from './assets/images/bet300.svg';
import { ReactComponent as Bet400Icon } from './assets/images/bet400.svg';
import { ReactComponent as Bet500Icon } from './assets/images/bet500.svg';
import { ReactComponent as Dep2Icon } from './assets/images/dep2.svg';
import { ReactComponent as Dep3Icon } from './assets/images/dep3.svg';
import { ReactComponent as Dep4Icon } from './assets/images/dep4.svg';
import { ReactComponent as Game3Icon } from './assets/images/game3.svg';
import { ReactComponent as Game5Icon } from './assets/images/game5.svg';
import { ReactComponent as Game10Icon } from './assets/images/game10.svg';
import { ReactComponent as MobileAppIcon } from './assets/images/mobileApp.svg';
import { ReactComponent as Money100Icon } from './assets/images/money100.svg';
import { ReactComponent as Money500Icon } from './assets/images/money500.svg';
import { ReactComponent as Money1000Icon } from './assets/images/money1000.svg';
import { ReactComponent as Money2000Icon } from './assets/images/money2000.svg';
import { ReactComponent as Money5000Icon } from './assets/images/money5000.svg';
import { ReactComponent as SupportIcon } from './assets/images/support.svg';

export const icons = {
  profile: ProfileIcon,
  email: EmailIcon,
  fund: FundIcon,
  bet100: Bet100Icon,
  bet200: Bet200Icon,
  bet300: Bet300Icon,
  bet400: Bet400Icon,
  bet500: Bet500Icon,
  dep2: Dep2Icon,
  dep3: Dep3Icon,
  dep4: Dep4Icon,
  game3: Game3Icon,
  game5: Game5Icon,
  game10: Game10Icon,
  mobileApp: MobileAppIcon,
  money100: Money100Icon,
  money500: Money500Icon,
  money1000: Money1000Icon,
  money2000: Money2000Icon,
  money5000: Money5000Icon,
  support: SupportIcon,
};
