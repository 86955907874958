import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: resetSupport } = actions;

export * from './thunks';
export * from './actions';
export * as supportSelectors from './selectors';

export default reducer;
