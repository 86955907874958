import React, { useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openInfoPopup } from '@box/redux/app';
import { useAppSelector } from '@box/redux/hooks';
import { chatsUserInitSelectors } from '@box/redux/chats/init';
import { setUploadError } from '@box/redux/support';

import { Fields, Props, WrapperProps } from './ChatForm.types';

import { isFileImage, uploadFile } from '../../utils/user';

export const withState = (Component: FC<Props>) => {
  const wrapper: FC<WrapperProps> = ({ ...props }) => {
    const { sendMessage, loading } = props;

    const userId = useAppSelector(chatsUserInitSelectors.userId);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileReady, setFileReady] = useState(false);

    const { register, handleSubmit, control, watch, setValue, reset } =
      useForm();

    const message = watch('message');

    const isSubmitActive = !!(file || message?.trim());

    const onSubmit = (data: Fields) => {
      if (!loading && isSubmitActive) {
        sendMessage({ message: data.message, file });
        reset({});
        setValue('message', '');

        setFile(null);
        setFileName('');
        setFileReady(false);
      }
    };

    const onFileChange = async (e) => {
      const { files } = e.target;
      const value = files[0];

      e.target.value = null;

      if (!!value && !isFileImage(value)) {
        dispatch(
          openInfoPopup({
            type: 'error',
            text: t('fields.file_type_error'),
          })
        );

        return;
      }

      setFileName(value.name);

      const response = await uploadFile(value, userId);

      if (response && response.error) {
        dispatch(setUploadError(response.message));
        dispatch(
          openInfoPopup({
            type: 'error',
            text: response.message,
          })
        );
        setFileName('');

        return;
      }
      setFileReady(true);
      setFile(response);
    };

    const resetFile = () => {
      setFile(null);
      setFileName('');
    };

    return (
      <Component
        {...props}
        register={register}
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        isSubmitActive={isSubmitActive}
        fileName={fileName}
        fileReady={fileReady}
        deleteFile={resetFile}
        changeFile={onFileChange}
        loading={loading}
      />
    );
  };

  return wrapper;
};
