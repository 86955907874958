import React, { FC } from 'react';
import classNames from 'classnames';
import { LineLoader } from '@box/ui';

import { Props } from './LightBlockWithLoading.types';

import styles from './lightBlockWithLoading.module.scss';

export const LightBlockWithLoading: FC<Props> = ({ className, loading }) => {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.container_loading]: loading },
        className
      )}
    >
      <LineLoader color='yellow' />
    </div>
  );
};
