import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideModal, openModal } from '@box/redux/app';

import { ErrorPopup } from '../../ErrorPopup';

import './gameFundsError.scss';

export const GameFundsErrorPopup = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const openTopUp = () => {
    dispatch(openModal('deposit'));
    dispatch(hideModal('game-fund-error'));
  };

  return (
    <ErrorPopup
      className='game-funds-error'
      id='game-fund-error'
      title={t('current_game.founds_error_title')}
      buttonText={t('navigation.top_up')}
      buttonClick={openTopUp}
    >
      <p className='error-popup__text'>{t('current_game.founds_error_text')}</p>
    </ErrorPopup>
  );
};
