import { useTranslation } from 'react-i18next';

export type UseBonusLabel = (count: number) => { label: string };

export const useBonusLabel: UseBonusLabel = (count) => {
  const { t } = useTranslation();
  const label = (count) => {
    switch (count) {
      case 0:
        return t('deposit.first_dep_message');
      case 1:
        return t('deposit.second_dep_message');
      case 2:
        return t('deposit.third_dep_message');
      case 3:
        return t('deposit.fourth_dep_message');
      default:
        return t('deposit.first_dep_message');
    }
  };

  return { label: label(count) };
};
