import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import forIn from 'lodash/forIn';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { zodResolver } from '@hookform/resolvers/zod';
import { useApp } from '@box/shared/hooks';
import {
  userSelectors,
  userUpdateSelectors,
  userUpdateReset,
  createUserDocument,
  documentsSelectors,
  updateUserDocument,
} from '@box/redux/user';
import { commonSelectors } from '@box/redux/common';
import { personalDataSchema } from '@box/shared/utils/validation';
import { DecemberInput, DecemberMaskedInput } from '@box/ui';

import {
  Button,
  Dropdown,
  FormSelect,
  RadioGroup,
} from '../../../../baseComponents';
import { getUpdateData } from '../../../../utils/getUpdateUserData';
import { getBirthdate } from '../../../../utils/getBirthdate';
import PhoneInput from '../../../PhoneInput/PhoneInput';
import { preparePhone } from '../../../../utils/user';
import { useFormErrorPopup } from '../../../../hooks';

import { Documents } from '../Documents';

import { Detail } from './Detail';
import { DisabledContext } from './disabledContext';
import { EmailVerify } from '../EmailVerify';

dayjs.extend(customParseFormat);

export const UserInfo = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useDispatch();
  const countries = useSelector(commonSelectors.settingsCountries);
  const loading = useSelector(userUpdateSelectors.loading);
  const documentsLoading = useSelector(documentsSelectors.loading);
  const documentsInit = useSelector(documentsSelectors.init);
  const currentUserDocument = useSelector(documentsSelectors.current);
  const user = useSelector(userSelectors.user);
  const codes = useSelector(commonSelectors.codes);

  const isVerify = useSelector(userSelectors.isVerify);
  const isVerifyPending = useSelector(userSelectors.isPendingVerification);
  const isVerifyDenied = useSelector(userSelectors.isVerifyDenied);
  const isVerifyNotStarted = useSelector(userSelectors.isVerifyNotStarted);
  const isEmailVerified = useSelector(userSelectors.isEmailVerified);

  const hideDocs = isVerifyPending || isVerify;

  const formMethods = useForm({
    resolver: zodResolver(personalDataSchema),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = formMethods;

  useFormErrorPopup({ control, text: t('fields.form_error_message') });

  const inputsDisabled = isVerify || isVerifyPending;

  const getUserEmail = (email) => {
    if (isString(email)) {
      return email.includes('rand_') ? '' : email;
    }

    return '';
  };

  const getUserName = (name) => {
    if (user?.name_is_default) {
      return '';
    }
    return name;
  };

  useEffect(() => {
    if (user) {
      setValue('name', getUserName(user.name));
      setValue('surname', user.surname);
      setValue('email', getUserEmail(user.email));
      setValue('country_id', user.country_id);
      setValue('birthdate', getBirthdate(user.birthdate));
      setValue('phone', user.phone);
      setValue('city', user.city);
      setValue('gender', user.gender);
      setValue('address', user.address);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      reset();
      dispatch(userUpdateReset());
    };
  }, []);

  const prepareDocuments = (data) => {
    const formData = new FormData();

    const fields = {};

    forIn(data, (value, key) => {
      if (key.includes('document__')) {
        const name = key.replace('document__', '');

        fields[name] = value;
      }
    });

    if (!isEqual(data.document_type, get(currentUserDocument, 'type.alias'))) {
      formData.append('type', data.document_type);
    }

    if (!isEqual(data.scan, get(currentUserDocument, 'scan'))) {
      formData.append('scan', data.scan);
    }

    if (!isEqual(data.photo, get(currentUserDocument, 'photo'))) {
      formData.append('photo', data.photo);
    }

    formData.append('fields_values', JSON.stringify(fields));

    return formData;
  };

  const onSubmit = (data) => {
    const userData = getUpdateData(
      { ...user, birthdate: getBirthdate(user.birthdate) },
      {
        ...data,
        phone: `${preparePhone(data.phone)}`,
      }
    );
    userData.email = user.email;

    if (isEmpty(currentUserDocument)) {
      dispatch(
        createUserDocument({
          id: currentUserDocument.id,
          values: prepareDocuments(data),
          userData,
        })
      );
    } else {
      dispatch(
        updateUserDocument({
          id: currentUserDocument.id,
          values: prepareDocuments(data),
          userData,
        })
      );
    }
  };

  const getVerifyStatusText = () => {
    if (isVerifyNotStarted) return t('settings.verify_status_not_started');
    if (isVerify) return t('settings.verify_status_verified');
    if (isVerifyDenied) return t('settings.verify_status_denied');
    if (isVerifyPending) return t('settings.verify_status_under_review');
    return '';
  };

  const getVerifyStatus = () => {
    if (isVerifyNotStarted) return t('settings.not_verified');
    if (isVerify) return t('settings.verified');
    if (isVerifyPending) return t('settings.checked');
    if (isVerifyDenied) return t('settings.denied');
    return '';
  };

  return (
    <form
      className={classNames('user-info', { 'user-info_mobile': isMobile })}
      onSubmit={handleSubmit(onSubmit)}
    >
      {!isEmailVerified && <EmailVerify />}
      <div className='settings__block'>
        <h3 className='settings__block-title'>
          {t('settings.verify_status')} {getVerifyStatus()}
        </h3>
        <div className='verify-status-text'>{getVerifyStatusText()}</div>
      </div>

      <div className='settings__block'>
        <h3 className='settings__block-title settings__block-title--detail'>
          {t('settings.personal_data')}
          <Detail />
        </h3>
        <div className='row'>
          <div className='col-sm-6'>
            <DecemberInput
              className='control'
              color='outlined'
              as='input'
              label={t('settings.name')}
              error={t(errors.name?.message)}
              {...register('name', {
                disabled: inputsDisabled,
              })}
            />
          </div>
          <div className='col-sm-6'>
            <DecemberInput
              className='control'
              color='outlined'
              as='input'
              label={t('settings.surname')}
              error={t(errors.surname?.message)}
              errorMessage={t(errors.surname?.message)}
              {...register('surname', {
                disabled: inputsDisabled,
              })}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <DecemberInput
              className='control'
              color='outlined'
              label={t('settings.city')}
              error={t(errors.city?.message)}
              {...register('city', {
                disabled: inputsDisabled,
              })}
            />
          </div>
          <div className='col-sm-6'>
            <Controller
              control={control}
              name='birthdate'
              render={({ field }) => (
                <DecemberMaskedInput
                  className='control'
                  color='outlined'
                  placeholder='dd-mm-yyyy'
                  format='##-##-####'
                  label={t('settings.birthdate')}
                  disabled={inputsDisabled}
                  error={t(errors.birthdate?.message)}
                  value={field.value}
                  name={field.name}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <FormSelect
              control={control}
              name='country_id'
              rules={{ required: t('fields.required') }}
              options={countries}
              className={classNames('settings__dropdown', {
                settings__dropdown_mobile: isMobile,
              })}
              label={t('settings.country')}
              onChange={(val, field) => field.onChange(val.value)}
              disabled
            />
          </div>
          <div className='col-sm-6'>
            <span className='phone-label'>{t('settings.phone')}</span>
            <div
              className={classNames('settings__phone', {
                settings__phone_mobile: isMobile,
              })}
            >
              <Dropdown
                className='register__dropdown phone__dropdown'
                defaultValue={{ label: codes.country }}
                disabled
              />
              <PhoneInput
                control={control}
                error={t(errors.phone?.message)}
                code={codes.phone}
                disabled={inputsDisabled}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <DecemberInput
              className='control'
              color='outlined'
              as='input'
              placeholder={t('settings.email')}
              label={t('settings.email')}
              error={t(errors.email?.message)}
              readOnly
              {...register('email', {
                disabled: inputsDisabled,
              })}
            />
          </div>
          <div className='col-sm-6'>
            <DecemberInput
              className='control'
              color='outlined'
              label={t('settings.address')}
              error={t(errors.address?.message)}
              {...register('address', {
                disabled: inputsDisabled,
              })}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <Controller
              name='gender'
              control={control}
              rules={{ required: t('fields.required') }}
              render={({ field }) => (
                <RadioGroup
                  onChange={field.onChange}
                  value={field.value}
                  name='gender'
                  values={[
                    { label: t('settings.male'), value: 'm' },
                    { label: t('settings.female'), value: 'f' },
                  ]}
                  label={t('settings.gender')}
                  error={t(errors.gender?.message)}
                  ref={field.ref}
                  disabled={inputsDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>
      {!hideDocs && (
        <div className='settings__block'>
          <h3 className='settings__block-title'>{t('settings.documents')}</h3>
          <FormProvider {...formMethods}>
            <DisabledContext.Provider value={inputsDisabled}>
              <Documents />
            </DisabledContext.Provider>
          </FormProvider>
        </div>
      )}
      {inputsDisabled && (
        <div className='settings__block'>
          <div className='verify-status-text'>
            {t('settings.disabled_text')}
          </div>
        </div>
      )}
      <div className='settings__block'>
        <Button
          color='secondary'
          className={classNames('settings__button', {
            settings__button_mobile: isMobile,
          })}
          type='submit'
          loading={loading || documentsLoading}
          disabled={!documentsInit || inputsDisabled}
        >
          {t('settings.save')}
        </Button>
      </div>
    </form>
  );
};
