type AddSpaceForNumber = (num: number) => string;

export const addSpaceForNumber: AddSpaceForNumber = (num) => {
  return String(num)
    .split('')
    .reverse()
    .join('')
    .replace(/\d\d\d/g, '$& ')
    .split('')
    .reverse()
    .join('');
};
