import classNames from 'classnames';
import React from 'react';
import { useApp } from '@box/shared/hooks';

import { ImageSet } from '@box/ui';

import { paymentIcons } from './_data';
import { PaymentsProps } from './Payments.types';

import styles from './Payments.module.scss';

export const Payments: React.FC<PaymentsProps> = ({ className }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.payments, className, {
        [styles.payments_mobile]: isMobile,
      })}
    >
      <div className={styles.payments_inner}>
        {paymentIcons.map((item, index) => (
          <div className={styles.payments_item} key={index}>
            <ImageSet
              lazy
              src={item.icon}
              srcWebp={item.iconWebp}
              srcAvif={item.iconAvif}
              alt={item.alt}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
