import React from 'react';
// Remove Sentry LM-3067
// import * as Sentry from '@sentry/react';
// import { appConfig } from '@box/shared/config';
// import { Integrations } from '@sentry/tracing';
import * as ReactDOMClient from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import './i18n';
import { App } from './App';

// Remove Sentry LM-3067
// if (appConfig.sentryDSN) {
//   Sentry.init({
//     dsn: appConfig.sentryDSN,
//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0,
//     integrations: [
//       new Integrations.BrowserTracing(),
//       new Sentry.Integrations.Breadcrumbs({
//         console: false,
//       }),
//       new Sentry.Replay(),
//     ],
//     tracesSampleRate: 1.0,
//     normalizeDepth: 5,
//     environment: appConfig.env,
//     release: appConfig.release,
//   });
// }

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<App />);

reportWebVitals();
