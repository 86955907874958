// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs{align-items:center;display:flex;flex-wrap:nowrap;overflow-x:scroll;overflow-y:hidden;padding:17px 8px 17px 0}.\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs::-webkit-scrollbar{height:0}.\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs .\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tab{margin-left:8px}.\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs_skittles{display:flex;flex-wrap:wrap;justify-content:center;padding:0}.\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs_skittles .\\------shared-components-Modals-Deposits-Components-Payments-payments-module__tab{margin:8px 3px}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Deposits/Components/Payments/payments.module.scss"],"names":[],"mappings":"AACA,oFAEE,kBAAA,CADA,YAAA,CAGA,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,uBAAF,CAEE,uGACE,QAAJ,CAGE,uKACE,eADJ,CAIE,6FACE,YAAA,CAEA,cAAA,CADA,sBAAA,CAEA,SAFJ,CAII,gLACE,cAFN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.tabs {\n  display: flex;\n  align-items: center;\n  padding: 17px 0;\n  flex-wrap: nowrap;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  padding-right: 8px;\n\n  &::-webkit-scrollbar {\n    height: 0;\n  }\n\n  .tab {\n    margin-left: 8px;\n  }\n\n  &_skittles {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    padding: 0;\n\n    .tab {\n      margin: 8px 3px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs",
	"tab": "------shared-components-Modals-Deposits-Components-Payments-payments-module__tab",
	"tabs_skittles": "------shared-components-Modals-Deposits-Components-Payments-payments-module__tabs_skittles"
};
module.exports = ___CSS_LOADER_EXPORT___;
