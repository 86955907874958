import React from 'react';

import { useApp } from '@box/shared/hooks';
import { BonusesContentDecember } from './BonusesContentDecember';
import { BonusesContentJune } from './BonusesContentJune';
import { withBonusState } from './BonusesContent.state';

const December = withBonusState(BonusesContentDecember);
const June = withBonusState(BonusesContentJune);

export const BonusesContent = () => {
  const {
    userEnv: { isJune },
  } = useApp();

  if (isJune) {
    return <June />;
  }
  return <December />;
};
