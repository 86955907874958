import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: transactionsReset } = actions;

export * from './thunks';
export * from './actions';
export * as transactionsSelectors from './selectors';

export default reducer;
