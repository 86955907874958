export interface DepositFields {
  amount: number;
  pan: number;
  exp_year: number;
  exp_month: string;
  cvc: number;
  id: number;
  holder: string;
  currency: object;
  custom_phone: boolean;
}
