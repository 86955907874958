// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/// @ts-ignore
import { BrowserHistory } from 'history';
import { AxiosInstance } from 'axios';

import { Category, Provider, UserTransformBonus } from './models';

export type ExtraArgs = {
  route: (
    name: string,
    pathParams?: { [key: string]: string | number }
  ) => string;
  user?: {
    requestExtra: string;
  };
  transformers?: {
    providers: (providers: Provider[]) => Provider[];
    categories: (providers: Category[]) => Category[];

    commonBonuses: (bonuses: any[]) => any[];
    userBonuses: (bonuses: any[]) => UserTransformBonus[];
    singleUserBonus: (bonus) => UserTransformBonus;
  };
  getFinUrl?: (baseUrl: string) => string;
  slotUrl?: string;
  crmUrl?: string;
  mainUrl?: string;
  helpUrl?: string;
  history: BrowserHistory;
  api: AxiosInstance;
  availableLanguages?: { label: string; value: string }[];
  isMobile: boolean;
  isJune: boolean;
  mirrors: {
    isMirror1: boolean;
    isMirror2: boolean;
    isMirror3: boolean;
  };
};
