import icon1 from '../assets/Payment_01.png';
import icon2 from '../assets/Payment_02.png';
import icon3 from '../assets/Payment_03.png';
import icon4 from '../assets/Payment_04.png';
import icon5 from '../assets/Payment_05.png';
import icon6 from '../assets/Payment_06.png';
import icon7 from '../assets/Payment_07.png';
import icon8 from '../assets/Payment_08.png';
import icon9 from '../assets/Payment_09.png';
import icon10 from '../assets/Payment_10.png';
import icon11 from '../assets/Payment_11.png';
import icon12 from '../assets/Payment_12.png';
import icon1Webp from '../assets/Payment_01.webp';
import icon2Webp from '../assets/Payment_02.webp';
import icon3Webp from '../assets/Payment_03.webp';
import icon4Webp from '../assets/Payment_04.webp';
import icon5Webp from '../assets/Payment_05.webp';
import icon6Webp from '../assets/Payment_06.webp';
import icon7Webp from '../assets/Payment_07.webp';
import icon8Webp from '../assets/Payment_08.webp';
import icon9Webp from '../assets/Payment_09.webp';
import icon10Webp from '../assets/Payment_10.webp';
import icon11Webp from '../assets/Payment_11.webp';
import icon12Webp from '../assets/Payment_12.webp';
import icon1Avif from '../assets/Payment_01.avif';
import icon2Avif from '../assets/Payment_02.avif';
import icon3Avif from '../assets/Payment_03.avif';
import icon4Avif from '../assets/Payment_04.avif';
import icon5Avif from '../assets/Payment_05.avif';
import icon6Avif from '../assets/Payment_06.avif';
import icon7Avif from '../assets/Payment_07.avif';
import icon8Avif from '../assets/Payment_08.avif';
import icon9Avif from '../assets/Payment_09.avif';
import icon10Avif from '../assets/Payment_10.avif';
import icon11Avif from '../assets/Payment_11.avif';
import icon12Avif from '../assets/Payment_12.avif';

export const paymentIcons = [
  { icon: icon1, iconWebp: icon1Webp, iconAvif: icon1Avif, alt: 'visa' },
  { icon: icon2, iconWebp: icon2Webp, iconAvif: icon2Avif, alt: 'master_card' },
  { icon: icon3, iconWebp: icon3Webp, iconAvif: icon3Avif, alt: 'google_pay' },
  { icon: icon4, iconWebp: icon4Webp, iconAvif: icon4Avif, alt: 'apple_pay' },
  { icon: icon5, iconWebp: icon5Webp, iconAvif: icon5Avif, alt: 'bitcoin' },
  { icon: icon6, iconWebp: icon6Webp, iconAvif: icon6Avif, alt: 'qiwi' },
  { icon: icon7, iconWebp: icon7Webp, iconAvif: icon7Avif, alt: '_' },
  { icon: icon8, iconWebp: icon8Webp, iconAvif: icon8Avif, alt: 'visa' },
  { icon: icon9, iconWebp: icon9Webp, iconAvif: icon9Avif, alt: 'skrill' },
  { icon: icon10, iconWebp: icon10Webp, iconAvif: icon10Avif, alt: 'paytm' },
  { icon: icon11, iconWebp: icon11Webp, iconAvif: icon11Avif, alt: 'payeer' },
  { icon: icon12, iconWebp: icon12Webp, iconAvif: icon12Avif, alt: 'webmoney' },
];
