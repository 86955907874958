import { combineReducers } from 'redux';

import langReducer from './lang';
import jackpotReducer from './jackpot';
import imageViewerReducer from './imageViewer';
import modalsReducer from './modals';
import infoPopupReducer from './infoPopup';
import globalLoaderReducer from './globalLoader';
import confirmReducer from './confirm';
import statsReducer from './stats';
import versionReducer from './version';

const reducer = combineReducers({
  lang: langReducer,
  jackpot: jackpotReducer,
  imageViewer: imageViewerReducer,
  modals: modalsReducer,
  infoPopup: infoPopupReducer,
  globalLoader: globalLoaderReducer,
  confirm: confirmReducer,
  stats: statsReducer,
  version: versionReducer,
});

export * from './lang';
export * from './jackpot';
export * from './imageViewer';
export * from './modals';
export * from './infoPopup';
export * from './globalLoader';
export * from './confirm';
export * from './stats';
export * from './version';

export default reducer;
