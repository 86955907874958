import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { ratesSelectors } from '@box/redux/partner/rates';
import { userInitSelectors } from '@box/redux/authentication';
import { appConfig } from '@box/shared/config';
import { useApp } from '@box/shared/hooks';

import { Props } from './Referral.types';

import styles from './referral.module.scss';

import { ReactComponent as Close } from './assets/close.svg';

export const Referral: FC<Props> = ({ isClosed, close }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const currentRate = useAppSelector(ratesSelectors.currentRate);
  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const { companyName } = appConfig;

  const earn = useMemo(() => {
    if (currentRate) {
      return `${currentRate?.rate || ''} ${currentRate?.currency || ''}`;
    }
    return '';
  }, [currentRate]);

  const handleClick = () => {
    if (!isLogged) {
      dispatch(openModal('login'));
    } else {
      dispatch(openModal('referral'));
    }
  };

  return (
    <div
      className={classNames(styles.referral, {
        [styles.referral_mobile]: isMobile,
        [styles.referral_show]: earn,
        [styles.referral_hide]: isClosed,
      })}
    >
      <p className={styles.text}>
        {t('referral.earn.refer_friend')}: {t('referral.earn.earn')} {earn}{' '}
        {t('referral.earn.invite_friend')} {companyName} 💸
      </p>
      <button
        className={classNames(styles.close, {
          [styles.close_hidden]: !isMobile || isClosed,
        })}
        type='button'
        onClick={() => close()}
      >
        <Close />
      </button>
      <button type='button' onClick={handleClick} className={styles.button}>
        {t('referral.refer')}
      </button>
    </div>
  );
};
