export const generateUUID = () => {
  let uuid = '';
  const chars = '0123456789abcdef';
  for (let i = 0; i < 32; i += 1) {
    const randomCharIndex = Math.floor(Math.random() * chars.length);
    const char = chars[randomCharIndex];
    uuid += char;
    if (i === 7 || i === 11 || i === 15 || i === 19) {
      uuid += '-';
    }
  }
  return uuid;
};
