// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------ui-src-LightPopup-lightPopup-module__popup{border-radius:8px;bottom:-300px;left:50%;max-width:100%;position:fixed;transform:translateX(-50%);transition:bottom .5s linear;width:100%;z-index:100}.\\------ui-src-LightPopup-lightPopup-module__popup svg path{fill:#fda700}.\\------ui-src-LightPopup-lightPopup-module__popup--open,.\\------ui-src-LightPopup-lightPopup-module__popup--open.\\------ui-src-LightPopup-lightPopup-module__mobile{bottom:0}", "",{"version":3,"sources":["webpack://./../../ui/src/LightPopup/lightPopup.module.scss"],"names":[],"mappings":"AACA,mDAIE,iBAAA,CACA,aAAA,CAEA,QAAA,CAJA,cAAA,CAFA,cAAA,CAOA,0BAAA,CAEA,4BAAA,CARA,UAAA,CAIA,WAGF,CAII,4DACE,YAFN,CASI,qKACE,QAJN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.popup {\n  position: fixed;\n  width: 100%;\n  max-width: 100%;\n  border-radius: 8px;\n  bottom: -300px;\n  z-index: $layout-popup;\n  left: 50%;\n  transform: translateX(-50%);\n\n  transition: bottom 0.5s linear;\n\n  svg {\n    path {\n      fill: $color-web-orange;\n    }\n  }\n\n  &--open {\n    bottom: 0;\n\n    &.mobile {\n      bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "------ui-src-LightPopup-lightPopup-module__popup",
	"popup--open": "------ui-src-LightPopup-lightPopup-module__popup--open",
	"mobile": "------ui-src-LightPopup-lightPopup-module__mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
