import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { Props } from './ProgressBar.types';

import styles from './progressBar.module.scss';

export const ProgressBar: FC<Props> = ({
  needed,
  deposit,
  currency,
  progression_percentage,
}) => {
  const { t } = useTranslation();
  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
        [styles.container_june]: isJune,
      })}
    >
      <div className={styles.bar}>
        <div
          style={{
            width: `calc(100% * 0.01 * ${progression_percentage})`,
          }}
          className={styles.decor}
        >
          {progression_percentage > 10 && (
            <p className={styles.label}>
              {deposit} {currency}
            </p>
          )}
        </div>
        <div className={styles.shadow}>
          {progression_percentage < 90 && (
            <p className={classNames(styles.label, styles.label_dark)}>
              {needed} {currency}
            </p>
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>
          <div className={classNames(styles.box, styles.box_green)}>
            {deposit} {currency}
          </div>
          <p className={styles.text}>{t('bonuses.your_deposit')}</p>
        </div>
        <div className={styles.item}>
          <div className={styles.box}>
            {needed} {currency}
          </div>
          <p className={styles.text}>{t('bonuses.able_transfer')}</p>
        </div>
      </div>
    </div>
  );
};
