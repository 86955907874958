import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Collapsed } from '@box/shared/baseComponents';

import { Message, Button } from '../../ui';
import { DefaultsButtons } from '../DefaultsButtons';
import { Amount } from '../Amount';
import { Bonuses } from '../Bonuses';
import { FlexibleFields } from '../FlexibleFields';
import { Card } from '../Card';

import { Props } from './Form.types';

import styles from './form.module.scss';

export const Form: FC<Props> = ({
  onSubmit,
  isOpen,
  defaultsEnabled,
  bonuses,
  currencySymbol,
  amount,
  submitLoading,
  fields,
  isBeatifyCardForm,
  requestError,
  amountLabel,
}) => {
  const methods = useForm({
    defaultValues: {
      amount: bonuses?.defaults ? bonuses.defaults[1] : null,
    },
  });

  const { handleSubmit, watch } = methods;

  const { t } = useTranslation();

  const watchAmount = watch('amount');

  return (
    <Collapsed isOpen={isOpen}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.reverse}>
            {defaultsEnabled && (
              <DefaultsButtons
                percent={bonuses.percent}
                defaults={bonuses.defaults}
                currencySymbol={currencySymbol}
                minDepSum={bonuses.minDepSum}
                defaultValue={bonuses?.defaults ? bonuses.defaults[1] : null}
              />
            )}
            <div className={styles.row}>
              <Amount
                className={styles.amount}
                amount={amount}
                currencySymbol={currencySymbol}
                label={amountLabel}
              />
              {bonuses && bonuses?.percent && defaultsEnabled && (
                <Bonuses
                  className={styles.bonuses}
                  percent={bonuses.percent}
                  min={amount.min}
                  defaultValue={bonuses?.defaults ? bonuses.defaults[1] : null}
                  value={watchAmount}
                  minBonuses={Number(bonuses.minDepSum)}
                  currencySymbol={currencySymbol}
                />
              )}
            </div>
          </div>

          {isBeatifyCardForm && <Card isOpen={!!watchAmount} />}
          {fields && (
            <FlexibleFields className={styles.flexible} fields={fields} />
          )}
          <Button
            disabled={submitLoading}
            className={styles.button}
            label={t('deposit.submit')}
          />
          {requestError && <Message message={requestError} />}
        </form>
      </FormProvider>
    </Collapsed>
  );
};
