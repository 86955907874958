import React from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';
import { BaseInputProps } from './Base';

export const getInput = <
  TProps extends BaseInputProps & React.RefAttributes<HTMLInputElement>
>(
  styles: Record<string, string>,
  Element: React.ForwardRefExoticComponent<TProps>
) =>
  React.forwardRef<HTMLInputElement, TProps>(
    (
      { icons, name, label, error, className, color, ...props }: TProps,
      ref
    ) => {
      const {
        userEnv: { isMobile },
      } = useApp();
      const { startIcon, endIcon } = icons || {};

      return (
        <div
          className={classNames(
            styles.root,
            [styles[`root--${color}`]],
            {
              [styles['root--error']]: !!error,
              [styles['root--mobile']]: isMobile,
            },
            className
          )}
        >
          {/* Лэйбл */}
          {label && (
            <label className={classNames(styles.label)} htmlFor={name}>
              {label}
            </label>
          )}

          {/* Основной контент инпута */}
          <div className={styles.inputContent}>
            {/* Инконка вначале */}
            {startIcon && <div className={styles.startIcon}>{startIcon}</div>}

            <Element
              className={styles.input}
              name={name}
              ref={ref}
              {...(props as any)}
            />

            {/* Инконка в конце */}
            {endIcon && <div className={styles.endIcon}>{endIcon}</div>}
          </div>
          {/* Сообщение об ошибке */}
          {error && <span className={styles.error}>{error}</span>}
        </div>
      );
    }
  );
