import React, { useMemo, useState, useEffect, FC } from 'react';
import sortBy from 'lodash/sortBy';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { userBonusesSelectors } from '@box/redux/user';
import { openModal } from '@box/redux/app';
import { useNavigateToDeposit } from '@box/shared/hooks';

import { Props } from './BonusBadge.types';

import { Default } from './Default';
import { Light } from './Light';

export const BonusBadge: FC<Props> = ({ isLight }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(!localStorage.getItem('bonusCountdown'));
  const bonusList = useAppSelector(userBonusesSelectors.list);
  const isReady = useAppSelector(userBonusesSelectors.ready);

  const { openDeposit } = useNavigateToDeposit();

  /**
   * Каждые 10 секунд проверяем есть ли в localStorage счетчик времени.
   * Если есть - сравниваем его с настоящим временем и если их разница больше 36000000 (10 часов в миллисекундах),
   * сбрасываем счетчик и открываем табличку бонусов.
   */

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('bonusCountdown')) {
        if (
          Date.now() -
            Number(JSON.parse(localStorage.getItem('bonusCountdown'))) >
          36000000
        ) {
          setOpen(true);
          localStorage.removeItem('bonusCountdown');
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const percent: string = useMemo(() => {
    if (bonusList.length > 0) {
      const list = sortBy(bonusList, 'type');
      if (list[0]?.getting_params.length > 0) {
        return list[0]?.getting_params[0]?.percent;
      }
      return '';
    }
    return '';
  }, [bonusList]);

  const handleCloseClick = (e) => {
    e.stopPropagation();
    setOpen(false);
    localStorage.setItem('bonusCountdown', JSON.stringify(Date.now()));
  };

  const handleBadgeClick = () => {
    if (isLight) {
      openDeposit();
    } else {
      dispatch(openModal('deposit'));
    }
    setOpen(false);
    localStorage.setItem('bonusCountdown', JSON.stringify(Date.now()));
  };

  if (isLight) {
    return (
      <Light
        handleBadgeClick={handleBadgeClick}
        isOpen={isOpen}
        isReady={isReady}
        percent={percent}
      />
    );
  }
  return (
    <Default
      handleBadgeClick={handleBadgeClick}
      handleCloseClick={handleCloseClick}
      isOpen={isOpen}
      isReady={isReady}
      percent={percent}
    />
  );
};
