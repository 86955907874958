import React from 'react';
import { Link } from 'react-router-dom';

import { ImageSet } from '@box/ui';

import styles from './InfoFaq.module.scss';

import Decor from './assets/decor.png';
import DecorWebp from './assets/decor.webp';

export const InfoFaq = () => (
  <div className={styles.infoFaq}>
    <ImageSet
      className={styles.left}
      src={Decor}
      srcWebp={DecorWebp}
      alt='right'
    />
    <Link className={styles.link} to='/faqs'>
      Read this
    </Link>
    &nbsp;to know how to deposit via PhonePe
    <ImageSet src={Decor} srcWebp={DecorWebp} alt='left' />
  </div>
);
