import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import {
  fetchUserTransactions,
  paymentMethodsSelectors,
  transactionsSelectors,
} from '@box/redux/finance';
import { useDateFormat } from '@box/shared/hooks';
import { getStatus } from '@box/shared/utils';

import {
  TransactionHistoryProps,
  Props,
  TransactionList,
} from './DepositHistory.types';

export const withState = (Component: FC<TransactionHistoryProps>) => {
  const wrapper = ({ listId, requestMeta, userId }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const methods = useAppSelector(paymentMethodsSelectors.list);

    const currencies = useAppSelector(commonSelectors.currencies);
    const { list, ready, currentPage, lastPage, loading } = useAppSelector(
      (state) =>
        transactionsSelectors.listById(state, listId) as TransactionList
    );

    const { getLightDetalizationDate } = useDateFormat();

    const getCurrentMerchant = (alias) => {
      return methods.find((element) => element.alias === alias);
    };

    const getCurrencySymbol = (name) =>
      currencies.find((item) => item.code === name)?.symbol;

    const nextPage = () => {
      if (list) {
        dispatch(
          fetchUserTransactions({
            id: listId,
            userId,
            page: currentPage + 1,
            ...requestMeta,
          })
        );
      }
    };

    const previousPage = () => {
      if (list) {
        dispatch(
          fetchUserTransactions({
            id: listId,
            userId,
            page: currentPage - 1,
            ...requestMeta,
          })
        );
      }
    };

    const changePageNumber = (pageNumber) => {
      dispatch(
        fetchUserTransactions({
          id: listId,
          userId,
          page: pageNumber,
          ...requestMeta,
        })
      );
    };

    const handleOpenDepositModal = () => navigate('/profile/payments/deposit');

    useEffect(() => {
      dispatch(
        fetchUserTransactions({
          id: listId,
          userId,
          page: currentPage || 1,
          ...requestMeta,
        })
      );
    }, []);

    const depositList =
      list
        ?.map((item) =>
          +item.amountFloat
            ? {
                date: getLightDetalizationDate(item.created_at),
                amount: parseFloat(item.amountFloat).toFixed(2),
                currencySymbol: getCurrencySymbol(item.wallet.name),
                balance: item.wallet.balance_float,
                status: getStatus(item),
                method: getCurrentMerchant(item.meta?.payload?.method),
                merchant: item.meta?.external_object?.merchant?.name,
                payable_id: item.id,
                type: item?.meta?.type,
              }
            : null
        )
        .filter(Boolean) || [];

    const detalization = {
      date: 'detalization.date',
      transaction: 'detalization.transaction',
      amount: 'detalization.amount',
      payment_method: 'detalization.payment_method',
      status: 'detalization.status',
    };

    return (
      <Component
        depositList={depositList}
        detalizationList={Object.values(detalization)}
        loading={loading}
        isReady={ready}
        currentPage={currentPage}
        lastPage={lastPage}
        showNextPage={nextPage}
        showPreviousPage={previousPage}
        changePageNumber={changePageNumber}
        openDepositModal={handleOpenDepositModal}
      />
    );
  };

  return wrapper;
};
