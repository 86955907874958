import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Deposit } from '@box/shared/components/Deposit';
import { useApp } from '@box/shared/hooks';

import { Modal } from '../../../Modal/Modal';

import styles from './deposit.module.scss';

export const JuneDepositModal: FC = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <Modal
      headWithSvg
      size={isMobile ? 'smdoubleplus' : 'lg'}
      headColor='green'
      id='deposit'
      title={t('detalization.deposit')}
    >
      <div className={styles.body}>
        <Deposit isModal />
      </div>
    </Modal>
  );
};
