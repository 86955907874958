import * as Sentry from '@sentry/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkApi } from '@box/redux/types';
import {
  initChat,
  setChatId,
  setUnreadMessageCounter,
} from '@box/redux/support';
import { useAuthTokens } from '@box/redux/hooks';

import { getPureErrorMessage } from '../../utils';

import {
  FetchInitChatsUserPayload,
  FetchInitChatsUserReturn,
  GETChatsUser,
  POSTRefreshCrmToken,
  RefreshCrmTokenReturn,
} from './types';
import { fetchChatsRegister } from '../register';

export const refreshCrmToken = createAsyncThunk<
  RefreshCrmTokenReturn,
  void,
  AppThunkApi
>(
  'init/refreshCrmToken',
  async (_payload, { extra, dispatch, rejectWithValue }) => {
    const { api, crmUrl } = extra;

    const {
      crmRefreshToken,
      setCrmRefreshToken,
      setCrmAccessToken,
      deleteAllCrmTokens,
    } = useAuthTokens();

    const config = {
      baseURL: crmUrl,
      params: {
        auth: 1,
      },
    };

    try {
      const { data, status } = await api.post<POSTRefreshCrmToken>(
        'v1/login',
        {
          refresh_token: crmRefreshToken,
        },
        config
      );

      if (status === 200 || status === 201) {
        if (data?.access_token) {
          setCrmRefreshToken(data.refresh_token);
          setCrmAccessToken(data.access_token);
          dispatch(fetchInitChatsUser({ isRefresh: true }));
        } else {
          deleteAllCrmTokens();
          dispatch(fetchChatsRegister());
        }
        return data;
      }
      deleteAllCrmTokens();
      dispatch(fetchChatsRegister());

      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(data);

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(e);
      return rejectWithValue('error');
    }
  }
);

export const fetchInitChatsUser = createAsyncThunk<
  FetchInitChatsUserReturn,
  FetchInitChatsUserPayload,
  AppThunkApi
>(
  'init/fetchInitChatsUser',
  async (payload, { extra, dispatch, rejectWithValue }) => {
    const { api, crmUrl } = extra;

    const { isRefresh } = payload;

    const config = {
      baseURL: crmUrl,
    };

    try {
      const { data, status } = await api.get<GETChatsUser>(
        'v1/me?with[]=chats',
        config
      );

      if (status === 200) {
        if (data?.chats) {
          dispatch(setChatId(data.chats[0]?.id));
          dispatch(initChat({ chatId: data.chats[0]?.id, userId: data?.id }));
          dispatch(
            setUnreadMessageCounter(data.chats[0]?.unread_messages_count)
          );
        }

        return data.id;
      }

      if (!isRefresh && status === 401) {
        dispatch(refreshCrmToken());
      }

      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(data);

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      Sentry.configureScope((scope) => scope.setExtra('store', crmUrl));
      Sentry.captureException(e);
      return rejectWithValue('error');
    }
  }
);
