import { createReducer } from '@reduxjs/toolkit';
import {
  hideModal,
  openErrorPopup,
  openModal,
  resetModalProps,
  setModalProps,
  setRedirects,
} from './actions';

const initialState = { show: [], props: {} };

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openModal, (state, { payload: id }) => ({
      ...state,
      show: [...state.show, id],
    }))
    .addCase(hideModal, (state, { payload: id }) => ({
      ...state,
      show: state.show.filter((item) => item !== id),
    }))
    .addCase(setModalProps, (state, { payload: { id, props } }) => ({
      // Передача параметров в модальные окна
      ...state,
      props: {
        ...state.props,
        [id]: props,
      },
    }))
    .addCase(resetModalProps, (state, { payload: { id } }) => ({
      // Сброс параметров
      ...state,
      props: Object.keys(state.props).reduce(
        (acc, item) =>
          item === id ? acc : { ...acc, [item]: state.props[item] },
        {}
      ),
    }))
    .addCase(setRedirects, (state, { payload }) => ({
      // Установка редиректов при закрытии модального окна
      ...state,
      redirects: payload,
    }))
    .addCase(openErrorPopup, (state, { payload }) => ({
      ...state,
      show: [...state.show, 'error-popup'],
      props: {
        'error-popup': payload,
      },
    }));
});
