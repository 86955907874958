import { useDispatch } from 'react-redux';
import {
  resetList,
  resetOnlyList,
  setListCategory,
  setListProvider,
  setListSearch,
} from '@box/redux/games';
import { useCurrentGameList } from './useCurrentGameList';
import { useQueryParams } from './useQueryParams';

export const useGamesListFilters = ({
  listId: id,
  resetMode,
  withQueries,
  waitInit = true,
}) => {
  const { setParam } = useQueryParams();
  const dispatch = useDispatch();
  const currentList = useCurrentGameList(id);

  const getFilter = (filter) => (value) => {
    const reset = resetMode === 'onlyList' ? resetOnlyList : resetList;

    if (currentList?.initialized || !waitInit) {
      dispatch(reset(id));
      filter(value);
    }
  };

  const search = (value) => {
    dispatch(setListSearch({ list: id, value }));
    if (withQueries) setParam({ name: 'search', value });
  };

  const category = (value) => {
    dispatch(setListCategory({ list: id, value }));
    if (withQueries) setParam({ name: 'category', value });
  };

  const provider = (value) => {
    dispatch(setListProvider({ list: id, value }));
    if (withQueries) setParam({ name: 'provider', value });
  };

  const resetFilters = () => {
    if (withQueries) {
      setParam({ name: 'provider', value: '' });
      setParam({ name: 'category', value: '' });
      setParam({ name: 'search', value: '' });
    }

    dispatch(resetList(id));
  };

  return {
    setSearch: getFilter(search),
    setCategory: getFilter(category),
    setProvider: getFilter(provider),
    resetFilters,
  };
};
