import { useBreakPoints } from '../hooks';

export const isSomeQueries = (queries) => !!Object.keys(queries).length;

export const mobileGamesBreakPoints = Array(12)
  .fill(0)
  .map((_item, idx) => 1820 - 130 * (idx + 1))
  .sort((a, b) => a - b)
  .reduce(
    (acc, item, idx) => ({
      ...acc,
      [item]: { count: 3 + idx * 3, perRow: idx + 1 },
    }),
    {}
  );

export const useCasinoMobileListCount = () => {
  const value = useBreakPoints(mobileGamesBreakPoints);

  return {
    count: value?.count,
    perRow: value?.perRow,
  };
};
