// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-AviatorSingle-aviatorSingle-module__game{position:relative;width:100%}.\\------shared-components-AviatorSingle-aviatorSingle-module__game iframe{border-width:0;height:100%;position:absolute;width:100%}.\\------shared-components-AviatorSingle-aviatorSingle-module__game_mobile iframe{border:0;height:100%!important;position:relative;width:100%!important}", "",{"version":3,"sources":["webpack://./../../shared/components/AviatorSingle/aviatorSingle.module.scss"],"names":[],"mappings":"AACA,mEAEE,iBAAA,CADA,UACF,CAEE,0EAIE,cAAA,CADA,WAAA,CAFA,iBAAA,CACA,UAEJ,CAII,iFAIE,QAAA,CADA,qBAAA,CAFA,iBAAA,CACA,oBAAN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.game {\n  width: 100%;\n  position: relative;\n\n  iframe {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    border-width: 0;\n  }\n\n  &_mobile {\n    iframe {\n      position: relative;\n      width: 100% !important;\n      height: 100% !important;\n      border: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"game": "------shared-components-AviatorSingle-aviatorSingle-module__game",
	"game_mobile": "------shared-components-AviatorSingle-aviatorSingle-module__game_mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
