import React, { Suspense, lazy, FC } from 'react';

import { Props } from './Flag.types';

import { ReactComponent as WWFlag } from './assets/images/WW.svg';

const Component = lazy(() => import('./Flag'));

export const Flag: FC<Props> = ({ id, ...props }) => {
  return (
    <Suspense fallback={<WWFlag />}>
      <Component id={id} {...props} />
    </Suspense>
  );
};
