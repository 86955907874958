import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './DefaultButton.types';

import styles from './defaultButton.module.scss';

export const DefaultButton: FC<Props> = ({
  className,
  onClick,
  percent,
  active,
  item,
  currencySymbol,
  minBonuses,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(item);
  };

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        styles.item,
        {
          // Фикс бага с undefined default amount.
          // До активации кнопок активность определяется полем defaultValue,
          // после смотрит на поле active
          // Активация лежит в пропсе onClick
          [styles.item_active]: active === item,
          [styles.item_mobile]: isMobile,
        },
        className
      )}
    >
      <span className={styles.value}>
        {t('detalization.deposit')}: {item} {currencySymbol}
      </span>
      {percent && minBonuses <= item && (
        <span className={styles.bonus}>
          {t('bonuses.bonus')} +{percent}%
        </span>
      )}
    </button>
  );
};
