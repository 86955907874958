import React, { FC } from 'react';
import classNames from 'classnames';

import { useApp, useBanners } from '@box/shared/hooks';
import { getStoragePath } from '@box/shared/utils';

import styles from './banner.module.scss';

export const Banner: FC = () => {
  const { list } = useBanners('modal-register');

  const {
    userEnv: { isDesktop },
  } = useApp();

  const { images } = list[0] || {};

  if (!list[0]) return null;

  return (
    <div
      style={{
        backgroundImage: `url(${
          isDesktop
            ? getStoragePath(images?.desktop, 'main')
            : getStoragePath(images?.mobile, 'main')
        })`,
      }}
      className={classNames(styles.banner, {
        [styles.banner_mobile]: !isDesktop,
      })}
    />
  );
};
