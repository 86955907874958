import React, { FC } from 'react';

import { ImageSet } from '@box/ui';

import { Props } from './DesktopCard.types';

import styles from './desktopCard.module.scss';

import FirstIcon from './assets/images/1.png';
import FirstIconAvif from './assets/images/1.avif';
import SecondIcon from './assets/images/2.png';
import SecondIconAvif from './assets/images/2.avif';
import ThirdIcon from './assets/images/3.png';
import ThirdIconAvif from './assets/images/3.avif';
import FourthIcon from './assets/images/4.png';
import FourthIconAvif from './assets/images/4.avif';

export const DesktopCard: FC<Props> = ({
  level,
  name,
  rating,
  rate,
  wager,
  icon,
}) => {
  const iconUrl = () => {
    switch (icon) {
      case '1':
        return {
          png: FirstIcon,
          avif: FirstIconAvif,
        };
      case '2':
        return {
          png: SecondIcon,
          avif: SecondIconAvif,
        };
      case '3':
        return {
          png: ThirdIcon,
          avif: ThirdIconAvif,
        };
      default:
        return {
          png: FourthIcon,
          avif: FourthIconAvif,
        };
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.level}>{level}</div>
      <div className={styles.name}>{name}</div>
      <div className={styles.image}>
        <ImageSet src={iconUrl().png} srcAvif={iconUrl().avif} alt={name} />
      </div>
      <div className={styles.column}>{rating}</div>
      <div className={styles.column}>{rate}</div>
      <div className={styles.column}>{wager}</div>
    </div>
  );
};
