import { z } from 'zod';
import { resolveSafeParse } from './helper';

export const validateEmail = (
  email: string,
  message = 'fields.email_format_error'
) => {
  const result = z.string().email(message).safeParse(email);

  return resolveSafeParse(result);
};
