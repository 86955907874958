import { PlateWithCollapsed as Content } from './PlateWithCollapsed';
import { PlateItem } from './PlateItem';
import { PlateCollapse } from './PlateCollapse';
import { PlateCard } from './PlateCard';

export const PlateWithCollapsed = {
  Content,
  Item: PlateItem,
  Collapse: PlateCollapse,
  Card: PlateCard,
};

export { Plate } from './Plate';
