/* eslint-disable no-lonely-if */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import {
  setModalRegisterOpen,
  userInitSelectors,
} from '@box/redux/authentication';
import { openModal } from '@box/redux/app';

export const useOpenRegisterModal = (isValor?: boolean) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('email-verified')) {
      dispatch(setModalRegisterOpen(true));
    }
  }, []);

  const isUserReady = useAppSelector(userInitSelectors.isReady);
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const isModalRegisterOpen = useAppSelector(
    userInitSelectors.isModalRegisterOpen
  );

  useEffect(() => {
    if (
      isUserReady &&
      !isLogged &&
      isModalRegisterOpen &&
      !localStorage.getItem('hasShownModal')
    ) {
      dispatch(openModal('register'));
      localStorage.setItem('hasShownModal', 'true');
    } else {
      if (isUserReady && !isLogged && isModalRegisterOpen) {
        dispatch(openModal('register'));
      }
    }
  }, [isUserReady, isLogged, isModalRegisterOpen]);
};
