import { createAction } from '@reduxjs/toolkit';

import { AuthUserData, UserData, UserRolesList } from '../../types';
import { DepositBonus } from './types';

export const setUserInfo = createAction<UserData & Partial<AuthUserData>>(
  'user/setUserInfo'
);
export const updatePermissions = createAction<UserData>(
  'user/updatePermissions'
);
export const updateRoles = createAction<UserRolesList>('user/updateRoles');
export const updateDepositBonus = createAction<DepositBonus>(
  'user/updateDepositBonus'
);
