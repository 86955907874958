import classNames from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LightButton } from '@box/ui';
import { getImageUrl } from '@box/shared/utils';

import {
  BlockWithLoading,
  Button,
  LightBlockWithLoading,
} from '../../baseComponents';
import { getStoragePath } from '../../utils/getStoragePath';

import { withGameItemState } from '../GameItem';
import { ImageLoader } from '../ImageLoader';

import { Props } from './Games.types';

import styles from './game.module.scss';
import { ReactComponent as TriangleIcon } from './assets/triangle.svg';
import { ReactComponent as LogoIcon } from './assets/Logo.svg';
import { ReactComponent as JuneLogoIcon } from './assets/Logo_june.svg';
import { useApp, useOpenGames } from '../../hooks';

const ImagePlaceholder = () => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div className={styles.placeholder}>
      {isJune ? <JuneLogoIcon /> : <LogoIcon />}
    </div>
  );
};

const DefaultGame: FC<Props> = ({
  id,
  name,
  image,
  hasDemo,
  loading,
  handleClick,
  externalId,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile, isJune },
  } = useApp();

  const { openGame } = useOpenGames();

  const openDemoGame = () =>
    openGame({ id, withRedirect: true, hasDemo, demo: true });

  const type = () => {
    if (isMobile) {
      return 'defaultMobile';
    } else {
      return 'defaultDesktop';
    }
  };

  return (
    <div
      className={classNames(styles.card, {
        [styles.card_loading]: loading,
        [styles.card_mobile]: isMobile,
        [styles.card_june]: isJune,
      })}
      onClick={handleClick}
      aria-hidden='true'
    >
      <ImageLoader
        className={styles.img}
        src={
          isJune
            ? getImageUrl(externalId, type())
            : getStoragePath(image, 'main')
        }
        alt={name}
        fullback={<ImagePlaceholder />}
      />
      {isJune ? (
        <LightBlockWithLoading className={styles.loading} loading={loading} />
      ) : (
        <BlockWithLoading loading={loading} />
      )}
      {!isJune ? (
        <>
          <div className={styles.bottom}>
            <div className={styles.name}>{name}</div>
          </div>
          <div className={styles.buttons}>
            {isJune ? (
              <LightButton className={styles.buttonJune} onClick={handleClick}>
                {t('common.play')}
              </LightButton>
            ) : (
              <Button
                className={styles.button}
                color='secondary'
                hoverType='opacity'
                onClick={handleClick}
              >
                {t('common.play')}
              </Button>
            )}

            <span
              className={styles.demo}
              onClick={openDemoGame}
              aria-hidden='true'
            >
              {t('common.demo')}
            </span>
          </div>
        </>
      ) : (
        <div
          onClick={handleClick}
          aria-hidden='true'
          className={styles.hoverBox}
        >
          <p className={styles.name}>{name}</p>
          <TriangleIcon />
        </div>
      )}
    </div>
  );
};

export const Game = withGameItemState(DefaultGame);
