import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { requestStatuses } from '../utils/constants';

const all = (state: RootState) => state.bonuses;
export const list = (state: RootState) => all(state).list;
export const loading = (state: RootState) => all(state).loading;
export const init = (state: RootState) =>
  all(state).status === requestStatuses.init;
export const detail = (state: RootState) => all(state).detail;

const getId = (_state, id) => id;

export const bonusById = createSelector(
  [list, getId],
  (l, id) => l.find((item) => item.id === id) || null
);
