import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';
import {
  FetchFavoritesReturn,
  GETGameFavorites,
  SetFavoritePayload,
  SetFavoriteReturn,
  RemoveFavoriteReturn,
  RemoveFavoritePayload,
  POSTSetFavoriteRequest,
  DELETEFavoriteRequest,
} from './types';

export const fetchFavorites = createAsyncThunk<
  FetchFavoritesReturn,
  void,
  AppThunkApi
>('games/fetchFavorites', async (_payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.get<GETGameFavorites>('/games/favorite');

    if (status === 200) {
      return data.data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const setFavoriteRequest = createAsyncThunk<
  SetFavoriteReturn,
  SetFavoritePayload,
  AppThunkApi
>('games/setFavorite', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { gameId } = payload;

  try {
    const { status } = await api.post<POSTSetFavoriteRequest>(
      '/games/favorite',
      { id: gameId }
    );

    if (status === 201) {
      return { gameId };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const removeFavoriteRequest = createAsyncThunk<
  RemoveFavoriteReturn,
  RemoveFavoritePayload,
  AppThunkApi
>('games/removeFavorite', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { gameId } = payload;

  try {
    const { status } = await api.delete<DELETEFavoriteRequest>(
      `/games/favorite/${gameId}`
    );

    if (status === 200) {
      return { gameId };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
