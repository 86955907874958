import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { commonSelectors } from '@box/redux/common';
import {
  fetchWithdrawalRequests,
  walletsSelectors,
  withdrawalRequestsSelectors,
} from '@box/redux/finance';
import { getStatus } from '@box/shared/utils';

import { useDateFormat } from '../../../../hooks';

export const withWithdrawalList = (Component) => {
  const wrapper = ({ ...props }) => {
    const { t } = useTranslation();
    const { listId, userId } = props;

    const dispatch = useDispatch();
    const currencies = useSelector(commonSelectors.currencies);
    const { list, ready, currentPage, lastPage, loading } = useSelector(
      (state) => withdrawalRequestsSelectors.listById(state, listId)
    );
    const wallets = useSelector(walletsSelectors.list);

    const { getDetalizationDate } = useDateFormat();

    const getCurrencySymbol = (name) =>
      currencies.find((item) => item.code === name)?.symbol;

    const getWalletName = (walletId) =>
      wallets.find((item) => item.id === walletId)?.name;

    useEffect(() => {
      if (!ready && !loading) {
        dispatch(
          fetchWithdrawalRequests({
            id: listId,
            userId,
            sort: 'desc',
            sortby: 'created_at',
          })
        );
      }
    }, [ready, loading]);

    const nextPage = () => {
      dispatch(
        fetchWithdrawalRequests({
          id: listId,
          userId,
          page: currentPage + 1,
          sort: 'desc',
          sortby: 'created_at',
        })
      );
    };

    const resultList =
      list?.map((item) => ({
        date: getDetalizationDate(item.created_at),
        amount: parseFloat(+item.amount).toFixed(2),
        currencySymbol: getCurrencySymbol(getWalletName(item.wallet_id)),
        walletName: getWalletName(item.wallet_id),
        status: getStatus(item),
        isWithdrawal: true,
        withdrawal_amount: item?.withdrawal_amount,
        confirm: item?.confirm,
      })) || [];

    const waitingList = resultList.filter((item) => item?.status === 'pending');
    const approvedList = resultList.filter(
      (item) => item?.status === 'approved'
    );
    const declinedList = resultList.filter(
      (item) => item?.status === 'declined'
    );

    const Lists = [
      {
        title: t('detalization.completed'),
        list: approvedList,
      },
      {
        title: t('detalization.waiting'),
        list: waitingList,
      },
      {
        title: t('detalization.declined'),
        list: declinedList,
      },
    ];

    return (
      <>
        {Lists.map((elem, index) => (
          <Component
            key={index}
            title={elem.title}
            {...props}
            list={elem.list}
            nextPage={nextPage}
            isNextPageAvailable={currentPage < lastPage}
            pagingLoading={loading}
            loading={!ready}
          />
        ))}
      </>
    );
  };

  return wrapper;
};
