import React, { FC } from 'react';

import { Props } from './FaqsInput.types';

import { ReactComponent as SearchSvg } from './assets/images/search.svg';

import styles from './FaqsInput.module.scss';

export const FaqsInput: FC<Props> = ({ ...props }) => {
  return (
    <div className={styles.container}>
      <SearchSvg />
      <input className={styles.input} {...props} />
    </div>
  );
};
