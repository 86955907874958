/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useApp } from '@box/shared/hooks';
import { openImageViewer } from '@box/redux/app';
import { documentsSelectors } from '@box/redux/user';
import { ImageSet } from '@box/ui';

import { getStoragePath } from '../../../../utils/getStoragePath';

import { ReactComponent as FileIcon } from './assets/file.svg';
import ErrorPng from './assets/error.png';
import ErrorWebp from './assets/error.webp';
import ErrorAvif from './assets/error.avif';
import { useDisabled } from '../UserInfo';

const PhotoError = ({ label }) => {
  return (
    <div className='documents-photos-imageError'>
      <p className='documents-photos-imageError__label'>{label}</p>
      <ImageSet
        className='documents-photos-imageError__image'
        src={ErrorPng}
        srcWebp={ErrorWebp}
        srcAvif={ErrorAvif}
        alt='error'
      />
    </div>
  );
};

const Upload = ({ name, label, disabled, first }) => {
  const [isError, setError] = useState(false);
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useDispatch();

  const [preview, setPreview] = useState('');

  const currentDocument = useSelector(documentsSelectors.current);
  const errors = useSelector(documentsSelectors.error);

  const photoError = useMemo(() => {
    if (errors?.photo) {
      return errors.photo[0];
    }
    return '';
  }, [errors]);

  const scanError = useMemo(() => {
    if (errors?.scan) {
      return errors.scan[0];
    }
    return '';
  }, [errors]);

  const { control } = useFormContext();

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: { required: t('fields.required') },
  });

  useEffect(() => {
    if (!isEmpty(currentDocument)) {
      setPreview(getStoragePath(currentDocument[name], 'main'));
    }
  }, [currentDocument]);

  const onFileChange = async (e) => {
    const file = e.target.files[0];

    if (e.target.files[0]) {
      setPreview(URL.createObjectURL(file));
      onChange(file);
    }
  };

  const openPreview = () => {
    if (!isError) {
      dispatch(openImageViewer(preview));
    }
  };

  return (
    <div className='documents-photos__input'>
      <div className='documents-photos__input-head'>
        <span className='documents-photos__input-head-name'>{label}</span>
      </div>
      {!disabled && (
        <label
          className={classNames('documents-photos__input-upload', {
            'documents-photos__input-upload_mobile': isMobile,
          })}
          htmlFor={name}
        >
          <span>
            {t('common.upload')}
            <FileIcon />
          </span>
          <input
            id={name}
            type='file'
            onChange={onFileChange}
            accept='image/png, image/jpg, image/jpeg'
          />
        </label>
      )}
      {preview && (
        <div
          className={classNames('documents-photos__input-preview', {
            'documents-photos__input-preview_error': isError,
          })}
          onClick={openPreview}
          aria-hidden='true'
        >
          {isError && <PhotoError label={label} />}
          <img src={preview} alt={label} onError={() => setError(true)} />
        </div>
      )}
      {error && <span className='form-error'>{t(error.message)}</span>}
      {first && scanError && <span className='form-error'>{scanError}</span>}
      {!first && photoError && <span className='form-error'>{photoError}</span>}
    </div>
  );
};

export const Photos = React.memo(() => {
  const { t } = useTranslation();

  const { disabled } = useDisabled();

  return (
    <div className='documents-photos'>
      <Upload
        name='scan'
        label={t('settings.document_photo')}
        disabled={disabled}
        first
      />
      <Upload
        name='photo'
        label={t('settings.document_selfie')}
        disabled={disabled}
      />
    </div>
  );
});
