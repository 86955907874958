import React from 'react';

import { useBanners } from '@box/shared/hooks';

import { getStoragePath } from '../../../../utils';

import styles from './Banner.module.scss';

const Banner = () => {
  const { list } = useBanners('modal-register');

  const { title, description, images } = list[0] || {};

  if (!list[0]) return null;

  return (
    <div className={styles.banner}>
      <div
        className={styles.content}
        style={{
          backgroundImage: `url(${getStoragePath(images?.desktop, 'main')})`,
        }}
      >
        <span className={styles.title}>
          <small>{title}</small>
        </span>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};

export default Banner;
