import React, { FC } from 'react';
import { NavLink, useLocation, matchPath, useMatch } from 'react-router-dom';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import styles from './lightnavigation.module.scss';
import { ReactComponent as AviatorSvg } from './assets/images/aviator.svg';
import {
  LightNavigationProps,
  LightNavigationItem,
} from './LightNavigation.types';

export const CustomLink: FC<LightNavigationItem> = ({
  label,
  excludedActive,
  navigationMenu,
  component: Component,
  icon: Icon,
  isAviator,
  ...props
}) => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  const { pathname } = useLocation();

  const getClassNames = ({ isActive }: { isActive: boolean }): string => {
    const match = excludedActive?.find((item) => matchPath(item, pathname));

    return classNames(isDesktop ? styles.desktop_link : styles.navigationLink, {
      [styles.navigationActiveLink]: !match && isActive,
      [styles.navigationMenuLink]: navigationMenu && isDesktop,
    });
  };

  const isAviatorMatch = useMatch({
    path: '/casino/current/aviator',
    end: false,
  });

  return Component ? (
    <div className={getClassNames({ isActive: !!isAviatorMatch })}>
      <Component />
    </div>
  ) : (
    <NavLink end className={getClassNames} {...props}>
      {Icon && !navigationMenu && <Icon />}
      {label && (
        <p
          className={classNames(
            isDesktop ? styles.desktop_label : styles.label
          )}
        >
          {label}
        </p>
      )}
      {isAviator && (
        <div
          className={classNames(styles.aviator, {
            [styles.aviator_desktop]: isDesktop,
          })}
        >
          <AviatorSvg />
        </div>
      )}
    </NavLink>
  );
};

export const LightNavigation: FC<LightNavigationProps> = ({
  className,
  items,
}) => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  return (
    <nav className={classNames(className, styles.navigation)}>
      <ul
        className={classNames(styles.navigationList, {
          [styles.navigationList_desktop]: isDesktop,
        })}
      >
        {items.map((item, idx) => (
          <li className={styles.navigationItem} aria-hidden='true' key={idx}>
            <CustomLink {...item} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
