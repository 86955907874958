import { SupportChat as DefaultSupportChat } from './SupportChat';
import { LightSupportChat as DefaultLightSupportChat } from './LightSupportChat';
import { withState } from './SupportChat.state';

export const SupportChat = withState(DefaultSupportChat);
export const LightSupportChat = withState(DefaultLightSupportChat);

export { Badge as ChatBadge } from './Badge';

export type { SupportChatProps } from './SupportChat.types';
