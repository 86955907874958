import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  useLightNavLinks,
  useScrollAndResize,
  useApp,
} from '@box/shared/hooks';
import { LightNavigation } from '@box/ui';

import { MenuSearch } from '@box/shared/components';

import { MenuDropdown } from '@box/shared/components/MenuDropdown';
import styles from './menu.module.scss';
import { ReactComponent as SearchSvg } from './assets/images/search.svg';

export const Menu = () => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  const { t } = useTranslation();
  const [activeSearch, toggleActiveSearch] = useToggle(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(-1);

  const dropdownBreakpoint = 1400;
  const { windowSize } = useScrollAndResize();
  const allNavLinks = useLightNavLinks();
  const [visibleLinksCount, setVisibleLinksCount] = useState(5);

  useEffect(() => {
    if (windowSize.width > dropdownBreakpoint) {
      setVisibleLinksCount(Math.floor(windowSize.width / 225));
    } else {
      setVisibleLinksCount(5);
    }
  }, [windowSize.width]);

  const navLinks = allNavLinks.slice(0, visibleLinksCount);
  const dropdownLinks = allNavLinks.slice(visibleLinksCount);

  if (selectedLinkIndex !== -1) {
    const linkExists = navLinks.some(
      (link) => link.label === dropdownLinks[selectedLinkIndex]?.label
    );

    if (!linkExists) {
      navLinks.push(dropdownLinks[selectedLinkIndex]);
    }

    const linkInDropdownLinks = dropdownLinks.some(
      (link) => link.label === dropdownLinks[selectedLinkIndex]?.label
    );

    if (!linkInDropdownLinks) {
      dropdownLinks.splice(selectedLinkIndex, 1);
    }
  }

  return (
    <menu
      className={classNames(styles.menu, { [styles.menu_desktop]: isDesktop })}
    >
      <button
        onClick={toggleActiveSearch}
        className={styles.toggleSearch}
        type='button'
      >
        <SearchSvg />
        {!isDesktop && t('navigation.search')}
      </button>
      {activeSearch && (
        <MenuSearch setVisible={toggleActiveSearch} isOpen={activeSearch} />
      )}
      {!activeSearch && (
        <>
          <LightNavigation items={isDesktop ? navLinks : allNavLinks} />
          {dropdownLinks.length > 0 && isDesktop && (
            <MenuDropdown
              items={dropdownLinks}
              onLinkClick={setSelectedLinkIndex}
            />
          )}
        </>
      )}
    </menu>
  );
};
