import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useApp } from '@box/shared/hooks';
import { useAppDispatch } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';

export const useNavigateToDeposit = () => {
  const navigate = useNavigate();
  const { route, mirrors } = useApp();
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const isCurrentGame = useMatch(route('casino.current', { id }));

  const { isMirror3 } = mirrors;

  const handleNavigate = () => {
    navigate(route('profile.payments', { id: 'deposit' }));
  };

  const handleOpenModal = () => {
    dispatch(openModal('deposit'));
  };

  return {
    openDeposit:
      isMirror3 ||
      isCurrentGame ||
      window.location.href.includes('/casino/current')
        ? handleOpenModal
        : handleNavigate,
  };
};
