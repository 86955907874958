import { createSlice } from '@reduxjs/toolkit';
import { removeFavorite, setFavorite } from './actions';
import { fetchFavorites } from './thunks';
import { FavoritesInitialState } from './types';

const initialState: FavoritesInitialState = {
  list: [],
  loading: false,
};

export const slice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFavorites.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(fetchFavorites.rejected, (state) => {
        state.loading = false;
      })

      .addCase(setFavorite, (state, { payload }) => {
        state.list = [...state.list, payload];
      })
      .addCase(removeFavorite, (state, { payload }) => {
        state.list = state.list.filter((item) => item.id !== payload);
      });
  },
});
