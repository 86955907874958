import React, { FC } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApp, useNavigateToDeposit } from '@box/shared/hooks';

import { Props } from './Menu.types';

import styles from './menu.module.scss';

export const Menu: FC<Props> = ({ className, menuList, onClick }) => {
  const { t } = useTranslation();
  const { openDeposit } = useNavigateToDeposit();
  const location = useLocation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const checkActiveLink = (path: string) => {
    if (location.pathname === path) {
      return true;
    }
    return false;
  };

  const handleClick = () => {
    openDeposit();

    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames(
        styles.menu,
        { [styles.menu_mobile]: isMobile },
        className
      )}
    >
      {menuList.map((item, index) => {
        const { Icon } = item;
        if (item.type === 'deposit') {
          return (
            <button
              onClick={handleClick}
              type='button'
              className={classNames(styles.link, {
                [styles.link_active]: checkActiveLink(item.path),
              })}
            >
              <Icon />
              {t(item.label)}
            </button>
          );
        }

        return (
          <Link
            className={classNames(styles.link, {
              [styles.link_active]: checkActiveLink(item.path),
              [styles.link_loyalty]: item.type === 'loyalty',
            })}
            key={index}
            onClick={() => {
              if (onClick) {
                onClick();
              }
            }}
            to={item.path}
          >
            <Icon />
            {t(item.label)}
          </Link>
        );
      })}
    </div>
  );
};
