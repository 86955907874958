import { combineReducers } from 'redux';

import favoritesReducer from './favorites';
import currentReducer from './current';
import listsReducer from './lists';
import historyReducer from './history';
import catalogsReducer from './catalogs';

const reducer = combineReducers({
  favorites: favoritesReducer,
  current: currentReducer,
  lists: listsReducer,
  history: historyReducer,
  catalogs: catalogsReducer,
});

export * from './favorites';
export * from './current';
export * from './lists';
export * from './history';
export * from './catalogs';

export default reducer;
