import React, { FC } from 'react';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { Props } from './InnerModal.types';

import { ReactComponent as ArrowSvg } from '../../public/svg/arrows/back.svg';

import styles from './innerModal.module.scss';

export const InnerModal: FC<Props> = ({
  children,
  onBackClick,
  onClose,
  back,
  className,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };
  return (
    <>
      <div
        className={classNames(
          styles.modal,
          {
            [styles.modal_mobile]: isMobile,
          },
          className
        )}
      >
        <div className={styles.header}>
          <button onClick={onBackClick} className={styles.back} type='button'>
            <ArrowSvg />
            <p className={styles.text}>{back}</p>
          </button>
        </div>
        {children}
      </div>
      <div
        tabIndex={0}
        role='button'
        onKeyUp={handleKeyUp}
        className={styles.wrapper}
        onClick={onClose}
      />
    </>
  );
};
