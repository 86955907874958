import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideModal } from '@box/redux/app';

import { Modal } from '../../Modal';
import Tabs from '../../Tabs/Tabs';

import { ActiveTab, CompletedTab, NotStartedTab } from './BonusTabs';
import styles from './Bonuses.module.scss';

export const BonusesModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideModal('bonuses'));
  }, [pathname]);

  const tabs = useMemo(
    () => [
      {
        title: t('bonuses.active'),
        component: ActiveTab,
      },
      {
        title: t('bonuses.completed'),
        component: CompletedTab,
      },
      {
        title: t('bonuses.not_started'),
        component: NotStartedTab,
      },
    ],
    [language]
  );

  return (
    <Modal
      id='bonuses'
      title={t('bonuses.title')}
      bodyClassName={styles.modal__body}
    >
      <Tabs tabs={tabs} />
    </Modal>
  );
};
