import React, { FC } from 'react';
import Countdown from 'react-countdown';

import { Props } from './Timer.types';

import './timer.scss';

export const Timer: FC<Props> = ({ date }) => {
  return (
    <Countdown
      date={date}
      zeroPadTime={2}
      zeroPadDays={0}
      renderer={({ formatted: { days, hours, minutes, seconds } }) => (
        <div className='timer'>
          <div className='timer__block timer__day'>{days || '0'}</div>
          <div className='timer__block'>{hours || '0'}</div>
          <div className='timer__block'>{minutes || '0'}</div>
          <div className='timer__block'>{seconds || '0'}</div>
        </div>
      )}
    />
  );
};
