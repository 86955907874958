import React from 'react';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffectOnce } from 'react-use';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { fetchSlotGames, slotGamesSelectors } from '@box/redux/slots';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useSlotPagination } from '@box/shared/hooks';

import { SlotsWithStateWrapperProps } from './SlotGamesList.types';

export const slotsWithState = (Component) => {
  const wrapper = ({
    position,
    perPage,
    listId,
    ...props
  }: SlotsWithStateWrapperProps) => {
    const [params] = useSearchParams();

    const search = params.get('search');

    const currentList = useAppSelector((state) =>
      slotGamesSelectors.listById(state, listId)
    );

    const hasMorePages = useAppSelector((state) =>
      slotGamesSelectors.listHasMorePages(state, listId)
    );

    const { next } = useSlotPagination({
      perPage,
      search,
      listId,
      position,
    });

    return (
      <InfiniteScroll
        hasMore={hasMorePages}
        next={next}
        dataLength={currentList ? currentList.list.length : 0}
        loader={<BlockWithLoading loading />}
      >
        <Component list={currentList ? currentList.list : []} {...props} />
      </InfiniteScroll>
    );
  };

  return wrapper;
};

export const showCaseState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { alias, maxCount, perPage, listId } = props;

    const dispatch = useAppDispatch();

    const gamesList = useAppSelector((state) =>
      slotGamesSelectors.listById(state, listId)
    );

    useEffectOnce(() => {
      if (!gamesList) {
        dispatch(
          fetchSlotGames({
            page: 1,
            perPage,
            position: alias,
            listId,
          })
        );
      }
    });

    return (
      <Component
        {...props}
        list={
          gamesList
            ? maxCount
              ? gamesList.list.slice(0, maxCount)
              : gamesList.list
            : undefined
        }
        loading={gamesList ? gamesList?.loading : true}
      />
    );
  };

  return wrapper;
};
