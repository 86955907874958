import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import i18next from 'i18next';
import { openInfoPopup, resetModalProps } from '@box/redux/app';
import { useAppSelector } from '@box/redux/hooks';

import { Button } from '../../../baseComponents';
import { Modal } from '../../Modal';

import styles from './Transfer.module.scss';
import { TransferProps } from './Transfer.types';

export const TransferModal: FC<TransferProps> = ({
  onHide,
  id = 'transfer',
}) => {
  const props = useAppSelector((state) => state.app.modals.props);
  const dispatch = useDispatch();
  const currentProps = props[id];

  const handleHide = () => {
    if (onHide) onHide();

    dispatch(resetModalProps({ id }));
  };

  const onCopyToClipboard = () => {
    dispatch(
      openInfoPopup({
        text: i18next.t('settings.success_copy'),
        type: 'success',
      })
    );
  };

  return (
    <Modal
      className={styles.transfer}
      id={id}
      onHide={handleHide}
      title={currentProps?.title}
    >
      <div className={styles.body}>
        <div
          className='transfer__text'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: currentProps?.text }}
        />
        <CopyToClipboard
          text={currentProps?.walletNumber}
          onCopy={onCopyToClipboard}
        >
          <Button
            color='secondary'
            hoverType='opacity'
            className={styles.button}
            type='button'
          >
            {currentProps?.buttonText}
          </Button>
        </CopyToClipboard>
      </div>
    </Modal>
  );
};
