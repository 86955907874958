import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import { resetSupport } from '@box/redux/support';
import { addPartnerUrl } from '@box/redux/partner/streams';
import {
  setFirstVisitParams,
  getStatParams,
  getFirstVisitParams,
} from '@box/redux/utils';
import { openModal } from '@box/redux/app';

import { resetUserDocuments } from '@box/redux/user';
import {
  paymentReset,
  transactionsReset,
  walletReset,
  withdrawalRequestsReset,
} from '../../finance';
import {
  initUser,
  resetUser,
  updateDepositBonus,
  updatePermissions,
} from '../../user/info';
import { useAuthTokens } from '../../hooks';
import { fetchInitChatsUser } from '../../chats/init';
import { resetFavorites } from '../../games/favorites';
import { setUserId } from '../../chats/init/actions';
import { fetchChatsRegister } from '../../chats/register';
import { AppThunkApi } from '../../types';

import {
  AuthorizePayload,
  AuthUserReturn,
  CrmUserData,
  GETMe,
  POSTLogin,
  RefreshTokenReturn,
  FetchPermissionsReturn,
  FetchDepositBonusReturn,
  SetFirstVisitFetchReturn,
} from './types';
import { setIsConnected, setIsPartnerSetTrue } from './actions';

export const emptyToken = createAsyncThunk<void, void, AppThunkApi>(
  'auth/init/emptyToken',
  async (_payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { accessToken, isExpires, refreshToken, expiresIn } =
        useAuthTokens();

      await api.get<void>('/empty-token', {
        params: {
          isExpires,
          refreshToken:
            refreshToken === null
              ? false
              : refreshToken.length > 5
              ? refreshToken.substring(refreshToken.length - 5)
              : refreshToken,
          accessToken:
            accessToken === null
              ? false
              : accessToken.length > 5
              ? accessToken.substring(accessToken.length - 5)
              : accessToken,
          expiresIn,
        },
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const emptyRefreshToken = createAsyncThunk<void, void, AppThunkApi>(
  'auth/init/emptyRefreshToken',
  async (_payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { accessToken, isExpires, refreshToken, expiresIn } =
        useAuthTokens();

      await api.get<void>('/empty-refresh-token', {
        params: {
          isExpires,
          refreshToken:
            refreshToken === null
              ? false
              : refreshToken.length > 5
              ? refreshToken.substring(refreshToken.length - 5)
              : refreshToken,
          accessToken:
            accessToken === null
              ? false
              : accessToken.length > 5
              ? accessToken.substring(accessToken.length - 5)
              : accessToken,
          expiresIn,
        },
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logoutRequest = createAsyncThunk<void, void, AppThunkApi>(
  'auth/init/logoutRequest',
  async (_payload, { extra }) => {
    const { api } = extra;

    try {
      await api.post<void>('/logout');
    } catch (err) {
      return err;
    }
  }
);

export const authorize = createAsyncThunk<void, AuthorizePayload, AppThunkApi>(
  'auth/init/authorize',
  (payload, { dispatch, extra }) => {
    const { data, isRegisterRequest } = payload;

    const { history, mirrors, isJune } = extra;

    const { setAccessToken, setRefreshToken, setExpires } = useAuthTokens();

    const { isMirror3 } = mirrors;

    if (data.access_token && data.refresh_token) {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      setExpires(data.expires_in);
    }

    if (data.partner_stream !== null) {
      dispatch(addPartnerUrl(data.partner_stream.url));
    }

    if (isRegisterRequest && isJune) {
      if (isMirror3) {
        dispatch(openModal('deposit'));
      } else {
        history.push('/profile/payments/deposit');
      }
    }

    dispatch(initUser({ isLogged: true, user: data }));
  }
);

export const logout = createAsyncThunk<void, void, AppThunkApi>(
  'auth/init/logout',
  (_payload, thunkAPI) => {
    const { deleteAllTokens, deleteAllCrmTokens, deleteExpires } =
      useAuthTokens();

    deleteAllTokens();
    deleteAllCrmTokens();
    deleteExpires();
    Cookies.remove('isAutoAuth');

    thunkAPI.dispatch(resetUser());
    thunkAPI.dispatch(resetUserDocuments());
    thunkAPI.dispatch(walletReset());
    thunkAPI.dispatch(withdrawalRequestsReset());
    thunkAPI.dispatch(transactionsReset());
    thunkAPI.dispatch(paymentReset());
    thunkAPI.dispatch(resetFavorites());
    thunkAPI.dispatch(resetSupport());
    thunkAPI.dispatch(logoutRequest());
  }
);

export const refreshToken = createAsyncThunk<
  RefreshTokenReturn,
  void,
  AppThunkApi
>(
  'login/refreshToken',
  async (_payload, { dispatch, extra, rejectWithValue }) => {
    const { api, user } = extra;

    try {
      const { refreshToken: token, deleteAllTokens } = useAuthTokens();

      await dispatch(emptyRefreshToken());

      if (!token) {
        deleteAllTokens();

        dispatch(initUser({ isLogged: false }));

        return rejectWithValue(i18next.t('requestErrors.no_authorization'));
      }

      const { data, status } = await api.post<POSTLogin>('/login?refresh', {
        refresh_token: token,
        with: user.requestExtra,
      });

      if (status === 200) {
        dispatch(authorize({ data }));
        return data;
      }

      deleteAllTokens();

      dispatch(initUser({ isLogged: false }));

      return rejectWithValue('Error');
    } catch (err) {
      dispatch(logout());
      return rejectWithValue('Error');
    }
  }
);

export const connectExternal = createAsyncThunk<
  AuthUserReturn,
  string,
  AppThunkApi
>(
  'me/connect-external',
  async (payload, { dispatch, extra, rejectWithValue }) => {
    const { api, crmUrl } = extra;
    const { setCrmAccessToken } = useAuthTokens();
    try {
      const { data, status } = await api.post<CrmUserData>(
        'v1/me/connect-external',
        { main_access_token: payload },
        {
          baseURL: crmUrl,
        }
      );

      if (status === 200) {
        setUserId(data.id);
        if (data.access_token) {
          setCrmAccessToken(data.access_token);
        }
        dispatch(fetchInitChatsUser({}));
        return data;
      }

      if (status === 401) {
        dispatch(fetchChatsRegister());
        return rejectWithValue('Error');
      }

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.no_authorization'));
    }
  }
);

export const authUser = createAsyncThunk<AuthUserReturn, void, AppThunkApi>(
  'auth/init/authUser',
  async (_payload, { dispatch, extra, rejectWithValue }) => {
    const { api, user } = extra;

    try {
      const { accessToken, isExpires } = useAuthTokens();

      await dispatch(emptyToken());

      if (isExpires || !accessToken) {
        await dispatch(refreshToken());
        dispatch(setFirstVisitFetch());
        return rejectWithValue('Error');
      }

      const { data, status } = await api.get<GETMe>('/me', {
        params: {
          with: user.requestExtra,
        },
      });

      if (status === 200) {
        dispatch(authorize({ data }));
        dispatch(setIsConnected());
        setFirstVisitParams(data.partner_params);
        dispatch(setIsPartnerSetTrue());
        return data;
      }

      if (status === 401 || status === 403) {
        await dispatch(refreshToken());
        return rejectWithValue(i18next.t('requestErrors.no_authorization'));
      }

      dispatch(setFirstVisitFetch());

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.no_authorization'));
    }
  }
);

export const fetchPermissions = createAsyncThunk<
  FetchPermissionsReturn,
  void,
  AppThunkApi
>(
  'auth/init/fetchPermissions',
  async (_payload, { dispatch, extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data, status } = await api.get<GETMe>('/me', {
        params: {
          with: 'permissions,roles.permissions',
          append: 'is_deposit_wagered',
        },
      });

      if (status === 200) {
        dispatch(updatePermissions(data));
        return data;
      }

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.no_authorization'));
    }
  }
);

export const fetchDepositBonus = createAsyncThunk<
  FetchDepositBonusReturn,
  void,
  AppThunkApi
>(
  'auth/init/fetchDepositBonus',
  async (_payload, { dispatch, extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data, status } = await api.get<GETMe>('/me', {
        params: {
          with: 'depositBonus',
        },
      });

      if (status === 200) {
        dispatch(updateDepositBonus(data.deposit_bonus));
        return data;
      }

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.no_authorization'));
    }
  }
);

export const setFirstVisitFetch = createAsyncThunk<
  SetFirstVisitFetchReturn,
  void,
  AppThunkApi
>(
  'auth/init/setFirstVisitFetch',
  async (_payload, { extra, rejectWithValue }) => {
    const { api, isMobile } = extra;

    const urlParams = new URLSearchParams(window.location.search);
    const visit = urlParams.get('visit');

    if (visit === 'false') {
      return;
    }

    try {
      const statParams = getStatParams();
      if (Object.keys(getFirstVisitParams()).length === 0) {
        const { data, status } = await api.post(
          `/stat/visit?is_mobile=${!!isMobile}`,
          { partner_params: statParams }
        );

        if (status === 200) {
          setFirstVisitParams(data);
          return data;
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
