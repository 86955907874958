export interface Chat {
  id: number;
  title: string;
  description: string;
  image: string;
  type: string;
  chat_key: string;
  permission: string;
  last_message_at: string;
  last_message_id: number;
  fixed_message_id: number;
  ordering: number;
  admin_ordering: number;
  messages_count: number;
  subscribers_count: number;
  real_subscribers_count: number;
  last_viewed_at: string;
  unread_messages_count: number;
  viewed_messages_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
