import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './types';

const initialState: InitialState = {
  exchangeRate: '600 = $1',
  points: 100,
  historyList: [
    {
      date: '12.06.22',
      time: '22:01',
      vc: 1200,
      getMoney: '$2',
      exchangeRate: '600 : 1',
      status: 'Jackerys Velaryon',
    },
    {
      date: '12.06.22',
      time: '22:01',
      vc: 1200,
      getMoney: '$2',
      exchangeRate: '600 : 1',
      status: 'Jackerys Velaryon',
    },
  ],
};

export const slice = createSlice({
  name: 'loyalty/chart',
  initialState,
  reducers: {},
});
