import { createSlice } from '@reduxjs/toolkit';

import { requestStatuses } from '../../utils/constants';

import { fetchPaymentMethods } from './thunks';
import { PaymentMethodsInitialState } from './types';
import { setSelectedMethod } from './actions';

const initialState: PaymentMethodsInitialState = {
  list: [],
  status: requestStatuses.notStarted,
  selectedMethod: null,
};

export const slice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentMethods.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.status = requestStatuses.init;
      })
      .addCase(setSelectedMethod, (state, { payload }) => {
        state.selectedMethod = payload;
      });
  },
});
