import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';
import { userSelectors } from '@box/redux/user';

import { DefaultButton } from '../../ui/DefaultButton';

import { Props } from './DefaultsButtons.types';

import styles from './defaultsButtons.module.scss';

export const DefaultsButtons: FC<Props> = ({
  percent,
  defaults,
  currencySymbol,
  defaultValue,
  minDepSum,
}) => {
  const { setValue, watch } = useFormContext();
  const [isActive, setActive] = useState(false);

  const user = useAppSelector(userSelectors.user);

  const getItemValue = (value: number | number[]) => {
    if (typeof value === 'number') {
      return value;
    }
    if (user?.deposits_count) {
      return value[user.deposits_count];
    }
    return value[0];
  };

  const handleClick = (item) => {
    setValue('amount', item);
    setActive(true);
  };

  const watchAmount = watch('amount');

  return (
    <div className={styles.buttons}>
      {defaults.map((item, idx) => (
        <DefaultButton
          defaultValue={defaultValue}
          className={styles.item}
          item={getItemValue(item)}
          currencySymbol={currencySymbol}
          percent={percent}
          minBonuses={minDepSum}
          active={watchAmount}
          key={idx}
          onClick={handleClick}
          isActive={isActive}
        />
      ))}
    </div>
  );
};
