import isEmpty from 'lodash/isEmpty';

import { WithdrawalWallet } from '../../types';
import { getPureErrorMessage } from '../../utils';

import { GetOrCreateWallet } from './types';

export const getOrCreateWallet: GetOrCreateWallet = async ({
  userId,
  name,
  fieldValues,
  merchantId,
  methodId,
  api,
  confirm,
}) => {
  try {
    const { data } = await api.get<{ data: WithdrawalWallet[] }>(
      `/userdatas/${userId}/withdrawal-wallets`,
      {
        params: {
          fields_values: fieldValues,
          merchant_id: merchantId,
          method_id: methodId,
          confirm,
        },
      }
    );

    if (!isEmpty(data.data)) {
      return {
        withdrawalWalletId: data.data[0].id,
      };
    }

    const { data: newWithdrawal, status } = await api.post<WithdrawalWallet>(
      `/userdatas/${userId}/withdrawal-wallets`,
      {
        name,
        status: 'active',
        merchant_id: merchantId,
        method_id: methodId,
        fields_values: fieldValues,
        confirm,
      }
    );

    if (status === 201) {
      return {
        withdrawalWalletId: newWithdrawal.id,
      };
    }

    return {
      error: getPureErrorMessage(newWithdrawal) as string,
    };
  } catch (e) {
    return {
      error: 'Error',
    };
  }
};
