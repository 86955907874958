import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { NavigationTabs } from '../NavigationTabs/NavigationTabs';

import './gamesBlock.scss';
import { Icon } from '../Icon';

export const GamesBlock = ({
  children,
  tabs,
  linkUrl,
  linkText,
  title,
  className,
  defaultTabsIcon,
  isLarge,
  icon,
  isIconHidden,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(
        'games-block',
        { 'games-block_june': isJune },
        { 'games-block_large': isLarge },
        className
      )}
    >
      <div className='games-block__header'>
        <div className='games-block__row'>
          {icon && <Icon icon={icon} />}
          <h2 className='games-block__title'>{title}</h2>
        </div>
        {tabs && (
          <div className='games-block__tabs'>
            <NavigationTabs
              list={tabs}
              isIconHidden={isIconHidden}
              defaultIcon={defaultTabsIcon}
            />
          </div>
        )}
        {linkText && (
          <Link className='games-block__link' to={linkUrl}>
            {linkText}
          </Link>
        )}
      </div>
      <div className='games-block__body'>{children}</div>
    </div>
  );
};
