import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '@box/shared/hooks';

import { Modal } from '../../Modal';
import { Content } from './Content';

import './funds.scss';

export const FundsModal = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <Modal
      id='funds'
      className={classNames('funds', { funds_mobile: isMobile })}
      title={t('user_actions.withdrawal')}
    >
      <Content />
    </Modal>
  );
};
