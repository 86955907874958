import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { AppThunkApi } from '../../types';
import { openErrorPopup, openModal } from '../../app';
import { userInitSelectors } from '../../authentication';
import { balanceSelectors, userSelectors } from '../../user';

import {
  InitDemoGamePayload,
  InitDemoGameReturn,
  InitGamePayload,
  InitGameReturn,
} from './types';

export const initGame = createAsyncThunk<
  InitGameReturn,
  InitGamePayload,
  AppThunkApi<{}>
>(
  'games/current/initGame',
  async (payload, { extra, rejectWithValue, getState, dispatch }) => {
    const { api, history, route, isMobile } = extra;

    const { gameId, withRedirect, returnUrl } = payload;

    try {
      const userId = userSelectors.id(getState());
      const currency = userSelectors.currency(getState())?.code;

      const { data, status } = await api.post(
        `/userdatas/${userId}/gamesessions`,
        {
          game_id: gameId,
          currency,
          is_mobile: isMobile ? 1 : 0,
          demo_mode: false,
          return_url: returnUrl || window.location.origin,
        }
      );

      const statusesIncludes = [
        'game_not_found',
        'country_unavailable',
        'currency_unavailable',
        'demo_unavailable',
      ];

      if (status !== 201) {
        if (data?.errors) {
          const error = data?.errors[0];
          if (statusesIncludes.includes(error)) {
            dispatch(
              openErrorPopup({
                title: i18next.t(`current_game.${error}.title`),
                text: i18next.t(`current_game.${error}.text`),
              })
            );
            return rejectWithValue({});
          }
        }
        dispatch(
          openErrorPopup({
            title: i18next.t('current_game.internal_error.title'),
            text: i18next.t('current_game.internal_error.text'),
          })
        );
        return rejectWithValue({});
      }

      if (withRedirect) {
        history.push(route('casino.current', { id: gameId }), {
          backUrl: history.location.pathname,
        });
      }

      return data;
    } catch (error) {
      return rejectWithValue({});
    }
  }
);

export const initDemoGame = createAsyncThunk<
  InitDemoGameReturn,
  InitDemoGamePayload,
  AppThunkApi<{}>
>(
  'games/current/initDemo',
  async (payload, { extra, rejectWithValue, getState, dispatch }) => {
    const { api, history, route, isMobile } = extra;

    const { gameId, withRedirect, returnUrl } = payload;

    try {
      const isLogged = userInitSelectors.isLogged(getState());
      const currencySymbol = balanceSelectors.currencySymbol(getState());

      const { data, status } = await api.post('/gamesessions/demo', {
        game_id: gameId,
        is_mobile: isMobile ? 1 : 0,
        demo_mode: true,
        return_url: returnUrl || window.location.origin,
      });

      if (status === 201) {
        if (withRedirect) {
          history.push(route('casino.current', { id: gameId }), {
            backUrl: history.location.pathname,
          });
        }

        return data;
      }

      if (status === 400) {
        if (isLogged) {
          dispatch(
            openErrorPopup({
              title: i18next.t('currency.error.title'),
              text: i18next.t('currency.error.text', {
                currency: currencySymbol,
              }),
            })
          );

          return rejectWithValue({});
        }

        dispatch(openModal(isLogged ? 'deposit' : 'register'));

        return rejectWithValue({});
      }

      return rejectWithValue({});
    } catch (error) {
      return rejectWithValue({});
    }
  }
);
