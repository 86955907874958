import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useApp } from '@box/shared/hooks';

import './tabsItem.scss';

const TabsItem = ({ component: Component, active }) => {
  const [isAnimation, setIsAnimation] = useState(false);

  const {
    userEnv: { isJune, isDesktop },
  } = useApp();

  useEffect(() => {
    let timer;

    if (!timer) {
      setIsAnimation(true);

      timer = setTimeout(() => {
        setIsAnimation(false);
      }, 400);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [active]);

  return (
    <div
      className={classNames('tabs-item', { 'tabs-item--active': isAnimation })}
    >
      <Component isDesktop={isDesktop} isJune={isJune} />
    </div>
  );
};

TabsItem.propTypes = {
  component: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};

export default TabsItem;
