import {
  BlockWithLoading,
  Collapsed,
  FormSelect,
} from '@box/shared/baseComponents';
import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { LightButton, Text } from '@box/ui';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { useApp } from '@box/shared/hooks';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import {
  documentsSelectors,
  userUpdateSelectors,
  userSelectors,
} from '@box/redux/user';
import { UserDocumentType } from '@box/redux';
import { Photos } from '../Photos';
import { Inputs } from '../PersonalData.types';
import styles from '../personalData.module.scss';
import { Fields } from '../Fields';
import { DocumentProps } from './Document.types';

export const DocumentFields: React.FC<DocumentProps> = memo(
  ({ isUploadOpen, setIsUploadOpen }) => {
    const {
      userEnv: { isMobile },
    } = useApp();
    const { t } = useTranslation();
    const isVerifyNotStarted = useAppSelector(userSelectors.isVerifyNotStarted);
    const isVerifyDenied = useAppSelector(userSelectors.isVerifyDenied);
    const disabled = useAppSelector(selectIsDisabled);
    const init = useAppSelector(documentsSelectors.init);
    const loading = useAppSelector(userUpdateSelectors.loading);
    const documentsLoading = useAppSelector(documentsSelectors.loading);
    const documentsInit = useAppSelector(documentsSelectors.init);
    const types = useAppSelector(documentsSelectors.types);
    const {
      control,
      reset,
      formState: { errors, isSubmitted },
      getValues,
      watch,
    } = useFormContext<Inputs>();

    const formInitialData = getValues();

    const openUpload = () => setIsUploadOpen(true);

    const resetVerificationFiedls = () => {
      reset({
        ...formInitialData,
        scan: undefined,
        photo: undefined,
      });
    };
    const documentType = watch('document_type');

    const typesOptions = useMemo(
      () =>
        types.map((item) => ({
          label: get(item, 'name'),
          value: get(item, 'alias'),
        })),
      [types]
    );

    const selectedType: UserDocumentType = useMemo(
      () =>
        types.find((item) => item.alias === documentType) ||
        ({} as UserDocumentType),
      [types, documentType]
    );

    return (
      <BlockWithLoading className={styles.upload_loader} loading={!init}>
        <div
          className={classNames(styles.upload, {
            [styles.upload_desktop]: !isMobile,
          })}
        >
          <div className={styles.upload_desktop_content}>
            <Text
              as='div'
              color='violet'
              size={isMobile ? 'lg' : 'xxl'}
              weight='800'
              className={styles.title}
            >
              {t('settings.documents')}
            </Text>
            <div
              className={classNames(
                styles.verificationForm,
                {
                  [styles.verificationForm_field_4]:
                    (!!documentType && isUploadOpen) ||
                    (isSubmitted && !!documentType),
                },
                disabled && styles.verificationForm_disabled
              )}
            >
              <FormSelect
                name='document_type'
                menuPlacement='top'
                placeholder={`${t('settings.select')}...`}
                error={!!errors?.document_type?.message}
                errorMessage={t(errors.document_type?.message)}
                control={control as any}
                options={typesOptions}
                onChange={(v, field) => {
                  field.onChange(v.value);
                }}
                label={t('settings.document_type')}
                disabled={disabled}
                isMobile={isMobile}
                isMyProfile
              />

              <Collapsed
                className={classNames(
                  styles.collapsed,
                  !!documentType && styles.collapsed_preview
                )}
                isOpen={!!documentType}
              >
                <Fields fields={selectedType.fields} disabled={disabled} />
                {!isUploadOpen && !isSubmitted && (
                  <LightButton
                    className={styles.upload}
                    color='yellow'
                    onClick={openUpload}
                    type='button'
                  >
                    {t('settings.open_upload')}
                  </LightButton>
                )}
              </Collapsed>
              <Collapsed
                isOpen={isUploadOpen || (isSubmitted && !!documentType)}
              >
                <Photos disabled={disabled} />
              </Collapsed>
            </div>
            <div
              className={classNames(
                styles.verificationButtons,
                (isVerifyNotStarted || isVerifyDenied) &&
                  styles.verificationButtons_notVerificated
              )}
            >
              {(isVerifyNotStarted || isVerifyDenied) && (
                <LightButton
                  loading={loading || documentsLoading}
                  disabled={
                    !(!!formInitialData.scan || !!formInitialData.photo)
                  }
                  className={styles.submit}
                  color='yellow'
                  fixHeight
                  onClick={resetVerificationFiedls}
                >
                  <Text
                    size={isMobile ? 'xs' : 'sm'}
                    color='yellow'
                    as='span'
                    weight={400}
                  >
                    {t('settings.discard')}
                  </Text>
                </LightButton>
              )}

              <LightButton
                loading={loading || documentsLoading}
                disabled={!documentsInit || disabled || documentsLoading}
                className={styles.submit}
                color='yellow'
                fixHeight
                type='submit'
              >
                {t('settings.save')}
              </LightButton>
            </div>
          </div>
        </div>
      </BlockWithLoading>
    );
  }
);
