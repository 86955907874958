import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../types';
import { sortBonuses } from '../utils/sortBonuses';

import { FetchBonusesReturn, GETBonuses } from './types';

export const fetchBonuses = createAsyncThunk<
  FetchBonusesReturn,
  void,
  AppThunkApi
>('bonuses/fetch', async (_payload, { extra, rejectWithValue }) => {
  const { api, transformers } = extra;

  try {
    const { data, status } = await api.get<GETBonuses>('/bonuses');

    if (status === 200) {
      return {
        list: transformers.commonBonuses(sortBonuses(data.data)),
      };
    }

    return rejectWithValue('Error fetching bonuses');
  } catch (e) {
    return rejectWithValue('Error fetching bonuses');
  }
});
