import React, { FC } from 'react';
import classNames from 'classnames';
import { BonusCard, LightBonusItem } from '@box/shared/components';

import { useApp } from '@box/shared/hooks';
import { Props } from './List.types';

import styles from './list.module.scss';

export const List: FC<Props> = ({ label, list, counter }) => {
  const {
    userEnv: { isJune, isMobile },
  } = useApp();
  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
        [styles.container_june]: isJune,
      })}
    >
      <div className={styles.row}>
        <h4 className={styles.title}>{label}</h4>
        <div className={styles.counter}>{counter}</div>
      </div>
      <div className={styles.list}>
        {list.map((item, index) => {
          if (isJune) {
            return (
              <LightBonusItem
                key={index}
                image={item.img}
                bonusId={item.id}
                progression_percentage={item?.progress?.percent}
                {...item}
              />
            );
          }
          return (
            <BonusCard
              key={index}
              bonusId={item.id}
              image={item.img}
              progression_percentage={item?.progress?.percent}
              {...item}
            />
          );
        })}
      </div>
    </div>
  );
};
