import { createSlice } from '@reduxjs/toolkit';

import {
  setIsConnected,
  setIsPartnerSetTrue,
  setModalRegisterOpen,
} from './actions';

import {
  authorize,
  authUser,
  refreshToken,
  logout,
  connectExternal,
  setFirstVisitFetch,
  fetchPermissions,
} from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  isReady: false,
  isLogged: false,
  isConnected: false,
  isConnecting: false,
  isModalRegisterOpen: false,
  isPartnerSet: false,
  isWageredReady: false,
  isDepositWagered: false,
};

export const slice = createSlice({
  name: 'init',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authorize.fulfilled, (state) => {
        state.isLogged = true;
        state.isReady = true;
      })
      .addCase(refreshToken.rejected, (state) => {
        state.isReady = true;
      })
      .addCase(authUser.rejected, (state) => {
        state.isReady = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLogged = false;
      })
      .addCase(connectExternal.fulfilled, (state) => {
        state.isConnected = true;
      })
      .addCase(setIsConnected, (state) => {
        state.isConnected = true;
      })
      .addCase(setModalRegisterOpen, (state, action) => {
        state.isModalRegisterOpen = action.payload;
      })
      .addCase(setFirstVisitFetch.fulfilled, (state) => {
        state.isPartnerSet = true;
      })
      .addCase(setIsPartnerSetTrue, (state) => {
        state.isPartnerSet = true;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.isWageredReady = false;
        state.isDepositWagered = false;
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
        state.isWageredReady = true;
        state.isDepositWagered = payload.is_deposit_wagered;
      })
      .addCase(fetchPermissions.rejected, (state) => {
        state.isWageredReady = true;
        state.isDepositWagered = false;
      });
  },
});
