import { ReactComponent as ProfileIcon } from './assets/images/profile.svg';
import { ReactComponent as DepositIcon } from './assets/images/deposit.svg';
import { ReactComponent as WithdrawalIcon } from './assets/images/funds.svg';
import { ReactComponent as HistoryIcon } from './assets/images/history.svg';
import { ReactComponent as BonusesIcon } from './assets/images/bonuses.svg';
import { ReactComponent as LoyaltyIcon } from './assets/images/loyalty.svg';

import { Link } from './Menu';

export const menu: Link[] = [
  {
    label: 'user_actions.my_profile',
    type: 'navigate',
    path: '/profile/my_profile',
    Icon: ProfileIcon,
  },
  {
    label: 'detalization.deposit',
    type: 'deposit',
    path: '/profile/payments/deposit',
    Icon: DepositIcon,
  },
  {
    label: 'detalization.withdrawal',
    type: 'navigate',
    path: '/profile/payments/withdrawal',
    Icon: WithdrawalIcon,
  },
  {
    label: 'user_actions.transaction_history',
    type: 'navigate',
    path: '/profile/detalization',
    Icon: HistoryIcon,
  },
  {
    label: 'user_actions.bonuses',
    type: 'navigate',
    path: '/bonuses/list',
    Icon: BonusesIcon,
  },
  {
    label: 'settings.verification',
    type: 'navigate',
    path: '/profile/verification',
    Icon: ProfileIcon,
  },
  // LM-3262
  // {
  //   label: 'user_actions.loyalty_program',
  //   type: 'loyalty',
  //   path: '/profile/loyalty',
  //   Icon: LoyaltyIcon,
  // },
];
