export const useCalculation = (num: number, isMobile: boolean) => {
  const rotationValues = {
    10: 1.25,
    15: 1.3,
    30: 1.36,
    99: 1.37,
  };

  const rotationValuesMobile = {
    10: 1.25,
    15: 1.3,
    20: 1.34,
    30: 1.36,
    40: 1.37,
    50: 1.39,
    70: 1.41,
    90: 1.43,
    99: 1.44,
  };

  const calculateRotation = (num) => {
    if (isMobile) {
      if (num <= 1) {
        return num;
      }
      if (num <= 4) {
        return num;
      }
      for (let range in rotationValuesMobile) {
        if (num <= range) {
          return num * rotationValuesMobile[range];
        }
      }
      return num * 1.51;
    } else {
      if (num <= 1) {
        return num;
      }
      if (num <= 4) {
        return num;
      }
      for (let range in rotationValues) {
        if (num <= range) {
          return num * rotationValues[range];
        }
      }
      return num * 1.37;
    }
  };
  return {
    calculatedRotation: calculateRotation(num),
  };
};
