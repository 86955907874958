import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-number-format';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import { useApp } from '@box/shared/hooks';
import { commonSelectors } from '@box/redux/common';
import { getPhoneWithoutCode } from '@box/shared/utils';

import { Inputs } from '../PersonalData.types';

import styles from '../personalData.module.scss';

export const PhoneField = memo(() => {
  const {
    userEnv: { isMobile },
  } = useApp();
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);
  const codes = useAppSelector(commonSelectors.codes);
  const { control } = useFormContext<Inputs>();

  return (
    <div className={styles.field}>
      <span className={styles.field__label}>{t('settings.phone')}</span>
      <div className={styles.row}>
        <div
          className={classNames(styles.code, {
            [styles.code_disabled]: disabled,
            [styles.code_mobile]: isMobile,
          })}
        >
          +{codes?.phone}
        </div>
        <Controller
          control={control}
          name='phone'
          render={({ field, fieldState: { error } }) => (
            <div className={styles.maskBox}>
              <InputMask
                className={classNames(styles.mask, {
                  [styles.mask_error]: !!error,
                  [styles.mask_disabled]: disabled,
                  [styles.mask_mobile]: isMobile,
                })}
                format='###-###-##-##'
                value={getPhoneWithoutCode(codes.phone, field.value)}
                type='text'
                disabled={disabled}
                placeholder='123-456-78-90'
                onChange={(e) => field.onChange(e.target.value)}
                getInputRef={field.ref}
                isAllowed={() => true}
              />
              {error && (
                <span className={styles.error}>{t(error.message)}</span>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
});
