import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';
import { FetchGamesReturn, FetchGamesPayload, GETGames } from './types';

export const fetchGames = createAsyncThunk<
  FetchGamesReturn,
  FetchGamesPayload,
  AppThunkApi
>('games/fetchGames', async (payload, { extra, rejectWithValue }) => {
  const { api, isMobile } = extra;

  try {
    const { page, perPage, search, provider, category, sortBy } = payload;

    const { data, status } = await api.get<GETGames>('/games', {
      params: {
        page,
        per_page: perPage,
        provider,
        category,
        search: search ? decodeURIComponent(search) : undefined,
        is_mobile: isMobile ? 1 : 0,
        sortby: sortBy,
      },
    });

    if (status === 200) {
      return data;
    }

    return rejectWithValue('Не валидная форма обратитесь к знатокам');
  } catch (err) {
    return rejectWithValue('Не валидная форма обратитесь к знатокам');
  }
});
