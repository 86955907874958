import React from 'react';
import { useTranslation } from 'react-i18next';

import { STATUSES } from '@box/shared/types';
import { BlockWithLoading } from '../../../../baseComponents';
import { BonusCard } from '../BonuCard';
import { ListProps } from './List.types';

import styles from './List.module.scss';

const NoData = () => {
  const { t } = useTranslation();

  return (
    <h2 className={styles['modal-bonuses__no-data']}>{t('common.no_data')}</h2>
  );
};

export const List: React.FC<ListProps> = ({ values, loading, id, symbol }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['modal-bonuses']}>
      <BlockWithLoading
        loading={loading}
        loaderClassName={styles['loader-wrapper']}
      >
        {values.length ? (
          <>
            <div className={styles['modal-bonuses__list']}>
              {values.map((item) => {
                return (
                  <BonusCard
                    status={item.status as STATUSES}
                    minSum={item.getting_params[0]?.min}
                    maxBonus={item.getting_params[0]?.max}
                    amount={Math.floor(item.progress?.percent)}
                    wager={item.progress?.wager}
                    bonusName={item.name}
                    neededAmount={item.amount * item.progress.wager}
                    percent={item.progress?.percent}
                    key={item.id}
                    className={styles['modal-bonuses__item']}
                  />
                );
              })}
            </div>
            {id === 'active' && (
              <>
                <h2 className={styles['modal-bonuses__title']}>
                  {t('bonuses.how_rollover_works')}
                </h2>
                <p className={styles['modal-bonuses__text']}>
                  {t('bonuses.rollover_text', {
                    symbol,
                  })}
                </p>
              </>
            )}
          </>
        ) : (
          <NoData />
        )}
      </BlockWithLoading>
    </div>
  );
};
