import React, { InputHTMLAttributes, ReactNode } from 'react';
import { getInput } from '../getInput';

import baseStyles from './baseInput.module.scss';
import decemberStyles from './december.module.scss';

export type BaseInputProps = {
  icons?: {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
  };
  label?: string;
  name: string;
  error?: string;
  color?: 'primary' | 'transparent';
} & InputHTMLAttributes<HTMLInputElement>;

const baseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(
  (props, ref) => <input ref={ref} {...props} />
);

export const Input = getInput(baseStyles, baseInput);
export const DecemberInput = getInput(decemberStyles, baseInput);
