import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  fetchLogin,
  loginSelectors,
  registerSelectors,
  setIsButtonDisabled,
} from '@box/redux/authentication';
import { hideModal, openModal } from '@box/redux/app';
import { Input, LightButton, Password, Window } from '@box/ui';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import {
  getCountryIdByICode,
  getCurrencyIdByCode,
} from '@box/shared/utils/selectOptions';
import { useApp } from '@box/shared/hooks';

import { prepareUsername } from '../../../../utils/user';
import { Modal } from '../../../Modal/Modal';
import { passwordRegex, validatePhone } from '../../../../utils/validation';

import styles from './login.module.scss';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';

const schema = z.object({
  username: z
    .string()
    .email('fields.email_format_error')
    .or(z.string().refine(validatePhone, 'fields.phone_format_error')),
  password: z
    .string()
    .min(6, 'fields.password_length_error')
    .max(30, 'fields.password_max_length_error')
    .regex(passwordRegex, 'fields.invalid_field'),
});

export const JuneLoginModal = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const loading = useAppSelector(loginSelectors.loading);
  const registerLoading = useAppSelector(registerSelectors.loading);
  const error = useAppSelector(loginSelectors.error);
  const isUserNotFound = useAppSelector(loginSelectors.isUserNotFound);
  const currencies = useAppSelector(commonSelectors.currencies);
  const clientData = useAppSelector(commonSelectors.clientData);
  const countries = useAppSelector(commonSelectors.countries);
  const isButtonDisabled = useAppSelector(loginSelectors.isButtonDisabled);

  const isBangladesh = clientData?.country?.code === 'BD';

  const dispatch = useAppDispatch();

  const onSubmit = (data) => {
    dispatch(setIsButtonDisabled(true));
    data.username = prepareUsername(data.username);
    dispatch(
      fetchLogin({
        currency_id: getCurrencyIdByCode(
          currencies,
          clientData?.country?.default_currency_code
        ),
        country_id: getCountryIdByICode(countries, clientData?.country?.code),
        ...data,
      })
    );
  };

  const modalOnHide = () => reset();

  const openRecovery = () => {
    dispatch(hideModal('login'));
    dispatch(openModal('recovery'));
  };

  const openRegister = () => {
    dispatch(hideModal('login'));
    dispatch(openModal('register'));
  };

  return (
    <Modal
      id='login'
      className={classNames(styles.login, {
        [styles.login_desktop]: isDesktop,
      })}
      title={t('login_modal.title')}
      onClose={modalOnHide}
      size={isDesktop ? 'lg' : 'xs'}
      heightAuto={isDesktop}
      headColor='yellow'
      headWithSvg
    >
      <div className={styles.body}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.controls}>
            <Input
              placeholder={t('login_modal.login_field')}
              label={
                isBangladesh
                  ? t('settings.email')
                  : `${t('settings.email')} / ${t('login_modal.phone')}`
              }
              className={styles.input}
              error={t(errors.username?.message as string)}
              {...register('username')}
            />
            <div
              className={classNames(styles.row, {
                [styles.row_desktop]: isDesktop,
              })}
            >
              <Password
                placeholder={t('login_modal.password')}
                label={t('settings.password')}
                className={styles.input}
                type='password'
                error={t(errors.password?.message as string)}
                {...register('password')}
              />
              {!isDesktop && (
                <button
                  onClick={openRecovery}
                  type='button'
                  className={styles.recover}
                >
                  {t('login_modal.forgot_pass')}
                </button>
              )}
            </div>
          </div>

          <div className={styles.buttons}>
            {isDesktop && (
              <button
                onClick={openRecovery}
                type='button'
                className={styles.recover}
              >
                {t('login_modal.forgot_pass')}
              </button>
            )}
            <LightButton
              disabled={loading || registerLoading || isButtonDisabled}
              loading={loading || registerLoading}
              type='submit'
              className={styles.submit}
              color='yellow'
              fixHeight
            >
              {t('login_modal.submit')}
            </LightButton>
          </div>
        </form>
        {!isUserNotFound && <p className={styles.error}>{error}</p>}
      </div>
      <Window.Footer className={styles.footer}>
        <p className={styles.text}>
          {t('login_modal.i_do_not_have_an_account')}
        </p>
        <button type='button' onClick={openRegister} className={styles.link}>
          {t('login_modal.sign_up')}
          <ArrowSvg />
        </button>
      </Window.Footer>
    </Modal>
  );
};
