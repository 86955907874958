import { getStoragePath } from '../getStoragePath';
import {
  DefaultProvider1,
  DefaultProvider2,
  DefaultProvider3,
  DefaultProvider4,
} from './defaults/provider';

export const defaultProviderIcons = [
  DefaultProvider1,
  DefaultProvider2,
  DefaultProvider3,
  DefaultProvider4,
];

const getRandomDefaultIcon = (defaultList) => ({
  type: 'provider',
  default: true,
  num: Math.trunc(Math.random() * defaultList.length),
});

export const setProviderIcons = (list) =>
  list?.map((item) => ({
    ...item,
    icon: item.icon
      ? getStoragePath(item.icon, 'main')
      : getRandomDefaultIcon(defaultProviderIcons),
  }));

export const getProviderIcon = (icon) => defaultProviderIcons[icon.num];
