import { combineReducers } from 'redux';

import initReducer from './init';
import loginReducer from './login';
import passwordRecoveryReducer from './passwordRecovery';
import registerReducer from './register';
import passwordUpdateReducer from './passwordUpdate';

const reducer = combineReducers({
  init: initReducer,
  login: loginReducer,
  passwordRecovery: passwordRecoveryReducer,
  register: registerReducer,
  passwordUpdate: passwordUpdateReducer,
});

export * from './init';
export * from './login';
export * from './passwordRecovery';
export * from './register';
export * from './passwordUpdate';

export default reducer;
