import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { addUserBonus, userBonusesSelectors } from '@box/redux/user';
import { openBonusDetail } from '@box/redux/bonuses';
import { bannersButtonsType } from '@box/redux/utils';
import { useAppSelector } from '@box/redux/hooks';
import { userInitSelectors } from '@box/redux/authentication';
import { openModal } from '@box/redux/app';
import { useApp, useNavigateToDeposit } from '@box/shared/hooks';

import { isLinkWithProtocol } from '../../utils/isLinkWithProtocol';

export const withBannerState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { bonus, buttons, url, click_action } = props;

    const isLogged = useAppSelector(userInitSelectors.isLogged);
    const { openDeposit } = useNavigateToDeposit();
    const { userEnv } = useApp();
    const { host } = userEnv;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const addBonusLoading = useSelector((state) =>
      userBonusesSelectors.loadingById(state, bonus?.id)
    );

    const addBonusAction = () => dispatch(addUserBonus({ bonusId: bonus?.id }));

    const openBonusAction = () => dispatch(openBonusDetail(bonus));

    const redirectAction = (link) => {
      if (isLinkWithProtocol(link)) {
        window.location.href = link;
      } else {
        navigate(link);
      }
    };

    const onSlideClick = () => {
      switch (click_action) {
        case 'url':
          redirectAction(url);
          break;
        case 'bonus':
          if (bonus) {
            dispatch(openBonusDetail(bonus));
          }
          break;
        case 'referral':
          if (!isLogged) {
            dispatch(openModal('register'));
          } else {
            dispatch(openModal('referral'));
          }
          break;
        case 'deposit':
          if (!isLogged) {
            dispatch(openModal('register'));
          } else {
            switch (host) {
              case 'december':
                dispatch(openModal('deposit'));
                break;
              default:
                openDeposit();
                break;
            }
          }
          break;
        default:
          break;
      }
    };

    const buttonsList = useMemo(
      () =>
        buttons.map((item) => {
          switch (item.type) {
            case bannersButtonsType.REDIRECT:
              return {
                ...item,
                onClick: () => redirectAction(url),
              };

            case bannersButtonsType.OPEN_BONUS:
              return {
                ...item,
                onClick: openBonusAction,
              };

            case bannersButtonsType.GET_BONUS:
              return {
                ...item,
                onClick: addBonusAction,
                loading: addBonusLoading,
              };

            default:
              return {
                ...item,
                onClick: () => null,
              };
          }
        }),
      [buttons, addBonusLoading]
    );

    return (
      <Component
        {...props}
        firstButtonText={t('bonuses.activate')}
        secondButtonText={t('common.details')}
        onSlideClick={onSlideClick}
        buttons={buttonsList}
      />
    );
  };

  return wrapper;
};
