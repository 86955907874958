import React, { ReactNode, useState } from 'react';
import { Collapsed } from '@box/shared/baseComponents/Collapsed';
import { Input, BaseInputPorps, DecemberInput } from '../Base';

type HiddenInputProps = {
  rednerOpenElement: (open: () => void) => ReactNode;
} & BaseInputPorps;

export const withHidden =
  (
    Component: React.ForwardRefExoticComponent<
      BaseInputPorps & React.RefAttributes<HTMLInputElement>
    >
  ) =>
  ({ className, rednerOpenElement, ...props }: HiddenInputProps) => {
    const [open, setOpen] = useState(false);

    return (
      <div className={className}>
        <Collapsed isOpen={!open}>
          {rednerOpenElement(() => setOpen(true))}
        </Collapsed>
        <Collapsed isOpen={open}>
          <Component className={className} {...props} />
        </Collapsed>
      </div>
    );
  };

export const HiddenInput = withHidden(Input);
export const DecemberHiddenInput = withHidden(DecemberInput);
