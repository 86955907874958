import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './golderGard.scss';

export const GoldenCard = ({ className, children }) => {
  return (
    <div className={classNames('golden-card', className)}>
      <div className='golden-card__decor' />
      <div className='golden-card__decor' />
      <div className='golden-card__decor' />
      <div className='golden-card__decor' />
      <div className='golden-card__inner'>{children}</div>
    </div>
  );
};

GoldenCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
};

GoldenCard.defaultProps = {
  className: '',
};
