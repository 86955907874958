import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Trans } from 'react-i18next';

import { Modal } from '@box/ui';
import { useLockScroll } from '@box/shared/hooks';
import { useAppSelector } from '@box/redux/hooks';

import styles from './SocialsBanner.module.scss';
import { delayTime, socialsBannerLinks } from './SocialsBanner.constants';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { ReactComponent as GiftIcon } from './assets/gift.svg';
import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
import { ReactComponent as InstagramIcon } from './assets/instagram.svg';
import { ReactComponent as CrossIcon } from './assets/cross.svg';
import { blockOpen, isOpenBlocked } from './_state';

export const SocialsBanner = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openedModal = useAppSelector((state) => state.app.modals.show);

  useLockScroll(isOpen);

  useEffect(() => {
    // Временно закрыл
    if (!isOpenBlocked) {
      setTimeout(() => setIsOpen(false), delayTime);
    }
  }, []);

  const close = () => setIsOpen(false);

  const onDontShowClick = () => {
    blockOpen();
    close();
  };

  const open = isOpen && openedModal.length === 0;

  if (!open) return null;

  return createPortal(
    <Modal isOpen={open} close={close}>
      <div className={styles.banner}>
        <div className={styles.close} onClick={close} aria-hidden='true'>
          <CrossIcon />
        </div>
        <LogoIcon className={styles.logo} />
        <div className={styles.body}>
          <GiftIcon className={styles.gift} />
          <span className={styles.title}>
            <Trans
              i18nKey='socials_banner.title'
              components={{ golden: <span /> }}
            />
          </span>
          <div className={styles.inviteText}>
            <Trans i18nKey='socials_banner.invite' />
          </div>
          <div className={styles.links}>
            <a
              className={styles.link}
              href={socialsBannerLinks.tgLink}
              target='_blank'
              rel='noreferrer'
            >
              <TelegramIcon />
              Telegram
            </a>
            <a
              className={styles.link}
              href={socialsBannerLinks.instagramLink}
              target='_blank'
              rel='noreferrer'
            >
              <InstagramIcon />
              Instagram
            </a>
          </div>
          <div
            className={styles.dontShow}
            onClick={onDontShowClick}
            aria-hidden='true'
          >
            <Trans i18nKey='socials_banner.dont_show' />
          </div>
        </div>
      </div>
    </Modal>,
    document.body
  );
};
