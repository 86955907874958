import { Field } from '../fields';

import { UserWallet } from './wallets';

export interface PaymentFrame {
  width: number;
  height: number;
  src: string;
  type: 'iframe';
}

export interface MessageData {
  type: 'message';
  message: string;
}

export interface PaymentTransaction {
  id: number;
  payable_type: string;
  payable_id: number;
  type: string;
  amount: string;
  amountFloat?: string;
  confirmed: boolean;
  created_at: string;
  wallet_id: number;
  wallet: UserWallet;
  meta: {
    payload?: {
      method: string;
    };
    type?: string;
    external_object: {
      merchant: Merchant;
      result: {
        data: PaymentFrame | MessageData | null;
        error: {
          status: string;
          message?: string;
          description?: string;
        } | null;
        success: boolean;
      };
    };
  };
  withdrawal_amount?: string;
  confirm?: boolean;
}

export interface Merchant {
  id: number;
  name: string;
  alias: string;
  priority: number;
  enabled: boolean;
  fields: Field[];
}

export interface PaymentMethod {
  id: number;
  name: string;
  alias: string;
  priority: number;
  enabled: boolean;
  merchant: Merchant;
  fields: Field[];
  categories: unknown[];
  limits: {
    currency_code: string;
    min: number;
    max: number;
  };
  logo: string;
}
