import { createSlice } from '@reduxjs/toolkit';

import { setJackpot } from './actions';
import { initJackpot } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  amount: 0,
  currencyId: 0,
  ready: false,
};

export const slice = createSlice({
  name: 'app/jackpot',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initJackpot.fulfilled, (state, { payload }) => {
        state.amount = Math.trunc(payload.amount);
        state.currencyId = payload.currencyId;
        state.ready = true;
      })
      .addCase(setJackpot, (state, { payload }) => {
        state.amount = Math.trunc(payload.amount);
      });
  },
});
