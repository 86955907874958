export enum BonusTypes {
  'free_spins' = 'free_spins',
  '1st_dep_bonuses' = '1st_dep_bonuses',
  '2nd_dep_bonuses' = '2nd_dep_bonuses',
  '3rd_dep_bonuses' = '3rd_dep_bonuses',
  'bonuses' = 'bonuses',
  '1st_dep_free_spins' = '1st_dep_free_spins',
  '2nd_dep_free_spins' = '2nd_dep_free_spins',
  '3rd_dep_free_spins' = '3rd_dep_free_spins',
  'birthday' = 'birthday',
  'next_dep_bonuses' = 'next_dep_bonuses',
}

export enum BonusStatuses {
  created = 'created',
  ended = 'ended',
  activated = 'activated',
  spins_used = 'spins_used',
  processing = 'processing',
}

enum AccessLevel {
  'all' = 'all',
  'only_guests' = 'only_guests',
  'only_members' = 'only_members',
}

export interface Bonus {
  id: number;
  name: string;
  type: BonusTypes;
  alias: string;
  access_level: AccessLevel;
  can_activate: boolean;
  condition: string;
  img: {
    png: string;
    webp: string;
    avif: string;
  };
  default: unknown;
  description: string;
  wager_params: { wager: string };
  getting_params: { percent: string; min: string; max: string }[];
}
