import classNames from 'classnames';
import React, { useMemo, useState, FC } from 'react';

import { useBreakPoints } from '../../hooks/useBreakPoints';

import { Collapsed } from '../Collapsed';
import { PlateCard } from './PlateCard';
import { PlateCollapse } from './PlateCollapse';
import { PlateItem } from './PlateItem';
import { PlatesContext } from './State';
import { Plate } from './Plate';

import { PlateWithCollapsedProps } from './types';

import styles from './plate.module.scss';

export const PlateWithCollapsed: FC<PlateWithCollapsedProps> = ({
  className,
  children,
  perRow = 0,
  rowSpacing = 4,
  columnSpacing = 4,
  breakPoints,
  defaultPerRow = 4,
}) => {
  const [activePlate, setActivePlate] = useState(null);

  const breakPointsPerRow = useBreakPoints(breakPoints);

  const setPlate = (i) => {
    setActivePlate((prev) => (prev === i ? null : i));
  };

  const plates = useMemo(
    () =>
      children.filter(
        (item) => React.isValidElement(item) && item.type === PlateItem
      ),
    [children]
  );

  const items = useMemo(
    () =>
      plates
        .map((item, idx) => {
          if (React.isValidElement(item.props.children)) {
            return {
              card: {
                id: idx,
                component: item.props.children,
              },
            };
          }

          let card = item.props.children.find(
            (c) => React.isValidElement(c) && c.type === PlateCard
          );

          if (card) {
            card = React.cloneElement(card, {
              key: idx,
              id: idx,
            });
          }

          return {
            card: {
              id: idx,
              component: card,
            },
            collapse: {
              id: idx,
              component: item.props.children.find(
                (c) => React.isValidElement(c) && c.type === PlateCollapse
              ),
            },
          };
        })
        .filter((item) => !!item.card.component),
    [plates]
  );

  const rows = useMemo(() => {
    const count = perRow || breakPointsPerRow || defaultPerRow;

    return Array(Math.ceil(items.length / count))
      .fill(0)
      .map((_item, idx) => items.slice(idx * count, (idx + 1) * count));
  }, [items, perRow, breakPointsPerRow]);

  const contextValues = useMemo(
    () => ({ activePlate, setActivePlate: setPlate }),
    [activePlate]
  );

  return (
    <div className={classNames(styles['plates-with-collapsed'], className)}>
      <PlatesContext.Provider value={contextValues}>
        {rows.map((row, idx) => {
          const withActiveCollapse = row.find((f) => f.card.id === activePlate);

          const isCollapseActive = !!withActiveCollapse?.collapse?.component;

          return (
            <React.Fragment key={idx}>
              <Plate
                spacing={columnSpacing}
                style={{ marginBottom: rowSpacing }}
                perRow={perRow}
                breakPoints={breakPoints}
                defaultPerRow={defaultPerRow}
              >
                {row.map((item) => item.card.component)}
              </Plate>
              {isCollapseActive && (
                <Collapsed isOpen>
                  {withActiveCollapse.collapse.component}
                </Collapsed>
              )}
            </React.Fragment>
          );
        })}
      </PlatesContext.Provider>
    </div>
  );
};
