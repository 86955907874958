import React, { FC } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { useApp } from '@box/shared/hooks';

import { Props } from './Controller.types';

import styles from './controller.module.scss';
import { Label } from '../Label';
import { Message } from '../Message';

export const Controller: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      errorMessage,
      label,
      className,
      format,
      mask,
      type,
      id,
      defaultValue,
      placeholder,
      labelAlign,
      centered,
      textWeight = 'normal',
      borderColor = 'default',
      borderRadius = 'default',
      size = 'medium',
      labelColor,
      labelInside,
      currencySymbol,
      backgroundColor = 'white',
      ...props
    },
    ref
  ) => {
    const {
      userEnv: { isMobile },
    } = useApp();

    const classes = classNames(
      styles.input,
      styles[`input_text-weight_${textWeight}`],
      styles[`input_border-color_${borderColor}`],
      styles[`input_border-radius_${borderRadius}`],
      styles[`input_size_${size}`],
      styles[`input_background_${backgroundColor}`],
      {
        [styles.input_error]: errorMessage,
        [styles.input_centered]: centered,
        [styles.input_mobile]: isMobile,
      }
    );

    return (
      <div
        className={classNames(
          styles.controller,
          { [styles.controller_inside]: labelInside },
          className
        )}
      >
        {label && (
          <Label
            color={labelColor}
            className={styles.label}
            label={label}
            align={labelAlign}
          />
        )}
        {mask ? (
          format ? (
            <NumberFormat
              id={id}
              format={format + mask}
              allowEmptyFormatting
              defaultValue={defaultValue}
              className={classes}
              mask='_'
              getInputRef={ref}
              placeholder={placeholder}
              {...props}
              {...(type === ('tel' || 'password' || 'text') && { type })}
            />
          ) : (
            <NumberFormat
              {...props}
              format={mask}
              type='text'
              defaultValue={defaultValue}
              placeholder={placeholder}
              id={id}
              className={classes}
              getInputRef={ref}
            />
          )
        ) : (
          <input
            className={classes}
            id={id}
            type={type}
            ref={ref}
            placeholder={placeholder}
            {...props}
          />
        )}
        {errorMessage && (
          <Message message={errorMessage} className={styles.error} />
        )}
        {currencySymbol && <p className={styles.currency}>{currencySymbol}</p>}
      </div>
    );
  }
);
