import { ReactComponent as Casino } from './assets/casino.svg';
import { ReactComponent as LiveCasino } from './assets/live_casino.svg';
import { ReactComponent as Poker } from './assets/poker.svg';
import { ReactComponent as Games } from './assets/games.svg';
import { ReactComponent as Providers } from './assets/providers.svg';
import { ReactComponent as TopGames } from './assets/top_games.svg';
import { ReactComponent as TopGamesJune } from './assets/top_games_june.svg';
import { ReactComponent as Favorites } from './assets/favorites.svg';
import { ReactComponent as FavoritesJune } from './assets/favorites_june.svg';
import { ReactComponent as Live } from './assets/live.svg';
import { ReactComponent as Popular } from './assets/popular.svg';
import { ReactComponent as PopularJune } from './assets/popular_june.svg';
import { ReactComponent as Bonus } from './assets/bonus.svg';
import { ReactComponent as LeaderBoard } from './assets/leaderboard.svg';

import { ReactComponent as CasinoOutlined } from './assets/casino_outlined.svg';
import { ReactComponent as LiveOutlined } from './assets/live_outlined.svg';
import { ReactComponent as HomeOutlined } from './assets/home_outlined.svg';
import { ReactComponent as LiveCasinoOutlined } from './assets/live_casino_outlined.svg';
import { ReactComponent as Ticket } from './assets/ticket.svg';

import { ReactComponent as Apple } from './assets/apple.svg';
import { ReactComponent as Android } from './assets/android.svg';

import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as LogoWhite } from './assets/logo-white.svg';
import { ReactComponent as LogoJune } from './assets/logo-june.svg';

import { ReactComponent as Chat } from './assets/chat.svg';
import { ReactComponent as Phone } from './assets/phone.svg';

import { ReactComponent as AviatorLogo } from './assets/aviator_logo.svg';
import { ReactComponent as AviatorName } from './assets/aviator_name.svg';

import { ReactComponent as ResponsibleGamblingLink } from './assets/rgc.svg';
import { ReactComponent as BeGambleAwareLink } from './assets/bga.svg';
import { ReactComponent as EighteenPlus } from './assets/18.svg';
import { ReactComponent as HeatedJune } from './assets/heated.svg';

export const icons = {
  casino: Casino,
  liveCasino: LiveCasino,
  poker: Poker,
  games: Games,
  providers: Providers,
  topGames: TopGames,
  heatedJune: HeatedJune,
  topGamesJune: TopGamesJune,
  favorites: Favorites,
  favoritesJune: FavoritesJune,
  live: Live,
  popular: Popular,
  popularJune: PopularJune,
  bonus: Bonus,
  leaderBoard: LeaderBoard,

  'casino--outlined': CasinoOutlined,
  'live--outlined': LiveOutlined,
  'home--outlined': HomeOutlined,
  'liveCasino--outlined': LiveCasinoOutlined,
  ticket: Ticket,

  apple: Apple,
  android: Android,

  logo: Logo,
  'logo--white': LogoWhite,

  chat: Chat,
  phone: Phone,

  aviatorLogo: AviatorLogo,
  aviatorName: AviatorName,

  juneLogo: LogoJune,

  responsibleGamblingLink: ResponsibleGamblingLink,
  beGambleAwareLink: BeGambleAwareLink,
  eighteenPlus: EighteenPlus,
};
