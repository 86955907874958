import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DetailPopup } from '@box/shared/baseComponents';
import { useToggleActiveElement } from '@box/shared/hooks';

import { Props } from './Title.types';

import styles from './title.module.scss';

export const Title: FC<Props> = ({
  min,
  max,
  currency,
  available,
  isDepositWagered,
  className,
  commissionPercent,
}) => {
  const { t } = useTranslation();
  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  return (
    <div className={classNames(styles.container, className)}>
      <p className={styles.title}>
        {min} - {max} {currency}
      </p>
      <div className={styles.available}>
        {t('withdrawalRequest.available_for_withdrawal')}: {available}{' '}
        {currency}
      </div>
      {!isDepositWagered && (
        <div
          className={styles.detail}
          onClick={toggleOpen}
          aria-hidden='true'
          ref={elementRef}
        >
          <span>?</span>
          <DetailPopup
            className={styles.detailInfo}
            isOpen={isOpen}
            position='bottom-start'
          >
            {t('withdrawalRequest.popup_text', {
              percent: commissionPercent,
              amount: available,
              currency,
            })}
          </DetailPopup>
        </div>
      )}
    </div>
  );
};
