import { z } from 'zod';
import { birthdateValidation } from './birthday';
import { validatePhone } from './phone';

const required = {
  required_error: 'fields.required',
  invalid_type_error: 'fields.required',
};

export const personalDataSchema = z
  .object({
    name: z.string(required).nonempty('fields.required'),
    surname: z.string(required).nonempty('fields.required'),
    country_id: z.number(),
    city: z.string(required).nonempty('fields.required'),
    birthdate: z
      .string(required)
      .refine(birthdateValidation.correct, {
        message: 'fields.date_format_error',
      })
      .refine(birthdateValidation.age, { message: 'fields.date_age_error' }),
    address: z.string(required).nonempty('fields.required'),
    email: z.string(required).email('fields.email_format_error'),
    phone: z.string(required).refine(validatePhone),
    gender: z.enum(['m', 'f'], required),
    document_type: z.string(required).nonempty('fields.required'),
    scan: z.instanceof(File, { message: 'fields.required' }),
    photo: z.instanceof(File, { message: 'fields.required' }),
  })
  .merge(
    z
      .object({
        'document__id-card_number': z
          .string(required)
          .nonempty('fields.required'),
      })
      .partial()
  )
  .merge(
    z
      .object({
        'document__driving-license_number': z
          .string(required)
          .nonempty('fields.required'),
      })
      .partial()
  )
  .merge(
    z
      .object({
        document__passport_number: z
          .string(required)
          .nonempty('fields.required'),
      })
      .partial()
  )
  .refine(
    (data) =>
      !!data['document__id-card_number'] ||
      !!data['document__driving-license_number'] ||
      !!data.document__passport_number
  );
