import { createSlice } from '@reduxjs/toolkit';

import { initGame, initDemoGame } from './thunks';
import { CurrentGameInitialState } from './types';

const initialState: CurrentGameInitialState = {
  game: {} as CurrentGameInitialState['game'],
  loading: false,
  loadingId: '',
};

export const slice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initGame.pending, (state, { meta }) => {
        state.loading = true;
        state.loadingId = meta.arg.loadingId;
      })
      .addCase(initGame.fulfilled, (state, { payload }) => {
        state.game = payload;
        state.loading = false;
        state.loadingId = '';
      })
      .addCase(initGame.rejected, (state) => {
        state.loading = false;
        state.loadingId = '';
      })

      .addCase(initDemoGame.pending, (state, { meta }) => {
        state.loading = true;
        state.loadingId = meta.arg.loadingId;
      })
      .addCase(initDemoGame.fulfilled, (state, { payload }) => {
        state.game = payload;
        state.loading = false;
        state.loadingId = '';
      })
      .addCase(initDemoGame.rejected, (state) => {
        state.loading = false;
        state.loadingId = '';
      });
  },
});
