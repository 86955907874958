import { createSlice } from '@reduxjs/toolkit';

import {
  setActiveWalletBalance,
  setBonusBalance,
  resetBalances,
  initBalance,
} from './actions';
import { refreshBalance } from './thunks';

import { InitialState } from './types';

const initialState: InitialState = {
  main: {
    balance: 0,
    symbol: '',
    updatedAt: null,
  },
  bonus: {
    balance: 0,
  },
};

export const slice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(refreshBalance.fulfilled, (state, { payload }) => {
        state.bonus.balance = payload.bonusWallet;
        state.main.balance = payload.currencyWallet;
      })
      .addCase(setActiveWalletBalance, (state, { payload }) => {
        state.main.balance = payload.value;
        state.main.symbol = payload.symbol || state.main.symbol;
        state.main.updatedAt = payload.updatedAt;
      })
      .addCase(setBonusBalance, (state, { payload }) => {
        state.bonus.balance = payload.balance;
      })

      .addCase(initBalance, (state, { payload }) => {
        state.main.balance = payload.active;
        state.main.symbol = payload.symbol;
        state.bonus.balance = payload.bonus;
      })

      .addCase(resetBalances, (state) => {
        state.main.balance = 0;
        state.main.symbol = '';
        state.main.updatedAt = 0;
        state.bonus.balance = 0;
      });
  },
});
