import { ReactComponent as defaultIcon } from './assets/default_category.svg';
import { ReactComponent as blackJacks } from './assets/black_jacks.svg';
import { ReactComponent as liveCasino } from './assets/live_casino.svg';
import { ReactComponent as lottery } from './assets/lottery.svg';
import { ReactComponent as newIcon } from './assets/new.svg';
import { ReactComponent as scratchCards } from './assets/scratch_cards.svg';
import { ReactComponent as other } from './assets/other.svg';
import { ReactComponent as tableGames } from './assets/table_games.svg';
import { ReactComponent as videoPoker } from './assets/video_poker.svg';
import { ReactComponent as virtualSports } from './assets/virtual_sports.svg';
import { ReactComponent as slots } from './assets/slots.svg';
import { ReactComponent as roulette } from './assets/roulette.svg';
import { ReactComponent as topGames } from './assets/fire.svg';
import { ReactComponent as topGamesJune } from './assets/top_games_june.svg';
import { ReactComponent as popularJune } from './assets/popular_june.svg';
import { ReactComponent as heatedJune } from './assets/heated.svg';

const categoriesIcons = {
  new: newIcon,
  other,
  slots,
  lottery,
  roulette,
  videopoker: videoPoker,
  table: tableGames,
  Live: liveCasino,
  virtualsports: virtualSports,
  scratchcards: scratchCards,
  blackjacks: blackJacks,
  topgames: topGames,
};

const juneCategoriesIcons = {
  topgames: topGamesJune,
  popular: popularJune,
  heated: heatedJune,
};

export const setCategoriesIcons = (list) =>
  list?.map((item) => ({
    ...item,
    icon: item.icon || { type: 'category', alias: item.alias },
  }));

export const getCategoryIcon = (icon) =>
  categoriesIcons[icon.alias] || defaultIcon;

export const getJuneCategoryIcon = (icon) => {
  return juneCategoriesIcons[icon.alias] || null;
};
