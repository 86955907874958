import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  paymentMethodsSelectors,
  paymentSelectors,
  userPayment,
} from '@box/redux/finance';
import { balanceSelectors } from '@box/redux/user';
import { thunkWithConfirm } from '@box/redux/app';

import { paymentIntension } from '../../../utils';

import CurrencyTabs from './CurrencyTabs/CurrencyTabs';

export const withState = (Component) => {
  const wrapper = (props) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const { onPay, withInstruction } = props;

    const merchants = useSelector(paymentMethodsSelectors.list);
    const categories = useSelector(paymentMethodsSelectors.sortedByCategories);
    const frame = useSelector(paymentSelectors.frame);

    const tabs = useMemo(
      () => [
        {
          title: t('top_up.all'),
          component: () => (
            <CurrencyTabs
              currencies={merchants}
              onPay={onPay}
              withInstruction={withInstruction}
            />
          ),
        },
        ...categories.map((item) => ({
          title: item.title,
          component: () => (
            <CurrencyTabs
              currencies={item.list}
              onPay={onPay}
              withInstruction={withInstruction}
            />
          ),
        })),
      ],
      [merchants, language]
    );

    return <Component {...props} tabs={tabs} frame={frame} />;
  };

  return wrapper;
};

export const withPayState = (Component) => {
  const wrapper = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const walletSymbol = useSelector(balanceSelectors.currencySymbol);

    const onPay = (data) => {
      paymentIntension({ userId: data.userId, paymentPayload: data });

      dispatch(
        thunkWithConfirm({
          title: t('common.confirmation'),
          text: t('top_up.confirm', {
            amount: data.amount,
            symbol: walletSymbol,
          }),
          callback: () => dispatch(userPayment(data)),
        })
      );
    };

    return <Component {...props} onPay={onPay} withInstruction />;
  };

  return wrapper;
};
