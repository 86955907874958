import React, { FC } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useApp } from '@box/shared/hooks';

import { Overlay } from '../Overlay';

import styles from './Modal.module.scss';
import { Props } from './Modal.types';

export const Modal: FC<Props> = ({
  children,
  className,
  isOpen,
  close,
  heightAuto,
  ...props
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return createPortal(
    <div
      className={classNames(styles.modal, className, {
        [styles['modal--open']]: isOpen,
      })}
      {...props}
    >
      <Overlay isOpen={isOpen} onClick={close} />
      <div
        className={classNames(styles.container, {
          [styles.container_june]: isJune,
          [styles.container_heightAuto]: heightAuto,
        })}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};
