import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { setChatReady } from '@box/redux/support';
import { setFirstVisitParams } from '@box/redux/utils';

import { hideModal } from '../../app';
import { AppThunkApi } from '../../types';

import { authorize, connectExternal, setIsPartnerSetTrue } from '../init';

import { FetchLoginReturn, FetchLoginPayload, POSTLogin } from './types';
import { userRegistration } from '../register';
import { setIsUserNotFound, setIsButtonDisabled } from './actions';

export const fetchLogin = createAsyncThunk<
  FetchLoginReturn,
  FetchLoginPayload,
  AppThunkApi
>(
  'auth/login/fetchLogin',
  async (payload, { dispatch, extra, rejectWithValue }) => {
    const { api, user } = extra;

    dispatch(setIsButtonDisabled(true));

    try {
      const { username, password, isFromRegister, currency_id, country_id } =
        payload;

      const { data, status } = await api.post<POSTLogin>('/login', {
        username,
        password,
        with: user.requestExtra,
      });

      if (status === 200) {
        dispatch(setChatReady(false));
        dispatch(connectExternal(data.access_token));
        dispatch(authorize({ data }));
        setFirstVisitParams(data.partner_params);
        dispatch(setIsPartnerSetTrue());
        dispatch(setIsButtonDisabled(false));
        dispatch(hideModal('login'));

        if (isFromRegister) {
          dispatch(hideModal('register'));
        }

        return data;
      }

      // Если статус 404, то регистрируем пользователя, а также прячем ошибку
      if (status === 404 && !isFromRegister) {
        dispatch(setIsUserNotFound(true));
        dispatch(
          userRegistration({
            email: username,
            password,
            country_id,
            currency_id,
            isAutoAuth: true,
          })
        );
        return;
      }

      dispatch(setIsButtonDisabled(false));

      return rejectWithValue(i18next.t('requestErrors.fetch_login'));
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.fetch_login'));
    }
  }
);
