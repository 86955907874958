import React, { FC } from 'react';

import { icons } from './LoyaltyIcon.data';

import { Props } from './LoyaltyIcon.types';

export const LoyaltyIcon: FC<Props> = ({ className, iconId, color }) => {
  const Component = icons[iconId];

  if (Component) {
    return (
      <Component
        style={{ fill: `url('#${iconId}_svg__${color}')` }}
        className={className}
      />
    );
  }

  return null;
};
