import React, { FC, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Controller, Select } from '../../ui';
import { useValidate } from '../../validation';

import { Option, Props } from './FlexibleFields.types';

import styles from './FlexibleFields.module.scss';

export const FlexibleFields: FC<Props> = ({
  className,
  fields,
  isBanner,
  isDeposit,
  children,
  rowInDiv,
}) => {
  const isFirstRender = useRef(true);
  const { register, control, formState, unregister } = useFormContext();

  const { errors } = formState;
  const { customValidate, rulesForObject } = useValidate();

  const fieldsArr = useMemo(
    () =>
      fields.map((item) => {
        if (item.type === 'select') {
          const options: Option[] = Object.keys(item.options).map((key) => ({
            value: key,
            label: item.options[key],
          }));

          const onChange = (v, field) => field.onChange(v.value);

          return {
            ...item,
            options,
            onChange,
            label: item.label,
            rules: rulesForObject(item.rules),
            validate: customValidate(item.rules, item.mask),
          };
        }

        return item;
      }),
    [fields]
  );

  useEffect(() => {
    // ===== Условие необходимо, чтобы сбросить поле с суммой при переходе между платежками
    // ===== Важно: Хранить setValue вне условия, во избежание проблем, когда платежка одна
    if (!isFirstRender.current) {
      unregister();
    } else {
      isFirstRender.current = false;
    }
  }, [fields]);

  const fieldsFixed = useMemo(() => {
    const arrayOfSingles = [];
    const arrayOfRows = [];
    fieldsArr.forEach((item) => {
      if (item?.inline) {
        arrayOfRows.push(item);
      } else {
        arrayOfSingles.push(item);
      }
    });
    const array_size = 2;

    const sliced_array = [];

    for (let i = 0; i < arrayOfRows.length; i += array_size) {
      sliced_array.push(arrayOfRows.slice(i, i + array_size));
    }
    return { singles: arrayOfSingles, rows: sliced_array };
  }, [fieldsArr]);

  return (
    <div
      className={classNames(
        styles.fields,
        { [styles.fields_banner]: isBanner },
        {
          [styles.fields_banner_deposit]:
            (!!fieldsFixed.singles.length || !!fieldsFixed.rows.length) &&
            isDeposit,
        },
        { [styles.fields_withdrawal]: children },
        className
      )}
    >
      {children && children.length > 1 && children[0]}
      {fieldsFixed.singles.map((item, idx) =>
        item.type === 'select' ? (
          <Select
            className={classNames(styles.field, styles.item)}
            control={control}
            defaultValue={item.default_value}
            error={errors[item.name]?.message?.toString()}
            errorMessage={errors[item.name]?.message?.toString()}
            key={`${item.name}-${idx}`}
            name={item.name}
            label={item.label}
            placeholder={item.placeholder}
            options={item.options}
            isDeposit={isDeposit}
            menuPosition='fixed'
            {...item}
          />
        ) : (
          <div className={classNames(styles.box, styles.item)}>
            {isBanner && <p className={styles.label}>{item.label}:</p>}
            <Controller
              className={styles.field}
              label={item.label}
              errorMessage={errors[item.name]?.message?.toString()}
              defaultValue={item.default_value}
              placeholder={item.placeholder}
              {...register(item.name, {
                ...rulesForObject(item.rules),
                validate: customValidate(item.rules, item.mask),
              })}
              type={item.type}
              mask={item.mask}
              isSmall={isBanner}
              disabled={item.disabled}
              labelHidden={isBanner}
              format={item.format}
              key={`${item.name}-${idx}`}
            />
          </div>
        )
      )}
      {fieldsFixed.rows.map((i, idx) =>
        !rowInDiv ? (
          <div key={idx} className={styles.row}>
            {i.map((item, index) => {
              return (
                <Controller
                  className={styles.halfField}
                  label={item.label}
                  errorMessage={errors[item.name]?.message}
                  defaultValue={item.default_value}
                  placeholder={item.placeholder}
                  {...register(item.name, {
                    ...rulesForObject(item.rules),
                    validate: customValidate(item.rules, item.mask),
                  })}
                  type={item.type}
                  mask={item.mask}
                  disabled={item.disabled}
                  format={item.format}
                  key={index}
                />
              );
            })}
          </div>
        ) : (
          <>
            {i.map((item, index) => {
              return (
                <Controller
                  className={styles.halfField}
                  label={item.label}
                  errorMessage={errors[item.name]?.message}
                  defaultValue={item.default_value}
                  placeholder={item.placeholder}
                  {...register(item.name, {
                    ...rulesForObject(item.rules),
                    validate: customValidate(item.rules, item.mask),
                  })}
                  type={item.type}
                  mask={item.mask}
                  disabled={item.disabled}
                  format={item.format}
                  key={index}
                />
              );
            })}
          </>
        )
      )}
      {children && children.length > 1 ? children[1] : children}
    </div>
  );
};
