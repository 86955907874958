// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Deposit-Components-Payments-payments-module__tabs{align-items:stretch;display:flex;flex-wrap:wrap;gap:4px;margin-top:24px}.\\------shared-components-Deposit-Components-Payments-payments-module__tabs::-webkit-scrollbar{height:0}.\\------shared-components-Deposit-Components-Payments-payments-module__tabs_skittles{display:flex;flex-wrap:wrap;justify-content:center;padding:0}.\\------shared-components-Deposit-Components-Payments-payments-module__tabs_skittles .\\------shared-components-Deposit-Components-Payments-payments-module__tab{margin:8px 3px}.\\------shared-components-Deposit-Components-Payments-payments-module__tabs_mobile{flex-wrap:nowrap;margin-top:16px;overflow-x:scroll;overflow-y:hidden}", "",{"version":3,"sources":["webpack://./../../shared/components/Deposit/Components/Payments/payments.module.scss"],"names":[],"mappings":"AACA,4EACE,mBAAA,CACA,YAAA,CACA,cAAA,CACA,OAAA,CACA,eAAF,CAEE,+FACE,QAAJ,CAGE,qFACE,YAAA,CAEA,cAAA,CADA,sBAAA,CAEA,SADJ,CAGI,gKACE,cADN,CAKE,mFAGE,gBAAA,CACA,eAAA,CAHA,iBAAA,CACA,iBADJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.tabs {\n  align-items: stretch;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4px;\n  margin-top: 24px;\n\n  &::-webkit-scrollbar {\n    height: 0;\n  }\n\n  &_skittles {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    padding: 0;\n\n    .tab {\n      margin: 8px 3px;\n    }\n  }\n\n  &_mobile {\n    overflow-x: scroll;\n    overflow-y: hidden;\n    flex-wrap: nowrap;\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "------shared-components-Deposit-Components-Payments-payments-module__tabs",
	"tabs_skittles": "------shared-components-Deposit-Components-Payments-payments-module__tabs_skittles",
	"tab": "------shared-components-Deposit-Components-Payments-payments-module__tab",
	"tabs_mobile": "------shared-components-Deposit-Components-Payments-payments-module__tabs_mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
