import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { DepositFields, Field, PaymentFrame, PaymentMethod } from '@box/redux';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import {
  fetchPaymentMethods,
  paymentMethodsSelectors,
  paymentResetError,
  paymentSelectors,
  removeQR,
  removeTransactionsAmount,
  setSelectedMethod,
  userPayment,
} from '@box/redux/finance';
import { balanceSelectors, userSelectors } from '@box/redux/user';
import { userInitSelectors } from '@box/redux/authentication';

import { paymentIntension } from '../utils';

// Вынести типы для Deposit \ Modal Deposit

export type UseDeposit = () => {
  isIn: boolean;
  loading: boolean;
  methods: UseFormReturn<DepositFields, any>;
  payments: PaymentMethod[];
  isBeatifyCardForm: boolean;
  selectedMethod: PaymentMethod;
  setSelectedMethod: (method: PaymentMethod) => void;
  onSubmit: SubmitHandler<DepositFields>;
  bonuses: {
    percent: string;
    defaults: number[];
    minDepSum: number;
  };
  currencySymbol: string;
  requestError: string;
  fields: Field[];
  qr: string;
  bonusBalance: number;
  frame: PaymentFrame;
  transactionsAmount: number;
  defaultsEnabled: boolean;
  paymentLoading: boolean;
};

export const useDeposit: UseDeposit = () => {
  const dispatch = useAppDispatch();

  const methods = useForm<DepositFields>();

  const clientData = useAppSelector(commonSelectors.clientData);
  const payments = useAppSelector(paymentMethodsSelectors.list);
  const isPaymentsInit = useAppSelector(paymentMethodsSelectors.isInit);
  const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
  const frame = useAppSelector(paymentSelectors.frame);
  const qr = useAppSelector(paymentSelectors.qr);
  const transactionsAmount = useAppSelector(
    paymentSelectors.transactionsAmount
  );
  const userId = useAppSelector(userSelectors.id);
  const userInfo = useAppSelector(userSelectors.user);
  const currency = useAppSelector(userSelectors.currency);
  const loading = useAppSelector(paymentSelectors.loading);
  const requestError = useAppSelector(paymentSelectors.error);
  const bonusBalance = useAppSelector(balanceSelectors.balanceValue);
  const isIn = clientData?.country?.code === 'IN';
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const selectedMethod = useAppSelector(paymentMethodsSelectors.selectedMethod);

  const bonuses = useMemo(() => {
    const bonuses = {
      percent: userInfo?.deposit_bonus?.bonus?.getting_params[0]?.percent,
      defaults: [
        Number(clientData?.country?.payment_method_params?.dep_button1_amount),
        Number(clientData?.country?.payment_method_params?.dep_button2_amount),
        Number(clientData?.country?.payment_method_params?.dep_button3_amount),
        Number(clientData?.country?.payment_method_params?.dep_button4_amount),
      ],
      minDepSum: Number(userInfo?.deposit_bonus?.bonus?.getting_params[0]?.min),
    };
    return bonuses;
  }, [clientData, userInfo, currencySymbol]);

  const { setValue, watch } = methods;

  const watchAmount = watch('amount');

  const isBeatifyCardForm =
    clientData?.country?.payment_method_params?.enabled &&
    clientData?.country?.payment_method_params?.beatify_form_for_methods?.includes(
      selectedMethod?.id
    );

  const fields = useMemo(() => {
    let fields = [] as Field[];
    if (selectedMethod) {
      selectedMethod?.fields?.forEach((item) => {
        // Для Индии добавляем формат +91 на customer_phone
        if (item.name === 'customer_phone' && isIn) {
          fields.push({ ...item, format: '+91' });
        } else {
          fields.push(item);
        }
      });
    }
    return fields;
  }, [selectedMethod]);

  const handleSetSelectedMethod = (method: PaymentMethod) => {
    dispatch(removeQR());
    dispatch(removeTransactionsAmount());
    dispatch(setSelectedMethod(method));
  };

  const onSubmit: SubmitHandler<DepositFields> = (data) => {
    const value = {
      userId,
      amount: data.amount,
      currencyCode: currency.code,
      paymentMethodId: selectedMethod.id,
      merchantId: selectedMethod.merchant.id,
      fields: {
        ...data,
        hosted: false,
      },
    };

    paymentIntension({
      userId,
      paymentPayload: value,
    });

    dispatch(
      userPayment({
        userId,
        ...value,
      })
    );
  };

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchPaymentMethods());
    }
  }, [isLogged, lang]);

  useEffect(() => {
    dispatch(paymentResetError());
  }, [selectedMethod]);

  useEffect(() => {
    if (bonuses?.defaults[1]) {
      if (typeof watchAmount === 'undefined') {
        setValue('amount', bonuses?.defaults[1]);
      }
    }
  }, [bonuses, watchAmount]);

  useEffect(() => {
    if (payments) {
      dispatch(setSelectedMethod(payments[0]));
    }
  }, [payments]);

  return {
    methods,
    isIn,
    payments,
    selectedMethod,
    setSelectedMethod: handleSetSelectedMethod,
    loading,
    onSubmit,
    bonuses,
    currencySymbol,
    isBeatifyCardForm,
    requestError,
    fields,
    bonusBalance,
    qr,
    frame,
    transactionsAmount,
    defaultsEnabled: clientData?.country?.payment_method_params?.enabled,
    paymentLoading: !isPaymentsInit,
  };
};
