import { Dispatch, SetStateAction, useEffect } from 'react';
import isString from 'lodash/isString';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import forIn from 'lodash/forIn';
import { useUnmount } from 'react-use';

import {
  getBirthdate,
  getUpdateData,
  preparePhone,
  prepareDocuments,
} from '@box/shared/utils';
import {
  createUserDocument,
  documentsSelectors,
  initDocuments,
  updateUserDocument,
  userSelectors,
  userUpdateReset,
} from '@box/redux/user';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { ApiUserData } from '@box/redux/user/documents/types';
import { commonSelectors } from '@box/redux/common';
import { Inputs } from './PersonalData.types';

export const usePersonalData = (
  setIsUploadOpen: Dispatch<SetStateAction<boolean>>
) => {
  const { setValue, reset } = useFormContext<Inputs>();

  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelectors.user);

  const init = useAppSelector(documentsSelectors.init);

  const currentDocument = useAppSelector(documentsSelectors.current);
  const codes = useAppSelector(commonSelectors.codes);

  const getUserEmail = (email) => {
    if (isString(email)) {
      return email.includes('rand_') ? '' : email;
    }
    return '';
  };

  const getUserName = (usr: typeof user) => {
    if (usr?.name_is_default) {
      return '';
    }
    return usr.name;
  };

  const openUpload = () => setIsUploadOpen(true);

  useEffect(() => {
    if (!init) {
      dispatch(initDocuments());
    } else if (!isEmpty(currentDocument)) {
      setValue('document_type', currentDocument.type.alias);
      setValue('scan', currentDocument.scan);
      setValue('photo', currentDocument.photo);

      forIn(currentDocument.fields_values, (value, key) => {
        setValue(`document__${key}`, value);
      });

      openUpload();
    }
  }, [init]);

  useEffect(() => {
    if (user) {
      setValue('name', getUserName(user));
      setValue('surname', user.surname);
      setValue('email', getUserEmail(user.email));
      setValue('country_id', user.country_id);
      setValue('birthdate', getBirthdate(user.birthdate));
      setValue('phone', user.phone);
      setValue('city', user.city);
      setValue('gender', user.gender);
      setValue('address', user.address);
    }
  }, [user]);

  useUnmount(() => {
    reset();
    dispatch(userUpdateReset());
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const userData = getUpdateData(
      { ...user, birthdate: getBirthdate(user.birthdate) },
      {
        ...data,
        phone: `${preparePhone(`${codes.phone}${data.phone}`)}`,
      }
    ) as ApiUserData;
    userData.email = user.email;

    if (isEmpty(currentDocument)) {
      dispatch(
        createUserDocument({
          values: prepareDocuments(data, currentDocument),
          userData,
        })
      );
    } else {
      dispatch(
        updateUserDocument({
          id: currentDocument.id,
          values: prepareDocuments(data, currentDocument),
          userData,
        })
      );
    }
  };

  return onSubmit;
};
