import React, { lazy, Suspense } from 'react';
import Loading from '@box/shared/components/Loading/Loading';

import { userEnvironment } from '../userEnvironment';

const Desktop = lazy(() => import('@box/lafa-desktop'));

export const DesktopApp = () => {
  return (
    <Suspense
      fallback={<Loading isFailed={false} hideSpinner isJune={false} />}
    >
      <Desktop userEnv={userEnvironment} />
    </Suspense>
  );
};
