import React, { FC, useContext } from 'react';
import { useEffectOnce } from 'react-use';

import { PlatesContext } from './State';
import { PlateCardProps } from './types';

export const PlateCard: FC<PlateCardProps> = ({
  children,
  id,
  className,
  defaultActive,
  ...props
}) => {
  const { setActivePlate } = useContext(PlatesContext);

  useEffectOnce(() => {
    if (defaultActive) {
      setActivePlate(id);
    }
  });

  return (
    <div
      className={className}
      onClick={() => setActivePlate(id)}
      aria-hidden='true'
      {...props}
    >
      {children}
    </div>
  );
};
