import { createAction } from '@reduxjs/toolkit';

export const openModal = createAction<string>('modal/open');
export const hideModal = createAction<string>('modal/hide');
export const setModalProps = createAction<{ id: string; props: object }>(
  'modal/setProps'
);
export const resetModalProps = createAction<{ id: string }>('modal/resetProps');
export const setRedirects = createAction('modal/setRedirects');
export const openErrorPopup = createAction<{ title: string; text: string }>(
  'modal/openErrorPopup'
);
