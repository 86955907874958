import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { LineLoader } from '../LineLoader';

import { LightButtonProps } from './LightButton.types';

import styles from './lightbutton.module.scss';

export const LightButton: FC<LightButtonProps> = ({
  className,
  type = 'button',
  color = 'yellow',
  children,
  onClick,
  loading,
  disabled,
  withBorder = true,
  borderRadius = 'medium',
  isFlex = true,
  fixHeight,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        styles.button,
        styles[`button_color_${color}`],
        styles[`button_border-radius_${borderRadius}`],
        {
          [styles.button_disabled]: disabled,
          [styles.button_border]: withBorder,
          [styles.button_flex]: isFlex,
          [styles.button_fixHeight]: fixHeight,
          [styles.button_mobile]: isMobile,
        },
        className
      )}
    >
      {!loading ? children : <LineLoader />}
    </button>
  );
};
