import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Collapsed, SmallGoldenCard } from '../../baseComponents';

import './helpSpoiler.scss';
import { ReactComponent as QuestionIcon } from './assets/question.svg';
import { ReactComponent as Arrow } from './assets/gold_arrow.svg';

export const HelpSpoiler = ({ className, title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SmallGoldenCard
      className={classNames('help-spoiler', className, {
        'help-spoiler--open': isOpen,
      })}
    >
      <div className='help-spoiler__inner'>
        <div
          className='help-spoiler__head'
          onClick={() => setIsOpen((prev) => !prev)}
          aria-hidden='true'
        >
          <Arrow className='help-spoiler__arrow' />
          <span className='help-spoiler__title'>
            <QuestionIcon />
            {title}
          </span>
          <Arrow className='help-spoiler__arrow' />
        </div>
        <Collapsed isOpen={isOpen} bottomOffset={16}>
          <div className='help-spoiler__text'>{text}</div>
        </Collapsed>
      </div>
    </SmallGoldenCard>
  );
};

HelpSpoiler.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

HelpSpoiler.defaultProps = {
  className: '',
};
