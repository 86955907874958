import { createSlice } from '@reduxjs/toolkit';

import { ClientData, BonusTypesNames } from '../types';
import { setCommonsFailed } from './actions';

import {
  fetchCategories,
  fetchCommons,
  fetchProviders,
  fetchBonusTypes,
  fetchBannersList,
  fetchBanners,
} from './thunks';

import { InitialState } from './types';

const initialState: InitialState = {
  currencies: [],
  countries: [],
  banners: [],
  clientData: {} as ClientData,
  providers: [],
  categories: [],
  bonusTypes: {} as BonusTypesNames,
  languages: [],
  allBanners: [],
  bannersWithPosition: [],
  commonsReady: false,
  commonsFailed: false,
};

export const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommons.fulfilled, (state, { payload }) => {
        state.countries = payload.countries;
        state.currencies = payload.currencies;
        state.clientData = payload.clientData;
        state.languages = payload.languages;
        state.commonsReady = true;
      })

      .addCase(fetchProviders.fulfilled, (state, { payload }) => {
        state.providers = payload;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.categories = payload;
      })
      .addCase(fetchBonusTypes.fulfilled, (state, { payload }) => {
        state.bonusTypes = payload;
      })
      .addCase(fetchBannersList.fulfilled, (state, { payload }) => {
        state.allBanners = payload;
      })
      .addCase(fetchBanners.fulfilled, (state, { payload }) => {
        state.bannersWithPosition.push(payload);
      })
      .addCase(setCommonsFailed, (state, { payload }) => {
        state.commonsFailed = payload;
      });
  },
});
