import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ImageSet } from '@box/ui';

import { BadgeProps } from '../BonusBadge.types';

import styles from './light.module.scss';
import DecorPng from './assets/images/decor.png';
import DecorWebp from './assets/images/decor.webp';
import DecorAvif from './assets/images/decor.avif';

export const Light: FC<BadgeProps> = ({
  handleBadgeClick,
  isReady,
  isOpen,
  percent,
}) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={handleBadgeClick}
      role='button'
      onKeyPress={handleBadgeClick}
      tabIndex={0}
      className={classNames(styles.badge, {
        [styles.badge_open]: isReady && isOpen && percent,
      })}
    >
      <ImageSet
        src={DecorPng}
        srcWebp={DecorWebp}
        srcAvif={DecorAvif}
        alt={t('bonuses.bonus')}
      />
      <p className={styles.text}>+{percent}%</p>
    </div>
  );
};
