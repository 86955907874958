import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { getPureErrorMessage } from '../../utils';
import { useAuthTokens } from '../../hooks';
import { openInfoPopup, hideModal } from '../../app';
import { AppThunkApi } from '../../types';

import { setUserInfo } from '../info';

import {
  UpdatePasswordReturn,
  UpdatePasswordPayload,
  POSTUpdatePassword,
} from './types';

export const updatePassword = createAsyncThunk<
  UpdatePasswordReturn,
  UpdatePasswordPayload,
  AppThunkApi
>('password/update', async (payload, { dispatch, extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { setAccessToken } = useAuthTokens();

    const { data, status } = await api.post<POSTUpdatePassword>(
      '/password/update',
      payload
    );

    if (status === 200) {
      setAccessToken(data.access_token);

      dispatch(hideModal('settings'));
      dispatch(
        openInfoPopup({
          text: i18next.t('settings.success_submit_text'),
          type: 'success',
        })
      );

      dispatch(setUserInfo(data));

      return data;
    }

    const error = getPureErrorMessage(data.errors);

    return rejectWithValue(error.message);
  } catch (err) {
    return rejectWithValue('Error');
  }
});
