// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-June-Logout-logout-module__logout h3{font-size:20px;z-index:10}.\\------shared-components-Modals-June-Logout-logout-module__logout svg{top:30%}.\\------shared-components-Modals-June-Logout-logout-module__logout .\\------shared-components-Modals-June-Logout-logout-module__body{align-items:center;display:flex;gap:7px;justify-content:center;padding:25px 20px}.\\------shared-components-Modals-June-Logout-logout-module__logout .\\------shared-components-Modals-June-Logout-logout-module__button{font-size:17px;padding:15px;width:100%}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/June/Logout/logout.module.scss"],"names":[],"mappings":"AAEE,sEACE,cAAA,CACA,UADJ,CAIE,uEACE,OAFJ,CAKE,oIAEE,kBAAA,CADA,YAAA,CAIA,OAAA,CAFA,sBAAA,CACA,iBAFJ,CAME,sIAEE,cAAA,CACA,YAAA,CAFA,UAFJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.logout {\n  h3 {\n    font-size: 20px;\n    z-index: 10;\n  }\n\n  svg {\n    top: 30%;\n  }\n\n  .body {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 25px 20px;\n    gap: 7px;\n  }\n\n  .button {\n    width: 100%;\n    font-size: 17px;\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logout": "------shared-components-Modals-June-Logout-logout-module__logout",
	"body": "------shared-components-Modals-June-Logout-logout-module__body",
	"button": "------shared-components-Modals-June-Logout-logout-module__button"
};
module.exports = ___CSS_LOADER_EXPORT___;
