import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@box/ui';

import { Button, Modal } from '../../../baseComponents';
import { useConfirmModal } from './ConfirmModal.hook';

import styles from './confirmModal.module.scss';

export const ConfirmModalDecember = () => {
  const { t } = useTranslation();

  const { onClose, onConfirm, additional } = useConfirmModal();

  const { isOpen, title, text, extraText, yes, no, onlyYes } = additional;

  return (
    <Modal
      className={styles.modal}
      close={onClose}
      title={title}
      isOpen={isOpen}
      bodyClassName={styles.modal__body}
    >
      <Text
        className={styles.modal__text}
        color='black'
        size='md'
        align='center'
        as='p'
      >
        {text}
      </Text>
      {extraText && (
        <Text
          className={styles.modal__text}
          color='black'
          size='md'
          align='center'
          as='p'
        >
          {extraText}
        </Text>
      )}
      <div className={styles.modal__buttons}>
        <Button
          className={styles.modal__button}
          onClick={onConfirm}
          color='success'
          hoverType='opacity'
        >
          {yes || t('common.yes')}
        </Button>
        {!onlyYes && (
          <Button
            className={styles.modal__button}
            onClick={onClose}
            color='primary'
            hoverType='opacity'
          >
            {no || t('common.no')}
          </Button>
        )}
      </div>
    </Modal>
  );
};
