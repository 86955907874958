import { createAction } from '@reduxjs/toolkit';
import { PaymentFrame } from '../../types';

export const setFrame = createAction<PaymentFrame>('finance/payment/setFrame');
export const removeFrame = createAction('finance/payment/removeFrame');
export const paymentResetError = createAction(
  'finance/payment/paymentResetError'
);
export const setQR = createAction<string>('finance/payment/setQR');
export const setTransactionsAmount = createAction<number>(
  'finance/payment/setTransactionsAmount'
);
export const removeQR = createAction('finance/payment/removeQR');
export const removeTransactionsAmount = createAction(
  'finance/payment/removeTransactionsAmount'
);
