import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import { Input } from '@box/ui';

import { Inputs } from '../PersonalData.types';
import styles from '../personalData.module.scss';

export const CityField = memo(() => {
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);

  const {
    register,
    formState: { errors },
  } = useFormContext<Inputs>();

  return (
    <Input
      placeholder={t('settings.city')}
      error={t(errors.city?.message)}
      label={t('settings.city')}
      className={styles.control}
      {...register('city', {
        disabled,
      })}
    />
  );
});
