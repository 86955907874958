export const useReloadApp = () => {
  const reloadHandler = () => {
    // Reload the page
    window.location.reload();

    // Clear cache (if supported by the browser)
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
  };
  return {
    reloadHandler,
  };
};
