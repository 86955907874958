import { z } from 'zod';
import { resolveSafeParse } from './helper';

// Убрать все символы кроме цифр
export const clearPhoneNumber = (value: string) => value.replace(/\D/gi, '');

export const validatePhone = (
  value: string,
  min = 7,
  code: number | string = '[0-9]{1,3}'
) => {
  const regex = new RegExp(`^(${code})([0-9]{${min},})$`, 'im');
  return regex.test(clearPhoneNumber(value));
};

export const phoneValidator = (code: number, value: string, min: number) => {
  const result = z
    .string()
    .min(min)
    .refine((v) => validatePhone(v, min, code), 'fields.required')
    .safeParse(value);

  return resolveSafeParse(result);
};
