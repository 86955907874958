import { combineReducers } from '@reduxjs/toolkit';

import infoReducer from './info';
import balanceReducer from './balance';
import updateReducer from './update';
import bonusesReducer from './bonuses';
import documentsReducer from './documents';
import promocodesReducer from './promocodes';

const reducer = combineReducers({
  info: infoReducer,
  balance: balanceReducer,
  update: updateReducer,
  bonuses: bonusesReducer,
  documents: documentsReducer,
  promocodes: promocodesReducer,
});

export * from './info';
export * from './balance';
export * from './update';
export * from './bonuses';
export * from './documents';

export default reducer;
