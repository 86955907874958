export type UsePhoneFormats = (code: number) => {
  mask: string;
  placeholder: string;
  minValue: number;
};

export const usePhoneFormats: UsePhoneFormats = (code) => {
  switch (code) {
    // Нидерланды
    case 31:
      return {
        mask: `+${code} (##) ###-####`,
        placeholder: `+${code} (12) 345-6789`,
        minValue: 9,
      };
    // Бразилия
    case 55:
      return {
        mask: `+${code} (##) #####-####`,
        placeholder: `+${code} (12) 34567-8910`,
        minValue: 10,
      };
    // Англия
    case 44:
      return {
        mask: `+${code} (###) ####-####`,
        placeholder: `+${code} (123) 4567-8910`,
        minValue: 7,
      };
    // Россия
    case 7:
      return {
        mask: `+${code} (###) ###-##-##`,
        placeholder: `+${code} (123) 456-78-90`,
        minValue: 10,
      };
    // Азербайджан
    case 994:
      return {
        mask: `+${code} (###) ###-###`,
        placeholder: `+${code} (123) 456-789`,
        minValue: 8,
      };
    // Узбекистан
    case 998:
      return {
        mask: `+${code} (##) #######`,
        placeholder: `+${code} (12) 3456789`,
        minValue: 9,
      };
    // Турция
    case 90:
      return {
        mask: `+${code} (###) ###-####`,
        placeholder: `+${code} (123) 456-7890`,
        minValue: 10,
      };
    // Италия
    case 39:
      return {
        mask: `+${code} (###) ###-###-###`,
        placeholder: `+${code} (123) 456-789-102`,
        minValue: 3,
      };
    // Португалия
    case 351:
      return {
        mask: `+${code} (##) ###-###-###`,
        placeholder: `+${code} (12) 345-678-901`,
        minValue: 9,
      };
    // Испания
    case 34:
      return {
        mask: `+${code} (###) ###-###`,
        placeholder: `+${code} (123) 456-789`,
        minValue: 9,
      };
    // Румыния
    case 40:
      return {
        mask: `+${code} ###-###-###`,
        placeholder: `+${code} 123-456-789`,
        minValue: 9,
      };
    // Норвегия
    case 47:
      return {
        mask: `+${code} ## ## ## ##`,
        placeholder: `+${code} 12 34 56 78`,
        minValue: 8,
      };
    // Польша
    case 48:
      return {
        mask: `+${code} (###) ###-###`,
        placeholder: `+${code} (123) 456-789`,
        minValue: 9,
      };
    // Вьетнам
    case 84:
      return {
        mask: `+${code} (###) ####-####`,
        placeholder: `+${code} (123) 4567-8910`,
        minValue: 10,
      };
    // Германия
    case 49:
      return {
        mask: `+${code} #### #######`,
        placeholder: `+${code} 1234 5678910`,
        minValue: 9,
      };
    default:
      return {
        mask: `+${code} (###) ###-##-##`,
        placeholder: `+${code} (123) 456-78-90`,
        minValue: 10,
      };
  }
};
