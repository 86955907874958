import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Arrows, Button, ImageSet } from '@box/ui';
import { useAppDispatch } from '@box/redux/hooks';
import { addUserBonus } from '@box/redux/user';
import { useApp } from '@box/shared/hooks';

import { Props } from './Main.types';

import CoinPng from './assets/images/coin.png';
import CoinWebp from './assets/images/coin.webp';
import CoinAvif from './assets/images/coin.avif';
import CoinsPng from './assets/images/coins.png';
import CoinsWebp from './assets/images/coins.webp';
import CoinsAvif from './assets/images/coins.avif';
import CupPng from './assets/images/cup.png';
import CupWebp from './assets/images/cup.webp';
import CupAvif from './assets/images/cup.avif';

import styles from './main.module.scss';

export const Main: FC<Props> = ({ name, image, bonusId, description }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(addUserBonus({ bonusId }));
  };

  return (
    <div
      className={classNames(styles.main, { [styles.main_mobile]: isMobile })}
    >
      <div className={styles.coins}>
        <ImageSet
          src={CoinPng}
          srcAvif={CoinAvif}
          srcWebp={CoinWebp}
          alt='coin'
        />
        <ImageSet
          src={CoinsPng}
          srcAvif={CoinsAvif}
          srcWebp={CoinsWebp}
          alt='coins'
        />
      </div>
      {!isMobile && (
        <ImageSet
          className={styles.cup}
          src={CupPng}
          srcAvif={CupAvif}
          srcWebp={CupWebp}
          alt='Cup'
        />
      )}
      <div className={styles.imageBox}>
        <ImageSet
          className={styles.image}
          src={image.png}
          srcAvif={image.avif}
          srcWebp={image.webp}
          alt=''
        />
        {isMobile && <div className={styles.wrapper} />}
      </div>

      <h3 className={styles.title}>{name}</h3>
      <p className={styles.description}>{description}</p>
      <Button
        color='secondary'
        type='button'
        bold
        size='x-large'
        onClick={handleClick}
        className={styles.button}
      >
        {t('bonuses.activate')}
      </Button>
      <Arrows className={styles.arrow} />
    </div>
  );
};
