/* eslint-disable max-len */
export const loadingText = {
  en: 'Dear Client! We are updating and the website will be available very soon. Refresh the page by clicking the button below in 1 minute.',
  pt: 'Estimado cliente! Estamos atualizando e o site estará disponível muito em breve. Actualice a página clicando no botão abaixo em 1 minuto.',
};

export const buttonText = {
  en: 'Update',
  pt: 'Atualizar',
};
