import React from 'react';

import { useApp } from '@box/shared/hooks';
import { ConfirmModalJune } from './ConfirmModal.june';
import { ConfirmModalDecember } from './ConfirmModal.december';

export const ConfirmModal = () => {
  const {
    userEnv: { isJune },
  } = useApp();

  return isJune ? <ConfirmModalJune /> : <ConfirmModalDecember />;
};
