import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HTTPBackend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { currentLang } from '@box/shared/utils';
import { appConfig } from '@box/shared/config';

const loadPath = () => {
  return `${appConfig.translationUrl}/locale/result?host=lafa&code={{lng}}`;
};

let i18nInitialize = false;

const initI18n = () => {
  if (!i18nInitialize) {
    i18n
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
        lng: currentLang,
        fallbackLng: '',
        getAsync: true,
        backend: {
          // TODO: Нужно исправить кеширование переводов
          // backends: [LocalStorageBackend, HTTPBackend],
          backends: [HTTPBackend],
          backendOptions: [
            // {
            //   expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
            // },
            {
              loadPath: loadPath(),
            },
          ],
        },
      });
    i18nInitialize = true;
  }
};

export default initI18n();
