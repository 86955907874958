import { createSlice } from '@reduxjs/toolkit';
import { fetchWithdrawalMerchants } from './thunks';

const initialState = {
  list: [],
  loading: false,
  error: '',
  emptyData: false,
  init: false,
};

export const slice = createSlice({
  name: 'withdrawalMerchants',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWithdrawalMerchants.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.emptyData = false;
        state.init = false;
      })
      .addCase(fetchWithdrawalMerchants.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
        state.emptyData = payload.length === 0;
        state.init = true;
      })
      .addCase(fetchWithdrawalMerchants.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.emptyData = false;
        state.init = false;
      });
  },
});
