import { AsyncThunk, Middleware, Dispatch } from '@reduxjs/toolkit';

import { getStore } from '../getStore';
import rootReducer from '../rootReducer';

import { ExtraArgs } from './extras';

export type MiddlewareCreator = (
  extraParams: ExtraArgs
) => Middleware<{}, any, Dispatch<any>>;
export type RootState = ReturnType<typeof rootReducer>;
export type Store = ReturnType<typeof getStore>;
export type AppDispatch = Store['dispatch'];

export type AppThunk<
  ReturnType = void,
  PayloadType = void,
  RejectType = string
> = AsyncThunk<
  ReturnType,
  PayloadType,
  {
    state: RootState;
    dispatch: AppDispatch;
    extra: ExtraArgs;
    rejectValue: RejectType;
  }
>;

export type AppThunkApi<RejectType = string> = {
  state: RootState;
  dispatch: AppDispatch;
  extra: ExtraArgs;
  rejectValue: RejectType;
};
