import { FunctionComponent, ReactNode } from 'react';
import { NavLinkProps } from 'react-router-dom';

export interface NavigationItem extends Omit<NavLinkProps, 'children'> {
  label?: string;
  excludedActive?: string[];
  children?: ReactNode | ((props: { isActive: boolean }) => ReactNode);
  component?: FunctionComponent;
}

export interface INavigationProps {
  className?: string;
  items: NavigationItem[];
  size?: 'medium' | 'small';
}
