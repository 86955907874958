import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types';

export const lists = (state: RootState) => state.slots.slotGames;
export const listId = (_state: RootState, id: string) => id;

export const listById = createSelector([lists, listId], (state, id) => {
  if (state[id]) {
    return {
      ...state[id],
    };
  }
  return false;
});

export const listHasMorePages = createSelector([lists, listId], (state, id) => {
  if (state[id]) {
    return state[id]?.meta.last_page > state[id]?.meta.current_page;
  }

  return false;
});
