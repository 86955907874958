import { useState, useEffect } from 'react';

export const useMdRead = (md) => {
  const [mdString, setMdString] = useState('');
  useEffect(() => {
    fetch(md)
      .then((res) => res.text())
      .then((text) => setMdString(text));
  });

  return mdString;
};
