import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi, BannersWithPosition } from '../types';

import {
  FetchBannersByPositionPayload,
  FetchBannersByPositionReturn,
} from './types';

export const fetchBannersByPosition = createAsyncThunk<
  FetchBannersByPositionReturn,
  FetchBannersByPositionPayload,
  AppThunkApi
>('commons/fetchBanners', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { position } = payload;

  try {
    const { data, status } = await api.get<BannersWithPosition>(
      `/banner-positions/${position}`
    );

    if (status === 200) {
      return { list: data.banners };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
