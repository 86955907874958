export type FixGameId = (value: string | number) => string | number;

export const fixGameId = (value) => {
  const regex = /\D/gm;
  if (typeof value === 'number') {
    return value;
  }
  if (value.match(regex)) {
    return value;
  }

  return Number(value);
};
