export interface Task {
  id: number;
  points: number;
  text: string;
  progress?: number;
  icon:
    | 'profile'
    | 'email'
    | 'fund'
    | 'bet100'
    | 'bet200'
    | 'bet300'
    | 'bet400'
    | 'bet500'
    | 'dep2'
    | 'dep3'
    | 'dep4'
    | 'game3'
    | 'game5'
    | 'game10'
    | 'mobileApp'
    | 'money100'
    | 'money500'
    | 'money1000'
    | 'money2000'
    | 'money5000'
    | 'support';
  isDone?: boolean;
  progressType: 'bar' | 'support' | 'app' | 'empty';
  date?: number;
}

export interface InitialState {
  list: Task[];
}
