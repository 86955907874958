import { createAsyncThunk } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { AppThunkApi } from '../../types';
import { prepareGamesHistory } from '../../utils/games';

import {
  FetchGameHistoryReturn,
  FetchGameHistoryPayload,
  GETGameHistory,
  AddGameToHistoryReturn,
  AddGameToHistoryPayload,
} from './types';
import { list } from './selectors';

export const fetchGameHistory = createAsyncThunk<
  FetchGameHistoryReturn,
  FetchGameHistoryPayload,
  AppThunkApi
>('games/history/fetch', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { userId } = payload;

  try {
    const { data, status } = await api.get<GETGameHistory>(
      `/userdatas/${userId}/gamesessions`
    );

    if (status === 200) {
      return prepareGamesHistory(data.data);
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const addGameToHistory = createAsyncThunk<
  AddGameToHistoryReturn,
  AddGameToHistoryPayload,
  AppThunkApi
>('games/history/add', (payload, { getState }) => {
  const currentList = list(getState());

  return uniqBy([payload, ...currentList], 'id');
});
