import { createSlice } from '@reduxjs/toolkit';
import { emailResend } from './thunks';

import { InitialState } from './types';

const initialState: InitialState = {
  isLoading: false,
  error: '',
};

export const slice = createSlice({
  name: 'email',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(emailResend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(emailResend.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(emailResend.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      });
  },
});
