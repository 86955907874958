import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';

const initialState: InitialState = {
  status: {
    level: 'I',
    name: 'Jackerys Velaryon',
  },
  nextStatus: {
    level: 'II',
    name: 'Luceris Velarion',
  },
  points: 37,
  currentRating: 370,
  nextRating: 1000,
};

export const slice = createSlice({
  name: 'loyalty/user',
  initialState,
  reducers: {},
});
