export interface Jackpot {
  alias: string;
  amount: number;
  created_at: string;
  currency_id: number;
  default: boolean;
  description: string;
  id: number;
  last_won_at: string;
  name: string;
  params: {
    factor: number;
    last_calculated_at: string;
  };
  update_at: string;
}
