import { RootState } from '../../types';

export const loading = (state: RootState) => state.partner.rates.loading;
export const isReady = (state: RootState) => state.partner.rates.isReady;
export const error = (state: RootState) => state.partner.rates.error;
export const rates = (state: RootState) => state.partner.rates.rates;
export const currentRate = (state: RootState) =>
  state.partner.rates.rates[
    state.partner.rates.rates.length > 0
      ? state.partner.rates.rates.length - 1
      : 0
  ];
