import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPureErrorMessage } from '@box/redux/utils';

import { userSelectors } from '../info';

import { AppThunkApi } from '../../types';
import {
  CheckUserPromocodePayload,
  CheckUserPromocodeReturn,
  FetchPromocodesListReturn,
  GETPromocodesList,
  POSTUserPromocode,
} from './types';

export const fetchUserPromocodes = createAsyncThunk<
  FetchPromocodesListReturn,
  void,
  AppThunkApi
>(
  'user/fetchPromocodesList',
  async (_payload, { extra, rejectWithValue, getState }) => {
    const { api } = extra;
    const userId = userSelectors.id(getState());
    try {
      const { data, status } = await api.get<GETPromocodesList>(
        `/userdatas/${userId}/promocodes`
      );

      if (status === 200) {
        return data.data;
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

export const checkUserPromocode = createAsyncThunk<
  CheckUserPromocodeReturn,
  CheckUserPromocodePayload,
  AppThunkApi
>(
  'user/checkUserPromocode',
  async (payload, { extra, rejectWithValue, getState }) => {
    const { api } = extra;
    const { code } = payload;
    const userId = userSelectors.id(getState());
    try {
      const { data, status } = await api.post<POSTUserPromocode>(
        `/userdatas/${userId}/promocodes`,
        {
          code,
        }
      );

      if (status === 200) {
        return data;
      }
      const error = getPureErrorMessage(data.errors);
      return rejectWithValue(error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
