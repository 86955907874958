import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '@box/shared/hooks';

import { Modal } from '../../Modal/Modal';

import styles from './ServerErrorModal.module.scss';

export const ServerErrorModal = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <Modal
      headWithSvg={isJune}
      headColor={isJune ? 'green' : 'default'}
      id='serverError'
      title={t('server_error.title')}
    >
      <div className={styles.serverErrorModalText}>
        {t('server_error.text')}
      </div>
    </Modal>
  );
};
