import React, { FC } from 'react';
import classNames from 'classnames';
import { DetailPopup } from '@box/shared/baseComponents';
import {
  useApp,
  useBonusesCount,
  useBonusLabel,
  useToggleActiveElement,
} from '@box/shared/hooks';
import { useAppSelector } from '@box/redux/hooks';
import { userSelectors } from '@box/redux/user';

import { Props } from './BonusDepositDetail.types';

import styles from './bonusDepositDetail.module.scss';

export const BonusDepositDetail: FC<Props> = ({
  popupClassName,
  openUp,
  isBanner,
}) => {
  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  const {
    userEnv: { isJune },
  } = useApp();

  const userInfo = useAppSelector(userSelectors.user);

  const { count } = useBonusesCount(userInfo?.deposit_bonus?.bonus?.type);

  const { label } = useBonusLabel(count);

  return (
    <div
      className={classNames(styles.detail, {
        [styles.detail_june]: isJune,
        [styles.detail_openUp]: openUp,
        [styles.detail_banner]: isBanner,
      })}
      onClick={toggleOpen}
      aria-hidden='true'
      ref={elementRef}
    >
      <span>?</span>
      <DetailPopup
        openUp={openUp}
        className={classNames(styles.detailInfo, popupClassName)}
        isOpen={isOpen}
        position='bottom-start'
      >
        {label}
      </DetailPopup>
    </div>
  );
};
