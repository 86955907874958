import { Category, Provider } from '../common';

enum GameTechnology {
  html = 'HTML5',
}

export interface Game {
  adaptedDesktop: boolean;
  adaptedMobile: boolean;
  availableFreespins: boolean;
  categories: Category[];
  daily_sessions_count: number;
  has_demo: boolean;
  external_id: string;
  id: number;
  ig_id: number;
  integrator: {
    id: number;
    name: string;
    alias: string;
    games_count: number;
  };
  name: string;
  provider: Provider;
  sessions_count: number;
  technology: GameTechnology;
  thumbnail: string;
  weight: number;
  icon?: string;
}
