import { combineReducers } from 'redux';

import slotGamesReducer from './games';

const reducer = combineReducers({
  slotGames: slotGamesReducer,
});

export * from './games';

export default reducer;
