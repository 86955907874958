import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { RootState } from '../types';

import { isShowBanner } from '../utils/banners';
import { bannersPositions } from '../utils/constants';

const all = (state: RootState) => state.common;
const user = (state: RootState) => state.user.info;
const isLogged = (state: RootState) => state.auth.init.isLogged;

const initialBanners = (state: RootState) => state.common.banners;
export const clientData = (state: RootState) => all(state).clientData;
export const countries = (state: RootState) => all(state).countries;
export const currencies = (state: RootState) => all(state).currencies;
export const providers = (state: RootState) => all(state).providers;
export const categories = (state: RootState) => all(state).categories;
export const bonusTypes = (state: RootState) => all(state).bonusTypes;
export const languages = (state: RootState) => all(state).languages;
export const commonsReady = (state: RootState) => all(state).commonsReady;
export const commonsFailed = (state: RootState) => all(state).commonsFailed;

// Banners
export const banners = createSelector(
  [initialBanners, isLogged],
  (list, logged) =>
    list
      .filter((item) => isShowBanner(item.auth, logged))
      .sort((a, b) => b.weight - a.weight)
);

export const mainSliderBanners = createSelector([banners], (list) =>
  list
    .filter((item) => item.position === bannersPositions.MAIN_SLIDER)
    .sort((a, b) => b.weight - a.weight)
);

export const casinoSliderBanners = createSelector([banners], (list) =>
  list
    .filter((item) => item.position === bannersPositions.CASINO_SLIDER)
    .sort((a, b) => b.weight - a.weight)
);

export const staticBanner = createSelector(
  [banners],
  (list) =>
    list
      .filter((item) => item.position === bannersPositions.SECONDARY)
      .sort((a, b) => b.weight - a.weight)[0]
);

export const topUpBanner = createSelector(
  [banners],
  (list) =>
    list
      .filter((item) => item.position === bannersPositions.TOP_UP)
      .sort((a, b) => b.weight - a.weight)[0]
);
// Banners end

export const categoriesWithExcluded = (e) =>
  createSelector([categories], (categories) =>
    categories.filter((c) => !e.includes(c.alias))
  );

export const categoryByAlias = (state: RootState, a: string) =>
  categories(state).find((c) => c.alias === a) || {};

export const providerByAlias = (state: RootState, a: string) =>
  providers(state).find((p) => p.alias === a) || {};

export const codes = createSelector([user, countries], (u, cos) => ({
  country: cos.find((item) => item.id === u.country_id)?.code,
  phone: cos.find((item) => item.id === u.country_id)?.phone_code,
}));

export const settingsCountries = createSelector([countries], (list) =>
  list?.map((item) => ({
    label: get(item, 'name'),
    value: get(item, 'id'),
  }))
);

export const currencySymbolById = (id) =>
  createSelector([currencies], (c) => c.find((item) => item.id === id)?.symbol);

export const clientDataCountryId = createSelector(
  [countries, clientData],
  (c, d) => c.find((item) => item.code === d?.country?.code)?.id
);

export const clientDataCurrencyId = createSelector(
  [currencies, clientData],
  (c, d) => c.find((item) => item.code === d?.country?.code)?.id
);
