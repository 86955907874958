import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Message.types';

import styles from './message.module.scss';

export const Message: FC<Props> = ({ message, className }) => {
  return <p className={classNames(styles.message, className)}>{message}</p>;
};
