import { useRef, useEffect } from 'react';
import { useFormState, Control } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { openInfoPopup } from '@box/redux/app';

export const useFormErrorPopup = ({
  control,
  text,
}: {
  control: Control;
  text: string;
}) => {
  const dispatch = useDispatch();

  const { errors, isSubmitting } = useFormState({ control });

  const prevIsSubmitting = useRef(isSubmitting);

  useEffect(() => {
    if (!isEmpty(errors) && isSubmitting) {
      dispatch(
        openInfoPopup({
          type: 'error',
          text,
        })
      );
    }

    prevIsSubmitting.current = isSubmitting;
  }, [errors, isSubmitting]);
};
