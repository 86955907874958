import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useApp } from '@box/shared/hooks';
import { LightButton } from '@box/ui';
import { useAppDispatch } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';

import { Props } from './Footer.types';

import styles from './footer.module.scss';
import { ReactComponent as LockSvg } from './assets/images/lock.svg';
import { ReactComponent as Visa } from './assets/images/visa.svg';
import { ReactComponent as MasterCard } from './assets/images/master_card.svg';
import { ReactComponent as Pci } from './assets/images/pci.svg';

export const Footer: FC<Props> = ({ isModal, submitLoading, qrOpen, qr }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { route } = useApp();

  const {
    userEnv: { isMobile },
  } = useApp();

  const handleShowClick = () => {
    dispatch(openModal('payment-qr'));
  };

  return (
    <div
      className={classNames(styles.footer, {
        [styles.footer_mobile]: isMobile,
        [styles.footer_modal]: isModal,
      })}
    >
      <div
        className={classNames(styles.policy, {
          [styles.policy_mobile]: isMobile || isModal,
        })}
      >
        <LockSvg />
        <div className={styles.policyText}>
          {t('deposit.policy.policy_text')}{' '}
          <Link
            className={styles.link}
            to={route('politics.terms_and_conditions')}
          >
            {t('deposit.policy.terms')}
          </Link>{' '}
          {t('deposit.policy.end')}{' '}
          <Link className={styles.link} to={route('politics.privacy')}>
            {t('deposit.policy.privacy')}
          </Link>
          .
        </div>
      </div>
      <div className={classNames(styles.payments, styles.payments_desktop)}>
        <div className={styles.bottomImages}>
          <Visa />
          <MasterCard />
          <Pci />
        </div>
      </div>
      <div className={styles.buttons}>
        <LightButton
          color='green'
          type='submit'
          loading={submitLoading}
          disabled={submitLoading}
          fixHeight
          className={styles.submit}
        >
          {qrOpen ? t('deposit.repeat_deposit') : t('deposit.submit')}
        </LightButton>
        {qr && (
          <div className={styles.form_footer_button}>
            <LightButton
              className={styles.open}
              color='yellow'
              type='button'
              onClick={handleShowClick}
            >
              {t('deposit.open_qr')}
            </LightButton>
          </div>
        )}
      </div>
      <div className={classNames(styles.payments, styles.payments_mobile)}>
        <div className={styles.bottomImages}>
          <Visa />
          <MasterCard />
          <Pci />
        </div>
      </div>
    </div>
  );
};
