/* eslint-disable react/prop-types */
import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';

import { BlockWithLoading } from '../../baseComponents';

export const FrameLoading: FC<{
  children: ReactElement<PropsWithChildren<any>>;
  requestLoading?: boolean;
}> = ({ children, requestLoading }) => {
  const [frameLoading, setFrameLoading] = useState(true);

  const onFrameLoad = () => setFrameLoading(false);

  return (
    <>
      <BlockWithLoading loading={frameLoading || requestLoading} />
      {React.cloneElement(children, {
        ...children.props,
        onLoad: onFrameLoad,
        style: { display: frameLoading ? 'none' : '' },
      })}
    </>
  );
};
