import React, { useState } from 'react';
import classNames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useApp } from '@box/shared/hooks';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { paymentMethodsSelectors, paymentSelectors } from '@box/redux/finance';
import { ImageSet, LightButton } from '@box/ui';
import { balanceSelectors, userSelectors } from '@box/redux/user';
import { commonSelectors } from '@box/redux/common';
import { getStoragePath } from '@box/shared/utils';
import { merchantIcons } from '@box/shared/utils/merchants';
import { hideModal } from '@box/redux/app';

import { Modal } from '../../Modal/Modal';

import styles from './paymentqr.module.scss';

import Bonus from './assets/images/gift_small.png';
import BonusWebp from './assets/images/gift_small.webp';

export const PaymentQR = () => {
  const {
    userEnv: { isMobile },
  } = useApp();
  const selectedMethod = useAppSelector(paymentMethodsSelectors.selectedMethod);
  const qrCode = useAppSelector(paymentSelectors.qr);
  const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
  const transactionsAmount = useAppSelector(
    paymentSelectors.transactionsAmount
  );
  const clientData = useAppSelector(commonSelectors.clientData);
  const userInfo = useAppSelector(userSelectors.user);

  const defaultsEnabled = clientData?.country?.payment_method_params?.enabled;
  const bonusPercent =
    userInfo?.deposit_bonus?.bonus?.getting_params[0]?.percent;
  const minDepSum = userInfo?.deposit_bonus?.bonus?.getting_params[0]?.min;

  const getIcon = (item) => {
    if (item?.iframe_logo) {
      return (
        <img alt={item.alias} src={getStoragePath(item.iframe_logo, 'main')} />
      );
    } else if (item?.logo) {
      return <img alt={item.alias} src={getStoragePath(item.logo, 'main')} />;
    }

    const Component = merchantIcons.defaultIcon;
    return <Component />;
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isCopied, setCopied] = useState(false);

  const handleBackClick = () => {
    dispatch(hideModal('payment-qr'));
  };

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Modal
      headColor='blue'
      headWithSvg
      customHead
      hasCloseClick={false}
      title={selectedMethod?.name}
      id='payment-qr'
    >
      <div
        className={classNames(styles.payment, {
          [styles.payment_mobile]: isMobile,
        })}
      >
        <button
          className={styles.close}
          type='button'
          onClick={handleBackClick}
        />
        <div className={styles.header}>
          <div className={styles.icon}>{getIcon(selectedMethod)}</div>
          <div className={styles.row}>
            <p className={styles.value}>
              <span>
                {transactionsAmount} {currencySymbol}
              </span>
            </p>
          </div>
          {defaultsEnabled &&
            bonusPercent &&
            transactionsAmount >= Number(minDepSum) && (
              <div className={styles.bonus}>
                +{(transactionsAmount * Number(bonusPercent)) / 100}
                <ImageSet
                  src={Bonus}
                  srcWebp={BonusWebp}
                  className={styles.image}
                />
              </div>
            )}
        </div>
        <div className={styles.boxText}>{t('deposit.open_the_payment')}</div>
        <QRCodeSVG className={styles.qr} size={240} value={qrCode} />
        <div className={styles.copy}>
          <CopyToClipboard
            onCopy={handleCopy}
            className={styles.copy}
            text={qrCode}
          >
            <LightButton color={isCopied ? 'gray' : 'green'}>
              {isCopied ? t('deposit.copied') : t('deposit.copy_pix_code')}
            </LightButton>
          </CopyToClipboard>
        </div>
        <div className={styles.boxText}>{t('deposit.paste_the_code')}</div>
      </div>
    </Modal>
  );
};
