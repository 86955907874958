import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@box/redux/hooks';
import { balanceSelectors, userSelectors } from '@box/redux/user';

import { Props } from './Info.types';

import styles from './info.module.scss';
import { ReactComponent as BonusSvg } from './assets/images/bonus.svg';

export const Info: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const currency = useAppSelector(userSelectors.currency);
  const balance = useAppSelector(balanceSelectors.balanceValue);
  const bonusBalance = useAppSelector(balanceSelectors.bonusBalance);

  return (
    <div className={classNames(styles.info, className)}>
      <div className={styles.column}>
        <p className={styles.label}>{t('navigation.balance')}:</p>
        <p className={styles.value}>
          {currency.symbol} {balance > 0 ? Math.floor(balance * 100) / 100 : 0}
        </p>
      </div>
      <div className={styles.column}>
        <p className={styles.label}>{t('user_actions.bonuses')}:</p>
        <p className={styles.value}>
          <BonusSvg />{' '}
          {bonusBalance > 0 ? Math.floor(bonusBalance * 100) / 100 : 0}
        </p>
      </div>
    </div>
  );
};
