import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelectors } from '@box/redux/user';

import { DetailPopup } from '../../../../baseComponents';
import { useToggleActiveElement } from '../../../../hooks';

export const Detail = () => {
  const { t } = useTranslation();

  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  const isVerify = useSelector(userSelectors.isVerify);
  const isPendingVerify = useSelector(userSelectors.isPendingVerification);

  if (isVerify) return null;

  return (
    <div
      className='settings-detail'
      onClick={toggleOpen}
      aria-hidden='true'
      ref={elementRef}
    >
      <span>?</span>
      <DetailPopup
        className='settings-detail__info'
        isOpen={isOpen}
        position='bottom-start'
      >
        <p
          dangerouslySetInnerHTML={{
            __html: isPendingVerify
              ? t('settings.pending_verify')
              : t('settings.detail'),
          }}
        />
      </DetailPopup>
    </div>
  );
};
