import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = React.forwardRef(
  (
    {
      // error,
      // size,
      // message,
      as,
      // label,
      children,
      // inputIcon,
      color,
      className,
      type,
      iconPos,
      bordered,
      outline,
      to,
      onClick,
      disabled,
      tooltip,
      htmlFor,
      style,
      loading,
      hoverType,
      ...props
    },
    ref
  ) => {
    const { icon } = props;

    const classes = classNames(
      {
        button: true,

        'button--primary': color === 'primary',
        'button--secondary': color === 'secondary',
        'button--transparent': color === 'transparent',
        'button--success': color === 'success',

        'button--icon': icon && !children,
        'button--bordered': !!bordered,
        'button--outline-primary': outline === 'primary',
        'button--outline-secondary': outline === 'secondary',
        'button--icon-right': iconPos === 'right',

        'button--hover-opacity': hoverType === 'opacity',
        'button--hover-outline': hoverType === 'outline',
        'button--disabled': disabled,

        'button--loading': loading,
      },
      className || []
    );
    const styles = style || {};

    const attr = {
      ref,
      to,
      type,
      className: classes,
      onClick,
      disabled,
      style: { ...styles, color },
      'data-tip': tooltip || false,
      htmlFor,
    };

    const iconElem = icon || '';
    const text = children
      ? React.createElement('span', { key: 'span' }, children)
      : '';
    let content = iconPos === 'right' ? [text, iconElem] : [iconElem, text];

    // loading on button
    if (loading) {
      content = [
        content,
        <div className='loader-wrapper loader-wrapper--button' />,
      ];
      attr.disabled = 'disabled';
    }

    let tag = to ? Link : as || 'button';
    if (as) {
      tag = as;
    }

    return React.createElement(tag, attr, ...content);
  }
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  icon: PropTypes.oneOfType([PropTypes.any]),
  inputIcon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.oneOfType([PropTypes.any]),
  size: PropTypes.string,
  error: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  iconPos: PropTypes.string,
  bordered: PropTypes.bool,
  outline: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.bool,
  htmlFor: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  loading: PropTypes.bool,
  hoverType: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  icon: null,
  label: null,
  inputIcon: null,
  size: '',
  error: '',
  message: '',
  className: '',
  id: '',
  as: '',
  type: 'text',
  color: '',
  iconPos: '',
  bordered: false,
  outline: '',
  to: '',
  onClick: () => null,
  disabled: false,
  tooltip: false,
  htmlFor: '',
  style: {},
  loading: false,
  hoverType: 'outline',
};

export default Button;
