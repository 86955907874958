import React from 'react';
import { bonusStatuses } from '@box/redux/utils';
import { List } from './List';

export const ActiveTab = () => (
  <List
    id='active'
    statuses={[bonusStatuses.activated, bonusStatuses.spins_used]}
  />
);

export const CompletedTab = () => (
  <List id='completed' statuses={[bonusStatuses.ended]} />
);

export const NotStartedTab = () => (
  <List id='notStarted' statuses={[bonusStatuses.created]} />
);
