import isFunction from 'lodash/isFunction';
import { closeConfirm, confirmSelectors } from '@box/redux/app';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

export const useConfirmModal = () => {
  const dispatch = useAppDispatch();
  const callback = useAppSelector(confirmSelectors.callback);
  const unConfirm = useAppSelector(confirmSelectors.unConfirm);

  const isOpen = useAppSelector(confirmSelectors.isOpen);
  const title = useAppSelector(confirmSelectors.title);
  const text = useAppSelector(confirmSelectors.text);
  const extraText = useAppSelector(confirmSelectors.extraText);
  const yes = useAppSelector(confirmSelectors.yes);
  const no = useAppSelector(confirmSelectors.no);
  const onlyYes = useAppSelector(confirmSelectors.onlyYes);

  const close = () => dispatch(closeConfirm());

  const onConfirm = () => {
    if (isFunction(callback)) {
      callback();
    }

    close();
  };

  const onClose = () => {
    if (unConfirm) {
      unConfirm();
    }

    close();
  };

  return {
    onConfirm,
    onClose,
    additional: {
      isOpen,
      title,
      text,
      extraText,
      yes,
      no,
      onlyYes,
    },
  };
};
