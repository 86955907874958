import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { ImageSet } from '@box/ui';

import { Props } from './BonusCard.types';

import styles from './bonusCard.module.scss';

export const BonusCard: FC<Props> = ({
  className,
  name,
  image,
  bonusId,
  progression_percentage,
  status,
}) => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();

  const handleClick = () => {
    if (status) {
      if (status === 'created') {
        params.append('bonus', String(bonusId));
      } else {
        params.append('active-bonus', String(bonusId));
      }
    } else {
      params.append('bonus', String(bonusId));
    }

    window.scrollTo(0, 0);
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  return (
    <div className={classNames(styles.bonus, className)}>
      <div className={styles.info}>
        <div className={styles.column}>
          <h4 className={styles.title}>{name}</h4>
          {status === 'activated' && (
            <div className={styles.progress}>
              <p className={styles.label}>
                {t('bonuses.wagering_percentage')}
                <span>{`${
                  progression_percentage > 100 ? 100 : progression_percentage
                }%`}</span>
              </p>
              <div className={styles.bar}>
                <div
                  style={{
                    width: `calc(100% * 0.01 * ${progression_percentage})`,
                  }}
                  className={styles.decor}
                />
              </div>
            </div>
          )}
        </div>
        <button onClick={handleClick} className={styles.button} type='button'>
          {status === 'activated' || status === 'ended'
            ? t('bonuses.learn_more')
            : t('bonuses.activate')}
        </button>
      </div>
      <ImageSet
        className={styles.image}
        src={image.png}
        srcAvif={image.avif}
        srcWebp={image.webp}
        alt={name}
      />
    </div>
  );
};
