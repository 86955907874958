export const bonusProperties = [
  { label: 'bonuses.sum', field: 'sum' },
  { label: 'bonuses.type', field: 'type' },
  { label: 'bonuses.active_time', field: 'activeTime' },
  { label: 'bonuses.activate_time', field: 'activateTime' },
  { label: 'bonuses.wager', field: 'wager' },
  { label: 'bonuses.progress', field: 'progress' },
  { label: 'bonuses.target', field: 'target' },
  { label: 'bonuses.bets_sum', field: 'betsSum' },
  { label: 'bonuses.min_dep', field: 'minDep' },
  { label: 'bonuses.bonus_dep_sum', field: 'bonusDepSum' },
  { label: 'bonuses.max_bonus', field: 'maxBonus' },
  { label: 'bonuses.ends_at', field: 'endsAt' },
  { label: 'sidebar.providers', field: 'providers' },
  { label: 'sidebar.categories', field: 'categories' },
];

export const userWalletsProperties = [
  { label: 'bonuses.casino', field: 'casino' },
  { label: 'bonuses.first_dep', field: 'firstDep' },
  { label: 'bonuses.second_dep', field: 'secondDep' },
  { label: 'bonuses.third_dep', field: 'thirdDep' },
  { label: 'bonuses.birthday', field: 'birthday' },
];
