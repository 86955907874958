import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Payment.types';

import styles from './payment.module.scss';

export const Payment: FC<Props> = ({
  className,
  children,
  item,
  onClick,
  selectedItem,
  label,
  isWithdrawal,
}) => {
  const handleClick = () => {
    onClick(item);
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        styles.button,
        {
          [styles.button_active]: selectedItem?.id === item?.id,
        },
        className
      )}
    >
      <div
        className={classNames(styles.payment, {
          [styles.payment_active]: selectedItem?.id === item?.id,
          [styles.payment_withdrawal_active]:
            selectedItem?.id === item?.id && isWithdrawal,
        })}
      >
        <div className={styles.svg}>{children}</div>
        {label && <p className={styles.label}>{label}</p>}
      </div>
    </button>
  );
};
