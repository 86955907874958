import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  walletsSelectors,
  withdrawalRequest,
  withdrawalRequestsSelectors,
  resetWithdrawalError,
} from '@box/redux/finance';
import { balanceSelectors, userSelectors } from '@box/redux/user';
import { useToggleActiveElement } from '@box/shared/hooks';
import { userInitSelectors } from '@box/redux/authentication';
import { DecemberInput } from '@box/ui';

import { Button, DetailPopup } from '../../../baseComponents';

import { FlexibleFields } from '../../FlexibleFields';

export const MerchantForm = ({ activeTab, userId }) => {
  const { t } = useTranslation();
  const minDep = activeTab.limits.min;
  const maxDep = activeTab.limits.max;

  const methods = useForm();

  const { handleSubmit, register, watch, setValue, formState } = methods;

  const { errors } = formState;

  const watchAmount = watch('amount');

  const dispatch = useDispatch();
  const loading = useSelector(withdrawalRequestsSelectors.loading);
  const error = useSelector(withdrawalRequestsSelectors.error);
  const currentWallet = useSelector(walletsSelectors.current);
  const currencySymbol = useSelector(balanceSelectors.currencySymbol);
  const commissionRate = 0.25;
  const isDepositWagered = useSelector(userInitSelectors.isDepositWagered);
  const balance = useSelector(balanceSelectors.balanceValue);
  const currency = useSelector(userSelectors.currency);

  const currentFields = activeTab.fields || [];

  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  const fields = useMemo(() => [...currentFields], [currentFields]);

  const onSubmit = (data) => {
    const { amount } = data;
    delete data.amount;

    dispatch(
      withdrawalRequest({
        userId,
        name: activeTab.name,
        fieldValues: data,
        methodId: activeTab.id,
        merchantId: activeTab.merchant.id,
        amount,
        walletId: currentWallet.id,
        confirm: isDepositWagered ? 0 : 1,
        amountError: amount * 0.75 < minDep,
        currencyCode: currency?.code,
        minDep,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(resetWithdrawalError());
    };
  }, [activeTab]);

  // Логика работы с комиссией

  const available = () => {
    if (isDepositWagered) {
      return balance;
    }
    const balanceWithCommission = balance - balance * commissionRate;
    return Math.floor(balanceWithCommission * 100) / 100;
  };

  const [blocked, setBlocked] = useState('0');
  const [changesDisabled, setChangesDisabled] = useState(false);

  useEffect(() => {
    if (!changesDisabled) {
      setBlocked(
        String(
          Math.round(Number(watchAmount) - Number(watchAmount) * commissionRate)
        )
      );
    }
  }, [watchAmount, changesDisabled]);

  const handleBlockedChange = (e) => {
    setChangesDisabled(true);
    setBlocked(e.target.value);
    setValue(
      'amount',
      Math.round(Number(e.target.value) / (1 - commissionRate))
    );
  };

  const handleBlockedBlur = () => {
    setChangesDisabled(false);
  };

  const inputWithRateError = () => {
    if (!isDepositWagered) {
      if (Number(blocked) > available()) {
        return t('withdrawalRequest.amount_error');
      } else if (Number(blocked) < minDep) {
        return t('fields.payment_rules.minSum', { sum: minDep });
      }
    }

    return '';
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='funds-box'>
        <span className='funds-limits'>
          {minDep}-{maxDep} <span>{currencySymbol}</span>
        </span>
        <div className='funds-available'>
          {t('withdrawalRequest.available_for_withdrawal')}: {available()}{' '}
          {currencySymbol}
        </div>
        {!isDepositWagered && (
          <div
            className='funds-detail'
            onClick={toggleOpen}
            aria-hidden='true'
            ref={elementRef}
          >
            <span>?</span>
            <DetailPopup
              className='funds-detail-info'
              isOpen={isOpen}
              position='bottom-start'
            >
              {t('withdrawalRequest.popup_text', {
                percent: commissionRate * 100,
                amount: available(),
                currency: currency?.symbol,
              })}
            </DetailPopup>
          </div>
        )}
      </div>
      <FormProvider {...methods}>
        {isDepositWagered ? (
          <DecemberInput
            {...register('amount', {
              valueAsNumber: true,
              required: {
                value: true,
                message: t('fields.payment_rules.required'),
              },
              min: {
                value: minDep,
                message: t('fields.payment_rules.minSum', { sum: minDep }),
              },
              max: {
                value: maxDep,
                message: t('fields.payment_rules.maxSum', { sum: maxDep }),
              },
            })}
            name='amount'
            color='outlined'
            className='funds_control'
            label={t('top_up.sum')}
            type='number'
            placeholder={`${minDep} ${currency?.symbol || ''}`}
            error={errors.amount?.message}
          />
        ) : (
          <>
            <div className='funds__row'>
              <DecemberInput
                {...register('amount', {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: t('fields.payment_rules.required'),
                  },
                  min: {
                    value: minDep,
                    message: t('fields.payment_rules.minSum', { sum: minDep }),
                  },
                  max: {
                    value: maxDep,
                    message: t('fields.payment_rules.maxSum', { sum: maxDep }),
                  },
                })}
                name='amount'
                color='outlined'
                className='funds__amount'
                label={t('top_up.sum')}
                type='number'
                placeholder={`${minDep} ${currency?.symbol || ''}`}
                error={errors.amount?.message}
              />
              <DecemberInput
                defaultValue={`-${commissionRate * 100}%`}
                disabled
                label={t('withdrawalRequest.blocked')}
                type='text'
                className='funds__blocked'
                placeholder={`-${commissionRate * 100}%`}
                withPopup
                color='outlined'
                popupText={t('withdrawalRequest.popup_text', {
                  percent: commissionRate * 100,
                  amount: available(),
                  currency: currency?.symbol,
                })}
              />
            </div>
            <DecemberInput
              label={t('withdrawalRequest.total_to_be_received')}
              type='number'
              placeholder={`${minDep} ${currency?.symbol || ''}`}
              value={blocked}
              className='funds_control'
              color='outlined'
              onChange={handleBlockedChange}
              onBlur={handleBlockedBlur}
              message={inputWithRateError()}
              error={inputWithRateError()}
            />
          </>
        )}

        <FlexibleFields fields={fields} />
      </FormProvider>
      {error && <span className='form__form-error'>{error}</span>}
      <Button
        className='form__submit'
        loading={loading}
        color='secondary'
        type='submit'
        disabled={!!inputWithRateError()}
      >
        {t('common.submit')}
      </Button>
    </form>
  );
};
