import { createAction } from '@reduxjs/toolkit';

export const setChatOpen = createAction<boolean>('support/setChatOpen');
export const setChatId = createAction<number>('support/setChadId');
export const resetUnread = createAction('support/resetUnread');
export const setUnreadMessageCounter = createAction<number>(
  'support/setUnreadMessageCounter'
);
export const setLastUnreadMessage = createAction<string>(
  'support/setLastUnreadMessage'
);
export const setChatReady = createAction<boolean>('support/setChatReady');
export const setUploadError = createAction<string>('support/setError');
