import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@box/redux/hooks';
import { ratesSelectors } from '@box/redux/partner/rates';
import { appConfig } from '@box/shared/config';

import { useGetEarn } from '../../../hooks';

import { Modal } from '../../Modal';

import styles from './invitations.module.scss';

export const Invitations = () => {
  const { t } = useTranslation();

  const list = ['1', '2', '3', '4', '5', '6', '7'];

  const currentRate = useAppSelector(ratesSelectors.currentRate);
  const minValue = currentRate?.min_value;
  const currency = currentRate?.currency;

  const { companyName, companyShortName } = appConfig;

  const { earn } = useGetEarn(currentRate);

  return (
    <Modal title={t('referral.invite_your_friends')} dark id='invitations'>
      <div className={styles.container}>
        <div className={styles.box}>
          <p className={styles.text}>{t('referral.text')}</p>
          <p className={styles.subtitle}>{t('referral.terms')}:</p>
          <ul className={styles.list}>
            {list.map((item, index) => (
              <li key={index} className={styles.item}>
                {t(`referral.list.${item}`, {
                  companyName,
                  companyShortName,
                  earn,
                  minValue,
                  currency,
                })}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};
