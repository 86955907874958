import React, { FC } from 'react';
import classNames from 'classnames';

import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as JuneLogo } from './assets/juneLogo.svg';
import styles from './loading.module.scss';
import { buttonText, loadingText } from './Loading.data';

import { Props } from './Loading.types';

export const Loading: FC<Props> = ({ hideSpinner, isJune, isFailed }) => {
  const lang = localStorage.getItem('lang');
  const locales = () => {
    switch (lang) {
      case 'pt':
        return {
          text: loadingText.pt,
          button: buttonText.pt,
        };
      default:
        return {
          text: loadingText.en,
          button: buttonText.en,
        };
    }
  };

  const handleClick = () => {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  if (isFailed) {
    return (
      <div
        className={classNames(styles.loader, {
          [styles.loader_june]: isJune,
        })}
      >
        {isJune ? <JuneLogo /> : <Logo />}
        <p className={styles.text}>{locales().text}</p>
        <button onClick={handleClick} type='button' className={styles.button}>
          {locales().button}
        </button>
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.loader, { [styles.loader_june]: isJune })}
    >
      {isJune ? <JuneLogo /> : <Logo />}
      {isJune ? (
        <div className={styles.june_loader}>
          <div className={classNames(styles.inner, styles.inner_one)} />
          <div className={classNames(styles.inner, styles.inner_two)} />
          <div className={classNames(styles.inner, styles.inner_three)} />
        </div>
      ) : (
        <div
          className={classNames(styles.wrapper, {
            [styles.wrapper_hidden]: hideSpinner,
          })}
        />
      )}
    </div>
  );
};

export default Loading;
