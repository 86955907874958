import { createSelector, Selector } from '@reduxjs/toolkit';
import { BonusStatuses, RootState } from '../../types';
import { IsActivateById } from './types';

const all = (state: RootState) => state.user.bonuses;
export const list = (state: RootState) => all(state).list;
export const loading = (state: RootState) => all(state).loading;
export const ready = (state: RootState) => all(state).ready;
export const addedId = (state: RootState) => all(state).add.id;
export const addedLoading = (state: RootState) => all(state).add.loading;
export const loadingById = (state: RootState, id: string) =>
  all(state).add.id === id && all(state).add.loading;

const getExtra = (_state: RootState, statuses) => statuses;

export const filteredByStatuses = createSelector([list, getExtra], (l, s) =>
  l.filter((item) => s.includes(item.status))
);

export const isActivateById: IsActivateById = createSelector(
  [list, getExtra],
  (l, id) => {
    const bonus = l.find((item) => item.bonus_id === id);

    return !!bonus && bonus.status !== BonusStatuses.created;
  }
);

export const typeById: Selector = createSelector(
  [list, getExtra],
  (l, id: number) => {
    const bonus = l.find((item) => item.bonus_id === id);

    return bonus?.type;
  }
);

export const statusById: Selector = createSelector(
  [list, getExtra],
  (l, id: number) => {
    const bonus = l.find((item) => item.bonus_id === id);

    return bonus?.status;
  }
);

export const activeBonuses = createSelector(
  [list],
  (l) =>
    l.filter(
      (item) =>
        item.status === BonusStatuses.activated ||
        item.status === BonusStatuses.spins_used ||
        item.status === BonusStatuses.processing
    ) || []
);

export const createdBonuses = createSelector(
  [list],
  (l) => l.filter((item) => item.status === BonusStatuses.created) || []
);
