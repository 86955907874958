import { createAction } from '@reduxjs/toolkit';
import { ActionSetPayload } from './types';

export const resetList = createAction<string>('resetListgames/resetList');
export const resetOnlyList = createAction<string>(
  'resetListgames/resetOnlyList'
);
export const setListSearch = createAction<ActionSetPayload>(
  'games/setListSearch'
);
export const setListCategory = createAction<ActionSetPayload>(
  'games/setListCategory'
);
export const setListProvider = createAction<ActionSetPayload>(
  'games/setListProvider'
);
