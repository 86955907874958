import { useLocation } from 'react-router-dom';
import { menu } from './SidebarMenu.data';

export const useMenuList = () => {
  const location = useLocation();
  const currentPage = () => {
    return menu.find((item) => item.path === location.pathname);
  };

  return {
    menuList: menu,
    currentPage: currentPage(),
  };
};
