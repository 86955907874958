import { createSlice } from '@reduxjs/toolkit';

import { fetchLogin } from './thunks';
import { InitialState } from './types';
import { setIsButtonDisabled, setIsUserNotFound } from './actions';

const initialState: InitialState = {
  loading: false,
  error: '',
  isSuccess: false,
  isUserNotFound: false,
  isButtonDisabled: false,
};

export const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.isSuccess = false;
        state.isUserNotFound = false;
      })
      .addCase(fetchLogin.fulfilled, (state) => {
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(fetchLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isSuccess = false;
      })
      .addCase(setIsUserNotFound, (state, { payload }) => {
        state.isUserNotFound = payload;
      })
      .addCase(setIsButtonDisabled, (state, { payload }) => {
        state.isButtonDisabled = payload;
      });
  },
});
