import { RadioGroup } from '@box/shared/baseComponents';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';

import styles from '../personalData.module.scss';
import { Inputs } from '../PersonalData.types';

export const GenderField = memo(() => {
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);
  const {
    control,
    formState: { errors },
  } = useFormContext<Inputs>();
  return (
    <Controller
      name='gender'
      control={control}
      render={({ field }) => (
        <RadioGroup
          onChange={field.onChange}
          value={field.value}
          name='gender'
          values={[
            { label: t('settings.male'), value: 'm' },
            { label: t('settings.female'), value: 'f' },
          ]}
          label={t('settings.gender')}
          error={t(errors.gender?.message)}
          ref={field.ref}
          disabled={disabled}
          className={styles.radio}
        />
      )}
    />
  );
});
