import sortBy from 'lodash/sortBy';

import { Bonus, UserTransformBonus } from '../types';

export const sortBonuses = (list: Bonus[]) => {
  return sortBy(list, [(item) => item?.getting_params[0]?.percent]);
};

export const sortUserBonuses = (list: UserTransformBonus[]) => {
  return sortBy(list, [(item) => item?.getting_params[0]?.percent]);
};
