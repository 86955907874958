import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useAppDispatch } from '@box/redux/hooks';
import { hideModal, openModal } from '@box/redux/app';
import { useApp } from '@box/shared/hooks';
import { LightButton } from '@box/ui';

import { Modal } from '../../Modal/Modal';

import styles from './toSettings.module.scss';

export const ToSettings: FC = () => {
  const { t } = useTranslation();

  const {
    route,
    userEnv: { isJune, isDesktop },
  } = useApp();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleClick = () => {
    if (isJune) {
      navigate(route('profile.my_profile'));
      dispatch(hideModal('to-settings'));
    } else {
      dispatch(openModal('settings'));
      dispatch(hideModal('to-settings'));
    }
  };

  return (
    <Modal
      headColor={isJune ? 'yellow' : 'default'}
      id='to-settings'
      title={t('verified.to_settings.title')}
      size={isDesktop ? 'lg' : 'xs'}
      heightAuto={isDesktop}
      headWithSvg={isJune}
    >
      <div
        className={classNames(styles.container, {
          [styles.container_june]: isJune,
        })}
      >
        <p className={styles.text}>{t('verified.to_settings.text')}</p>
        {isJune ? (
          <LightButton className={styles.buttonJune} onClick={handleClick}>
            {t('verified.to_settings.button')}
          </LightButton>
        ) : (
          <button type='button' onClick={handleClick} className={styles.button}>
            {t('verified.to_settings.button')}
          </button>
        )}
      </div>
    </Modal>
  );
};
