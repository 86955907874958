export interface WithdrawalRequest {
  id: number;
  user_id: number;
  wallet_id: number;
  withdrawal_wallet_id: number;
  amount: string;
  auto: boolean;
  status: string;
  transaction_id: number;
  comment: string;
  comment_to_user: string;
  created_at: string;
  payout_amount: string;
}
