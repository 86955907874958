import React, { FC } from 'react';

import classNames from 'classnames';

import { Button, LightButton } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { Collapsed } from '../../../baseComponents';

import { Props } from './MessageButton.types';

import styles from './MessageButton.module.scss';

export const MessageButton: FC<Props> = ({
  id,
  text,
  buttonText,
  openButton,
  setOpenButton,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  const handleClick = () => {
    if (id === openButton) {
      setOpenButton(null);
    } else {
      setOpenButton(id);
    }
  };
  return (
    <div className={styles['button-wrapper']}>
      {isJune ? (
        <LightButton onClick={handleClick}>{buttonText}</LightButton>
      ) : (
        <Button
          color='secondary'
          size='small'
          onClick={handleClick}
          className={styles.button}
        >
          {buttonText}
        </Button>
      )}
      <Collapsed isOpen={id === openButton}>
        <div
          className={classNames(styles.text, { [styles.text_june]: isJune })}
        >
          {text}
        </div>
      </Collapsed>
    </div>
  );
};
