// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-JuneBonusesList-BonusesList-module__container{padding-top:16px}", "",{"version":3,"sources":["webpack://./../../shared/components/JuneBonusesList/BonusesList.module.scss"],"names":[],"mappings":"AACA,wEACE,gBAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.container {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "------shared-components-JuneBonusesList-BonusesList-module__container"
};
module.exports = ___CSS_LOADER_EXPORT___;
