import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './CircleTimer.module.scss';

import { CircleTimerProps, TimerProps } from './CircleTimer.types';

const Timer: FC<TimerProps> = ({
  size,
  text,
  radius,
  strokeDashoffset,
  circumference,
  counter,
  fontSize,
}) => {
  return (
    <div style={{ height: size, width: size }} className={styles.timer}>
      <div
        className={classNames(
          styles.info,
          styles[`info_font-size_${fontSize}`]
        )}
      >
        <p className={styles.counter}>{counter}</p>
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.background}>
        <svg className={styles.svg}>
          <circle cx={radius} cy={radius} r={radius} />
        </svg>
      </div>
      <div className={styles.circle}>
        <svg className={styles.svg}>
          <circle
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset()}
            r={radius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    </div>
  );
};

export const CircleTimer: FC<CircleTimerProps> = ({
  className,
  milliseconds,
  size,
  fontSize = 'medium',
}) => {
  const radius = size / 2;
  const circumference = size * Math.PI;

  const [minutesCountdown, setMinutesCountdown] = useState(milliseconds);
  const [secondsCountdown, setSecondsCountdown] = useState(
    Math.floor(milliseconds - Math.floor(milliseconds / 60000) * 60000)
  );

  const strokeDashoffset = () => {
    return circumference - (minutesCountdown / milliseconds) * circumference;
  };

  const secondsDashoffset = () => {
    return circumference - (secondsCountdown / 60000) * circumference;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMinutesCountdown((minutesCountdown) => minutesCountdown - 100);

      if (minutesCountdown > 0) {
        if (secondsCountdown > 100) {
          setSecondsCountdown((secondsCountdown) => secondsCountdown - 100);
        } else {
          setSecondsCountdown(59999);
        }
      }

      if (secondsCountdown <= 0) {
        clearInterval(interval);
      }

      if (milliseconds < 0) {
        setMinutesCountdown(0);
        setSecondsCountdown(0);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [minutesCountdown, secondsCountdown]);

  const secondsValue = Math.floor(secondsCountdown / 1000);
  const minutesValue = Math.floor(minutesCountdown / 60000);

  return (
    <div className={classNames(styles.container, className)}>
      <Timer
        size={size}
        text='Daqiqalar'
        radius={radius}
        strokeDashoffset={strokeDashoffset}
        circumference={circumference}
        counter={minutesValue}
        fontSize={fontSize}
      />
      <Timer
        size={size}
        text='Soniya'
        radius={radius}
        strokeDashoffset={secondsDashoffset}
        circumference={circumference}
        counter={secondsValue}
        fontSize={fontSize}
      />
    </div>
  );
};
