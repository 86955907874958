import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './jackpotNumbers.scss';

const Number = ({ activeNumber, isHidden }) => {
  const arr = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map((_item, idx) => idx),
    []
  );

  return (
    <div
      className='jackpot-numbers__single'
      style={{ display: isHidden ? 'none' : 'block' }}
    >
      <div style={{ transform: `translateY(-${10 * activeNumber}%)` }}>
        {arr.map((number) => (
          <div key={number}>{number}</div>
        ))}
      </div>
    </div>
  );
};

Number.propTypes = {
  activeNumber: PropTypes.number.isRequired,
  isHidden: PropTypes.bool,
};

Number.defaultProps = {
  isHidden: false,
};

export const JackpotNumbers = ({ value }) => {
  const numbers = useMemo(
    () =>
      String(value)
        .padStart(8, '0')
        .split('')
        .map((item) => +item),
    [value]
  );

  return (
    <div className='jackpot-numbers'>
      {numbers.map((item, idx) => (
        <React.Fragment key={idx}>
          {(idx + 1) % 3 === 0 && <span>,</span>}
          <Number activeNumber={item} isHidden={!(!!idx || !!item)} />
        </React.Fragment>
      ))}
    </div>
  );
};

JackpotNumbers.propTypes = {
  value: PropTypes.number.isRequired,
};
