import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: walletReset } = actions;

export * from './actions';
export * as walletsSelectors from './selectors';

export default reducer;
