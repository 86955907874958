import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useApp, useToggleActiveElement } from '@box/shared/hooks';
import { DetailPopup } from '@box/shared/baseComponents';

import { Label } from '../Label';
import { Message } from '../Message';

import { Props } from './Controller.types';

import styles from './controller.module.scss';

export const Controller: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      errorMessage,
      label,
      className,
      format,
      mask,
      type,
      id,
      defaultValue,
      placeholder,
      labelInside,
      currencySymbol,
      isSmall,
      labelHidden,
      disabled,
      withPopup,
      popupText,
      isWithdrawal,
      ...props
    },
    ref
  ) => {
    const classes = classNames(styles.input, {
      [styles.input_error]: errorMessage,
    });

    const {
      userEnv: { isMobile },
    } = useApp();

    const { t } = useTranslation();

    const {
      toggle: toggleOpen,
      isOpen,
      elementRef,
    } = useToggleActiveElement({ hideWhenClickOutside: true });

    return (
      <div
        className={classNames(
          styles.controller,
          { [styles.controller_mobile]: isMobile },
          { [styles.controller_inside]: labelInside },
          { [styles.controller_small]: isSmall },
          { [styles.controller_disabled]: disabled },
          className
        )}
      >
        {label && !labelHidden && (
          <div className={styles.labelBox}>
            <Label className={styles.label} label={label} />
            {withPopup && (
              <div
                className={styles.detail}
                onClick={toggleOpen}
                aria-hidden='true'
                ref={elementRef}
              >
                <span>?</span>
                <DetailPopup
                  className={classNames(styles.detailInfo, {
                    [styles.detailInfo_withdrawal]: isWithdrawal,
                  })}
                  isOpen={isOpen}
                  position='bottom-start'
                >
                  {popupText}
                </DetailPopup>
              </div>
            )}
          </div>
        )}
        {mask ? (
          format ? (
            <NumberFormat
              id={id}
              format={format + mask}
              allowEmptyFormatting
              defaultValue={defaultValue}
              className={classes}
              mask='_'
              disabled={disabled}
              getInputRef={ref}
              placeholder={placeholder}
              {...props}
              {...(type === ('tel' || 'password' || 'text') && {
                type,
              })}
            />
          ) : (
            <NumberFormat
              {...props}
              format={mask}
              type='text'
              defaultValue={defaultValue}
              placeholder={placeholder}
              id={id}
              disabled={disabled}
              className={classes}
              getInputRef={ref}
            />
          )
        ) : (
          <input
            className={classes}
            id={id}
            type={type}
            ref={ref}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
        )}
        {errorMessage && (
          <Message message={errorMessage} className={styles.error} />
        )}
        {currencySymbol && <p className={styles.currency}>{currencySymbol}</p>}
      </div>
    );
  }
);
