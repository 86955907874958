// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------ui-src-Overlay-Overlay-module__overlay{animation:\\------ui-src-Overlay-Overlay-module__open .5s ease;background:rgba(25,3,51,.902);height:100vh;left:0;opacity:0;position:fixed;top:0;transition:opacity .5s ease;width:100vw}.\\------ui-src-Overlay-Overlay-module__overlay--open{opacity:1}.\\------ui-src-Overlay-Overlay-module__overlay_june{background:#202040f2}", "",{"version":3,"sources":["webpack://./../../ui/src/Overlay/Overlay.module.scss"],"names":[],"mappings":"AACA,+CASE,6DAAA,CAFA,6BAAA,CAFA,YAAA,CAHA,MAAA,CACA,SAAA,CAGA,cAAA,CALA,KAAA,CAOA,2BAAA,CAJA,WAKF,CAEE,qDACE,SAAJ,CAGE,oDACE,oBADJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.overlay {\n  top: 0;\n  left: 0;\n  opacity: 0;\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  background: #190333e6;\n  transition: opacity 0.5s ease;\n  animation: open 0.5s ease;\n\n  &--open {\n    opacity: 1;\n  }\n\n  &_june {\n    background: rgba(32, 32, 64, 0.95);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "------ui-src-Overlay-Overlay-module__overlay",
	"open": "------ui-src-Overlay-Overlay-module__open",
	"overlay--open": "------ui-src-Overlay-Overlay-module__overlay--open",
	"overlay_june": "------ui-src-Overlay-Overlay-module__overlay_june"
};
module.exports = ___CSS_LOADER_EXPORT___;
