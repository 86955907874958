import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: resetUser } = actions;

export * from './actions';
export * as userSelectors from './selectors';
export * from './thunks';

export default reducer;
