import React, { useImperativeHandle, useRef } from 'react';
import isString from 'lodash/isString';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import styles from './RadioGroup.module.scss';

import { RadioGroupProps } from './RadioGroup.types';

export const RadioGroup = React.forwardRef(
  (
    {
      name,
      values,
      onChange,
      value,
      label,
      error,
      disabled,
      className,
    }: RadioGroupProps,
    ref
  ) => {
    const rootRef = useRef<HTMLInputElement>();
    const {
      userEnv: { isJune },
    } = useApp();

    useImperativeHandle(ref, () => ({
      focus: () => rootRef.current.scrollIntoView({ block: 'center' }),
    }));

    return (
      <div
        className={classNames(
          styles.radioGroup,
          { [styles.radioGroup_june]: isJune },
          className
        )}
        ref={rootRef}
      >
        <span className={styles.label}>{label}</span>
        <div className={styles.list}>
          {values.map((item, idx) => (
            <label
              className={styles.field}
              htmlFor={`${name}-${idx}`}
              key={idx}
            >
              <input
                id={`${name}-${idx}`}
                onChange={() => onChange(item.value)}
                value={item.value}
                name={name}
                type='radio'
                checked={item.value === value}
                disabled={disabled}
              />
              <span />
              <span className={styles.field}>{item.label}</span>
            </label>
          ))}
        </div>
        {error && isString(error) && (
          <span className={styles.error}>{error}</span>
        )}
      </div>
    );
  }
);
