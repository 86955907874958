import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PhoneInput from '@box/shared/components/PhoneInput/PhoneInput';
import { loginSelectors, registerSelectors } from '@box/redux/authentication';
import { commonSelectors } from '@box/redux/common';
import { useAppSelector } from '@box/redux/hooks';

import { useApp } from '@box/shared/hooks';
import { Form, SelectWithFlag } from '../../../../baseComponents';
import Banner from '../Banner/Banner';
import { createCountriesCodeOptions } from '../../../../utils/selectOptions';

import { useQuickFields } from './Quick.fields';
import styles from './Quick.module.scss';

export const Quick: React.FC = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isDesktop, isJune },
  } = useApp();
  const countries = useAppSelector(commonSelectors.countries);
  const loading = useAppSelector(registerSelectors.loading);
  const loginLoading = useAppSelector(loginSelectors.loading);
  const error = useAppSelector(registerSelectors.error);
  const loginError = useAppSelector(loginSelectors.error);
  const isButtonDisabled = useAppSelector(loginSelectors.isButtonDisabled);
  const { fields, form, juneFields, onSubmit, isBangladesh } = useQuickFields();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  const countryId = watch('country_id');

  return (
    <div
      className={classNames(styles.quick, {
        [styles.quick_withoutPhone]: isBangladesh,
      })}
    >
      {!isJune && <Banner />}
      <Form
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        loginLoading={loginLoading}
        register={register}
        fields={isJune ? juneFields : fields}
        formError={loginError ? error : ''}
        submitText={t('fields.register')}
        isButtonDisabled={isButtonDisabled}
        className={classNames(styles.form, {
          [styles.form_june]: isJune,
          [styles.form_june_desktop]: isJune && isDesktop,
          [styles.form_withoutPhone]: isBangladesh,
        })}
      >
        {isJune && !isBangladesh && (
          <div className={styles.phone}>
            <p className={styles.phone_label}>{t('fields.phone_number')}</p>
            <div className={styles.phone_row}>
              <SelectWithFlag
                className={styles.select}
                control={control as any}
                placeholder={t('fields.select')}
                name='country_id'
                options={createCountriesCodeOptions(countries)}
                onChange={(v, field) => field.onChange(v.value)}
              />
              <PhoneInput
                control={control as any}
                disabled={false}
                code={
                  countries.find((item) => item.id === Number(countryId))
                    ?.phone_code
                }
                error={t(errors.phone?.message)}
              />
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
