import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  resetUnreadMessagesCounter,
  sendMessage,
  setChatOpen,
  supportSelectors,
} from '@box/redux/support';
import { modalSelectors } from '@box/redux/app';
import { chatsUserInitSelectors } from '@box/redux/chats/init';
import { useAppSelector, useAppDispatch } from '@box/redux/hooks';
import { useDisabledByPath } from '@box/shared/hooks';

import { appConfig } from '../../config';
import { useDateFormat } from '../../hooks/useDateFormat';
import { MessageData, OnSendMessage } from './SupportChat.types';

const getTextMessage = (text) => {
  if (!text) return '';
  if (typeof text === 'string') return text;
  if (!text.message) return '';
  return text.message;
};

export const withState = (Component) => {
  const wrapper = ({ ...props }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const messages = useAppSelector(supportSelectors.messages);
    const isOpen = useAppSelector(supportSelectors.isOpen);
    const chatId = useAppSelector(supportSelectors.chatId);
    const openedModals = useAppSelector(modalSelectors.openedModals);
    const userId = useAppSelector(chatsUserInitSelectors.userId);
    const sendLoading = useAppSelector(supportSelectors.sendLoading);
    const messagesLoading = useAppSelector(supportSelectors.messagesLoading);
    const disabled = useDisabledByPath({ path: '/aviator' });

    const checkModals = () => {
      return (
        openedModals?.includes('register') ||
        openedModals?.includes('login') ||
        location.pathname === '/profile/payments/deposit'
      );
    };

    const unreadMessagesCount = useAppSelector(
      supportSelectors.unreadMessagesCount
    );

    const handleChatOpen = () => {
      dispatch(setChatOpen(true));
      dispatch(resetUnreadMessagesCounter({ chatId, userId }));
    };

    useEffect(() => {
      if (
        !messagesLoading &&
        unreadMessagesCount !== 0 &&
        !isOpen &&
        userId &&
        !checkModals()
      ) {
        disabled(handleChatOpen);
      }
    }, [
      unreadMessagesCount,
      isOpen,
      userId,
      chatId,
      openedModals,
      messagesLoading,
    ]);

    const { supportChatMessageDate } = useDateFormat();

    const onSendMessage: OnSendMessage = ({ message, file }) => {
      const messageData: MessageData = message
        ? {
            chatId,
            userId,
            text: message,
            files: file ? [file.id] : [],
          }
        : {
            chatId,
            userId,
            files: file ? [file.id] : [],
          };
      dispatch(sendMessage(messageData));
    };

    const onClose = () => dispatch(setChatOpen(false));

    const messagesList = useMemo(
      () =>
        messages.map((item) => ({
          text: getTextMessage(item?.data?.text),
          isSelf: item.user_id === userId,
          image:
            item?.files && item?.files.length > 0
              ? `${appConfig.crmStorage}/${item?.files[0]?.path}`
              : '',
          date: supportChatMessageDate(item.created_at),
          buttons: item.buttons,
        })),
      [messages, userId]
    );

    return (
      <Component
        {...props}
        messages={messagesList}
        onSendMessage={onSendMessage}
        close={onClose}
        isOpen={isOpen}
        sendLoading={sendLoading}
        messagesLoading={messagesLoading}
      />
    );
  };

  return wrapper;
};
