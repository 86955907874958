import { useEffect } from 'react';

export const useScrollToInputInsideBlock = () => {
  useEffect(() => {
    const onResize = () => {
      if (document.activeElement.tagName === 'INPUT') {
        window.setTimeout(() => {
          document.activeElement.scrollIntoView({
            block: 'center',
          });
        }, 100);
      }
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
};
