import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { emailResend, emailSelectors } from '@box/redux/email';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { LightButton } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { Props } from './EmailVerified.types';

import styles from './emailVerified.module.scss';

export const EmailVerified: FC<Props> = memo(({ className }) => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const isLoading = useAppSelector(emailSelectors.isLoading);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(emailResend());
  };
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.container_mobile]: isMobile,
      })}
    >
      <p className={styles.text}>
        <span>{t('settings.email_not_verified')}</span>.{' '}
        {t('settings.please_verify')}
      </p>
      <LightButton
        disabled={isLoading}
        onClick={handleClick}
        className={styles.button}
        loading={isLoading}
        color='green'
        type='button'
      >
        {t('settings.send_mail_again')}
      </LightButton>
    </div>
  );
});
