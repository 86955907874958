import React from 'react';
import { useSelector } from 'react-redux';

export const withPropsState = (Component) => {
  const wrapper = ({ ...props }) => {
    const id = 'error-popup';

    const modalProps = useSelector((state) => state.app.modals.props);
    const currentProps = modalProps[id];

    return (
      <Component
        {...props}
        title={currentProps?.title}
        text={currentProps?.text}
        id={id}
      />
    );
  };

  return wrapper;
};
