export const appConfig = {
  host: process.env.HOST || '',
  mainApi: process.env.SERVICE_MAIN_URL || '/m-api',
  slotApi: process.env.SERVICE_SLOT_URL || '/s-api',
  finTransactions: process.env.SERVICE_FIN_URL || '/f-transactions',
  crmApi: process.env.CRM_API,
  mainStorage: process.env.MAIN_STORAGE_URL || '',
  crmStorage: process.env.CRM_STORAGE,
  pusherClaster: process.env.PUSHER_CLUSTER || 'ap2',
  pusherAppKey: process.env.PUSHER_APP_KEY,
  env: process.env.NODE_ENV,
  sentryDSN: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
  license_link: process.env.LICENSE_LINK || '',
  responsible_gambling_link: process.env.RESPONSIBLE_GAMBLING_LINK || '',
  begambleaware: process.env.BEGAMBLEAWARE_LINK || '',
  helpApi: process.env.HELP_BASE_URL,
  translationUrl: process.env.TRANSLATION_URL,
  companyName: process.env.COMPANY_NAME || '',
  companyShortName: process.env.COMPANY_SHORT_NAME || '',
  companyAddress: process.env.COMPANY_ADDRESS || '',
  facebook: process.env.FACEBOOK_LINK || '',
  instagram: process.env.INSTAGRAM_LINK || '',
  twitter: process.env.TWITTER_LINK || '',
  telegram: process.env.TELEGRAM_LINK || '',
  telegram_uz: process.env.TELEGRAM_UZ_LINK || '',
  telegram_br: process.env.TELEGRAM_BR_LINK || '',
  telegram_en: process.env.TELEGRAM_EN_LINK || '',
  telegram_in: process.env.TELEGRAM_IN_LINK || '',
  apk_name: process.env.APK_NAME || '',
  mirror1: process.env.MIRROR_1 || '',
  mirror2: process.env.MIRROR_2 || '',
  mirror3: process.env.MIRROR_3 || '',
  image_url: process.env.IMAGE_URL || '',
  image_hash: process.env.IMAGE_HASH || '',
  main_url: process.env.MAIN_URL || '',
};

export default appConfig;
