import { useAppDispatch } from '@box/redux/hooks';
import { fetchGames } from '@box/redux/games';
import { useCurrentGameList } from './useCurrentGameList';

export const useGamesPagination = ({ id, perPage }) => {
  const dispatch = useAppDispatch();
  const list = useCurrentGameList(id);
  const isPagingAvailable = list?.meta?.current_page < list?.meta?.last_page;
  const nextPage = list?.meta?.current_page ? list.meta.current_page + 1 : 1;

  const loadNextPage = ({
    category,
    provider,
    search,
  }: {
    category: string;
    provider: string;
    search?: string;
  }) => {
    if (list?.initialized && !list?.loading) {
      dispatch(
        fetchGames({
          list: id,
          page: nextPage,
          perPage,
          category,
          provider,
          search,
          ...list?.filters,
        })
      );
    }
  };

  return {
    isPagingAvailable,
    loadNextPage,
  };
};
