import React, { FC } from 'react';
import { LightButton } from '@box/ui';
import { useTranslation } from 'react-i18next';

import { Props } from './ProgressBar.types';

import styles from './progressBar.module.scss';
import { ReactComponent as AppleIcon } from './assets/images/apple.svg';
import { ReactComponent as AndroidIcon } from './assets/images/android.svg';

export const ProgressBar: FC<Props> = ({ progress, progressType }) => {
  const { t } = useTranslation();

  if (progressType === 'bar') {
    return (
      <div className={styles.bar}>
        <div style={{ left: `${progress}%` }} className={styles.percent}>
          {progress}%
        </div>
        <div className={styles.line}>
          <div
            style={{ width: `${progress}%` }}
            className={styles.lineActive}
          />
          <div style={{ left: `${progress}%` }} className={styles.dot} />
        </div>
      </div>
    );
  }

  if (progressType === 'app') {
    return (
      <div className={styles.app}>
        <div className={styles.icon}>
          <AppleIcon />
        </div>
        <div className={styles.icon}>
          <AndroidIcon />
        </div>
      </div>
    );
  }

  if (progressType === 'support') {
    return (
      <div className={styles.support}>
        <LightButton className={styles.button} color='green'>
          {t('bonuses.activate')}
        </LightButton>
      </div>
    );
  }

  return null;
};
