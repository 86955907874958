import Cookies from 'js-cookie';

const EXPIRES = { expires: 365 };

export const checkForCookie = (key: string) => {
  const LSvalue = localStorage.getItem(key);
  if (LSvalue) {
    Cookies.set(key, LSvalue, EXPIRES);
    localStorage.removeItem(key);
  }
};
