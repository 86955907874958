import { createAsyncThunk } from '@reduxjs/toolkit';
import { currentLang } from '@box/shared/utils';
import sortBy from 'lodash/sortBy';

import { AppThunkApi } from '../types';

import {
  FetchCategoriesReturn,
  FetchCommonsReturn,
  GETCategories,
  FetchCategoriesPayload,
  GETCombine,
  FetchProvidersReturn,
  GETProvides,
  FetchBonusTypesReturn,
  GETBonusTypes,
  FetchBannersListReturn,
  GETBannersList,
  FetchBannersReturn,
  FetchBannersPayload,
  GETBannersWithPosition,
  SendPartnersTokensReturn,
  SendPartnersTokensPayload,
  POSTPartnersTokens,
} from './types';
import { setCommonsFailed } from './actions';

export const fetchCommons = createAsyncThunk<
  FetchCommonsReturn,
  void,
  AppThunkApi
>(
  'commons/fetchAll',
  async (_payload, { extra, dispatch, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data } = await api.get<GETCombine>('/combine', {
        params: {
          models: 'countries,currencies,languages',
          extras: 'clientdatas',
        },
      });

      if (data) {
        dispatch(setCommonsFailed(false));
        return {
          currencies: sortBy(data.currencies?.data, 'name') || [],
          countries: data.countries?.data || [],
          clientData: data.clientdatas,
          languages: data.languages.data,
        };
      }

      dispatch(setCommonsFailed(true));
      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

/* Новый эндпоинты для баннеров */

export const fetchBannersList = createAsyncThunk<
  FetchBannersListReturn,
  void,
  AppThunkApi
>('commons/fetchBannersList', async (_payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.get<GETBannersList>('/banner-positions');

    if (status === 200) {
      return data.data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const fetchBanners = createAsyncThunk<
  FetchBannersReturn,
  FetchBannersPayload,
  AppThunkApi
>('commons/fetchBanners', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { alias } = payload;

  try {
    const { data, status } = await api.get<GETBannersWithPosition>(
      `/banner-positions/${alias}`
    );

    if (status === 200) {
      return data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

/* Конец - Новый эндпоинты для баннеров */

export const fetchCategories = createAsyncThunk<
  FetchCategoriesReturn,
  FetchCategoriesPayload,
  AppThunkApi
>('categories/fetchCategories', async (payload, { extra, rejectWithValue }) => {
  const { transformers, api, isMobile } = extra;

  try {
    const { data, status } = await api.get<GETCategories>('/game-categories', {
      params: {
        is_mobile: isMobile ? 1 : 0,
        lang: payload ? payload.lang : currentLang,
      },
    });

    if (status === 200) {
      return transformers.categories(data.data);
    }

    return rejectWithValue('Error');
  } catch (err) {
    return rejectWithValue('AAAA');
  }
});

export const fetchProviders = createAsyncThunk<
  FetchProvidersReturn,
  void,
  AppThunkApi
>('providers/fetchProvider', async (_payload, thunkAPI) => {
  const { transformers, api, isMobile } = thunkAPI.extra;

  try {
    const { data, status } = await api.get<GETProvides>('/game-providers', {
      params: {
        is_mobile: isMobile ? 1 : 0,
      },
    });

    if (status === 200) {
      return transformers.providers(data.data);
    }

    return thunkAPI.rejectWithValue('Error');
  } catch (err) {
    return thunkAPI.rejectWithValue('Error');
  }
});

export const fetchBonusTypes = createAsyncThunk<
  FetchBonusTypesReturn,
  void,
  AppThunkApi
>('commons/bonusTypes', async (_payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.get<GETBonusTypes>('/bonuses/types');

    if (status === 200) {
      return data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const sendPartnersTokens = createAsyncThunk<
  SendPartnersTokensReturn,
  SendPartnersTokensPayload,
  AppThunkApi
>(
  'auth/partnersTokens/setPartnersTokens',
  async (payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    const { partner_mirror, token, refresh_token, expires } = payload;

    const config = {
      baseURL: `https://${partner_mirror}`,
    };

    try {
      const { data, status } = await api.post<POSTPartnersTokens>(
        '/api/v1/me/pass-token',
        {
          data: {
            token,
            refresh_token,
            expires,
          },
        },
        config
      );

      if (status === 200) {
        return data;
      }

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue('Error');
    }
  }
);
