import React from 'react';
import PropTypes from 'prop-types';

import './detailLink.scss';
import { ReactComponent as DetailLinkIcon } from './assets/detail.svg';

export const DetailLink = ({ text, onClick }) => {
  return (
    <div className='detail-link' onClick={onClick} aria-hidden='true'>
      <DetailLinkIcon />
      <span className='detail-link__text'>{text}</span>
    </div>
  );
};

DetailLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

DetailLink.defaultProps = {
  onClick: () => null,
};
