import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkApi } from '@box/redux/types';
import { getStatParams } from '@box/redux/utils';

import { FetchRepeatVisitReturn, POSTRepeatVisit } from './types';

export const fetchRepeatVisit = createAsyncThunk<
  FetchRepeatVisitReturn,
  void,
  AppThunkApi
>(
  'app/stats/fetchRepeatVisit',
  async (_payload, { extra, rejectWithValue }) => {
    const { api, isMobile } = extra;

    const statParams = getStatParams();

    try {
      const { data, status } = await api.post<POSTRepeatVisit>(
        `/stat/visit/repeat?is_mobile=${!!isMobile}`,
        { partner_params: statParams }
      );

      if (status === 200) {
        return data;
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);
