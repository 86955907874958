import React, { FC } from 'react';
import reactDom from 'react-dom';
import classNames from 'classnames';

import {
  useApp,
  useLockScroll,
  useScrollToInputInsideBlock,
} from '@box/shared/hooks';

import { Props } from './CustomModal.types';

import styles from './customModal.module.scss';

export const CustomModal: FC<Props> = ({
  close,
  isOpen,
  className,
  id,
  children,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  useLockScroll(!!isOpen);
  useScrollToInputInsideBlock();

  const handleClose = () => {
    close();
  };

  return reactDom.createPortal(
    <div
      className={classNames(styles.modal, {
        [styles.modal_open]: isOpen,
        [styles.modal_mobile]: isMobile,
      })}
      id={id}
    >
      <div className={styles.overlay} onClick={close} aria-hidden='true' />
      <div className={classNames(styles.body, className)}>
        {children}
        <button onClick={handleClose} type='button' className={styles.close} />
      </div>
    </div>,
    document.getElementById('modals')
  );
};
