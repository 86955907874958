import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LightButton } from '@box/ui';
import { useApp, useNavigateToDeposit } from '@box/shared/hooks';
import { useAppDispatch } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { Collapsed } from '@box/shared/baseComponents';
import Cookies from 'js-cookie';

import { ReactComponent as ArrowIcon } from '@box/shared/icons/arrows/dropdown_arrow.svg';
import { Info } from './Info';
import { Menu } from './Menu';
import { useMenuList } from './useMenuList';

import styles from './sidebarMenu.module.scss';
import { ReactComponent as LogoutIcon } from './assets/images/logout.svg';

export const SidebarMenu: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const { openDeposit } = useNavigateToDeposit();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    openDeposit();
  };

  const handleLogout = () => {
    dispatch(openModal('logout'));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { menuList, currentPage } = useMenuList();

  const Icon = currentPage?.Icon;

  const isAutoAuth = Cookies.get('isAutoAuth');

  if (isMobile) {
    return (
      <div className={classNames(styles.sidebar, styles.sidebar_mobile)}>
        {currentPage && !isOpen && (
          <button onClick={handleOpen} type='button' className={styles.open}>
            <div className={styles.left}>
              <Icon />
              {t(currentPage.label)}
            </div>
            <div className={styles.right}>
              <ArrowIcon />
            </div>
          </button>
        )}
        <Collapsed isOpen={isOpen}>
          <>
            <Info
              className={classNames(styles.info, {
                [styles.info_closed]: !isOpen,
              })}
            />
            <LightButton
              className={styles.button}
              color='green'
              onClick={handleClick}
            >
              {t('user_actions.topUp')}
            </LightButton>
            <Menu
              onClick={handleClose}
              menuList={menuList}
              className={styles.menu}
            />
            {isAutoAuth === '1' && (
              <button
                type='button'
                onClick={handleLogout}
                className={styles.logout}
              >
                <LogoutIcon />
                {t('user_actions.logout')}
              </button>
            )}

            {isOpen && (
              <button
                type='button'
                onClick={handleClose}
                className={styles.hide}
              >
                <ArrowIcon />
              </button>
            )}
          </>
        </Collapsed>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.sidebar, {
        [styles.sidebar_mobile]: isMobile,
      })}
    >
      <Info className={styles.info} />
      <LightButton
        className={styles.button}
        color='green'
        onClick={handleClick}
      >
        {t('user_actions.topUp')}
      </LightButton>
      <Menu menuList={menuList} className={styles.menu} />
      {isAutoAuth === '1' && (
        <button type='button' onClick={handleLogout} className={styles.logout}>
          <LogoutIcon />
          {t('user_actions.logout')}
        </button>
      )}
    </div>
  );
};
