import classNames from 'classnames';
import React, { FC, CSSProperties } from 'react';

import styles from './Loader.module.scss';

interface ILoaderProps {
  className?: string;
  color?: 'light' | 'dark';
  style?: CSSProperties;
}

export const Loader: FC<ILoaderProps> = ({
  className,
  color = 'light',
  style,
}) => {
  return (
    <div
      className={classNames(
        styles.loader,
        className,
        styles[`loader--${color}`]
      )}
      style={style}
    >
      <span className={styles.spinner} />
    </div>
  );
};
