import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeImageViewer, imageViewerSelectors } from '@box/redux/app';

export const withReduxState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { onHide } = props;

    const dispatch = useDispatch();
    const isOpen = useSelector(imageViewerSelectors.isOpen);
    const image = useSelector(imageViewerSelectors.image);

    const onClose = () => {
      if (onHide) onHide();
      dispatch(closeImageViewer());
    };

    return (
      <Component {...props} isOpen={isOpen} image={image} close={onClose} />
    );
  };

  return wrapper;
};
