import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './smallGoldenCard.scss';

export const SmallGoldenCard = ({
  children,
  className,
  showBottomDecor,
  small,
}) => {
  return (
    <div
      className={classNames('small-golden-card', className, {
        'small-golden-card--bottom-decor': !!showBottomDecor,
        'small-golden-card--small': !!small,
      })}
    >
      {children}
      <div className='bottom-decor' />
    </div>
  );
};

SmallGoldenCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
  showBottomDecor: PropTypes.bool,
  small: PropTypes.bool,
};

SmallGoldenCard.defaultProps = {
  className: '',
  showBottomDecor: false,
  small: false,
};
