import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Label.types';

import styles from './label.module.scss';

export const Label: FC<Props> = ({
  label,
  className,
  align = 'left',
  color = 'default',
}) => {
  return (
    <p
      className={classNames(
        styles.label,
        styles[`label_align_${align}`],
        styles[`label_color_${color}`],
        className
      )}
      dangerouslySetInnerHTML={{
        __html: label,
      }}
    />
  );
};
