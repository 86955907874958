import React, { useState, FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { loyaltyTasksSelectors } from '@box/redux/loyalty';
import { useAppSelector } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';

import { CheckboxWithState } from '../../CheckboxWithState';
import { TaskCard } from '../TaskCard';

import styles from './tasksList.module.scss';

export const TasksList: FC = () => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const [isCompletedHidden, setCompletedHidden] = useState(false);

  const { t } = useTranslation();

  const tasksList = useAppSelector(loyaltyTasksSelectors.tasksList);
  const tasksListOnlyUncompleted = useAppSelector(
    loyaltyTasksSelectors.tasksListOnlyUncompleted
  );
  const tasksListOnlyCompleted = useAppSelector(
    loyaltyTasksSelectors.tasksListOnlyCompleted
  );

  const tasks = () => {
    return isCompletedHidden ? tasksListOnlyUncompleted : tasksList;
  };

  return (
    <div
      className={classNames(styles.list, { [styles.list_mobile]: isMobile })}
    >
      <h3 className={styles.title}>{t('loyalty.tasks.title')}</h3>
      <div className={styles.row}>
        <p className={styles.subtitle}>
          {t('loyalty.tasks.done')}: {tasksListOnlyCompleted.length} /{' '}
          {tasksList.length}
        </p>
        <CheckboxWithState
          setValue={setCompletedHidden}
          isActive={isCompletedHidden}
          label={t('loyalty.tasks.hide_completed')}
        />
      </div>
      <div className={styles.tasks}>
        {tasks().map((item, index) => {
          return <TaskCard key={index} {...item} />;
        })}
      </div>
    </div>
  );
};
