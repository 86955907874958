import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { hideModal } from '@box/redux/app';
import { useApp } from '@box/shared/hooks';
import { Button } from '@box/shared/baseComponents';
import { logout } from '@box/redux/authentication';
import { CustomModal } from '@box/shared/components/Modal';
import * as Sentry from '@sentry/react';

import { userSelectors } from '@box/redux/user';
import styles from './logout.module.scss';

export const LogoutModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    userEnv: { isDesktop },
    route,
  } = useApp();
  const user = useAppSelector(userSelectors.user);

  const handleLogout = () => {
    const event = {
      message: 'Logout - yes',
      tags: {
        page: window.location.pathname,
        id: user.id,
        email: user?.email,
      },
    };
    Sentry.captureEvent(event);
    dispatch(logout());
    dispatch(hideModal('logout'));
    navigate(route('main'));
  };

  const handleStay = () => {
    // const event = {
    //   message: 'Logout - no',
    //   tags: {
    //     page: window.location.pathname,
    //     id: user.id,
    //     email: user?.email,
    //   },
    // };
    // Sentry.captureEvent(event);
    dispatch(hideModal('logout'));
    navigate(route('main'));
  };

  return (
    <CustomModal
      id='logout'
      className={classNames(styles.logout, {
        [styles.logout_mobile]: !isDesktop,
      })}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>
          {t('exit_modal.title1')} <br /> {t('exit_modal.title2')}
        </h3>
        <div className={styles.buttons}>
          <Button
            color='primary'
            className={styles.button}
            onClick={handleLogout}
          >
            {t('exit_modal.button1')}
          </Button>
          <Button
            className={classNames('animationButton', styles.button)}
            color='secondary'
            onClick={handleStay}
            hoverType='outline'
          >
            {t('exit_modal.button2')}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
