import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { selectIsDisabled } from '@box/redux/forms';
import { useAppSelector } from '@box/redux/hooks';
import { MaskedInput } from '@box/ui';

import { Inputs } from '../PersonalData.types';
import styles from '../personalData.module.scss';

export const BirthdateField = memo(() => {
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);
  const {
    control,
    formState: { errors },
  } = useFormContext<Inputs>();

  return (
    <Controller
      control={control}
      name='birthdate'
      render={({ field }) => (
        <MaskedInput
          error={t(errors.birthdate?.message)}
          label={t('settings.birthdate')}
          className={styles.control}
          format='##-##-####'
          placeholder='dd-mm-yyyy'
          disabled={disabled}
          name={field.name}
          value={field.value}
          ref={field.ref}
          onChange={(e) => field.onChange(e.target.value)}
        />
      )}
    />
  );
});
