import { generatePath } from 'react-router-dom';

export const getRoute = (routes) => (name, params) => {
  let currentPath = routes[name];

  if (Array.isArray(currentPath)) {
    // eslint-disable-next-line prefer-destructuring
    currentPath = currentPath[0];
  }

  try {
    return generatePath(currentPath, params);
  } catch (e) {
    return '/notfound';
  }
};
