import { createSlice } from '@reduxjs/toolkit';

import { fetchCatalog } from './thunks';
import { CatalogsInitialState } from './types';

const defaultList = {
  list: [],
  ready: false,
  loading: false,
};

const initialState: CatalogsInitialState = {};

export const slice = createSlice({
  name: 'games/catalogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalog.pending, (state, { meta }) => {
        const { alias } = meta.arg;

        state[alias] = { ...defaultList };
        state[alias].loading = true;
      })
      .addCase(fetchCatalog.fulfilled, (state, { payload }) => {
        const { alias } = payload;
        const currentCatalog = state[alias];

        if (currentCatalog) {
          currentCatalog.list = payload.list;
          currentCatalog.ready = true;
          currentCatalog.loading = false;
        }
      });
  },
});
