import React, { FC, useMemo } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { Props } from './Circle.types';

import styles from './circle.module.scss';
import { ReactComponent as JuneSvg } from './assets/images/june.svg';

export const Circle: FC<Props> = ({ progression_percentage }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  const trailColor = useMemo(() => {
    if (isJune) {
      return '#E6EFE6';
    }

    if (isMobile) {
      return '#E6EFE6';
    }

    return '#D8D8D8';
  }, [isJune, isMobile]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
        [styles.container_june]: isJune,
      })}
    >
      <div className={styles.circle}>
        <CircularProgressbarWithChildren
          value={progression_percentage}
          strokeWidth={5}
          styles={buildStyles({
            pathColor: '#46AC16',
            trailColor,
            strokeLinecap: 'butt',
          })}
        >
          <div className={styles.content}>
            {progression_percentage > 100 ? 100 : progression_percentage}%
          </div>
          {isJune && (
            <div className={styles.svg}>
              <JuneSvg />
            </div>
          )}
        </CircularProgressbarWithChildren>
      </div>

      <p className={styles.label}>{t('bonuses.wagering_bar')}</p>
    </div>
  );
};
