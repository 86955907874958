import { LightButton } from '@box/ui';
import classNames from 'classnames';
import React from 'react';
import { ReactComponent as NavigationArrow } from '@box/shared/icons/navigation_arrow.svg';
import { useApp } from '@box/shared/hooks';
import styles from './Pagination.module.scss';
import { Props } from './Pagination.types';

export const Pagination = ({
  currentPage,
  lastPage,
  loading,
  showNextPage,
  showPreviousPage,
  changePageNumber,
}: Props) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(currentPage + 2, lastPage);

  return (
    <div
      className={classNames(styles.pagination, {
        [styles.pagination_mobile]: isMobile,
      })}
    >
      <LightButton
        className={styles.prevButton}
        disabled={currentPage === 1 || loading}
        onClick={showPreviousPage}
        color='white'
      >
        <NavigationArrow />
      </LightButton>
      <div className={styles.pages}>
        {currentPage > 3 && (
          <>
            <LightButton
              type='button'
              onClick={() => changePageNumber(1)}
              className={styles.page}
              color='white'
            >
              1
            </LightButton>
            {currentPage !== 4 && <span className={styles.dots}>...</span>}
          </>
        )}

        {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
          const pageNumber = startPage + i;
          return (
            <LightButton
              type='button'
              key={pageNumber}
              disabled={currentPage === pageNumber || loading}
              onClick={() => changePageNumber(pageNumber)}
              className={classNames(styles.page, {
                [styles.page_active]: currentPage === pageNumber,
              })}
              color='white'
            >
              {pageNumber}
            </LightButton>
          );
        })}
        {currentPage < lastPage - 2 && (
          <>
            {currentPage !== lastPage - 3 && (
              <span className={styles.dots}>...</span>
            )}
            <LightButton
              type='button'
              onClick={() => changePageNumber(lastPage)}
              className={styles.page}
              color='white'
            >
              {lastPage}
            </LightButton>
          </>
        )}
      </div>
      <LightButton
        className={styles.nextButton}
        disabled={currentPage === lastPage || loading}
        onClick={showNextPage}
        color='white'
      >
        <NavigationArrow />
      </LightButton>
    </div>
  );
};
