import { useEffect, useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import {
  fetchSlotGames,
  resetSlotsList,
  slotGamesSelectors,
} from '@box/redux/slots';

export const useSlotPagination = ({
  listId,
  perPage,
  search = '',
  position,
}: {
  listId: string;
  perPage: number;
  search?: string;
  position: string;
}) => {
  const dispatch = useAppDispatch();

  const currentList = useAppSelector((state) =>
    slotGamesSelectors.listById(state, listId)
  );

  useLayoutEffect(() => {
    if (!currentList) {
      dispatch(
        fetchSlotGames({
          position,
          perPage,
          page: 1,
          listId,
          search,
        })
      );
    }
  }, [currentList]);

  const next = () => {
    if (currentList && !currentList?.loading && currentList?.ready) {
      dispatch(
        fetchSlotGames({
          position,
          perPage,
          page: currentList.meta.current_page + 1,
          listId,
          search,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(resetSlotsList(listId));
    dispatch(fetchSlotGames({ position, perPage, page: 1, search, listId }));
  }, [search]);

  return {
    next,
  };
};
