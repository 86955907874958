import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { LightButton } from '../LightButton';

import styles from './lightPopup.module.scss';
import type { Props } from './LightPopup.types';

export const LightPopup: FC<Props> = ({
  isOpen,
  text,
  buttonText,
  onClose,
  icon: Icon,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.popup, {
        [styles.mobile]: isMobile,
        [styles['popup--open']]: isOpen,
      })}
    >
      <div className='error-light-popup__content'>
        <div className='error-light-popup__head'>
          <div
            className='error-light-popup__icon'
            onClick={onClose}
            role='button'
            tabIndex={0}
            aria-hidden='true'
          >
            <Icon />
          </div>
          <span className='error-light-popup__title'>{text}</span>
        </div>
        <div className='error-light-popup__body'>
          {buttonText && (
            <div className='error-light-popup__bottom'>
              <LightButton
                className='error-light-popup__button'
                color='yellow'
                onClick={onClose}
              >
                {buttonText}
              </LightButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
