import React, { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SmallLoader } from '../../baseComponents';

import { ComponentProps } from './ChatForm.types';

import styles from './chatForm.module.scss';

export const ChatForm: FC<ComponentProps> = ({
  onSubmit,
  fileName,
  deleteFile,
  control,
  isSubmitActive,
  changeFile,
  loading,
  disabled,
  fileReady,
  isLight,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.form, { [styles.form_light]: isLight })}>
      <form
        className={classNames(styles.main, {
          [styles.main_light]: isLight,
        })}
        onSubmit={onSubmit}
      >
        <Controller
          name='message'
          control={control}
          render={({ field }) => (
            <TextareaAutosize
              className={styles.message}
              placeholder={t('support.message')}
              value={field.value || ''}
              maxRows={3}
              onChange={(v) => field.onChange(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  onSubmit(e);
                }
              }}
            />
          )}
        />
        <label
          className={styles.anchorFile}
          htmlFor='file'
          style={{ display: !(isSubmitActive || loading) ? 'block' : 'none' }}
        >
          <input type='file' id='file' onChange={changeFile} />
        </label>
        {isSubmitActive && !loading && (
          <button className={styles.submit} type='submit' disabled={disabled} />
        )}
        <SmallLoader
          isLight={isLight}
          className={styles.loader}
          isActive={loading}
        />
      </form>
      {fileName && (
        <div className={styles.file}>
          <span className={styles.name}>{fileName}</span>
          {fileReady ? (
            <div
              className={styles.delete}
              onClick={deleteFile}
              aria-hidden='true'
            />
          ) : (
            <SmallLoader isLight={isLight} isActive />
          )}
        </div>
      )}
    </div>
  );
};
