/* eslint-disable no-unsafe-optional-chaining */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { copyTextToClipboard } from '@box/shared/utils';
import { ratesSelectors } from '@box/redux/partner/rates';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { LightButton } from '@box/ui';
import { Collapsed } from '@box/shared/baseComponents';
import { streamsSelectors, fetchPartnerUrl } from '@box/redux/partner/streams';
import { appConfig } from '@box/shared/config';

import { useApp, useGetEarn } from '@box/shared/hooks';
import { Modal } from '../../../Modal/Modal';

import styles from './referral.module.scss';

import { ReactComponent as CopySvg } from './assets/images/copy.svg';
// import { ReactComponent as TelegramSvg } from '../../Referral/assets/telegram.svg';
// import { ReactComponent as WhatsappSvg } from '../../Referral/assets/whatsapp.svg';
import { ReactComponent as CopiedSvg } from './assets/images/copied.svg';

const list = ['1', '2'];

export const ReferralJune: FC = () => {
  const [copied, setCopied] = useState(false);
  const [openInstruction, toggleInstruction] = useToggle(false);
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const dispatch = useAppDispatch();

  const { companyName, companyShortName } = appConfig;

  const currentRate = useAppSelector(ratesSelectors.currentRate);

  const minValue = currentRate?.min_value;

  const partnerUrl = useAppSelector(streamsSelectors.url);
  const loading = useAppSelector(streamsSelectors.loading);
  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const { earn } = useGetEarn(currentRate);

  const minDeposit = currentRate?.rate * 2;

  const handleCopyText = () => {
    if (partnerUrl) {
      copyTextToClipboard(partnerUrl);
      setCopied(true);
    }
  };

  const handleGetUrl = () => {
    dispatch(fetchPartnerUrl());
  };

  useEffect(() => {
    if (!loading && !partnerUrl && isLogged) {
      handleGetUrl();
    }
  }, [partnerUrl, isLogged]);

  return (
    <Modal
      headColor='blue'
      id='referral'
      size={isDesktop ? 'lg' : 'xs'}
      heightAuto={isDesktop}
      backgroundColor='blue'
      headWithSvg={isDesktop}
      title={t('referral.title')}
    >
      <div
        className={classNames(styles.body, {
          [styles.body_desktop]: isDesktop,
        })}
      >
        <div className={styles.box}>
          <h4 className={styles.title}>{t('referral.share_code')}</h4>

          <p className={styles.text}>{t('referral.your_code')}</p>
          <div className={styles.row}>
            <div className={styles.ref}>
              <p className={styles.refText}>{partnerUrl?.slice(8)}</p>
            </div>
            <LightButton
              className={styles.copy}
              onClick={handleCopyText}
              type='button'
            >
              <CopiedSvg
                className={classNames(styles.copied, {
                  [styles.copied_active]: copied,
                })}
              />

              <CopySvg
                className={classNames(styles.copy_icon, {
                  [styles.copy_icon_disabled]: copied,
                })}
              />
            </LightButton>
          </div>
          {/* LM-2699 {partnerUrl && (
            <>
              <p className={styles.shareText}>{t('referral.or_share')}</p>
              <div className={styles.sharedSocials}>
                <a
                  className={styles.sharedWhatsapp}
                  target='_blank'
                  href={`whatsapp://send?text=${t('referral.shared_text', {
                    companyName,
                  })}${earn} ${partnerUrl}`}
                  rel='noreferrer'
                >
                  <WhatsappSvg /> {t('referral.shared_whatsapp')}
                </a>
                <a
                  className={styles.sharedTelegram}
                  target='_blank'
                  href={`https://telegram.me/share/url?url=${partnerUrl}&text=${t(
                    'referral.shared_text'
                  )} ${earn}`}
                  rel='noreferrer'
                >
                  <TelegramSvg /> {t('referral.shared_telegram')}
                </a>
              </div>
            </>
          )} */}
          <p className={styles.main_title}>
            {t('referral.earn.money', { earn })}
          </p>
          <h4 className={styles.title}>{t('referral.invite')}</h4>
          <p className={styles.text}>{t('referral.receive_cash')}</p>
          <p className={styles.subtitle}>{t('referral.invitations')}?</p>
          <button
            onClick={toggleInstruction}
            type='button'
            className={classNames(styles.button, {
              [styles.button_show]: openInstruction,
            })}
          >
            {t('referral.instruction')}
          </button>
          <Collapsed isOpen={openInstruction}>
            <div className={styles.container}>
              <h4 className={styles.title}>
                {t('referral.invite_your_friends')}
              </h4>
              <p className={styles.text}>{t('referral.text')}</p>
              <h4 className={styles.title}>{t('referral.terms')}:</h4>
              <ul className={styles.list}>
                {list.map((item, index) => {
                  return (
                    <li key={index} className={styles.item}>
                      {t(`referral.list.${item}`, {
                        companyName,
                        companyShortName,
                        earn,
                        minValue,
                        currency: currentRate?.currency,
                        minDeposit,
                      })}
                    </li>
                  );
                })}
              </ul>
              <p className={styles.text}>{t('referral.details')}</p>
            </div>
          </Collapsed>
        </div>
      </div>
    </Modal>
  );
};
