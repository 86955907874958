import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import Cookies from 'js-cookie';

const keys = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  EXPIRES: 'token_expires_in',
  CRM_TOKEN: 'crm_token',
  CRM_REFRESH_TOKEN: 'crm_refresh_token',
  CRM_UUID: 'uuid',
};
const EXPIRES = { expires: 365 };

export const useAuthTokens = () => {
  const getAccess = (): string | null => Cookies.get(keys.TOKEN);
  const getRefresh = (): string | null => Cookies.get(keys.REFRESH_TOKEN);
  const getCrmAccess = (): string | null => Cookies.get(keys.CRM_TOKEN);
  const getCrmRefreshToken = (): string | null =>
    Cookies.get(keys.CRM_REFRESH_TOKEN);
  const getCrmUuid = (): string | null => Cookies.get(keys.CRM_UUID);
  const getExpireIn = (): string | null => Cookies.get(keys.EXPIRES);

  const setAccessToken = (value: string) =>
    Cookies.set(keys.TOKEN, value, EXPIRES);
  const setRefreshToken = (value: string) =>
    Cookies.set(keys.REFRESH_TOKEN, value, EXPIRES);
  const setCrmAccessToken = (value: string) =>
    Cookies.set(keys.CRM_TOKEN, value, EXPIRES);
  const setCrmRefreshToken = (value: string) =>
    Cookies.set(keys.CRM_REFRESH_TOKEN, value, EXPIRES);
  const setCrmUuid = (value: string) =>
    Cookies.set(keys.CRM_UUID, value, EXPIRES);

  const deleteAccessToken = () => Cookies.remove(keys.TOKEN);
  const deleteRefreshToken = () => Cookies.remove(keys.REFRESH_TOKEN);
  const deleteCrmAccessToken = () => Cookies.remove(keys.CRM_TOKEN);
  const deleteCrmUuid = () => Cookies.remove(keys.CRM_UUID);
  const deleteCrmRefreshToken = () => Cookies.remove(keys.CRM_REFRESH_TOKEN);
  const deleteExpires = () => Cookies.remove(keys.EXPIRES);

  const deleteAllTokens = () => {
    deleteExpires();
    deleteAccessToken();
    deleteRefreshToken();
  };

  const deleteAllCrmTokens = () => {
    deleteCrmAccessToken();
    deleteCrmRefreshToken();
    deleteCrmUuid();
  };

  const setExpires = (value: string) =>
    value && Cookies.set(keys.EXPIRES, value, EXPIRES);

  const isExpires = (): boolean => {
    try {
      const expires = +Cookies.get(keys.EXPIRES);

      if (!isNumber(expires)) return true;

      return dayjs.unix(expires).diff(dayjs(), 'milliseconds') <= 0;
    } catch (e) {
      return true;
    }
  };

  return {
    accessToken: getAccess(),
    refreshToken: getRefresh(),
    crmAccessToken: getCrmAccess(),
    crmRefreshToken: getCrmRefreshToken(),
    crmUuid: getCrmUuid(),
    setCrmUuid,
    setAccessToken,
    setRefreshToken,
    setCrmAccessToken,
    setCrmRefreshToken,
    deleteAccessToken,
    deleteRefreshToken,
    deleteCrmAccessToken,
    deleteCrmRefreshToken,
    deleteAllTokens,
    deleteAllCrmTokens,
    setExpires,
    deleteExpires,
    isExpires: isExpires(),
    keys,
    expiresIn: getExpireIn(),
  };
};
