import { createSlice } from '@reduxjs/toolkit';

import { requestStatuses } from '../utils/constants';
import { closeBonusDetail, openBonusDetail } from './actions';

import { fetchBonuses } from './thunks';

const initialState = {
  list: [],
  status: requestStatuses.notStarted,
  loading: false,
  detail: null,
};

export const slice = createSlice({
  name: 'bonuses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBonuses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBonuses.fulfilled, (state, { payload }) => {
        state.list = payload.list;
        state.status = requestStatuses.init;
        state.loading = false;
      })
      .addCase(fetchBonuses.rejected, (state) => {
        state.loading = false;
      })

      .addCase(openBonusDetail, (state, { payload }) => {
        state.detail = payload;
      })
      .addCase(closeBonusDetail, (state) => {
        state.detail = null;
      });
  },
});
