import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { LightButton, ImageSet } from '@box/ui';

import { ProgressBar } from './ProgressBar';

import { Props } from './lightBonusItem.types';

import styles from './lightBonusItem.module.scss';

export const LightBonusItem: FC<Props> = ({
  className,
  name,
  status,
  progression_percentage,
  bonusId,
  image,
}) => {
  const [params, setParams] = useSearchParams();

  const { t } = useTranslation();

  const handleClick = () => {
    if (status) {
      if (status === 'created') {
        params.append('bonus', String(bonusId));
      } else {
        params.append('active-bonus', String(bonusId));
      }
    } else {
      params.append('bonus', String(bonusId));
    }
    window.scrollTo(0, 0);
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  return (
    <div
      className={classNames(styles.bonus, className, {
        [styles.bonus_completed]: status === 'ended',
      })}
    >
      <div className={styles.info}>
        <div className={styles.column}>
          <h4 className={styles.title}>{name}</h4>
        </div>
        <LightButton
          onClick={handleClick}
          className={styles.button}
          type='button'
          fixHeight
        >
          {status === 'activated' || status === 'ended'
            ? t('bonuses.learn_more')
            : t('bonuses.activate')}
        </LightButton>
      </div>
      {status === 'activated' ? (
        <ProgressBar percent={progression_percentage} />
      ) : (
        <ImageSet
          className={styles.image}
          src={image.png}
          srcAvif={image.avif}
          srcWebp={image.webp}
          alt={name}
        />
      )}
    </div>
  );
};
