import React from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Plate, BlockWithLoading } from '../../baseComponents';
import { Game } from './Game';

import styles from './games.module.scss';

export const Games = ({ list, loading, perRow, breakPoints }) => {
  const {
    userEnv: { isJune, isMobile },
  } = useApp();
  return (
    <div className={classNames(styles.games, { [styles.games_june]: isJune })}>
      <BlockWithLoading loading={loading}>
        <Plate
          perRow={perRow}
          breakPoints={breakPoints}
          spacing={!isJune ? 12 : isMobile ? 4 : 8}
        >
          {list.map((item, idx) => (
            <Game
              id={item.id}
              name={item.name}
              image={item.thumbnail}
              hasDemo={item.has_demo}
              key={idx}
              externalId={item.external_id}
            />
          ))}
        </Plate>
      </BlockWithLoading>
    </div>
  );
};
