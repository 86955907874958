/* eslint-disable no-console */
import axios from 'axios';

import { appConfig } from '../config';

export const fetchAppVersion = async () => {
  try {
    const response = await axios.get('/version.txt');
    const appVersion = response.data.trim();

    return appVersion;
  } catch (error) {
    if (appConfig.env === 'development') {
      console.error('Error getting version:', error);
    }
    return null; //
  }
};
