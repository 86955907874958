import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { getPureErrorMessage } from '../../utils';

import { fetchDepositBonus, userInitSelectors } from '../../authentication';
import { openInfoPopup, openModal } from '../../app';
import { AppThunkApi } from '../../types';

import { userSelectors } from '../info';

import { isActivateById } from './selectors';
import {
  InitUserBonusesReturn,
  GETUserBonuses,
  AddUserBonusReturn,
  AddUserBonusPayload,
  POSTUserBonus,
  DeleteUserBonusReturn,
  DeleteUserBonusPayload,
  DELETEUserBonus,
} from './types';

export const initUserBonuses = createAsyncThunk<
  InitUserBonusesReturn,
  void,
  AppThunkApi
>(
  'user/bonuses/init',
  async (_payload, { extra, rejectWithValue, getState }) => {
    const { api, transformers } = extra;

    const userId = userSelectors.id(getState());

    if (!userId) return rejectWithValue('No user id');

    try {
      const { data, status } = await api.get<GETUserBonuses>(
        `/userdatas/${userId}/bonuses`
      );

      if (status === 200) {
        return transformers.userBonuses(data.data);
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

export const addUserBonus = createAsyncThunk<
  AddUserBonusReturn,
  AddUserBonusPayload,
  AppThunkApi
>(
  'user/bonuses/add',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api, transformers, history, mirrors, isJune } = extra;

    const { bonusId } = payload;

    const { isMirror3 } = mirrors;

    try {
      const isLogged = userInitSelectors.isLogged(getState());

      const isActivated = isActivateById(getState(), bonusId);

      if (!isLogged) {
        dispatch(openModal('register'));

        return rejectWithValue('No auth');
      }

      if (isActivated) {
        dispatch(
          openInfoPopup({
            type: 'error',
            text: i18next.t('bonuses.active_warning'),
          })
        );

        return rejectWithValue('Already activate');
      }

      const { data, status } = await api.post<POSTUserBonus>(
        `/bonuses/${bonusId}/add`,
        {
          data: { amount: 100 },
        }
      );

      if (status === 200) {
        dispatch(
          openInfoPopup({
            type: 'success',
            text: i18next.t('bonuses.success_add'),
          })
        );
        dispatch(fetchDepositBonus());

        return transformers.singleUserBonus(data.data);
      }

      if (isJune) {
        if (isMirror3) {
          dispatch(openModal('deposit'));
        } else {
          history.push('/profile/payments/deposit');
        }
      } else {
        dispatch(openModal('deposit'));
      }

      dispatch(
        openInfoPopup({
          type: 'error',
          text: getPureErrorMessage(data),
        })
      );

      return rejectWithValue('Error fetching bonuses');
    } catch (e) {
      return rejectWithValue('Error fetching bonuses');
    }
  }
);

export const deleteUserBonus = createAsyncThunk<
  DeleteUserBonusReturn,
  DeleteUserBonusPayload,
  AppThunkApi
>(
  'user/bonuses/delete',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api } = extra;

    const userId = userSelectors.id(getState());

    const { bonusId } = payload;

    try {
      const { data, status } = await api.delete<DELETEUserBonus>(
        `/userdatas/${userId}/bonuses/${bonusId}`
      );

      if (status === 200) {
        dispatch(
          openInfoPopup({
            type: 'success',
            text: data,
          })
        );

        return data || {};
      }

      return rejectWithValue('Error fetching bonuses');
    } catch (e) {
      return rejectWithValue('Error fetching bonuses');
    }
  }
);
