import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './Status.types';

import styles from './status.module.scss';

export const Status: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const statusLabel = useMemo(() => {
    if (status === 'ended') {
      return t('bonuses.completed');
    }

    return t('bonuses.active');
  }, [status]);

  return (
    <div
      className={classNames(styles.status, {
        [styles.status_mobile]: isMobile,
      })}
    >
      <p className={styles.text}>{t('bonuses.status')}:</p>
      <p className={styles.text}>{statusLabel}</p>
      <div className={styles.decor} />
    </div>
  );
};
