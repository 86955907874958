import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { useAppSelector } from '@box/redux/hooks';
import { userSelectors } from '@box/redux/user';
import { useApp } from '@box/shared/hooks';

import { DefaultButton } from '../../../Payments';

import { Props } from './DefaultsButtons.types';

import styles from './defaultsButtons.module.scss';

export const DefaultsButtons: FC<Props> = ({
  percent,
  defaults,
  currencySymbol,
  minDepSum,
}) => {
  const { setValue, watch } = useFormContext();

  const {
    userEnv: { isMobile },
  } = useApp();

  const user = useAppSelector(userSelectors.user);

  const getItemValue = (value: number | number[]) => {
    if (typeof value === 'number') {
      return value;
    }
    if (user?.deposits_count) {
      return value[user.deposits_count];
    }
    return value[0];
  };

  const handleClick = (item) => {
    setValue('amount', item);
  };

  const watchAmount = watch('amount');

  return (
    <div
      className={classNames(styles.buttons, {
        [styles.buttons_mobile]: isMobile,
      })}
    >
      <div className={styles.container}>
        {defaults.map((item, idx) => (
          <DefaultButton
            className={styles.item}
            item={getItemValue(item)}
            currencySymbol={currencySymbol}
            percent={percent}
            active={watchAmount}
            minBonuses={minDepSum}
            key={idx}
            onClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};
