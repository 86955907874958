// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Deposits-Components-DefaultsButtons-defaultsButtons-module__buttons{display:grid;gap:10px;grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(2,1fr);margin-bottom:20px;width:100%}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Deposits/Components/DefaultsButtons/defaultsButtons.module.scss"],"names":[],"mappings":"AACA,qGACE,YAAA,CAIA,QAAA,CAHA,mCAAA,CACA,gCAAA,CACA,kBAAA,CAEA,UAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.buttons {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  margin-bottom: 20px;\n  gap: 10px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "------shared-components-Modals-Deposits-Components-DefaultsButtons-defaultsButtons-module__buttons"
};
module.exports = ___CSS_LOADER_EXPORT___;
