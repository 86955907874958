export interface Link {
  url: string;
  label: string;
  active: boolean;
}

export interface DataWithPagination<T> {
  data: T;
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: Link[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}
