import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import { Input } from '@box/ui';

import styles from '../personalData.module.scss';
import { Inputs } from '../PersonalData.types';

export const AddressField = memo(() => {
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);
  const {
    register,
    formState: { errors },
  } = useFormContext<Inputs>();
  return (
    <Input
      placeholder={t('settings.address')}
      error={t(errors.address?.message)}
      label={t('settings.address')}
      className={styles.control}
      {...register('address', {
        disabled,
      })}
    />
  );
});
