import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';

import {
  FetchCatalogPayload,
  FetchCatalogReturn,
  GETGameCatalogs,
} from './types';

export const fetchCatalog = createAsyncThunk<
  FetchCatalogReturn,
  FetchCatalogPayload,
  AppThunkApi
>('games/catalogs/fetch', async (payload, { extra, rejectWithValue }) => {
  const { api, isMobile } = extra;

  const { alias } = payload;

  try {
    const { data, status } = await api.get<GETGameCatalogs>(
      `/game-catalogs/${alias}`,
      {
        params: {
          is_mobile: isMobile,
        },
      }
    );

    if (status === 200) {
      return {
        alias,
        list: data.data,
      };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
