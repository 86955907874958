import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './MenuSearch.module.scss';
import { SearchListProps } from './MenuSearch.types';
import { SearchListItem } from './SearchListItem';

export const SearchList: FC<SearchListProps> = ({
  list,
  loading,
  gameLoading,
  onClose,
}) => {
  return loading || list?.length ? (
    <div
      className={classNames(styles.searchContainer, {
        [styles['searchContainer--loading']]: gameLoading,
      })}
    >
      {!loading ? (
        <ul className={styles.list}>
          {list?.map((item, idx) => (
            <SearchListItem onClose={onClose} item={item} key={idx} />
          ))}
        </ul>
      ) : (
        <div className={styles.listLoading}>
          <h3>Loading...</h3>
        </div>
      )}
    </div>
  ) : null;
};
