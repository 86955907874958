import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { loyaltyUserSelectors } from '@box/redux/loyalty';
import { openModal } from '@box/redux/app';
import { useApp } from '@box/shared/hooks';

import { DetailInfo } from '../../DetailInfo';
import { Avatar } from './Avatar';

import { Props } from './UserPanel.types';

import styles from './userPanel.module.scss';

export const UserPanel: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useAppDispatch();

  const status = useAppSelector(loyaltyUserSelectors.status);
  const nextStatus = useAppSelector(loyaltyUserSelectors.nextStatus);
  const points = useAppSelector(loyaltyUserSelectors.points);
  const currentRating = useAppSelector(loyaltyUserSelectors.currentRating);
  const nextRating = useAppSelector(loyaltyUserSelectors.nextRating);

  const handleClick = () => {
    dispatch(openModal('loyalty-statuses'));
  };

  if (isMobile) {
    return (
      <div className={classNames(styles.panel, styles.panel_mobile, className)}>
        <div className={styles.container}>
          <div className={styles.status}>{status.level}</div>
          <div className={classNames(styles.status, styles.status_next)}>
            {nextStatus.level}
          </div>
          <div className={styles.line} />
          <Avatar className={styles.avatar} value={points} />
          <div className={styles.info}>
            <div className={styles.row}>
              <div className={styles.column}>
                <p className={styles.label}>
                  {t('loyalty.user.my_current_status')}:
                </p>
                <p className={styles.value}>{status.name}</p>
              </div>
              <div className={styles.column}>
                <p className={styles.label}>{t('loyalty.user.my_rating')}:</p>
                <p className={classNames(styles.rating, styles.rating_yellow)}>
                  {currentRating}
                </p>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <p className={styles.label}>{t('loyalty.user.next_status')}:</p>
                <p className={styles.value}>{nextStatus.name}</p>
              </div>
              <div className={styles.column}>
                <p className={styles.label}>{t('loyalty.user.need_rating')}:</p>
                <p className={styles.rating}>{nextRating}</p>
              </div>
            </div>
          </div>
        </div>
        <DetailInfo
          label={t('loyalty.user.all_about_statuses')}
          isButton
          onClick={handleClick}
          className={styles.detailInfoMobile}
        />
      </div>
    );
  }
  return (
    <div className={classNames(styles.panel, className)}>
      <div className={styles.container}>
        <div className={styles.status}>{status.level}</div>
        <div className={styles.info}>
          <div className={styles.column}>
            <div>
              <p className={styles.label}>
                {t('loyalty.user.my_current_status')}:
              </p>
              <p className={styles.value}>{status.name}</p>
            </div>
            <div>
              <p className={styles.label}>{t('loyalty.user.my_rating')}:</p>
              <p className={classNames(styles.rating, styles.rating_yellow)}>
                {currentRating}
              </p>
            </div>
          </div>
          <Avatar className={styles.avatar} value={points} />
          <div className={classNames(styles.column, styles.column_second)}>
            <div>
              <p className={styles.label}>{t('loyalty.user.next_status')}:</p>
              <p className={styles.value}>{nextStatus.name}</p>
            </div>
            <div>
              <p className={styles.label}>{t('loyalty.user.need_rating')}:</p>
              <p className={styles.rating}>{nextRating}</p>
            </div>
          </div>
        </div>
        <div className={classNames(styles.status, styles.status_next)}>
          {nextStatus.level}
        </div>
        <DetailInfo
          label={t('loyalty.user.all_about_statuses')}
          isButton
          onClick={handleClick}
          className={styles.detailInfo}
        />
      </div>
    </div>
  );
};
