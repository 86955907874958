/* eslint max-len: ["error", { "code": 170 }] */

import { useTranslation } from 'react-i18next';

import { currentUrl } from '../utils';

export const useDescription = (description?: string) => {
  const { t } = useTranslation();

  const host = currentUrl();

  return {
    description: description || t('page.description', { host }),
  };
};
