import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './badge.scss';

export const Badge = ({ children, className, color }) => {
  return (
    <span
      className={classNames('badge', className, {
        'badge--primary': color === 'primary',
        'badge--secondary': color === 'secondary',
      })}
    >
      {children}
    </span>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
  color: '',
};
