export interface UserWallet {
  id: number;
  balance: string;
  balance_float: number;
  name: string;
  slug: string;
}

export interface WithdrawalWallet {
  id: number;
  user_id: number;
  withdrawal_merchant_id: number;
  name: string;
  status: string;
  fields_values: Record<string, unknown>;
}
