import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { ImageSet } from '@box/ui';

import { Props } from './BonusIcon.types';

import styles from './bonusIcon.module.scss';

import ActivePng from './assets/images/active.png';
import ActiveWebp from './assets/images/active.webp';
import ActiveAvif from './assets/images/active.avif';
import StartedPng from './assets/images/started.png';
import StartedWebp from './assets/images/started.webp';
import StartedAvif from './assets/images/started.avif';

export const BonusIcon: FC<Props> = ({ className, status }) => {
  const icon = useMemo(() => {
    if (status === 'created') {
      return {
        png: StartedPng,
        webp: StartedWebp,
        avif: StartedAvif,
      };
    }
    return {
      png: ActivePng,
      webp: ActiveWebp,
      avif: ActiveAvif,
    };
  }, [status]);

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.box,
          { [styles.box_active]: status === 'activated' || status === 'ended' },
          className
        )}
      >
        <ImageSet
          className={styles.image}
          src={icon.png}
          srcAvif={icon.avif}
          srcWebp={icon.webp}
          alt='Icon'
        />
      </div>
    </div>
  );
};
