import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Label.types';

import styles from './label.module.scss';

export const Label: FC<Props> = ({ label, className }) => {
  return (
    <p
      className={classNames(styles.label, className)}
      dangerouslySetInnerHTML={{
        __html: label,
      }}
    />
  );
};
