import React, { FC, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Controller, Select } from '../../ui';
import { useValidate } from '../../validate';

import { Option, Props } from './FlexibleFields.types';

import styles from './FlexibleFields.module.scss';

export const FlexibleFields: FC<Props> = ({ className, fields }) => {
  const isFirstRender = useRef(true);

  const { register, control, formState, unregister } = useFormContext();

  const { errors } = formState;
  const { customEmailValidate, rulesForObject } = useValidate();

  const fieldsArr = useMemo(
    () =>
      fields.map((item) => {
        if (item.type === 'select') {
          const options: Option[] = Object.keys(item.options).map((key) => ({
            value: key,
            label: item.options[key],
          }));

          const onChange = (v, field) => field.onChange(v.value);

          return {
            ...item,
            options,
            onChange,
            label: item.label,
            rules: rulesForObject(item.rules),
            validate: customEmailValidate(item.rules),
          };
        }

        return item;
      }),
    [fields]
  );

  useEffect(() => {
    // ===== Условие необходимо, чтобы сбросить поле с суммой при переходе между платежками
    // ===== Важно: Хранить setValue вне условия, во избежание проблем, когда платежка одна
    if (!isFirstRender.current) {
      unregister();
    } else {
      isFirstRender.current = false;
    }
  }, [fields]);

  return (
    <div className={classNames(styles.fields, className)}>
      {fieldsArr?.map((item, idx) =>
        item.type === 'select' ? (
          <Select
            className={styles.field}
            control={control}
            defaultValue={item.default_value}
            error={errors[item.name]}
            errorMessage={errors[item.name]?.message}
            key={`${item.name}-${idx}`}
            name={item.name}
            label={item.label}
            placeholder={item.placeholder}
            options={item.options}
            {...item}
          />
        ) : (
          <Controller
            className={styles.field}
            label={item.label}
            errorMessage={errors[item.name]?.message}
            defaultValue={item.default_value}
            placeholder={item.placeholder}
            {...register(item.name, {
              ...rulesForObject(item.rules),
              validate: customEmailValidate(item.rules),
            })}
            type={item.type}
            mask={item.mask}
            format={item.format}
            key={`${item.name}-${idx}`}
          />
        )
      )}
    </div>
  );
};
