import { createSelector } from '@reduxjs/toolkit';
import { isPendingVerification, isVerify } from '../user/info/selectors';
import { documentsSelectors, userUpdateSelectors } from '../user';

export const selectIsDisabled = createSelector(
  [isVerify, isPendingVerification],
  (isVerified, isPending) => isVerified || isPending
);

export const selectLoadings = createSelector(
  [
    userUpdateSelectors.loading,
    documentsSelectors.loading,
    documentsSelectors.init,
  ],
  (userLoading, documentLoading, documentInit) => [
    userLoading,
    documentLoading,
    documentInit,
  ]
);
