import { createSlice } from '@reduxjs/toolkit';

import {
  paymentResetError,
  setFrame,
  removeFrame,
  setQR,
  removeQR,
  setTransactionsAmount,
  removeTransactionsAmount,
} from './actions';
import { userPayment } from './thunks';
import { PaymentInitialState } from './types';

const initialState: PaymentInitialState = {
  frame: null,
  loading: false,
  error: '',
  qr: '',
  transactionsAmount: 0,
};

export const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userPayment.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(userPayment.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(userPayment.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(paymentResetError, (state) => {
        state.error = '';
      })

      .addCase(setFrame, (state, { payload }) => {
        state.frame = payload;
      })
      .addCase(removeFrame, (state) => {
        state.frame = null;
      })
      .addCase(setQR, (state, { payload }) => {
        state.qr = payload;
      })
      .addCase(removeQR, (state) => {
        state.qr = '';
      })
      .addCase(setTransactionsAmount, (state, { payload }) => {
        state.transactionsAmount = payload;
      })
      .addCase(removeTransactionsAmount, (state) => {
        state.transactionsAmount = 0;
      });
  },
});
