// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/buttonArrow.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".game-funds-error .error-popup__button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:10px;background-repeat:no-repeat;display:flex;font-weight:400;justify-content:center;margin-top:30px;padding:10px 15px 10px 30px;text-transform:none;width:auto}", "",{"version":3,"sources":["webpack://./../../shared/components/ErrorPopups/GameFundsError/gameFundsError.scss"],"names":[],"mappings":"AAEE,uCAOE,wDAAA,CAEA,wBAAA,CADA,2BAAA,CAJA,YAAA,CAFA,eAAA,CAGA,sBAAA,CAKA,eAAA,CAJA,2BAAA,CAHA,mBAAA,CAFA,UAQJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.game-funds-error {\n  .error-popup__button {\n    width: auto;\n    font-weight: 400;\n    text-transform: none;\n    display: flex;\n    justify-content: center;\n    padding: 10px 15px 10px 30px;\n    background-image: url('./assets/buttonArrow.svg');\n    background-repeat: no-repeat;\n    background-position: 10px center;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
