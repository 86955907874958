import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { emailResend, emailSelectors } from '@box/redux/email';
import { useApp } from '@box/shared/hooks';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { Loader } from '@box/ui/src/Loader';

import styles from './emailVerify.module.scss';

export const EmailVerify = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const isLoading = useAppSelector(emailSelectors.isLoading);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(emailResend());
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        <span>{t('settings.email_not_verified')}</span>.{' '}
        {t('settings.please_verify')}
      </p>
      <button
        disabled={isLoading}
        onClick={handleClick}
        className={classNames(styles.button, {
          [styles.button_mobile]: isMobile,
        })}
        type='button'
      >
        {!isLoading ? t('settings.send_mail_again') : <Loader />}
      </button>
    </div>
  );
};
