import { RootState } from '../../types';

export const isLogged = (state: RootState) => state.auth.init.isLogged;
export const isReady = (state: RootState) => state.auth.init.isReady;
export const isConnected = (state: RootState) => state.auth.init.isConnected;
export const isModalRegisterOpen = (state: RootState) =>
  state.auth.init.isModalRegisterOpen;
export const isPartnerSetTrue = (state: RootState) =>
  state.auth.init.isPartnerSet;
export const isDepositWagered = (state: RootState) =>
  state.auth.init.isDepositWagered;
export const isWageredReady = (state: RootState) =>
  state.auth.init.isWageredReady;
