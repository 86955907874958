import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';

import {
  FetchUserTransactionsPayload,
  FetchUserTransactionsReturn,
  GETTransactions,
} from './types';

export const fetchUserTransactions = createAsyncThunk<
  FetchUserTransactionsReturn,
  FetchUserTransactionsPayload,
  AppThunkApi
>(
  'transactions/fetchTransactions',
  async (payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    const subtypeIn = ['payment', 'rollback'];
    const amount_non_zero = 1;

    const { userId, type, confirmed, page, sortby, sort } = payload;

    try {
      const { data, status } = await api.get<GETTransactions>(
        `/userdatas/${userId}/transactions`,
        {
          params: {
            type,
            confirmed,
            page,
            sortby,
            sort,
            subtypeIn,
            amount_non_zero,
          },
        }
      );

      if (status === 200) {
        return {
          list: data.data,
          currentPage: data.meta.current_page,
          lastPage: data.meta.last_page,
        };
      }

      return rejectWithValue('Transaction error');
    } catch (err) {
      return rejectWithValue('Error');
    }
  }
);
