/* eslint-disable max-len */
import Step1 from './assets/images/1.png';
import Step1Webp from './assets/images/1.webp';
import Step1Avif from './assets/images/1.avif';
import Step2 from './assets/images/2.png';
import Step2Webp from './assets/images/2.webp';
import Step2Avif from './assets/images/2.avif';
import Step3 from './assets/images/3.png';
import Step3Webp from './assets/images/3.webp';
import Step3Avif from './assets/images/3.avif';
import Step4 from './assets/images/4.png';
import Step4Webp from './assets/images/4.webp';
import Step4Avif from './assets/images/4.avif';
import Step5 from './assets/images/5.png';
import Step5Webp from './assets/images/5.webp';
import Step5Avif from './assets/images/5.avif';
import Step6 from './assets/images/6.png';
import Step6Webp from './assets/images/6.webp';
import Step6Avif from './assets/images/6.avif';

export const stepsData = [
  {
    counter: 1,
    title: 'Registration',
    text: 'Make a deposit of at least 100 UAH/10 EUR/10 USD/250 RUB/50 TRY/30 PLN/100 MDL/10 GBP/200 INR/10 CAD/35 BRL***.',
    description:
      '***The deposit amount required is stated in the bonus description (Details).',
    image: Step1,
  },
  {
    counter: 2,
    title: 'Make a deposit',
    text: 'Make a deposit of at least 100 UAH/10 EUR/10 USD/250 RUB/50 TRY/30 PLN/100 MDL/10 GBP/200 INR/10 CAD/35 BRL***.',
    description:
      '***The deposit amount required is stated in the bonus description (Details).',
    image: Step2,
  },
  {
    counter: 3,
    title: 'Wager the bonus and fulfil the requirements',
    text: 'Once you have made a deposit into your betting account, go to the Bonuses section in My Account > Bonuses',
    image: Step3,
  },
  {
    counter: 4,
    title: 'Follow the progress',
    text: 'Next, complete the requirements outlined in the bonus description.',
    image: Step4,
  },
  {
    counter: 5,
    title: 'Receive your bonus',
    text: 'Once you have made a deposit into your betting account, go to the Bonuses section in My Account > Funds, and click “GET A BONUS”.',
    image: Step5,
  },
  {
    counter: 6,
    title: 'Take your winnings',
    text: 'Once you have made a deposit into your betting account, go to the Bonuses section in My Account > Funds, and click “GET A BONUS”.',
    image: Step6,
  },
];
