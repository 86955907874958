import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';

import { getPureErrorMessage } from '../../utils';
import { hideModal, openModal, setModalProps, openInfoPopup } from '../../app';
import { AppThunkApi } from '../../types';

import { ForgotPasswordPayload, ResetPasswordPayload } from './types';

export const resetPassword = createAsyncThunk<
  void,
  ResetPasswordPayload,
  AppThunkApi
>('password/reset', async (payload, { dispatch, extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.post('/password/reset', payload);

    if (status === 200) {
      dispatch(hideModal('recovery-continue'));
      dispatch(
        openInfoPopup({
          text: i18next.t('recovery_continue.success_submit_text'),
          type: 'success',
        })
      );

      return;
    }

    return rejectWithValue(getPureErrorMessage(data));
  } catch (err) {
    return rejectWithValue('Error');
  }
});

export const forgotPassword = createAsyncThunk<
  void,
  ForgotPasswordPayload,
  AppThunkApi
>('password/forgot', async (payload, { dispatch, extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data } = await api.post('/password/forgot', {
      email: payload.email,
    });

    if (!data.errors) {
      const event = {
        message: 'Recovery password',
        tags: {
          page: window.location.pathname,
          email: payload.email,
        },
      };
      Sentry.captureEvent(event);
      dispatch(hideModal('recovery'));
      dispatch(
        setModalProps({
          id: 'info--recovery',
          props: {
            title: i18next.t('recovery.info.title'),
            text: i18next.t('recovery.info.text'),
          },
        })
      );

      dispatch(openModal('info--recovery'));
      return;
    }

    return rejectWithValue(getPureErrorMessage(data));
  } catch (err) {
    return rejectWithValue('Error');
  }
});
