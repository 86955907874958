import { useEffectOnce } from 'react-use';

import { AvailablePositions } from '@box/redux/banners/types';
import { Banner } from '@box/redux/types';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { bannersSelectors, fetchBannersByPosition } from '@box/redux/banners';

export const useBanners = (position: AvailablePositions) => {
  const dispatch = useAppDispatch();

  const banners = useAppSelector((state) =>
    bannersSelectors.getBannerListByPosition(state, position)
  );

  useEffectOnce(() => {
    if (!banners?.init) {
      dispatch(fetchBannersByPosition({ position }));
    }
  });

  return banners || { list: [] as Banner[], loading: true };
};
