import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { BackArrowButton, Button } from '@box/ui';
import {
  paymentMethodsSelectors,
  paymentSelectors,
  removeQR,
  removeTransactionsAmount,
} from '@box/redux/finance';
import { getStoragePath } from '@box/shared/utils';
import { merchantIcons } from '@box/shared/utils/merchants';
import { DetailPopup } from '@box/shared/baseComponents';
import { balanceSelectors } from '@box/redux/user';

import styles from './paymentQR.module.scss';

export const PaymentQR = () => {
  const [isCopied, setCopied] = useState(false);

  const selectedMethod = useAppSelector(paymentMethodsSelectors.selectedMethod);
  const qrCode = useAppSelector(paymentSelectors.qr);
  const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
  const transactionsAmount = useAppSelector(
    paymentSelectors.transactionsAmount
  );

  const getIcon = (item) => {
    if (item?.iframe_logo) {
      return (
        <img alt={item.alias} src={getStoragePath(item.iframe_logo, 'main')} />
      );
    } else if (item?.logo) {
      return <img alt={item.alias} src={getStoragePath(item.logo, 'main')} />;
    }

    const Component = merchantIcons.defaultIcon;
    return <Component />;
  };

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isPaid, setPaid] = useState(false);

  const handlePaid = () => {
    setPaid(true);
  };

  const handleRepeat = () => {
    dispatch(removeTransactionsAmount());
    dispatch(removeQR());
  };

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className={styles.payment}>
      <BackArrowButton
        className={styles.back}
        text={t('deposit.back')}
        onClick={handleRepeat}
      />
      <div className={styles.icon}>{getIcon(selectedMethod)}</div>
      <p className={styles.text}>{t('deposit.payment_is_initialized')}</p>
      <div className={styles.row}>
        <p className={styles.value}>
          {t('deposit.amount_payable')}:{' '}
          <span>
            {transactionsAmount} {currencySymbol}
          </span>
        </p>
      </div>
      <QRCodeSVG className={styles.qr} size={240} value={qrCode} />
      <p className={styles.text}>{t('deposit.do_not_close')}</p>
      <p className={styles.text}>{t('deposit.scan_the_qr_code')}</p>
      <input className={styles.input} value={qrCode} disabled />
      <div className={styles.copy}>
        <CopyToClipboard
          onCopy={handleCopy}
          className={styles.copy}
          text={qrCode}
        >
          <Button>{t('deposit.copy')}</Button>
        </CopyToClipboard>
        <DetailPopup className={styles.popup} isOpen={isCopied}>
          {t('deposit.copied')}
        </DetailPopup>
      </div>
      {!isPaid ? (
        <Button onClick={handlePaid} color='secondary'>
          {t('deposit.paid')}
        </Button>
      ) : (
        <>
          <p className={styles.text}>{t('deposit.payment_processed')}</p>
          <Button onClick={handleRepeat}>{t('deposit.repeat_deposit')}</Button>
        </>
      )}
    </div>
  );
};
