import React, { useEffect, useRef, useState, FC } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';

import { Props } from './Collapsed.types';

export const Collapsed: FC<Props> = React.forwardRef(
  ({ children, className, isOpen, bottomOffset = 0, style }, ref) => {
    const [height, setHeight] = useState(0);
    const [isOverflowHidden, setIsOverflowHidden] = useState(isOpen);
    const innerRef = useRef();

    useEffect(() => {
      let observer = null;

      if (!observer) {
        observer = new ResizeObserver((entries) => {
          setHeight(entries[0].contentRect.height + bottomOffset);
        });

        observer.observe(innerRef.current);
      }

      return () => {
        observer?.disconnect();
      };
    }, []);

    useEffect(() => {
      let timeout;

      if (!isOpen) {
        setIsOverflowHidden(true);
      } else {
        timeout = setTimeout(() => setIsOverflowHidden(false), 300);
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [isOverflowHidden, isOpen]);

    return (
      <div
        className={classNames('collapsed', className)}
        style={{
          height: isOpen ? height : 0,
          transition: 'height 0.3s ease',
          overflow: isOverflowHidden ? 'hidden' : 'unset',
          width: '100%',
          ...style,
        }}
        ref={ref}
      >
        <div className='collapsed__inner' ref={innerRef}>
          {children}
        </div>
      </div>
    );
  }
);

Collapsed.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  bottomOffset: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Collapsed.defaultProps = {
  className: '',
  isOpen: false,
  bottomOffset: 0,
  style: {},
};
