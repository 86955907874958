import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import styles from './Dropdown.module.scss';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { DropdownMenuProps, DropdownProps } from './Dropdown.types';

const DropdownMenu = ({
  className,
  options,
  onSelectItem,
  activeItem,
}: DropdownMenuProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.menu, className)}>
      {options?.length ? (
        <ul className={styles.menuList}>
          {options.map((item, index) => (
            <li
              className={classNames(styles.menuItem, {
                [styles.menuItem_active]: activeItem?.value === item.value,
              })}
              key={index}
              onClick={() => onSelectItem(item)}
              aria-hidden='true'
            >
              {item.label}
            </li>
          ))}
        </ul>
      ) : (
        <div className={styles.menuNoValue}>{t('modal_reg.no_values')}</div>
      )}
    </div>
  );
};

const Dropdown = ({
  className,
  menuClassName,
  onChange,
  defaultValue = null,
  options,
  outlined = false,
  openTop = false,
  placeholder,
  label,
  disabled,
}: DropdownProps) => {
  const rootRef = useRef();
  const [data, setData] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);

  const classes = classNames(styles.dropdown, className, {
    [styles.dropdown_outlined]: outlined,
    [styles.dropdown_open]: isOpen,
    [styles.dropdown_openTop]: openTop,
  });

  const toggleOpen = () => !disabled && setIsOpen((prev) => !prev);

  const handleChange = (newValue) => {
    setData(newValue);
    setIsOpen(false);
    if (onChange) onChange(newValue);
  };

  const closeIfClickOutside = useCallback(
    (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(rootRef.current)) {
        setIsOpen(false);
      }
    },
    [rootRef]
  );

  useEffect(() => {
    window.addEventListener('click', closeIfClickOutside);
    return () => window.removeEventListener('click', closeIfClickOutside);
  }, []);

  return (
    <div className={classes} ref={rootRef}>
      {!!label && (
        <label className={styles.label} htmlFor='dropdown'>
          {label}
        </label>
      )}
      <div className={styles.content} onClick={toggleOpen} aria-hidden='true'>
        <div className={styles.value}>
          {data?.label || defaultValue?.label || placeholder}
        </div>
        <ArrowIcon />
      </div>
      <DropdownMenu
        className={menuClassName}
        onSelectItem={handleChange}
        options={options}
        activeItem={data}
      />
    </div>
  );
};

export default Dropdown;
