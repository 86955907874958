import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { hideModal } from '@box/redux/app';
import { userSelectors } from '@box/redux/user';
import { useApp } from '@box/shared/hooks';
import { LightButton } from '@box/ui';
import { logout } from '@box/redux/authentication';
import * as Sentry from '@sentry/react';

import { Modal } from '@box/shared/components/Modal';
import styles from './logout.module.scss';

export const JuneLogoutModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    route,
    userEnv: { isDesktop },
  } = useApp();
  const user = useAppSelector(userSelectors.user);

  const handleLogout = () => {
    const event = {
      message: 'Logout - yes',
      tags: {
        page: window.location.pathname,
        id: user.id,
        email: user?.email,
      },
    };
    Sentry.captureEvent(event);
    dispatch(logout());
    dispatch(hideModal('logout'));
    navigate(route('main'));
  };

  const handleStay = () => {
    // const event = {
    //   message: 'Logout - no',
    //   tags: {
    //     page: window.location.pathname,
    //     id: user.id,
    //     email: user?.email,
    //   },
    // };
    // Sentry.captureEvent(event);
    dispatch(hideModal('logout'));
    navigate(route('main'));
  };

  return (
    <Modal
      headColor='yellow'
      id='logout'
      size={isDesktop ? 'md' : 'smdoubleplus'}
      title={`${t('exit_modal.title1')} <br /> ${t('exit_modal.title2')}`}
      className={classNames(styles.logout, {
        [styles.logout_desktop]: isDesktop,
      })}
      headWithSvg
      heightAuto={isDesktop}
    >
      <div className={styles.body}>
        <LightButton className={styles.button} onClick={handleLogout}>
          {t('exit_modal.button1')}
        </LightButton>
        <LightButton
          className={styles.button}
          onClick={handleStay}
          color='green'
        >
          {t('exit_modal.button2')}
        </LightButton>
      </div>
    </Modal>
  );
};
