import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppSelector } from '@box/redux/hooks';
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'js-cookie';

export const useClearPartnerUrl = () => {
  const isPartnerSetTrue = useAppSelector(userInitSelectors.isPartnerSetTrue);
  const [params, setParams] = useSearchParams();

  let search = window.location.search.substring(1);
  let paramsValue = false;
  let subs = {};

  if (search) {
    paramsValue = JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  }

  forIn(paramsValue, (value, key) => {
    if (key.match(/^sub\d+/gm)) {
      if (!isEmpty(value)) {
        subs[key] = value;
        delete paramsValue[key];
      } else {
        subs[key] = null;
        delete paramsValue[key];
      }
    }
  });

  const removeKeysStartingWithSub = (obj) => {
    for (let key in obj) {
      if (key.startsWith('sub')) {
        delete obj[key];
      }
    }
  };

  const subParams = Object.keys(subs)
    .filter((param) => param.startsWith('sub'))
    .map((param) => params.get(param));

  useEffect(() => {
    removeKeysStartingWithSub(paramsValue);
    if (Object.keys(paramsValue).length > 0 || Object.keys(subs).length > 0) {
      localStorage.setItem('Origin-Referer', window.location.href);
    }
  }, [paramsValue, subs]);

  useEffect(() => {
    if (params.get('visit') === 'false') {
      Cookies.set(
        'first_visit_params',
        JSON.stringify({
          webmaster: params.get('webmaster'),
          subs: {
            sub1: params.get('sub1'),
            sub2: params.get('sub2'),
            sub3: params.get('sub3'),
            sub4: params.get('sub4'),
            sub5: params.get('sub5'),
          },
          stream: params.get('stream'),
          click_id: params.get('click_id'),
          partner_stream: params.get('partner_stream'),
          partner_click_id: params.get('partner_click_id'),
        }),
        {
          expires: 365,
        }
      );
    }

    if (isPartnerSetTrue) {
      params.delete('webmaster');
      params.delete('subs');
      params.delete('sub1');
      params.delete('sub2');
      params.delete('sub3');
      params.delete('sub4');
      params.delete('sub5');
      params.delete('stream');
      params.delete('click_id');
      params.delete('partner_stream');
      params.delete('partner_click_id');
      params.delete('repeat_visit');
      params.delete('visit');
      subParams.forEach((item, index) => {
        params.delete(`sub${index + 1}`);
      });
      setParams(params);
    }
  }, [isPartnerSetTrue]);
};
