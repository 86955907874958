export const faqsSelectList = [
  {
    value: 'pay_tm',
    label: 'PAY TM',
  },
  {
    value: 'upi',
    label: 'Upi',
  },
  {
    value: 'phonepe',
    label: 'PhonePe',
  },
  {
    value: 'netbanking',
    label: 'Netbanking',
  },
  {
    value: 'visa_mastercard',
    label: 'Visa/MC',
  },
];
