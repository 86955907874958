export const isShowHeaderBalance = () =>
  JSON.parse(localStorage.getItem('header_balance')) === 1;

export const toggleHeaderBalance = () => {
  const mode = isShowHeaderBalance() ? '0' : '1';
  localStorage.setItem('header_balance', mode);
};

export const checkBalanceVisible = () => {
  if (JSON.parse(localStorage.getItem('header_balance')) === null) {
    localStorage.setItem('header_balance', 1);

    return true;
  }

  return isShowHeaderBalance();
};
