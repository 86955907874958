import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './darkDecoratedButton.scss';

export const DarkDecoratedButton = ({ children, onClick, className }) => {
  return (
    <button
      className={classNames('dark-decorated-button', className)}
      type='button'
      onClick={onClick}
    >
      {children}
    </button>
  );
};

DarkDecoratedButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

DarkDecoratedButton.defaultProps = {
  onClick: () => null,
  className: '',
};
