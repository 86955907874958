import React from 'react';

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

export const ProgressBar = ({ percent }: { percent: string | number }) => {
  let percentage = Number(percent);
  return (
    <div
      className={classNames(styles.container, {
        [styles.container_success]: percent === 100,
      })}
    >
      {percent !== 100 && (
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({
            pathColor: '#0A893D',
            trailColor: ' #E6EFE6',
            strokeLinecap: 'butt',
          })}
        >
          <div className={styles.content}>
            {Math.floor(percentage > 100 ? 100 : percentage)}%
          </div>
        </CircularProgressbarWithChildren>
      )}
    </div>
  );
};
