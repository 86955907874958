import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Props } from './Status.types';

import styles from './status.module.scss';
import { ReactComponent as ActivatedSvg } from './assets/images/activated.svg';
import { ReactComponent as CreatedSvg } from './assets/images/created.svg';
import { ReactComponent as EndedSvg } from './assets/images/ended.svg';

export const Status: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const textValue = useMemo(() => {
    switch (status) {
      case 'activated':
        return t('bonuses.active');
      case 'created':
        return t('bonuses.not_started');
      default:
        return t('bonuses.completed');
    }
  }, [status]);

  return (
    <div className={styles.status}>
      {status === 'activated' && <ActivatedSvg />}
      {status === 'ended' && <EndedSvg />}
      {status === 'created' && <CreatedSvg />}
      <p className={classNames(styles.text, styles[`text_color_${status}`])}>
        {t('bonuses.status')} <span>{textValue}</span>
      </p>
    </div>
  );
};
