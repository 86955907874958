import { useApp, useBreakPoints } from '@box/shared/hooks';

const perRowBreakpoints = {
  1626: 7,
  1625: 6,
  1450: 5,
  1250: 4,
};

const junePerRowBreakpoints = {
  1920: 8,
  1680: 7,
  1440: 6,
};

const perPageBreakpoints = {
  1626: perRowBreakpoints[1626] * 8,
  1625: perRowBreakpoints[1625] * 8,
  1450: perRowBreakpoints[1450] * 8,
  1250: perRowBreakpoints[1250] * 8,
};

export const useCasinoGamesCount = (): {
  perRow: number;
  perPage: number;
} => {
  const {
    userEnv: { isJune },
  } = useApp();

  const perRow = useBreakPoints(
    isJune ? junePerRowBreakpoints : perRowBreakpoints
  );
  const perPage = useBreakPoints(perPageBreakpoints);

  return {
    perRow,
    perPage,
  };
};
