import React, { useEffect } from 'react';

import { DesktopApp } from './desktop';
import { MobileApp } from './mobile';
import { LayoutApp } from './layout';
import { isMobile, checkMobile, isLayout } from './userEnvironment';

export const App = () => {
  useEffect(() => {
    checkMobile();
  }, []);

  if (isLayout) {
    return <LayoutApp />;
  }

  if (isMobile) {
    return <MobileApp />;
  }

  return <DesktopApp />;
};
