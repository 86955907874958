import React, { FC, useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import camelCase from 'lodash/camelCase';
import isRegExp from 'lodash/isRegExp';

import { Field } from '@box/redux';

import { useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import { DecemberMaskedInput, DecemberInput } from '@box/ui';
import { disabledCountryCodes } from '../Modals/TopUp/constants';
import { FormSelect } from '../../baseComponents';

import styles from './FlexibleFields.module.scss';
import { Option } from './FlexibleFields.types';

export const FlexibleFields: FC<{
  className?: string;
  fields: Field[];
  custom_phone?: boolean;
}> = ({ className, fields }) => {
  const { t } = useTranslation();

  const isFirstRender = useRef(true);

  const { register, control, formState, setValue, unregister } =
    useFormContext();

  const { errors } = formState;

  // TODO: не грамотно сделал
  const clientData = useAppSelector(commonSelectors.clientData);
  const isShow = disabledCountryCodes.includes(clientData?.country?.code);

  const getCustomValidate = (rules, mask) => (v) => {
    if (rules.email) {
      if (!v.match(/^\S+@\S+$/)) return t('fields.payment_rules.email_format');
    }

    if (mask) {
      if (v.includes(' ')) return t('fields.invalid_field');
    }

    return true;
  };

  const getRulesObj = (rules) =>
    Object.keys(rules).reduce((acc, item) => {
      const key = camelCase(item);
      let value = rules[item];

      if (key === 'pattern' && value) {
        const regexp = new RegExp(value.substr(1).slice(0, -1));
        value = isRegExp(regexp) ? regexp : null;
      }

      return value
        ? {
            ...acc,
            [key]: {
              value,
              message: t(`fields.payment_rules.${key}`, {
                extra: value,
              }),
            },
          }
        : acc;
    }, {});

  const fieldsArr = useMemo(
    () =>
      fields.map((item) => {
        if (item.type === 'select') {
          const options: Option[] = Object.keys(item.options).map((key) => ({
            value: key,
            label: item.options[key],
          }));

          const onChange = (v, field) => field.onChange(v.value);

          return {
            ...item,
            options,
            onChange,
            label: item.label,
            rules: getRulesObj(item.rules),
            validate: getCustomValidate(item.rules, item.mask),
          };
        }

        return item;
      }),
    [fields]
  );

  useEffect(() => {
    // ===== Условие необходимо, чтобы сбросить поле с суммой при переходе между платежками
    // ===== Важно: Хранить setValue вне условия, во избежание проблем, когда платежка одна
    if (!isFirstRender.current) {
      unregister();
    } else {
      isFirstRender.current = false;
    }
    // =====
    fields?.forEach((field) => {
      if (field.hidden) {
        setValue(field.name, field.default_value);
      }
    });
  }, [fields]);

  const fieldsFixed = useMemo(() => {
    const arrayOfSingles = [];
    const arrayOfRows = [];
    fieldsArr.forEach((item) => {
      if (item?.inline) {
        arrayOfRows.push(item);
      } else {
        arrayOfSingles.push(item);
      }
    });
    const array_size = 2;

    const sliced_array = [];

    for (let i = 0; i < arrayOfRows.length; i += array_size) {
      sliced_array.push(arrayOfRows.slice(i, i + array_size));
    }
    return { singles: arrayOfSingles, rows: sliced_array };
  }, [fieldsArr]);

  return (
    <div className={classNames(styles.fields, className)}>
      {fieldsFixed.singles.map((item, idx) =>
        item.type === 'select' ? (
          <FormSelect
            className={classNames(styles.field, {
              [styles.hiddenField]: item.hidden,
            })}
            control={control}
            defaultValue={item.default_value}
            error={errors[item.name]}
            errorMessage={errors[item.name]?.message}
            key={`${item.name}-${idx}`}
            name={item.name}
            label={item.label}
            placeholder={item.placeholder}
            options={item.options}
            {...item}
          />
        ) : item.mask ? (
          <DecemberMaskedInput
            className={classNames(styles.field, {
              [styles.hiddenField]: item.hidden,
            })}
            label={item.label}
            error={t(errors[item.name]?.message as string)}
            defaultValue={item.default_value}
            placeholder={item.placeholder}
            {...register(item.name, {
              ...getRulesObj(item.rules),
              validate: getCustomValidate(item.rules, item.mask),
            })}
            type={item.type}
            mask={item.mask && item.format ? '_' : ''}
            format={
              isShow && item.name === 'customer_phone'
                ? `+91 ${item.mask}`
                : item.mask
            } // необходимо сделать проверку на индию
            key={`${item.name}-${idx}`}
          />
        ) : (
          <DecemberInput
            className={classNames(styles.field, {
              [styles.hiddenField]: item.hidden,
            })}
            label={item.label}
            error={t(errors[item.name]?.message as string)}
            placeholder={item.placeholder}
            {...register(item.name, {
              ...getRulesObj(item.rules),
              validate: getCustomValidate(item.rules, item.mask),
            })}
            type={item.type}
            key={`${item.name}-${idx}`}
          />
        )
      )}
      {fieldsFixed.rows.map((i, idx) => {
        return (
          <div key={idx} className={styles.row}>
            {i.map((item, index) => {
              return item.mask ? (
                <DecemberMaskedInput
                  className={styles.halfField}
                  label={item.label}
                  error={t(errors[item.name]?.message as string)}
                  defaultValue={item.default_value}
                  placeholder={item.placeholder}
                  {...register(item.name, {
                    ...getRulesObj(item.rules),
                    validate: getCustomValidate(item.rules, item.mask),
                  })}
                  type={item.type}
                  mask={item.mask && item.format ? '_' : ''}
                  format={
                    isShow && item.name === 'customer_phone'
                      ? `+91 ${item.mask}`
                      : item.mask
                  } // необходимо сделать проверку на индию
                  key={`${item.name}-${index}`}
                />
              ) : (
                <DecemberInput
                  className={styles.halfField}
                  label={item.label}
                  error={t(errors[item.name]?.message as string)}
                  placeholder={item.placeholder}
                  {...register(item.name, {
                    ...getRulesObj(item.rules),
                    validate: getCustomValidate(item.rules, item.mask),
                  })}
                  type={item.type}
                  key={`${item.name}-${index}`}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
