import React, { FC } from 'react';
import classNames from 'classnames';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useApp, useLockScroll } from '@box/shared/hooks';

import { ChatForm } from '../../ChatForm';
import { SupportChatProps } from '../SupportChat.types';

import { MessageItem } from './MessageItem';

import styles from './lightSupportChat.module.scss';

import { ReactComponent as CrossSvg } from './assets/images/cross.svg';
import { ReactComponent as IconSvg } from './assets/images/icon.svg';

export const LightSupportChat: FC<SupportChatProps> = ({
  messages,
  onSendMessage,
  close,
  sendLoading,
  isOpen,
}) => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  useLockScroll(isDesktop ? false : isOpen);
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapper_open]: isOpen,
        [styles.wrapper_desktop]: isDesktop,
      })}
    >
      <div
        className={classNames(styles.container, {
          [styles.container_desktop]: isDesktop,
        })}
      >
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.avatar}>
              <div className={styles.box}>
                <IconSvg />
              </div>
            </div>
            <p className={styles.name}>Smart Assistant</p>
          </div>
          <button onClick={close} type='button' className={styles.close}>
            <CrossSvg />
          </button>
        </div>
        <div className={styles.main}>
          <BlockWithLoading loading={false}>
            <div className={styles.messages}>
              {[...messages].reverse().map((item, idx) => (
                <MessageItem buttons={item.buttons} key={idx} {...item} />
              ))}
            </div>
          </BlockWithLoading>
        </div>
        <div className={styles.footer}>
          <ChatForm
            isLight
            loading={sendLoading}
            sendMessage={onSendMessage}
            disabled={sendLoading}
          />
        </div>
      </div>
    </div>
  );
};
