import { createReducer } from '@reduxjs/toolkit';

import { closeConfirm, thunkWithConfirm } from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  isOpen: false,
  text: '',
  extraText: '',
  title: '',
  callback: null,
  onUnConfirm: null,
  yes: '',
  no: '',
  onlyYes: false,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(closeConfirm, () => initialState)
    .addCase(thunkWithConfirm, (_state, { payload }) => ({
      isOpen: true,
      ...payload,
    }));
});
