import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { userInitSelectors } from '@box/redux/authentication';
import { Input, LightButton } from '@box/ui';
import {
  checkUserPromocode,
  promocodesSelectors,
} from '@box/redux/user/promocodes';
import { resetPromocodeStatus } from '@box/redux/user/promocodes/action';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';

import { useApp } from '@box/shared/hooks';
import styles from './promocode.module.scss';
import { Inputs, Props } from './Promocode.types';

import { ReactComponent as CancelIcon } from './assets/images/cancel.svg';

export const Promocode: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  const dispatch = useAppDispatch();
  const loading = useAppSelector(promocodesSelectors.checkLoading);
  const error = useAppSelector(promocodesSelectors.error);
  const checked = useAppSelector(promocodesSelectors.checked);
  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const { reset, register, handleSubmit, watch } = useForm<Inputs>();

  const watchCode = watch('code');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(checkUserPromocode(data));
  };

  const handleResetForm = () => {
    dispatch(resetPromocodeStatus());
    reset({ code: '' });
  };

  useEffect(() => {
    if (error) {
      dispatch(resetPromocodeStatus());
      reset({ code: '' });
    }
  }, [watchCode]);

  const handleClick = () => {
    if (!isLogged) {
      dispatch(openModal('register'));
    }
  };

  return (
    <div
      className={classNames(
        styles.promocode,
        {
          [styles.promocode_december]: !isJune,
          [styles.promocode_mobile]: isMobile,
        },
        className
      )}
    >
      <div className={styles.text}>{t('promocodes.title')}</div>
      <div className={styles.description}>{t('promocodes.description')}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={classNames(styles.input_container, {
            [styles.input_container_error]: error,
            [styles.input_container_success]: checked,
          })}
        >
          <Input
            className={classNames(styles.input, {
              [styles.input_error]: error,
              [styles.input_success]: checked,
            })}
            placeholder={t('promocodes.name')}
            id='code'
            color='transparent'
            {...register('code', { maxLength: 30 })}
          />
          <div className={styles.error}>
            {error && (
              <span className={styles.error_text}>
                {error} <CancelIcon onClick={handleResetForm} />
              </span>
            )}
            {checked && (
              <span className={styles.success_text}>
                {t('promocodes.success')}
              </span>
            )}
          </div>
        </div>
        <LightButton
          disabled={!!error || loading}
          className={styles.button}
          type={isLogged ? 'submit' : 'button'}
          borderRadius={!isJune ? 'small' : 'medium'}
          color={!isJune ? 'light-green' : 'yellow'}
          withBorder={isJune}
          onClick={handleClick}
          fixHeight
        >
          <BlockWithLoading color='white' loading={loading}>
            {t('promocodes.button')}
          </BlockWithLoading>
        </LightButton>
      </form>
    </div>
  );
};
