import React, { useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { FrameLoading } from '@box/shared/components';
import { useApp, useOpenGames } from '@box/shared/hooks';
import { useAppSelector } from '@box/redux/hooks';
import { currentGameSelectors } from '@box/redux/games';
import { userInitSelectors } from '@box/redux/authentication';

import styles from './aviatorSingle.module.scss';

export const AviatorSingle: FC = () => {
  const technology = useAppSelector(currentGameSelectors.technology);
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const navigate = useNavigate();

  const {
    mirrors,
    userEnv: { isJune, isMobile },
  } = useApp();

  const { isMirror1 } = mirrors;

  const { openGame } = useOpenGames(window.location.href);

  const { height } = useWindowSize();

  const ratio =
    ((technology?.height || 1 + 1) / (technology?.width || 1)) * 100;

  const style = {
    height: !technology?.height ? '100%' : '0',
    paddingBottom: `${ratio.toFixed(3)}%`,
  };

  const mobileStyle = {
    height: `calc(${height}px - ${isJune ? 66 : 57}px)`,
  };

  useEffect(() => {
    if (!technology) {
      openGame({ id: 'aviator', hasDemo: true, withRedirect: false });
    }
  }, [technology]);

  useEffect(() => {
    if (isLogged || !isMirror1) {
      navigate('/casino/current/aviator');
    }
  }, [isLogged]);

  return (
    <div
      style={isMobile ? mobileStyle : style}
      className={classNames(styles.game, { [styles.game_mobile]: isMobile })}
    >
      <FrameLoading requestLoading={!technology}>
        <iframe
          allowFullScreen
          src={isMobile ? technology?.href : technology?.src || ''}
          height={technology?.height}
          width={technology?.width}
          title='game'
        />
      </FrameLoading>
    </div>
  );
};
