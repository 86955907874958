import React from 'react';
import { useTranslation } from 'react-i18next';
import { LightButton, Text } from '@box/ui';

import { Modal } from '../../../baseComponents';
import { useConfirmModal } from './ConfirmModal.hook';

import styles from './confirmModal.module.scss';

export const ConfirmModalJune = () => {
  const { t } = useTranslation();
  const { onClose, onConfirm, additional } = useConfirmModal();

  const { isOpen, title, text, extraText, yes, no, onlyYes } = additional;

  return (
    <Modal
      className={styles.modal}
      close={onClose}
      title={title}
      isOpen={isOpen}
      bodyClassName={styles.modal__body}
    >
      <Text
        className={styles.modal__text}
        color='violet'
        size='md'
        align='center'
        as='p'
      >
        {text}
      </Text>
      {extraText && (
        <Text
          className={styles.modal__text}
          color='violet'
          size='md'
          align='center'
          as='p'
        >
          {extraText}
        </Text>
      )}
      <div className={styles.modal__buttons}>
        <LightButton
          className={styles['modal__button-june']}
          onClick={onConfirm}
          color='green'
        >
          {yes || t('common.yes')}
        </LightButton>
        {!onlyYes && (
          <LightButton
            className={styles['modal__button-june']}
            onClick={onClose}
            color='yellow'
          >
            {no || t('common.no')}
          </LightButton>
        )}
      </div>
    </Modal>
  );
};
