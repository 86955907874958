import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { LightTabs } from '@box/shared/components';
import { Collapsed } from '@box/shared/baseComponents';

import styles from './header.module.scss';
import { HeaderProps } from './header.types';

export const Header: FC<HeaderProps> = ({ tabsComponents, activeBonus }) => {
  const { t } = useTranslation();

  const tabs = [t('bonuses.all'), t('bonuses.history')];

  return (
    <div className={styles.header}>
      <Collapsed isOpen={!activeBonus}>
        <LightTabs
          className={styles.control}
          tabs={tabs}
          tabsComponents={tabsComponents}
        />
      </Collapsed>
    </div>
  );
};
