import { appConfig } from '../config';

export const socialLinks = {
  telegram: appConfig.telegram,
  facebook: appConfig.facebook,
  instagram: appConfig.instagram,

  telegramUZ: appConfig.telegramUZ,
  telegramBR: appConfig.telegramBR,
  telegramEN: appConfig.telegramEN,
  telegramIN: appConfig.telegramIN,
};

export const juneSocialLinks = {
  telegram: appConfig.telegram,
  twitter: appConfig.twitter,
  instagram: appConfig.instagram,
  facebook: appConfig.facebook,
};
