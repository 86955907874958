import { captureException } from '@sentry/minimal';

import { api } from '../../api';

export const paymentIntension = ({ userId, paymentPayload }) => {
  const {
    amount,
    currencyCode,
    returnUrl,
    paymentMethodId,
    merchantId,
    fields,
  } = paymentPayload;

  try {
    api.post(`/userdatas/${userId}/payment-intention`, {
      amount,
      currency: currencyCode,
      type: 'payment',
      meta: {
        return_url: returnUrl || window.location.href,
        payment_method_id: paymentMethodId,
        merchant_id: merchantId,
        payload: { ...fields },
      },
    });
  } catch (e) {
    captureException('paymentIntension', e);
  }
};
