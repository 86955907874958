import { UserRates } from '@box/redux';
import { useMemo } from 'react';

type UseGetEarn = (rates: UserRates) => { earn: string };

export const useGetEarn: UseGetEarn = (rate) => {
  const earn = useMemo(() => {
    if (rate) {
      return `${rate?.rate || ''} ${rate?.currency || ''}`;
    }
    return '';
  }, [rate]);

  return { earn };
};
