import { api } from '../api';
import { appConfig } from '../config';

export const preparePhone = (v) => {
  let phone = v.replace('(', '');
  phone = phone.replace(')', '');
  phone = phone.replaceAll('-', '');
  phone = phone.replace('+', '');

  return phone.trim().split(' ').join('').split('-').join('');
};

export const getPhoneWithoutCode = (code, phone) => phone?.replace(code, '');

export const getCurrentWallet = (currencyName, wallets) =>
  wallets.find((item) => item.name === currencyName) || {};

export const withStr =
  'wallets,currency,language,permissions,roles.permissions,depositBonus';

export const isFileImage = (file) => file?.type.split('/')[0] === 'image';

export const prepareUsername = (username) => {
  if (username.includes('@')) return username;

  return `${preparePhone(username)}`;
};

export const uploadFile = async (file, userId) => {
  const formData = new FormData();
  formData.append('file', file);

  const config = {
    baseURL: appConfig.crmApi,
  };

  try {
    const { data, status } = await api.post(
      `/v1/users/${userId}/upload`,
      formData,
      config
    );

    if (status === 200) {
      return data;
    }

    const errorMessage = data?.errors?.file?.[0] || 'Unexpected error';

    return {
      error: true,
      message: errorMessage,
    };
  } catch (e) {
    return null;
  }
};
