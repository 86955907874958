import { createAsyncThunk } from '@reduxjs/toolkit';
import { setChatReady } from '@box/redux/support';
import Cookies from 'js-cookie';

import { getPureErrorMessage } from '../../utils';
import { hideModal, langSelectors, openModal } from '../../app';
import { AppThunkApi } from '../../types';
import { getFirstVisitParams } from '../../utils/stats';
import { useAuthTokens } from '../../hooks';
import { authorize, connectExternal, setFirstVisitFetch } from '../init';
import { fetchLogin, setIsButtonDisabled } from '../login';

import { POSTUserDatas, UserRegistrationPayload } from './types';

export const userRegistration = createAsyncThunk<
  void,
  UserRegistrationPayload,
  AppThunkApi
>(
  'auth/register/userRegistration',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api, user, isJune } = extra;

    const { isAutoAuth } = payload;

    const lang = langSelectors.current(getState());
    const stats = getFirstVisitParams();
    const { crmUuid } = useAuthTokens();

    delete payload.isAutoAuth;

    try {
      const { data, status } = await api.post<POSTUserDatas>('/userdatas', {
        ...payload,
        auth: 1,
        with: user.requestExtra,
        language_id: lang.id,
        crm_params: { uuid: crmUuid },
        partner_params: stats,
        ...(isAutoAuth && { auto: 1 }),
      });

      if (status === 201) {
        dispatch(setChatReady(false));
        dispatch(connectExternal(data.access_token));
        dispatch(authorize({ data, isRegisterRequest: true }));
        dispatch(hideModal('register'));
        dispatch(setIsButtonDisabled(false));

        // Если это авторега, то сетим метку в куки и закрываем модалку логина
        if (isAutoAuth) {
          dispatch(setIsButtonDisabled(false));
          dispatch(hideModal('login'));
          Cookies.set('isAutoAuth', '1', { expires: 365 });
        }

        if (!isJune) {
          dispatch(openModal('deposit'));
        }

        dispatch(setFirstVisitFetch());

        return;
      }

      if (payload?.email && payload?.password) {
        dispatch(
          fetchLogin({
            username: payload.email,
            password: payload.password,
            isFromRegister: true,
          })
        );
      }

      const error = getPureErrorMessage(data.errors);
      dispatch(setIsButtonDisabled(false));
      return rejectWithValue(error);
    } catch (err) {
      return rejectWithValue('Error');
    }
  }
);
