import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Text } from '@box/ui';

import { Props } from './VerifyStatus.types';

import styles from './verifyStatus.module.scss';

export const VerifyStatus: FC<Props> = ({
  isVerify,
  isVerifyNotStarted,
  isVerifyDenied,
  isVerifyPending,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const getVerifyStatusText = () => {
    if (isVerifyNotStarted) return t('settings.verify_status_not_started');
    if (isVerify) return t('settings.verify_status_verified');
    if (isVerifyDenied) return t('settings.verify_status_denied');
    if (isVerifyPending) return t('settings.verify_status_pending');

    return '';
  };

  const getVerifyStatus = () => {
    if (isVerifyNotStarted) {
      return t('settings.not_verified');
    }
    if (isVerify) {
      return t('settings.verified');
    }
    if (isVerifyDenied) {
      return t('settings.denied');
    }
    if (isVerifyPending) {
      return t('settings.pending');
    }

    return '';
  };

  const getVerifyStatusColor = () => {
    if (isVerifyNotStarted) {
      return 'red';
    }
    if (isVerify) {
      return 'green';
    }
    if (isVerifyDenied) {
      return 'red';
    }
    if (isVerifyPending) {
      return 'yellow';
    }

    return 'red';
  };
  return (
    <div
      className={classNames(styles.box, {
        [styles.box_desktop]: isDesktop,
      })}
    >
      <div
        className={classNames(
          styles.status,
          styles.status_color,
          styles[`status_color_${getVerifyStatusColor()}`]
        )}
      >
        <Text
          as='div'
          size={isDesktop ? 'xxl' : 'lg'}
          color='violet'
          weight='800'
        >
          {t('personal_data.status')}:
        </Text>
        <Text weight={700} as='span' size='xs'>
          {getVerifyStatus()}
        </Text>
      </div>
      <p className={styles.subtitle}>{getVerifyStatusText()}</p>
    </div>
  );
};
