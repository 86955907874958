import { createSelector } from '@reduxjs/toolkit';

import { isLogged } from '../../authentication/init/selectors';
import { RootState, UserRolesNames } from '../../types';

export const user = (state: RootState) => state.user.info;
export const id = (state: RootState) => state.user.info.id;
export const currency = (state: RootState) =>
  state.user.info.currency || ({} as any);
export const roles = (state: RootState) => user(state).roles;
export const isDemoGames = (state: RootState) => !isLogged(state);

// Verify
export const isVerify = (state: RootState) =>
  !!user(state).roles?.find((item) => item.name === UserRolesNames.verified);
export const isPendingVerification = (state: RootState) =>
  !!user(state).roles?.find(
    (item) => item.name === UserRolesNames.pending_verification
  );
export const isVerifyDenied = (state: RootState) =>
  !!user(state).roles?.find(
    (item) => item.name === UserRolesNames.verification_denied
  );
export const isVerifyNotStarted = createSelector(
  [isVerify, isPendingVerification, isVerifyDenied],
  (verify, pending, denied) => !verify && !pending && !denied
);
export const isEmailVerified = (state: RootState) =>
  !!user(state).roles?.find(
    (item) => item.name === UserRolesNames.email_verified
  );
export const isWithdrawal = (state: RootState) =>
  !!user(state).permissionsArray.includes('manage-withdrawal-requests');
// End verify
