import { combineReducers } from 'redux';

import transactionsReducer from './transactions';
import paymentReducer from './payment';
import walletsReducer from './wallets';
import withdrawalRequestsReducer from './withdrawalRequests';
import withdrawMerchantsReducer from './withdrawalMerchants';
import paymentMethodsReducer from './paymentMethods';

const reducer = combineReducers({
  transactions: transactionsReducer,
  payment: paymentReducer,
  wallets: walletsReducer,
  withdrawalRequests: withdrawalRequestsReducer,
  withdrawalMerchants: withdrawMerchantsReducer,
  paymentMethods: paymentMethodsReducer,
});

export * from './transactions';
export * from './payment';
export * from './wallets';
export * from './withdrawalRequests';
export * from './withdrawalMerchants';
export * from './paymentMethods';

export default reducer;
