import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import Cookies from 'js-cookie';

const keys = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  EXPIRES: 'token_expires_in',
  CRM_TOKEN: 'crm_token',
  CRM_REFRESH_TOKEN: 'crm_refresh_token',
  CRM_UUID: 'uuid',
};

const EXPIRES = { expires: 365 };

export const useAuthCookies = () => {
  const getAccessToken = () => Cookies.get(keys.ACCESS_TOKEN);
  const getRefreshToken = () => Cookies.get(keys.REFRESH_TOKEN);
  const getCrmAccess = () => Cookies.get(keys.CRM_TOKEN);
  const getCrmRefreshToken = () => Cookies.get(keys.CRM_REFRESH_TOKEN);
  const getCrmUuid = () => Cookies.get(keys.CRM_UUID);
  const getExpiresIn = () => Cookies.get(keys.EXPIRES);

  const setAccessToken = (value: string) =>
    Cookies.set(keys.ACCESS_TOKEN, value, EXPIRES);
  const setRefreshToken = (value: string) =>
    Cookies.set(keys.REFRESH_TOKEN, value, EXPIRES);
  const setCrmAccessToken = (value: string) =>
    Cookies.set(keys.CRM_TOKEN, value, EXPIRES);
  const setCrmRefreshToken = (value: string) =>
    Cookies.set(keys.CRM_REFRESH_TOKEN, value, EXPIRES);
  const setCrmUuid = (value: string) =>
    Cookies.set(keys.CRM_UUID, value, EXPIRES);

  const deleteAccessToken = () => Cookies.remove(keys.ACCESS_TOKEN);
  const deleteRefreshToken = () => Cookies.remove(keys.REFRESH_TOKEN);
  const deleteCrmAccessToken = () => Cookies.remove(keys.CRM_TOKEN);
  const deleteCrmUuid = () => Cookies.remove(keys.CRM_UUID);
  const deleteCrmRefreshToken = () => Cookies.remove(keys.CRM_REFRESH_TOKEN);

  const deleteAllTokens = () => {
    deleteAccessToken();
    deleteRefreshToken();
  };

  const deleteAllCrmTokens = () => {
    deleteCrmAccessToken();
    deleteCrmUuid();
    deleteCrmRefreshToken();
  };

  const setExpires = (value: string) =>
    value && Cookies.set(keys.EXPIRES, value, EXPIRES);

  const isExpires = (): boolean => {
    try {
      const expires = +Cookies.get(keys.EXPIRES);

      if (!isNumber(expires)) return true;

      return dayjs.unix(expires).diff(dayjs(), 'milliseconds') <= 0;
    } catch (e) {
      return true;
    }
  };

  const deleteExpires = () => Cookies.remove(keys.EXPIRES);

  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    crmAccessToken: getCrmAccess(),
    crmRefreshToken: getCrmRefreshToken(),
    crmUuid: getCrmUuid(),
    expiresIn: getExpiresIn(),
    setCrmUuid,
    setCrmAccessToken,
    setCrmRefreshToken,
    setAccessToken,
    setRefreshToken,
    deleteAccessToken,
    deleteRefreshToken,
    deleteCrmAccessToken,
    deleteCrmUuid,
    deleteCrmRefreshToken,
    deleteAllTokens,
    deleteAllCrmTokens,
    deleteExpires,
    setExpires,
    isExpires: isExpires(),
    keys,
  };
};
