import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { Props } from './Instruction.types';

import styles from './instruction.module.scss';

export const Instruction: FC<Props> = ({ wager, percent, max, min }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  // Понадобиться в будущем, когда появится текст для этой модалки
  // const aboutMd = useMdRead(AboutMd);
  // const termsMd = useMdRead(TermsMd);

  const [params, setParams] = useSearchParams();

  const handleInstructionClick = () => {
    params.append('instruction', '1');
    window.scrollTo(0, 0);
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
      })}
    >
      <div className={styles.row}>
        <div className={styles.box}>
          <div className={styles.item}>
            <p className={styles.text}>
              <span>{t('bonuses.wager')}</span>
            </p>
            <p className={styles.text}>
              <span>{wager}</span>
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.text}>
              <span>{t('bonuses.min_dep')}</span>
            </p>
            <p className={styles.text}>
              <span>{min}</span>
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.text}>
              <span>{t('bonuses.max_bonus')}</span>
            </p>
            <p className={styles.text}>
              <span>{max}</span>
            </p>
          </div>
        </div>
        {/* <div className={classNames(styles.box, styles.box_dark)}>
          <h4 className={styles.title}>{t('bonuses.to_the_deposit')}</h4>
          <p className={styles.text}>
            <span>{t('bonuses.from_the_deposit')}</span>
          </p>
          <Button
            onClick={handleInstructionClick}
            bold
            color='transparent'
            size='large'
            textColor='secondary'
            uppercase
            className={classNames(styles.button, {
              [styles.button_margin]: !isMobile,
            })}
          >
            {t('bonuses.instruction')}
          </Button>
        </div> */}
      </div>
      {/* Понадобиться в будущем, когда появится текст для этой модалки */}
      {/* <div className={styles.dropdowns}>
        <Dropdown
          isMobile={isMobile}
          className={styles.dropdown}
          title={t('bonuses.about_deposit_bonus')}
        >
          <ReactMarkdown>{aboutMd}</ReactMarkdown>
        </Dropdown>
        <Dropdown
          isMobile={isMobile}
          className={styles.dropdown}
          title={t('bonuses.general_terms')}
        >
          <ReactMarkdown>{termsMd}</ReactMarkdown>
        </Dropdown>
      </div> */}
    </div>
  );
};
