import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp, useBreakPoints } from '@box/shared/hooks';
import { BonusDepositDetail } from '@box/shared/components';
import { ImageSet } from '@box/ui';

import { Props } from './BonusJune.types';

import styles from './bonusJune.module.scss';
import DecorPng from './assets/images/decor.png';
import DecorWebp from './assets/images/decor.webp';
import DecorAvif from './assets/images/decor.avif';

export const BonusJune: FC<Props> = ({
  valueBonuses,
  minBonuses,
  percent,
  currencySymbol,
  isBanner,
  className,
}) => {
  const { t } = useTranslation();

  const breakPoints: number = useBreakPoints({ 350: 10, 351: 12 });

  const {
    userEnv: { isMobile },
  } = useApp();

  const value = (Number(valueBonuses) * Number(percent)) / 100;

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.container_banner]: isBanner,
          [styles.container_mobile]: isMobile,
        },
        className
      )}
    >
      <div className={styles.content}>
        {valueBonuses >= Number(minBonuses) ? (
          <div className={styles.main}>
            <div className={styles.box}>
              <p className={styles.label}>
                +
                {valueBonuses
                  ? String(value).length > breakPoints
                    ? `${String(value).slice(0, breakPoints)}...`
                    : value
                  : 10}{' '}
                {currencySymbol}
              </p>
              <ImageSet
                src={DecorPng}
                srcWebp={DecorWebp}
                srcAvif={DecorAvif}
                alt={t('bonuses.bonus')}
              />
              {!isBanner && (
                <p className={styles.label}>{t('bonuses.bonus')}!</p>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.other}>
            <p className={styles.emoji}>🙁</p>
            <p className={styles.label}>
              {t('bonuses.not_available')}
              <br />
              <span>
                {t('deposit.from')} {minBonuses} {currencySymbol}{' '}
                {t('deposit.bonus_message')}
              </span>
            </p>
          </div>
        )}
        <BonusDepositDetail isBanner={isBanner} />
      </div>
    </div>
  );
};
