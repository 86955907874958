import classNames from 'classnames';
import React, { FC } from 'react';

import { useApp } from '@box/shared/hooks';
import styles from './Overlay.module.scss';
import { Props } from './Overlay.types';

export const Overlay: FC<Props> = ({
  children,
  className,
  onClick,
  isOpen,
  ...props
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(styles.overlay, className, {
        [styles['overlay--open']]: isOpen,
        [styles.overlay_june]: isJune,
      })}
      onClick={onClick}
      aria-hidden='true'
      {...props}
    >
      {children}
    </div>
  );
};
