import classNames from 'classnames';
import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Hyphen from 'react-hyphen';

import styles from './GradientLink.module.scss';
import { GradientLinkProps, ContainerProps } from './GradientLink.types';

const Container: FC<ContainerProps> = ({
  className,
  children,
  isLink,
  ...rest
}) => {
  const linkProps = rest as LinkProps;

  const cn = classNames(styles.container, className);

  return isLink ? (
    <Link className={cn} {...linkProps}>
      {children}
    </Link>
  ) : (
    <div className={cn} style={linkProps.style}>
      {children}
    </div>
  );
};

export const GradientLink: FC<GradientLinkProps> = ({
  className,
  title,
  description,
  icon: Icon,
  alt,
  hideArrow,
  size = 'big',
  color = 'primary',
  align = 'left',
  ...linkProps
}) => {
  const IconComponent = React.memo(() =>
    typeof Icon === 'string' ? (
      <img className={styles.icon} src={Icon} alt={alt} />
    ) : (
      <Icon className={styles.icon} />
    )
  );

  return (
    <Container
      className={classNames(
        className,
        styles[`size-${size}`],
        styles[`color-${color}`],
        styles[`align-${align}`]
      )}
      isLink={!!linkProps.to}
      {...(linkProps as LinkProps)}
    >
      <IconComponent />
      <div className={styles.content}>
        <Hyphen>
          <span className={styles.title}>{title}</span>
        </Hyphen>
        <Hyphen>
          <span className={styles.description}>{description}</span>
        </Hyphen>
      </div>
      {!hideArrow && <span className={styles.arrow} />}
    </Container>
  );
};
