import type UserEnvironment from '@box/shared/types/userEnvironment';

// Locale mode

export const locationForLayout = ['/wheel', '/aviatorland'];

export const isLayout = Boolean(
  locationForLayout.includes(window.location.pathname) ||
    window.location.pathname.includes('/help')
);

const toMain = (url?: string) => {
  if (url) {
    window.location.href = `${window.location.origin}${url}`;
  } else {
    window.location.href = window.location.origin;
  }
};

export const isInvestments = Boolean(
  ['/investments'].includes(window.location.pathname)
);

export const isAviatorLand = Boolean(
  ['/aviatorland'].includes(window.location.pathname)
);
// App mode

const setAppMode: (mode: 1 | 2) => void = (mode) =>
  localStorage.setItem('app_mode', JSON.stringify(mode));

export const isDesktop: boolean =
  JSON.parse(localStorage.getItem('app_mode')) !== 2;

export const isMobile: boolean =
  JSON.parse(localStorage.getItem('app_mode')) === 2;

const setMobile = () => {
  setAppMode(2);
  window.location.reload();
};

const setDesktop = () => {
  setAppMode(1);
  window.location.reload();
};

const isMobileDevice = Boolean(
  navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
);

export const checkMobile = () => {
  const appMode = JSON.parse(localStorage.getItem('app_mode'));

  if (!appMode && isMobileDevice) {
    setMobile();
  }
};

const isAppleDevice = Boolean(
  navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i)
);

const isAndroidDevice = Boolean(navigator.userAgent.match(/android/i));

const isFirefox = !!navigator.userAgent.match(/firefox/i);

const toggleMode = () => (isMobile ? setDesktop() : setMobile());

const isSafari =
  navigator.userAgent.search('Safari') >= 0 &&
  navigator.userAgent.search('Chrome') < 0;

export const userEnvironment: UserEnvironment = {
  toggleAppMode: toggleMode,
  isMobileDevice,
  isAppleDevice,
  isFirefox,
  isAndroidDevice,
  isSafari,
  isLayoutMode: isLayout,
  isJune: false,
  isDesktop,
  isMobile,
  toMain,
  isInvestments,
  isAviatorLand,
  host: 'december',
};
