// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-baseComponents-LightBlockWithLoading-lightBlockWithLoading-module__container{align-items:center;display:none;flex-direction:column;justify-content:center;opacity:1}.\\------shared-baseComponents-LightBlockWithLoading-lightBlockWithLoading-module__container_loading{display:flex}", "",{"version":3,"sources":["webpack://./../../shared/baseComponents/LightBlockWithLoading/lightBlockWithLoading.module.scss"],"names":[],"mappings":"AACA,4FAGE,kBAAA,CAFA,YAAA,CACA,qBAAA,CAEA,sBAAA,CACA,SAAF,CAEE,oGACE,YAAJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.container {\n  display: none;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  opacity: 1;\n\n  &_loading {\n    display: flex;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "------shared-baseComponents-LightBlockWithLoading-lightBlockWithLoading-module__container",
	"container_loading": "------shared-baseComponents-LightBlockWithLoading-lightBlockWithLoading-module__container_loading"
};
module.exports = ___CSS_LOADER_EXPORT___;
