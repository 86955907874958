export interface Field {
  custom_phone?: string;
  name: string;
  label: string;
  placeholder: string;
  default_value?: string;
  type: string;
  options?: {
    label: string;
    value: string;
  }[];
  rules?: Record<string, unknown>;
  mask?: string;
  format?: string;
  disabled?: boolean;
  onChange?: (e: any) => void;
  value?: number;
}

export type FieldsList = Field[];
