import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LightButton } from '@box/ui';
import { useAppDispatch } from '@box/redux/hooks';
import { setChatOpen } from '@box/redux/support';
import { useApp } from '@box/shared/hooks';

import styles from './lightsupport.module.scss';

export const LightSupport = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setChatOpen(true));
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_desktop]: isDesktop,
      })}
    >
      <div>
        <p className={styles.title}>{t('footer.support.question')}</p>
        <p className={styles.subtitle}>{t('footer.support.answer')}</p>
      </div>
      <div className={styles.buttons}>
        <LightButton
          onClick={handleClick}
          className={styles.button}
          color='blue'
          fixHeight
        >
          {t('footer.support.support')}
        </LightButton>
      </div>
    </div>
  );
};
