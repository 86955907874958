import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch } from '@box/redux/hooks';
import { hideModal, openModal } from '@box/redux/app';
import { useApp, useDisabledByPath } from '@box/shared/hooks';
import { Window } from '@box/ui';

import { Modal } from '../../../Modal/Modal';
import Tabs from '../../../Tabs/Tabs';
import { Quick } from '../../Register/Quick';

import styles from './register.module.scss';

import { ReactComponent as Detail1 } from './assets/images/1.svg';
import { ReactComponent as Detail2 } from './assets/images/2.svg';
import { ReactComponent as Detail3 } from './assets/images/3.svg';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';
import { Banner } from './Banner';

const details = [Detail1, Detail2, Detail3];

export const JuneRegisterModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    userEnv: { isDesktop },
    route,
  } = useApp();

  const disabled = useDisabledByPath({ path: '/aviator' });

  const tabsData = [{ title: t('modal_reg.quick.title'), component: Quick }];

  const handleOpenUserAgreement = () => {
    dispatch(hideModal('register'));
    navigate(route('politics.user_agreement'));
  };

  const openUserAgreement = () => {
    disabled(handleOpenUserAgreement);
  };

  const openLogin = () => {
    dispatch(hideModal('register'));
    dispatch(openModal('login'));
  };

  return (
    <Modal
      headColor='green'
      id='register'
      size={isDesktop ? 'lg' : 'smdoubleplus'}
      title={t('modal_reg.title')}
      className={classNames(styles.register, {
        [styles.register_desktop]: isDesktop,
      })}
      headWithSvg
      heightAuto={isDesktop}
    >
      <div className={styles.body}>
        <Banner />
        <div className={styles.box}>
          <Tabs tabs={tabsData} />
        </div>

        <div className={styles.policy}>
          {t('modal_reg.policy.start')}{' '}
          <span onClick={openUserAgreement} aria-hidden='true'>
            {t('modal_reg.policy.valorLink')}
          </span>
          {t('modal_reg.policy.end')}
        </div>
      </div>
      <div className={styles.details}>
        {details.map((item, index) => {
          const Svg = item;
          return (
            <div key={index} className={styles.item}>
              <Svg />
              <p className={styles.text}>{t(`modal_reg.list.${index + 1}`)}</p>
            </div>
          );
        })}
      </div>
      <Window.Footer className={styles.footer}>
        <p className={styles.footer__text}>
          {t('modal_reg.i_already_have_an_account')}
        </p>
        <button type='button' onClick={openLogin} className={styles.link}>
          {t('navigation.sign_in')}
          <ArrowSvg />
        </button>
      </Window.Footer>
    </Modal>
  );
};
