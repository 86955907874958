import React, { lazy, Suspense } from 'react';
import Loading from '@box/shared/components/Loading/Loading';

import { userEnvironment } from '../userEnvironment';

const Mobile = lazy(() => import('@box/lafa-mobile'));

export const MobileApp = () => {
  return (
    <Suspense
      fallback={<Loading isFailed={false} hideSpinner isJune={false} />}
    >
      <Mobile userEnv={userEnvironment} />
    </Suspense>
  );
};
