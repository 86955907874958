import React, { useState, FC, useMemo, useEffect, ChangeEvent } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { PaymentMethod, Field } from '@box/redux';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import {
  fetchWithdrawalMerchants,
  setWithdrawalLoading,
  walletsSelectors,
  withdrawalMerchantsSelectors,
  withdrawalRequest,
  withdrawalRequestsSelectors,
} from '@box/redux/finance';
import { fetchPermissions, userInitSelectors } from '@box/redux/authentication';
import { balanceSelectors, userSelectors } from '@box/redux/user';

import { Props, Fields } from './Withdrawal.types';

export const withState = (Component: FC<Props>) => {
  const wrapper: FC = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>(null);

    const minDep = selectedMethod?.limits?.min;

    const methods = useForm<Fields>({ mode: 'all' });

    const { watch, setValue, clearErrors } = methods;

    const watchAmount = watch('amount');

    const payments = useAppSelector(withdrawalMerchantsSelectors.list);
    const loading = useAppSelector(withdrawalMerchantsSelectors.loading);
    const submitLoading = useAppSelector(withdrawalRequestsSelectors.loading);
    const isLogged = useAppSelector(userInitSelectors.isLogged);
    const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
    const currentWallet = useAppSelector(walletsSelectors.current);
    const userId = useAppSelector(userSelectors.id);
    const currency = useAppSelector(userSelectors.currency);
    const withdrawalInit = useAppSelector(withdrawalMerchantsSelectors.init);
    const error = useAppSelector(withdrawalRequestsSelectors.error);
    const isDepositWagered = useAppSelector(userInitSelectors.isDepositWagered);
    const isWageredReady = useAppSelector(userInitSelectors.isWageredReady);
    const balance = useAppSelector(balanceSelectors.balanceValue);
    const commissionRate = 0.25;

    const available = () => {
      if (isDepositWagered) {
        return balance;
      }
      const balanceWithCommission = balance - balance * commissionRate;
      return Math.floor(balanceWithCommission * 100) / 100;
    };

    const fields = useMemo(() => {
      let fields = [] as Field[];

      if (selectedMethod) {
        selectedMethod?.fields?.forEach((item) => {
          fields.push(item);
        });
      }
      return fields;
    }, [selectedMethod, isDepositWagered]);

    const onSubmit: SubmitHandler<Fields> = (data) => {
      dispatch(setWithdrawalLoading());
      const { amount } = data;
      delete data.amount;

      dispatch(
        withdrawalRequest({
          userId,
          name: selectedMethod.name,
          fieldValues: data,
          methodId: selectedMethod.id,
          merchantId: selectedMethod.merchant.id,
          amount,
          walletId: currentWallet.id,
          confirm: isDepositWagered ? 0 : 1,
          amountError: amount * 0.75 < minDep,
          currencyCode: currency?.code,
          minDep,
        })
      );
    };

    useEffectOnce(() => {
      if (isLogged && !withdrawalInit) {
        dispatch(fetchWithdrawalMerchants());
      }
    });

    useEffect(() => {
      dispatch(fetchPermissions());
    }, []);

    // Логика работы с комиссией

    const [blocked, setBlocked] = useState('0');
    const [changesDisabled, setChangesDisabled] = useState(false);

    useEffect(() => {
      if (!changesDisabled) {
        setBlocked(
          String(
            Math.floor(
              Number(watchAmount) - Number(watchAmount) * commissionRate
            )
          )
        );
      }
    }, [watchAmount, changesDisabled]);

    const handleBlockedChange = (e: ChangeEvent<HTMLInputElement>) => {
      setChangesDisabled(true);
      setBlocked(e.target.value);
      setValue(
        'amount',
        Math.ceil(Number(e.target.value) / (1 - commissionRate))
      );
      clearErrors('amount');
    };

    const handleBlockedBlur = () => {
      setChangesDisabled(false);
    };

    const inputWithRateError = () => {
      if (!isDepositWagered) {
        if (Number(blocked) > available()) {
          return t('withdrawalRequest.amount_error');
        } else if (Number(blocked) < minDep) {
          return t('fields.payment_rules.minSum', { sum: minDep });
        }
      }

      return '';
    };

    // Проверить переводы, там текст на английском не правильный
    // Добавить еще новые

    return (
      <FormProvider {...methods}>
        <Component
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          payments={payments}
          paymentsLoading={loading || !isWageredReady}
          currencySymbol={currencySymbol}
          onSubmit={onSubmit}
          fields={fields}
          submitLoading={submitLoading}
          error={error}
          available={available()}
          isDepositWagered={isDepositWagered}
          commissionPercent={String(commissionRate * 100)}
          blocked={blocked}
          onBlockedChange={handleBlockedChange}
          onBlockedBlur={handleBlockedBlur}
          inputWithRateError={inputWithRateError()}
        />
      </FormProvider>
    );
  };

  return wrapper;
};
