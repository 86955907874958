import { createSlice } from '@reduxjs/toolkit';

import { addPartnerUrl } from './actions';
import { fetchPartnerUrl } from './thunks';

import { InitialState } from './types';

const initialState: InitialState = {
  error: '',
  loading: false,
  url: '',
};

export const slice = createSlice({
  name: 'streams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPartnerUrl.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.url = payload.url;
      })
      .addCase(fetchPartnerUrl.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      })
      .addCase(addPartnerUrl, (state, { payload }) => {
        state.url = payload;
      });
  },
});
