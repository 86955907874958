import values from 'lodash/values';
import { useAuthCookies, useAuthTokens } from '@box/redux/hooks';
import { useEffect } from 'react';
import { checkForCookie } from './checkForCookie';

export const useCheckTokens = () => {
  const { keys } = useAuthCookies();
  const { keys: tokenKeys } = useAuthTokens();

  useEffect(() => {
    values(keys).map((key) => checkForCookie(key));
    values(tokenKeys).map((key) => checkForCookie(key));
  }, []);
};
