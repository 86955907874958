import React, { FC } from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import Select, { SelectInstance } from 'react-select';
import { useApp } from '@box/shared/hooks';

import { Option, Props } from './FormSelect.types';

import './formSelect.scss';

export const FormSelect: FC<Props> = React.forwardRef<
  SelectInstance<Option>,
  Props
>(
  (
    {
      name,
      control,
      options,
      placeholder,
      onChange,
      className,
      rules,
      label,
      defaultValue,
      menuPlacement,
      error,
      errorMessage,
      disabled,
      isAviator,
      isMyProfile,
    },
    ref
  ) => {
    const {
      userEnv: { isJune, isMobile },
    } = useApp();

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <div className={classNames('form-select-root', className)}>
              {label && (
                <span
                  className={classNames('form-select-label', {
                    'form-select-label_june': isJune,
                    'form-select-label_aviator': isAviator,
                    'form-select-label_june_profile': isMyProfile,
                  })}
                >
                  {label}
                </span>
              )}
              <Select
                ref={ref}
                className={classNames('form-select', {
                  'form-select_mobile': isMobile,
                  'form-select--error': !!error,
                })}
                classNamePrefix='form-select'
                placeholder={placeholder}
                onChange={(v) => onChange(v, field)}
                options={options}
                isSearchable
                menuPlacement={menuPlacement}
                value={
                  options.find((item) => item.value === field.value) || null
                }
                isDisabled={disabled}
              />
              {errorMessage && (
                <span className='form-select-error'>{errorMessage}</span>
              )}
            </div>
          );
        }}
      />
    );
  }
);
