import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { LoyaltyIcon } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { ProgressBar } from './ProgressBar';
import { Countdown } from './Countdown';

import { Props } from './TaskCard.types';

import styles from './taskCard.module.scss';
import { ReactComponent as Logo } from './assets/images/logo.svg';
import { ReactComponent as MarkSvg } from './assets/images/mark.svg';

export const TaskCard: FC<Props> = ({
  points,
  text,
  progress,
  icon,
  className,
  isDone,
  progressType,
  date,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();
  return (
    <div
      className={classNames(
        styles.card,
        { [styles.card_mobile]: isMobile },
        className
      )}
    >
      <div
        className={classNames(
          styles.header,
          styles[`header_color_${isDone ? 'gray' : 'yellow'}`]
        )}
      >
        <LoyaltyIcon color={isDone ? 'gray' : 'yellow'} iconId={icon} />
        {date && (
          <Countdown
            color={isDone ? 'gray' : 'yellow'}
            className={styles.countdown}
            date={date}
          />
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.counter}>
            <Logo />
            <p className={styles.points}>+{points}</p>
          </div>
          <p className={styles.text}>{t(text)}</p>
        </div>
        <div className={styles.footer}>
          {isDone ? (
            <p className={styles.done}>
              <MarkSvg /> {t('loyalty.tasks.done')}
            </p>
          ) : (
            <ProgressBar progressType={progressType} progress={progress} />
          )}
        </div>
      </div>
    </div>
  );
};
