import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '@box/shared/hooks';
import { Modal } from '../../../Modal/Modal';
import { DesktopCard } from './DesktopCard';
import { MobileCard } from './MobileCard';

import { statusesList } from './LoyaltyStatuses.data';

import styles from './loyaltyStatuses.module.scss';

export const LoyaltyStatusesModal: FC = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <Modal
      id='loyalty-statuses'
      size={isMobile ? 'xs' : 'xl'}
      headColor='blue'
      headWithSvg
      title={t('loyalty.more_about_statuses')}
    >
      <div className={styles.body}>
        {!isMobile ? (
          <>
            <div className={styles.header}>
              <div className={styles.headerColumn}>{t('loyalty.status')}</div>
              <div className={styles.headerColumn}>
                {t('loyalty.user.need_rating')}
              </div>
              <div className={styles.headerColumn}>
                {t('loyalty.chart.exchange_rate')}
              </div>
              <div className={styles.headerColumn}>{t('loyalty.wager')}</div>
            </div>
            <div className={styles.main}>
              {statusesList.map((item, index) => {
                return <DesktopCard {...item} key={index} />;
              })}
            </div>
          </>
        ) : (
          statusesList.map((item, index) => {
            return <MobileCard {...item} key={index} />;
          })
        )}
      </div>
    </Modal>
  );
};
