import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Arrows, LightButton } from '@box/ui';

import { addUserBonus } from '@box/redux/user';
import { useAppDispatch } from '@box/redux/hooks/appHooks';
import { useApp } from '@box/shared/hooks';

import { Props } from './Main.types';

import styles from './main.module.scss';

export const Main: FC<Props> = ({ name, getting_params, wager, id }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activate = () => dispatch(addUserBonus({ bonusId: id }));

  const bonusInfo = [
    { value: wager, label: t('bonuses.wager') },
    { value: getting_params[0]?.min, label: t('bonuses.min_dep') },
    { value: getting_params[0]?.max, label: t('bonuses.max_bonus') },
  ];
  return (
    <div
      className={classNames(styles.main, { [styles.main_mobile]: isMobile })}
    >
      <h3 className={styles.title}>
        {name}
        <span> +{getting_params[0]?.percent}%</span>
      </h3>
      <p className={styles.description}>{t('bonuses.for_new_users')}</p>
      <div className={styles.info_block}>
        {bonusInfo?.map((element, index) => (
          <div key={index} className={styles.info}>
            {element.label}
            <span> {element.value}</span>
          </div>
        ))}
      </div>

      <LightButton onClick={activate} className={styles.button}>
        {t('bonuses.activate')}
      </LightButton>
      <Arrows className={styles.arrow} />
    </div>
  );
};
