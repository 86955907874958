// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Transfer-Transfer-module__transfer .\\------shared-components-Modals-Transfer-Transfer-module__body{padding:32px 32px 24px}.\\------shared-components-Modals-Transfer-Transfer-module__transfer .\\------shared-components-Modals-Transfer-Transfer-module__button{margin-top:12px}.\\------shared-components-Modals-Transfer-Transfer-module__transfer .\\------shared-components-Modals-Transfer-Transfer-module__text{white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Transfer/Transfer.module.scss"],"names":[],"mappings":"AAEE,oIACE,sBADJ,CAIE,sIACE,eAFJ,CAKE,oIACE,oBAHJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.transfer {\n  .body {\n    padding: 32px 32px 24px 32px;\n  }\n\n  .button {\n    margin-top: 12px;\n  }\n\n  .text {\n    white-space: pre-wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transfer": "------shared-components-Modals-Transfer-Transfer-module__transfer",
	"body": "------shared-components-Modals-Transfer-Transfer-module__body",
	"button": "------shared-components-Modals-Transfer-Transfer-module__button",
	"text": "------shared-components-Modals-Transfer-Transfer-module__text"
};
module.exports = ___CSS_LOADER_EXPORT___;
