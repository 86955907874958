// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-BonusesListHeader-header-module__header{width:100%}.\\------shared-components-BonusesListHeader-header-module__control{padding:16px 0!important}", "",{"version":3,"sources":["webpack://./../../shared/components/BonusesListHeader/header.module.scss"],"names":[],"mappings":"AACA,kEACE,UAAF,CAGA,mEACE,wBAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.header {\n  width: 100%;\n}\n\n.control {\n  padding: 16px 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "------shared-components-BonusesListHeader-header-module__header",
	"control": "------shared-components-BonusesListHeader-header-module__control"
};
module.exports = ___CSS_LOADER_EXPORT___;
