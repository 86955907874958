import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Props } from './Chart.types';

import styles from './chart.module.scss';

export const Chart: FC<Props> = ({ historyList }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.chart}>
      <div className={styles.header}>
        <div className={styles.headerColumn}>
          {t('loyalty.chart.date_and_time')}
        </div>
        <div className={styles.headerColumn}>
          {t('loyalty.chart.given_away_vc')}
        </div>
        <div className={styles.headerColumn}>{t('loyalty.chart.received')}</div>
        <div className={styles.headerColumn}>
          {t('loyalty.chart.exchange_rate')}
        </div>
        <div
          className={classNames(styles.headerColumn, styles.headerColumn_last)}
        >
          {t('loyalty.chart.player_status')}
        </div>
      </div>
      <div className={styles.main}>
        {historyList.map((item, index) => {
          return (
            <div key={index} className={styles.row}>
              <div className={styles.rowItem}>
                <p className={styles.value}>{item.date}</p>
                <p className={classNames(styles.value, styles.value_shadow)}>
                  {item.time}
                </p>
              </div>
              <div className={styles.rowItem}>
                <p className={classNames(styles.value, styles.value_bold)}>
                  {item.vc}
                </p>
              </div>
              <div className={styles.rowItem}>
                <p className={classNames(styles.value, styles.value_bold)}>
                  {item.getMoney}
                </p>
              </div>
              <div className={styles.rowItem}>
                <p className={classNames(styles.value, styles.value_bold)}>
                  {item.exchangeRate}
                </p>
              </div>
              <div className={classNames(styles.rowItem, styles.rowItem_last)}>
                <p className={styles.value}>{item.status}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
