import { useTranslation } from 'react-i18next';

import { CasinoIcon, LiveCasinoIcon } from '../icons';

import { useApp } from './useApp';

type MainLink = {
  icon: React.ElementType;
  title: string;
  description: string;
  to: string;
};

type UseMainLink = () => MainLink[];

export const useMainLinks: UseMainLink = () => {
  const { t } = useTranslation();

  const { route } = useApp();

  return [
    {
      icon: CasinoIcon,
      title: t('applications.names.casino'),
      description: t('applications.descriptions.casino'),
      to: route('casino'),
    },
    {
      icon: LiveCasinoIcon,
      title: t('applications.names.live_games'),
      description: t('applications.descriptions.live_games'),
      to: route('casino.list', { id: 'live' }),
    },
  ];
};
