import React, { FC } from 'react';
import classNames from 'classnames';
import { merchantIcons } from '@box/shared/utils/merchants';
import { getStoragePath } from '@box/shared/utils';

import { Payment } from '../../ui';

import { Props } from './Payments.types';

import styles from './payments.module.scss';

export const Payments: FC<Props> = ({
  payments,
  tabsSkittles,
  selectedMethod,
  setSelectedMethod,
}) => {
  const getIcon = (item) => {
    // На платежку Узбекистана выводим другую картинку
    if (item?.logo) {
      return <img alt={item.alias} src={getStoragePath(item.logo, 'main')} />;
    }
    const Component = merchantIcons.defaultIcon;
    return <Component />;
  };

  const handleClick = (item) => {
    setSelectedMethod(item);
  };

  return (
    <div
      className={classNames(styles.tabs, {
        // LM-3061 - Remove
        // [styles.tabs_skittles]: tabsSkittles,
      })}
    >
      {payments.map((item, idx) => (
        <Payment
          className={styles.tab}
          onClick={handleClick}
          item={item}
          selectedItem={selectedMethod}
          key={idx}
          label={item.name}
        >
          {getIcon(item)}
        </Payment>
      ))}
    </div>
  );
};
