/* eslint-disable react/prop-types */
import React, { useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import forIn from 'lodash/forIn';
import { DecemberInput } from '@box/ui';

import { FormSelect, Plate } from '../../../../baseComponents';

import { useDisabled } from '../UserInfo';

const breakPoints = {
  550: 2,
  450: 1,
};

const SelectField = React.memo(({ field, rules, disabled }) => {
  const name = `document__${field.name}`;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const options = useMemo(
    () =>
      Object.keys(field.options).map((key) => ({
        value: key,
        label: field.options[key],
      })),
    [field]
  );

  const onChange = (v, field) => field.onChange(v.value);

  return (
    <FormSelect
      name={name}
      label={field.label}
      placeholder={field.placeholder}
      error={!!get(errors, name)}
      errorMessage={get(errors, `${name}.message`)}
      control={control}
      onChange={onChange}
      options={options}
      rules={rules}
      disabled={disabled}
    />
  );
});

export const Fields = React.memo(({ fields = [] }) => {
  const { t } = useTranslation();

  const { disabled } = useDisabled();

  const {
    register,
    unregister,
    getValues,
    formState: { errors },
  } = useFormContext();

  const getRulesObj = (rules) =>
    Object.keys(rules).reduce(
      (acc, item) => ({
        ...acc,
        [item]: {
          value: rules[item],
          message: t(`fields.payment_rules.${item}`, { extra: rules[item] }),
        },
      }),
      {}
    );

  useEffect(() => {
    // Удаление старых полей при смене документа
    const prevFields = getValues();

    forIn(prevFields, (_value, key) => {
      if (key.includes('document__')) {
        unregister(key);
      }
    });
  }, [fields]);

  return (
    <Plate breakPoints={fields.length > 1 ? breakPoints : null}>
      {fields.map((item, idx) =>
        item.type === 'select' ? (
          <SelectField field={item} rules={getRulesObj(item.rules)} key={idx} />
        ) : (
          <DecemberInput
            {...register(`document__${item.name}`, {
              ...getRulesObj(item.rules),
            })}
            label={item.label}
            placeholder={item.placeholder}
            error={t(errors[`document__${item.name}`]?.message)}
            color='outlined'
            disabled={disabled}
            key={idx}
          />
        )
      )}
    </Plate>
  );
});
