import { useTranslation } from 'react-i18next';

import { initUserBonuses } from '@box/redux/user';
import { openModal } from '@box/redux/app';

import { setModalRegisterOpen } from '@box/redux/authentication';
import { UserAction } from './UserDropdown.types';

export const useMenuItems: (isAutoAuth: boolean) => UserAction[] = (
  isAutoAuth
) => {
  const { t } = useTranslation();

  const list = [
    {
      label: t('user_actions.top_up'),
      func: (dispatch) => dispatch(openModal('deposit')),
    },
    {
      label: t('user_actions.withdrawal'),
      func: (dispatch) => dispatch(openModal('funds')),
    },
    {
      label: t('user_actions.settings'),
      func: (dispatch) => dispatch(openModal('settings')),
    },
    {
      label: t('user_actions.detalization'),
      func: (dispatch) => dispatch(openModal('detalization')),
    },
    {
      label: t('settings.verification'),
      func: (dispatch) => dispatch(openModal('verification')),
    },
    {
      label: t('user_actions.bonuses'),
      func: (dispatch) => {
        dispatch(initUserBonuses());
        dispatch(openModal('bonuses'));
      },
    },
  ];

  if (isAutoAuth) {
    list.push({
      label: t('user_actions.logout'),
      func: (dispatch) => {
        dispatch(setModalRegisterOpen(false));
        dispatch(openModal('logout'));
      },
    });
  }

  return list;
};
