/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react';
import { setVersion, setVersionOutdated } from '@box/redux/app';
import { useAppDispatch } from '@box/redux/hooks';

export const useAppVersion = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Проверяем, поддерживаются ли Service Workers
    if (!('serviceWorker' in navigator)) {
      console.warn('Service Workers are not supported');
      return () => {};
    }

    const handleServiceWorkerMessage = (event) => {
      const { type, version } = event.data;

      if (type === 'current-version') {
        // Обработка полученной версии
        console.info('📌', 'Current app version:', version, '📌');
        dispatch(setVersion(version));
      } else if (type === 'new-version') {
        // Обработка новой версии
        dispatch(setVersion(version));
        dispatch(setVersionOutdated(true));
        console.info('📦', 'New version available:', version, '📦');
      }
    };

    navigator.serviceWorker.addEventListener(
      'message',
      handleServiceWorkerMessage
    );

    // Отправка сообщения для получения текущей версии
    navigator.serviceWorker.controller?.postMessage('get-version');

    return () => {
      // Чистка слушателя события при размонтировании компонента
      navigator.serviceWorker.removeEventListener(
        'message',
        handleServiceWorkerMessage
      );
    };
  }, []);
};
