import { createSlice } from '@reduxjs/toolkit';
import { userRegistration } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  loading: false,
  error: '',
  isSuccess: false,
};

export const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRegistration.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.isSuccess = false;
      })
      .addCase(userRegistration.fulfilled, (state) => {
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(userRegistration.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload;
      });
  },
});
