// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-LoadingSection-LoadingSection-module__loader{align-items:center;display:flex;inset:0;justify-content:center;position:absolute}", "",{"version":3,"sources":["webpack://./../../shared/components/LoadingSection/LoadingSection.module.scss"],"names":[],"mappings":"AACA,uEAIE,kBAAA,CAFA,YAAA,CAGA,OAAA,CAFA,sBAAA,CAFA,iBAIF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.loader {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  inset: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "------shared-components-LoadingSection-LoadingSection-module__loader"
};
module.exports = ___CSS_LOADER_EXPORT___;
