import { useContext } from 'react';

import { AppContext } from '../contexts';
import { IAppContext } from '../contexts/appMode';

export const useApp = (): IAppContext => {
  const appContextValue = useContext(AppContext);

  return appContextValue;
};
