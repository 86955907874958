import classNames from 'classnames';
import React from 'react';
import reactDom from 'react-dom';
import { useApp } from '@box/shared/hooks';
import { useLockScroll, useScrollToInputInsideBlock } from '../../hooks';

import './modal.scss';
import { ReactComponent as BackSvg } from './assets/back.svg';
import { ReactComponent as JuneSvg } from './assets/june.svg';
import { ModalProps } from './Modal.types';

const Modal: React.FC<ModalProps> = ({
  children,
  id,
  title,
  titleSize = 'medium',
  subtitle,
  className,
  isOpen,
  close,
  backHead,
  onBackClick,
  noCapitalize,
  dark,
  bodyClassName,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();
  useLockScroll(!!isOpen);
  useScrollToInputInsideBlock();

  return reactDom.createPortal(
    <div
      className={classNames('modal', className, {
        'modal--open': isOpen,
        modal_dark: dark,
        modal_june: isJune,
      })}
      id={id}
    >
      <div className='modal__overlay' onClick={close} aria-hidden='true' />
      <div className={classNames('modal__body', bodyClassName)}>
        <div className='modal__head'>
          {backHead ? (
            <>
              <button
                onClick={onBackClick}
                type='button'
                className='modal__back'
              >
                <BackSvg />
              </button>
              <div
                className={classNames(
                  'modal__head-info',
                  'modal__head-info_back-head'
                )}
              >
                <div
                  className={classNames(
                    'modal__title',
                    'modal__title_back-head'
                  )}
                >
                  {title}
                </div>
                <div className='modal__subtitle'>{subtitle}</div>
              </div>
            </>
          ) : (
            <div className='modal__head-info'>
              <div
                className={classNames('modal__title', {
                  'modal__title_no-capitalize': noCapitalize,
                  [`modal__title_${titleSize}`]: titleSize,
                })}
              >
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                {isJune && <JuneSvg />}
              </div>
            </div>
          )}
          <div className='modal__close' onClick={close} aria-hidden='true' />
        </div>
        <div className='modal__content'>{children}</div>
      </div>
    </div>,
    document.getElementById('modals')
  );
};

export default Modal;
