import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';

import { FetchPartnerUrlReturn, POSTPartnerUrl } from './types';

export const fetchPartnerUrl = createAsyncThunk<
  FetchPartnerUrlReturn,
  void,
  AppThunkApi
>('streams/fetchPartnerUrl', async (_payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.post<POSTPartnerUrl>('/partner/streams');

    if (status === 200) {
      return data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
