import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useToggleActiveElement } from '@box/shared/hooks';
import { DetailPopup } from '@box/shared/baseComponents';

import styles from './show.module.scss';

export const Show: FC<{ className?: string }> = ({ className }) => {
  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  const { t } = useTranslation();

  return (
    <div
      onClick={toggleOpen}
      aria-hidden='true'
      ref={elementRef}
      className={classNames(styles.show, className)}
    >
      <div className={styles.decor}>
        <span>?</span>
      </div>
      <DetailPopup
        className={styles.popup}
        isOpen={isOpen}
        position='bottom-start'
      >
        {t('detalization.request')}
      </DetailPopup>
    </div>
  );
};
