import { useState, useEffect } from 'react';

export const useOrientation = () => {
  const checkOrientation = () => {
    if (window.screen.orientation) {
      return window.screen.orientation.type.includes('portrait');
    }
    return window.innerHeight > window.innerWidth;
  };

  const [isPortrait, setIsPortrait] = useState(checkOrientation);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(checkOrientation());
    };

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener(
        'change',
        handleOrientationChange
      );
      return () => {
        window.screen.orientation.removeEventListener(
          'change',
          handleOrientationChange
        );
      };
    } else {
      window.addEventListener('resize', handleOrientationChange);
      return () => {
        window.removeEventListener('resize', handleOrientationChange);
      };
    }
  }, []);

  return isPortrait;
};
