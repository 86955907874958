// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-June-Register-Banner-banner-module__banner{background-position:0;background-size:cover;border:2px solid #f1f3f6;border-radius:8px;box-sizing:border-box;height:80px;width:100%}.\\------shared-components-Modals-June-Register-Banner-banner-module__banner_mobile{height:96px}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/June/Register/Banner/banner.module.scss"],"names":[],"mappings":"AACA,4EAKE,qBAAA,CADA,qBAAA,CADA,wBAAA,CAIA,iBAAA,CADA,qBAAA,CALA,WAAA,CACA,UAKF,CAEE,mFACE,WAAJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.banner {\n  height: 80px;\n  width: 100%;\n  border: 2px solid #f1f3f6;\n  background-size: cover;\n  background-position: left;\n  box-sizing: border-box;\n  border-radius: 8px;\n\n  &_mobile {\n    height: 96px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "------shared-components-Modals-June-Register-Banner-banner-module__banner",
	"banner_mobile": "------shared-components-Modals-June-Register-Banner-banner-module__banner_mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
