import React from 'react';
import classNames from 'classnames';
import { LineLoader, Loader } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import styles from './LoadingSection.module.scss';
import { LoadingSectionProps } from './LoadingSection.types';

export const LoadingSection: React.FC<LoadingSectionProps> = ({
  className,
  color = 'yellow',
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div className={classNames(styles.loader, className)}>
      {isJune ? <LineLoader color={color} /> : <Loader />}
    </div>
  );
};
