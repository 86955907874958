import { Bonus } from '../bonuses';

import { UserData } from './info';

export interface UserBonus {
  activated_at: string;
  amount: number;
  bonus_id: number;
  bonus: Bonus;
  created_at: string;
  id: number;
  progress: {
    amount: number;
    message: string;
    percent: number;
    total: number;
    wager: number;
  };
  status: string;
  updated_at: string;
  user: UserData;
  user_id: number;
}

export interface UserTransformBonus extends Bonus {
  activated_at: string;
  amount: number;
  bonus_id: number;
  created_at: string;
  progress: {
    amount: number;
    message: string;
    percent: number;
    total: number;
    wager: number;
  };
  status: string;
  updated_at: string;
  user: UserData;
  user_id: number;
}
