import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { commonSelectors } from '@box/redux/common';
import { openBonusDetail } from '@box/redux/bonuses';
import { getStoragePath } from '../../../utils/getStoragePath';

export const Banner = () => {
  const dispatch = useDispatch();

  const banner = useSelector(commonSelectors.topUpBanner);

  const openBanner = () => dispatch(openBonusDetail(banner?.bonus));

  if (!banner) return null;

  return (
    <div className='top-up-banner' onClick={openBanner} aria-hidden='true'>
      <picture>
        <source
          srcSet={getStoragePath(banner.images.mobile, 'main')}
          media='(max-width:460px)'
        />
        <img src={getStoragePath(banner.images.desktop, 'main')} alt='bonus' />
      </picture>
      <div className='top-up-banner__title'>{banner.title}</div>
      <div className='top-up-banner__subtitle'>{banner.description}</div>
    </div>
  );
};
