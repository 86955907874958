import { createSlice } from '@reduxjs/toolkit';

import { fetchGames } from './thunks';
import {
  resetList,
  resetOnlyList,
  setListSearch,
  setListCategory,
  setListProvider,
} from './actions';
import { DefaultList, InitialState } from './types';

const defaultList: DefaultList = {
  initialized: false,
  loading: true,
  filters: {},
  data: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 50,
    total: 50,
  },
  error: false,
};

const initialState = {} as InitialState;

export const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state, action) => {
        const { list: listName } = action.meta.arg;

        if (!(listName in state)) {
          state[listName] = defaultList;
        } else {
          state[listName].loading = true;
        }
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        const { list: listName, page, filters } = action.meta.arg;
        const list = state[listName];

        const currentList = list.data;
        const newList = action.payload.data;

        list.filters = filters || list.filters;
        list.data = list.data.length
          ? [
              ...currentList,
              ...newList.filter((object1) => {
                return !currentList.some(
                  (object2) => object1.id === object2.id
                );
              }),
            ]
          : action.payload.data;
        list.meta = action.payload.meta;
        list.current_page = page;
        list.loading = false;
        list.initialized = true;
      })
      .addCase(resetList, (state, action) => {
        state[action.payload] = {
          ...defaultList,
          initialized: true,
          loading: false,
        };
      })
      .addCase(setListSearch, (state, { payload }) => {
        const list = state[payload.list];

        if (list) {
          list.filters = {
            ...list.filters,
            search: payload.value,
          };
        }
      })
      .addCase(setListCategory, (state, { payload }) => {
        const list = state[payload.list];

        if (list) {
          list.filters = {
            ...list.filters,
            category: payload.value,
          };
        }
      })
      .addCase(setListProvider, (state, { payload }) => {
        const list = state[payload.list];

        if (list) {
          list.filters = {
            ...list.filters,
            provider: payload.value,
          };
        }
      })
      .addCase(resetOnlyList, (state, action) => {
        // Сброс только списка, с сохранением фильтров
        const list = state[action.payload];

        if (list) {
          list.data = [];
        }
      });
  },
});
