import React, { FC } from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import ReactSelect, { SelectInstance } from 'react-select';

import { Label } from '../Label';
import { Message } from '../Message';

import { Option, Props } from './Select.types';

import './select.scss';

export const Select: FC<Props> = React.forwardRef<
  SelectInstance<Option>,
  Props
>(
  (
    {
      name,
      control,
      options,
      placeholder,
      onChange,
      className,
      rules,
      label,
      defaultValue,
      menuPlacement,
      menuPosition = 'absolute',
      error,
      errorMessage,
      disabled,
      isDeposit,
    },
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <div className={className}>
              {label && (
                <Label label={label} className='deposit-select_june__label' />
              )}
              <ReactSelect
                ref={ref}
                className={classNames('deposit-select_june', {
                  'deposit-select_june_error': !!error,
                  'deposit-select_june_card': isDeposit,
                })}
                classNamePrefix='deposit-select_june'
                placeholder={placeholder}
                onChange={(v) => onChange(v, field)}
                options={options}
                isSearchable={false}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                value={
                  options.find((item) => item.value === field.value) || null
                }
                isDisabled={disabled}
              />
              {errorMessage && (
                <Message
                  message={errorMessage}
                  className='deposit-select__error'
                />
              )}
            </div>
          );
        }}
      />
    );
  }
);
