import { UserData } from '../index';

import { Game } from './game';

export interface Session {
  id: number;
  uuid: string;
  user_id: number;
  game_id: number;
  ip: string;
  language_code: string;
  country_code: string;
  is_mobile: boolean;
  demo_mode: boolean;
  return_url: string;
  game: Game;
  technology: {
    height: number;
    src: string;
    type: string;
    width: number;
  };
  userdata: UserData;
  currency: {
    id: 0;
    code: string;
    name: string;
    symbol: string;
  };
  language: {
    id: 0;
    code: string;
    name: string;
    native_name: string;
  };
}

export type SessionList = Session[];
