import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './ProgressBar.types';

import styles from './progressBar.module.scss';

export const ProgressBar: FC<Props> = ({
  status,
  amount,
  neededAmount,
  percent,
}) => {
  return (
    <div
      className={classNames(styles.bar, {
        [styles.bar_active]: status === 'activated',
      })}
    >
      <div
        style={{ width: `calc(100% * 0.01 * ${percent})` }}
        className={styles.decor}
      />
      <p className={styles.count}>
        {amount} / {neededAmount}
      </p>
    </div>
  );
};
