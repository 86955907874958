import React, { FC } from 'react';
import classNames from 'classnames';
import { useAppDispatch } from '@box/redux/hooks';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { ReactComponent as Arrow } from '@box/shared/icons/arrows/dropdown_arrow.svg';
import { useToggleActiveElement } from '../../hooks';

import styles from './UserDropdown.module.scss';
import { UserDropdownProps } from './UserDropdown.types';
import { useMenuItems } from './UserDropdown.menuItems';
import { ReactComponent as Avatar } from './assets/avatar.svg';

export const UserDropdown: FC<UserDropdownProps> = ({ className, small }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, toggle, elementRef } = useToggleActiveElement({
    hideWhenClickOutside: true,
  });

  const isAutoAuth = Cookies.get('isAutoAuth');

  const menuItems = useMenuItems(isAutoAuth === '1');

  return (
    <div
      className={classNames(styles.container, className, {
        [styles['container--active']]: isOpen,
        [styles['container--small']]: small,
      })}
      onClick={toggle}
      aria-hidden='true'
      ref={elementRef}
    >
      <div className={styles.badge}>
        <div className={styles.avatar}>
          <Avatar />
        </div>
        <div className={styles.badgeText}>
          {t('navigation.my_account')}
          <Arrow />
        </div>
      </div>
      <div className={styles.menu}>
        {menuItems.map((item, index) => (
          <div
            className={styles.menuItem}
            onClick={() => item.func(dispatch)}
            aria-hidden='true'
            key={index}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};
