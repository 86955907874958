import { z } from 'zod';

export const resolveSafeParse = (
  value: z.SafeParseReturnType<string, string>
) => {
  if (!value.success) {
    return (value as z.SafeParseError<string>).error.issues[0].message;
  }
  return true;
};
