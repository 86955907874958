export interface LoyaltyHistory {
  date: string;
  time: string;
  vc: number;
  getMoney: string;
  exchangeRate: string;
  status: string;
}

export interface InitialState {
  points: number;
  exchangeRate: string;
  historyList: LoyaltyHistory[];
}
