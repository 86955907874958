import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchUserTransactions,
  transactionsSelectors,
} from '@box/redux/finance';

export const withPagination = (Component) => {
  const wrapper = ({ ...props }) => {
    const { listId, requestMeta, userId } = props;
    const dispatch = useDispatch();

    const currentList = useSelector((state) =>
      transactionsSelectors.listById(state, listId)
    );

    const nextPage = () => {
      dispatch(
        fetchUserTransactions({
          id: listId,
          userId,
          page: currentList.currentPage + 1,
          ...requestMeta,
        })
      );
    };

    return (
      <Component
        {...props}
        nextPage={nextPage}
        isNextPageAvailable={currentList.currentPage < currentList.lastPage}
        pagingLoading={currentList.loading}
      />
    );
  };

  return wrapper;
};
