import React from 'react';

import { ImageSet } from '@box/ui';

import ErrorPng from '../assets/images/error.png';
import ErrorWebp from '../assets/images/error.webp';
import ErrorAvif from '../assets/images/error.avif';
import styles from '../photos.module.scss';
import { PhotoErrorProps } from './PhotoError.types';

export const PhotoError: React.FC<PhotoErrorProps> = ({ label }) => {
  return (
    <div className={styles.imageError}>
      <p className={styles.imageError_label}>{label}</p>
      <ImageSet
        className={styles.imageError_image}
        src={ErrorPng}
        srcWebp={ErrorWebp}
        srcAvif={ErrorAvif}
        alt='error'
      />
    </div>
  );
};
