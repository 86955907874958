import React, { FC } from 'react';

import { Props } from './Label.types';

export const Label: FC<Props> = ({ id, label, icon: Icon }) => {
  return (
    <label htmlFor={id}>
      {label}
      {Icon && <Icon />}
    </label>
  );
};
