import React, { FC } from 'react';
import classNames from 'classnames';

import { BadgeProps } from '../BonusBadge.types';

import styles from './default.module.scss';
import { ReactComponent as BonusSvg } from './assets/images/bonus.svg';

export const Default: FC<BadgeProps> = ({
  handleBadgeClick,
  isReady,
  isOpen,
  percent,
  handleCloseClick,
}) => {
  return (
    <div
      onClick={handleBadgeClick}
      role='button'
      onKeyPress={handleBadgeClick}
      tabIndex={0}
      className={classNames(styles.badge, {
        [styles.badge_open]: isReady && isOpen && percent,
      })}
    >
      <button type='button' onClick={handleCloseClick} className={styles.close}>
        +
      </button>
      <div className={styles.svg}>
        <BonusSvg />
      </div>
      <p className={styles.text}>+{percent}%</p>
    </div>
  );
};
