import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openInfoPopup } from '@box/redux/app';

import { useApp } from './useApp';
import { appConfig } from '../config';

let deferredPrompt;

export const usePwa = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { route, userEnv } = useApp();

  const [isInstalled, setIsInstalled] = useState(
    localStorage.getItem('is_pwa_installed')?.toString() === String(1) || false
  );

  useEffect(() => {
    window.addEventListener(
      'beforeinstallprompt',
      (e) => {
        e.preventDefault();

        setIsInstalled(false);
        deferredPrompt = e;
      },
      true
    );

    window.addEventListener('appinstalled', () => {
      dispatch(openInfoPopup({ type: 'success', text: t('pwa.success') }));
    });

    if (!userEnv.isSafari) {
      setTimeout(() => {
        setIsInstalled(!deferredPrompt);
      }, 1);
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }
  }, [deferredPrompt]);

  const installPwa = async () => {
    if (userEnv.isAndroidDevice) {
      const href = document.createElement('a');
      href.setAttribute('target', '_blank');
      href.setAttribute(
        'href',
        `/com.${appConfig.apk_name}.android(release).apk`
      );

      href.click();

      return;
    }

    if (!deferredPrompt) {
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        navigate(route('pwa-instruction'));

        return;
      }

      return;
    }

    deferredPrompt.prompt();
    const choiceResult = await deferredPrompt.userChoice;

    if (choiceResult.outcome === 'accepted') {
      localStorage.setItem('is_pwa_installed', String(1));
      setIsInstalled(true);
    }
  };

  return {
    isInstalled: userEnv.isFirefox ? false : isInstalled,
    installPwa,
  };
};
