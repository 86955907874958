import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { fetchChatsRegister } from '@box/redux/chats/register';
import { fetchInitChatsUser } from '@box/redux/chats/init';
import { useApp } from '@box/shared/hooks';
import { useAppDispatch, useAuthTokens } from '@box/redux/hooks';
import { setChatOpen } from '@box/redux/support';

import { Button, LinkIcon } from '../../../baseComponents';

import { SupportProps } from './Support.types';
import styles from './Support.module.scss';

export const Support: React.FC<SupportProps> = ({ className }) => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const dispatch = useAppDispatch();

  const { crmAccessToken } = useAuthTokens();

  useEffect(() => {
    if (crmAccessToken) {
      dispatch(fetchInitChatsUser({}));
    } else {
      dispatch(fetchChatsRegister());
    }
  }, []);

  const openSupportChat = () => dispatch(setChatOpen(true));

  return (
    <div
      className={classNames(styles.support, styles['content-block'], className)}
    >
      <span className={styles['content-block-title']}>
        {t('footer.support.question')}
      </span>
      <div className={styles['content-block-buttons']}>
        <Button
          color='primary'
          className={classNames(
            styles['content-button'],
            styles['support-button'],
            { [styles['content-button_mobile']]: isMobile }
          )}
          icon={<LinkIcon name='chat' />}
          iconPos='right'
          onClick={openSupportChat}
        >
          {t('footer.support.support')}
        </Button>
      </div>
    </div>
  );
};
