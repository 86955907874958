import { getStoragePath } from './getStoragePath';

export const getDesktopBanners = (list) => {
  if (!Array.isArray(list)) return list;

  return list.map((item) => ({
    ...item,
    title: item.title?.includes('_') ? '' : item.title,
    description: item.description,
    backgroundImg: getStoragePath(item.images?.desktop, 'main'),
    bonus: item.bonus,
  }));
};

export const getStaticBanner = (banner) =>
  banner
    ? {
        ...banner,
        title: banner.title?.includes('_') ? '' : banner.title,
        description: banner.description,
        backgroundImg: getStoragePath(banner?.images?.desktop, 'main'),
        bonus: banner.bonus,
      }
    : {};

export const getMobileBanners = (list) => {
  if (!Array.isArray(list)) return [];

  return list.map((item) => ({
    ...item,
    title: item.title?.includes('_') ? '' : item.title,
    description: item.description,
    backgroundImg: getStoragePath(item.images?.mobile, 'main'),
    bonus: item.bonus,
  }));
};

export const isShowBanner = (auth, logged) => {
  if (auth === 'auth') return logged;

  if (auth === 'guest') return !logged;

  return true;
};
