import Img1 from './assets/1.png';
import Img1Webp from './assets/1.webp';
import Img1Avif from './assets/1.avif';
import Img2 from './assets/2.png';
import Img2Webp from './assets/2.webp';
import Img2Avif from './assets/2.avif';
import Img3 from './assets/3.png';
import Img3Webp from './assets/3.webp';
import Img3Avif from './assets/3.avif';
import Img4 from './assets/4.png';
import Img4Webp from './assets/4.webp';
import Img4Avif from './assets/4.avif';
import Img5 from './assets/5.png';
import Img5Webp from './assets/5.webp';
import Img5Avif from './assets/5.avif';

export const images = {
  free_spins: {
    png: Img1,
    webp: Img1Webp,
    avif: Img1Avif,
  },
  '1st_dep_bonuses': {
    png: Img3,
    webp: Img3Webp,
    avif: Img3Avif,
  },
  '2nd_dep_bonuses': {
    png: Img3,
    webp: Img3Webp,
    avif: Img3Avif,
  },
  '3rd_dep_bonuses': {
    png: Img3,
    webp: Img3Webp,
    avif: Img3Avif,
  },
  '4th_dep_bonuses': {
    png: Img3,
    webp: Img3Webp,
    avif: Img3Avif,
  },
  bonuses: {
    png: Img2,
    webp: Img2Webp,
    avif: Img2Avif,
  },
  '1st_dep_free_spins': {
    png: Img4,
    webp: Img4Webp,
    avif: Img4Avif,
  },
  '2nd_dep_free_spins': {
    png: Img4,
    webp: Img4Webp,
    avif: Img4Avif,
  },
  '3rd_dep_free_spins': {
    png: Img4,
    webp: Img4Webp,
    avif: Img4Avif,
  },
  birthday: {
    png: Img5,
    webp: Img5Webp,
    avif: Img5Avif,
  },
  next_dep_bonuses: {
    png: Img3,
    webp: Img3Webp,
    avif: Img3Avif,
  },
};
