import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Step } from './Step';

import { stepsData } from './Steps.data';

import styles from './steps.module.scss';

export const Steps: FC = () => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.steps, { [styles.steps_mobile]: isMobile })}
    >
      {stepsData.map((item, index) => (
        <Step className={styles.step} key={index} {...item} />
      ))}
    </div>
  );
};
