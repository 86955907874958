import { useLocation } from 'react-router-dom';

export type UseDisabledByPath = ({
  path,
}: {
  path: string;
}) => (callback: () => void) => void;

export const useDisabledByPath: UseDisabledByPath = ({ path }) => {
  const location = useLocation();

  const handleDisabled = (callback: () => void) => {
    if (location.pathname !== path) {
      callback();
    }
  };

  return handleDisabled;
};
