import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Arrows, Button } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { Props } from './Main.types';

import styles from './main.module.scss';

export const Main: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();
  return (
    <div
      className={classNames(styles.main, { [styles.main_mobile]: isMobile })}
    >
      <h4 className={styles.title}>{t('bonuses.welcome_bonus')}</h4>
      <p className={styles.text}>{t('bonuses.wager_your_bonus')}</p>
      {!isMobile && (
        <>
          <p className={styles.description}>{t('bonuses.stake_is_counted')}</p>
          <p className={styles.description}>
            {t('bonuses.after_verification')}
          </p>
        </>
      )}
      <Button
        color='secondary'
        type='button'
        size='x-large'
        bold
        onClick={onClick}
        className={styles.buttonSingle}
      >
        {t('bonuses.activate')}
      </Button>
      <Arrows className={styles.arrows} />
    </div>
  );
};
