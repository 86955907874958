import Cookies from 'js-cookie';
import { checkForJSON } from '@box/shared/utils';

export const setFirstVisitParams = (params: object) =>
  Cookies.set('first_visit_params', JSON.stringify(params), {
    path: '/',
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  });
export const getFirstVisitParams = () =>
  checkForJSON(Cookies.get('first_visit_params')) || ({} as any);
