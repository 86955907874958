import React from 'react';

import { UserEnvironment } from '../types';

type Language = {
  label: string;
  value: string;
};
export interface IAppContext {
  route: (pathName: string, params?: { [key: string]: string }) => string;
  availableLanguages: Language[];
  mirrors: {
    isMirror1: boolean;
    isMirror2: boolean;
    isMirror3: boolean;
  };
  userEnv: UserEnvironment;
}

export const AppContext = React.createContext<IAppContext>({} as IAppContext);

export default AppContext;
