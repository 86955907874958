import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  FC,
  ChangeEvent,
} from 'react';
import debounce from 'debounce';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { fetchGames, resetList, currentGameSelectors } from '@box/redux/games';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { useApp, useCurrentGameList } from '../../hooks';

import styles from './MenuSearch.module.scss';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { MenuSearchProps } from './MenuSearch.types';
import { SearchList } from './SearchList';

export const MenuSearch: FC<MenuSearchProps> = ({
  setVisible,
  className,
  isOpen,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune },
  } = useApp();

  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState<string>('');

  const gameLoading = useAppSelector(currentGameSelectors.loading);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const gamesList = useCurrentGameList('search');

  const debounceChange = useCallback(
    debounce((value: string) => {
      if (value.trim()) {
        dispatch(resetList('search'));
        dispatch(fetchGames({ list: 'search', search: value }));
      } else {
        dispatch(resetList('search'));
      }
    }, 500),
    []
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32 && inputValue.trim() === '') {
      e.preventDefault();
    }
  };

  const onClose = () => {
    setVisible(false);
    setInputValue('');
    dispatch(resetList('search'));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debounceChange(e.target.value);
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setVisible(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    inputRef.current.focus();

    return () => {
      setInputValue('');
      dispatch(resetList('search'));
    };
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, []);

  return (
    <div
      className={classNames(
        styles.search,
        { [styles.search_june]: isJune },
        className
      )}
      ref={containerRef}
    >
      <input
        className={styles.input}
        type='text'
        placeholder={t('sidebar.search')}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={inputValue}
        disabled={gameLoading}
        ref={inputRef}
      />
      <SearchList
        loading={gamesList?.loading}
        list={gamesList?.data}
        onClose={onClose}
        gameLoading={gameLoading}
      />
      <button className={styles.close} type='button' onClick={onClose}>
        <CloseIcon />
      </button>
    </div>
  );
};
