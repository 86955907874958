import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { Props } from './MinMax.types';

import styles from './minMax.module.scss';

export const MinMax: FC<Props> = ({ min, max, currency }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const { setValue } = useFormContext();

  const handleMinClick = () => {
    setValue('amount', min);
  };

  const handleMaxClick = () => {
    setValue('amount', max);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_desktop]: isDesktop,
      })}
    >
      <button onClick={handleMinClick} type='button' className={styles.button}>
        {t('deposit.min')} {min} {currency}
      </button>
      <button onClick={handleMaxClick} type='button' className={styles.button}>
        {t('deposit.max')} {max} {currency}
      </button>
    </div>
  );
};
