import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { Collapsed } from '@box/shared/baseComponents';
import { LightButton, Text } from '@box/ui';
import { useTranslation } from 'react-i18next';
import { useApp } from '@box/shared/hooks';

import { FlexibleFields, Amount, Controller } from '../../../Payments';

import { Props } from './Form.types';

import styles from './form.module.scss';

export const Form: FC<Props> = ({
  onSubmit,
  isOpen,
  amount,
  currency,
  fields,
  amountLabel,
  submitLoading,
  error,
  available,
  isDepositWagered,
  commissionPercent,
  blocked,
  onBlockedBlur,
  onBlockedChange,
  inputWithRateError,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <Collapsed isOpen={isOpen}>
      <form
        className={classNames(styles.form, {
          [styles.form_mobile]: isMobile,
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          size={isMobile ? 'lg' : 'xxl'}
          weight='800'
          color='violet'
          as='span'
        >
          {t('withdrawalRequest.details')}
        </Text>
        <Text
          className={styles.withdrawal_details}
          size={isMobile ? 'xs' : 'sm'}
          color='violet'
          as='div'
        >
          {t('withdrawalRequest.ready_to_withdrawal')}:{' '}
          <Text
            as='span'
            size={isMobile ? 'xs' : 'sm'}
            color='violet'
            weight={700}
          >
            {available}
            {currency}
          </Text>
        </Text>
        <div>
          {isDepositWagered ? (
            <FlexibleFields
              rowInDiv
              className={styles.flexible}
              fields={fields}
            >
              <Amount
                amount={amount}
                currencySymbol={currency}
                label={`${amountLabel} (${t(
                  'deposit.max'
                )}: ${available}${currency})`}
              />
              <LightButton
                color='blue'
                type='submit'
                fixHeight
                disabled={
                  !!inputWithRateError || submitLoading || available <= 0
                }
                loading={submitLoading}
                className={styles.button}
              >
                {t('withdrawalRequest.continue')}
              </LightButton>
            </FlexibleFields>
          ) : (
            <>
              <div className={styles.flexible}>
                <Amount
                  className={styles.amount}
                  amount={amount}
                  currencySymbol={currency}
                  label={amountLabel}
                />
                <Controller
                  defaultValue={`-${commissionPercent}%`}
                  disabled
                  label={t('withdrawalRequest.blocked')}
                  type='text'
                  placeholder={`-${commissionPercent}%`}
                  className={styles.percent}
                  withPopup
                  isWithdrawal={isMobile}
                  popupText={t('withdrawalRequest.popup_text', {
                    percent: commissionPercent,
                    amount: available,
                    currency,
                  })}
                />
                <Controller
                  label={t('withdrawalRequest.total_to_be_received')}
                  type='number'
                  placeholder={`${amount.min} ${currency || ''}`}
                  className={styles.input}
                  value={blocked}
                  onChange={onBlockedChange}
                  onBlur={onBlockedBlur}
                  errorMessage={inputWithRateError}
                />
              </div>
              <FlexibleFields
                rowInDiv
                className={styles.flexible}
                fields={fields}
              >
                <LightButton
                  color='blue'
                  type='submit'
                  fixHeight
                  disabled={
                    !!inputWithRateError || submitLoading || available <= 0
                  }
                  loading={submitLoading}
                  className={styles.button}
                >
                  {t('withdrawalRequest.continue')}
                </LightButton>
              </FlexibleFields>
            </>
          )}
          {error && <p className={styles.error}>{error}</p>}
        </div>
      </form>
    </Collapsed>
  );
};
