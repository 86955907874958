import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Lazy from 'react-lazyload';

import './imageLoader.scss';

export const ImageLoader = ({ className, src, alt, fullback: Loading }) => {
  const [loading, setLoading] = useState(true);
  const imgRef = useRef();

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const PlaceholderComponent =
    typeof Loading === 'function' ? <Loading /> : Loading;

  return (
    <Lazy className={classNames('image', className)} offset={100}>
      <div className='image__placeholder'>{PlaceholderComponent}</div>
      <img
        src={src}
        alt={alt}
        ref={imgRef}
        style={{ display: loading ? 'none' : 'unset' }}
        onLoad={stopLoading}
      />
    </Lazy>
  );
};

ImageLoader.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  fullback: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

ImageLoader.defaultProps = {
  className: '',
  alt: '',
};
