import { createSlice } from '@reduxjs/toolkit';

import { addTransaction } from './actions';
import { fetchUserTransactions } from './thunks';
import { TransactionInitialState } from './types';

const defaultList = {
  loading: true,
  list: [],
  currentPage: 1,
  lastPage: 1,
  ready: false,
};

const initialState: TransactionInitialState = {};

export const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTransactions.pending, (state, { meta }) => {
        const currentList = state[meta.arg.id];

        if (!currentList) {
          state[meta.arg.id] = defaultList;
        } else {
          currentList.loading = true;
        }
      })
      .addCase(fetchUserTransactions.fulfilled, (state, { payload, meta }) => {
        const currentList = state[meta.arg.id];

        currentList.loading = false;
        currentList.list = payload.list;
        currentList.currentPage = payload.currentPage;
        currentList.lastPage = payload.lastPage;
        currentList.ready = true;
      })
      .addCase(fetchUserTransactions.rejected, (state, { meta }) => {
        const currentList = state[meta.arg.id];

        currentList.ready = true;
        currentList.loading = false;
      })
      .addCase(addTransaction, (state, { payload }) => {
        const newTransaction = { ...payload.transaction, status: 'pending' };

        payload.targetLists.forEach((item) => {
          const currentList = state[item];

          if (currentList) {
            currentList.list.unshift(newTransaction);
          }
        });
      });
  },
});
