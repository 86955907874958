export interface Tournament {
  id: number;
  title: string;
  description: string;
  status: string;
  started_at: string;
  ended_at: string;
  winner_id: number;
  created_at: string;
  updated_at: string;
  images: {
    main: string;
    mobile: string;
    original: string;
  };
  winners: unknown;
  prize_pool: string;
}
