import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DecemberBonusModal, Promocode } from '@box/shared/components';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { prepareUserBonusesWithImage } from '@box/shared/utils';

import { ActiveBonus } from '../ActiveBonus';
import { List } from './List';

import { Props } from './BonusesContent.types';

import styles from './bonusesContent.module.scss';

export const BonusesContentDecember: FC<Props> = ({
  bonusParam,
  currentBonus,
  activeBonusParam,
  currentActiveBonus,
  userBonusesLoading,
  loading,
  list,
  isLogged,
  bonusFilter,
  ended,
  activated,
  created,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.container_hidden]: activeBonusParam || bonusParam,
        })}
      >
        <h4 className={styles.title}>{t('promocodes.promocodes')}</h4>
        <Promocode />
        {!isLogged ? (
          <BlockWithLoading loading={loading || userBonusesLoading}>
            {list?.length > 0 && (
              <List
                counter={list.length}
                list={list}
                label={t('bonuses.all')}
              />
            )}
          </BlockWithLoading>
        ) : bonusFilter === 'history' ? (
          ended?.length > 0 && (
            <List
              counter={ended?.length}
              list={prepareUserBonusesWithImage(ended)}
              label={t('bonuses.completed')}
            />
          )
        ) : (
          <>
            {activated?.length > 0 && (
              <List
                counter={activated?.length}
                label={t('bonuses.active')}
                list={prepareUserBonusesWithImage(activated)}
              />
            )}
            {created?.length > 0 && (
              <List
                counter={created?.length}
                label={t('bonuses.not_started')}
                list={prepareUserBonusesWithImage(created)}
              />
            )}
          </>
        )}
      </div>
      {activeBonusParam && currentActiveBonus && (
        <ActiveBonus
          progression_percentage={currentActiveBonus?.progress?.percent}
          status={currentActiveBonus?.status}
          name={currentActiveBonus?.name}
          amount={currentActiveBonus?.progress?.amount}
          total={currentActiveBonus?.progress?.total}
          wager={currentActiveBonus?.progress?.wager}
          description={currentActiveBonus?.description}
        />
      )}
      {bonusParam && currentBonus && (
        <DecemberBonusModal bonusId={currentBonus.id} {...currentBonus} />
      )}
    </>
  );
};
