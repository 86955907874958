import { Currency, Language } from '../common';
import { Game } from '../games';

export interface Permissions {
  id: number;
  name: string;
}

export enum UserRolesNames {
  'admin' = 'admin',
  'manager' = 'manager',
  'user' = 'user',
  'bot' = 'bot',
  'fraud' = 'fraud',
  'pending_verification' = 'pending_verification',
  'verification_denied' = 'verification_denied',
  'verified' = 'verified',
  'blocked' = 'blocked',
  'email_verified' = 'email_verified',
}

export type UserRole = {
  description: string | null;
  id: number;
  name: UserRolesNames;
  priority: number;
  permissions: Permissions[];
};
export type UserRolesList = UserRole[];

export enum Gender {
  MALE = 'm',
  FEMALE = 'f',
}

export type UserRates = {
  action: string;
  countries_code: string[];
  currency: string;
  min_value: number;
  rate: number;
};

export type UserData = {
  created_at: string;
  id: number;
  name: string;
  surname: string;
  birthdate: string;
  country_id: number;
  currency_id: number;
  language_id: number;
  access_token: string;
  city: string;
  phone: string;
  email: string;
  permissions: Permissions[];
  address: string;
  gender: Gender;
  roles?: UserRolesList;
  currency?: Currency;
  language: Language;
  name_is_default?: boolean;
  gameSessions: Session[];
  partner_params: Record<string, unknown>;
  deposits_count: number;
  is_deposit_wagered?: boolean;
  partner_stream: {
    code: string;
    id: number;
    name: string;
    url: string;
    rates: UserRates[];
  };
  deposit_bonus: {
    bonus: {
      id: number;
      name: string;
      type: string;
      alias: string;
      active?: boolean;
      getting_params: {
        max: string;
        min: string;
        percent: string;
      }[];
    };
  };
};

export type Country = {
  id: number;
  code: string;
  name: string;
  default_currency_code: string;
  default_language_code: string;
  defaultCurrency: {
    id: number;
    code: string;
    name: string;
    symbol: string;
  };
  defaultLanguage: {
    id: number;
    code: string;
    name: string;
    native_name: string;
  };
};

export interface Session {
  id: number;
  uuid: string;
  user_id: number;
  game_id: number;
  ip: string;
  language_code: string;
  country_code: string;
  is_mobile: boolean;
  demo_mode: boolean;
  return_url: string;
  game: Game;
  technology: {
    height: number;
    src: string;
    href: string;
    type: string;
    width: number;
  };
  userdata: UserData;
  currency: {
    id: 0;
    code: string;
    name: string;
    symbol: string;
  };
  language: {
    id: 0;
    code: string;
    name: string;
    native_name: string;
  };
}
