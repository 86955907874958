import { useEffect, useState } from 'react';

const PLAYERS_COUNT_KEY = 'players_count';

export const usePlayersCount = (): { count: number } => {
  const [count, setCount] = useState<number>(
    JSON.parse(localStorage.getItem(PLAYERS_COUNT_KEY)) ||
      Math.floor(Math.random() * 1000) + 4000
  );

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (!interval) {
      interval = setInterval(() => {
        const num =
          Math.floor(Math.random() * 100) * (Math.random() < 0.5 ? -1 : 1);
        let newCount: number;

        if (count - num <= 4000) {
          newCount = count + num;
        } else if (count + num >= 5000) {
          newCount = count - num;
        } else {
          newCount = count + num;
        }

        setCount(newCount);
        localStorage.setItem(PLAYERS_COUNT_KEY, JSON.stringify(newCount));
      }, 10000);
    }

    return () => clearInterval(interval);
  }, []);

  return { count };
};
