import { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import toPairs from 'lodash/toPairs';

/* 
breakPointsExample = {
  1200: 6,
  900: 5,
  600: 4
}
*/

export const useBreakPoints = (breakPoints = {}) => {
  const [value, setValue] = useState();
  const mediasRef = useRef([]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isEmpty(breakPoints)) {
      // Сортировка по убыванию ключей
      const pairs = toPairs(breakPoints).sort((a, b) => b[0] - a[0]);

      mediasRef.current = pairs.map((item, idx) => {
        let media;

        const isFirst = idx === 0;
        const nextItem = pairs[idx + 1];

        if (isFirst) {
          media = matchMedia(`(min-width: ${pairs[1][0]}px)`);
        } else if (nextItem) {
          media = matchMedia(
            `(min-width: ${nextItem[0]}px) and (max-width: ${item[0]}px)`
          );
        } else {
          media = matchMedia(`(max-width: ${item[0]}px)`);
        }

        const change = (e) => {
          if (e.matches) setValue(item[1]);
        };

        if (media.addEventListener) {
          media.addEventListener('change', change);
        } else if (media.addListener) {
          media.addListener(change);
        }

        // Инициализация начального значения
        if (media.matches) setValue(item[1]);

        return {
          media,
          // Сохранение события для очистки
          change,
        };
      });

      return () => {
        // Очистка событий
        mediasRef.current?.forEach((item) => {
          if (item.media.removeEventListener) {
            item.media.removeEventListener('change', item.change);
          } else if (item.media.removeListener) {
            item.media.removeListener(item.change);
          }
        });
      };
    }
  }, []);

  return value;
};
