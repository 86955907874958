import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';
import { selectIsDisabled } from '@box/redux/forms';
import { Input } from '@box/ui';

import styles from '../personalData.module.scss';
import { Inputs } from '../PersonalData.types';

export const NamesFields = memo(() => {
  const { t } = useTranslation();
  const disabled = useAppSelector(selectIsDisabled);
  const {
    register,
    formState: { errors },
  } = useFormContext<Inputs>();
  return (
    <>
      <Input
        placeholder={t('settings.name')}
        label={t('settings.name')}
        className={styles.control}
        error={t(errors.name?.message)}
        {...register('name', {
          disabled,
        })}
      />
      <Input
        placeholder={t('settings.surname')}
        error={t(errors.surname?.message)}
        label={t('settings.surname')}
        className={styles.control}
        {...register('surname', {
          disabled,
        })}
      />
    </>
  );
});
