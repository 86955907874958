import React, { useMemo } from 'react';
import { userBonusesSelectors, balanceSelectors } from '@box/redux/user';
import { commonSelectors } from '@box/redux/common';
import { useAppSelector } from '@box/redux/hooks';
import {
  WithBonusWalletsWrapperProps,
  BonusWalletsStateProps,
} from './BonusWallets.types';

export const withState = <Props,>(
  Component: React.FC<Props & BonusWalletsStateProps>
) => {
  const wrapper = ({
    isOpen,
    ...props
  }: WithBonusWalletsWrapperProps<Props>) => {
    const defaultBonuses = useAppSelector(userBonusesSelectors.activeBonuses);
    const bonusBalance = useAppSelector(balanceSelectors.bonusBalance);
    const bonusesTypes = useAppSelector(commonSelectors.bonusTypes);
    const symbol = useAppSelector(balanceSelectors.currencySymbol);

    const bonuses = useMemo(
      () =>
        defaultBonuses.reduce(
          (acc, item) => [
            ...acc,
            {
              label: bonusesTypes[item.type],
              value: item.amount,
            },
          ],
          []
        ),
      [defaultBonuses, bonusesTypes]
    );

    const summary = useMemo(
      () => bonuses.reduce((acc, item) => acc + item.value, 0),
      [bonuses]
    );

    return (
      isOpen && (
        <Component
          {...(props as Props)}
          bonusBalance={bonusBalance}
          bonuses={bonuses}
          summary={summary}
          symbol={symbol}
        />
      )
    );
  };

  return wrapper;
};
