import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { isShowBanner } from '../utils/banners';

import { AvailablePositions } from './types';

export const allBanners = (state: RootState) => state.banners;
const isLogged = (state: RootState) => state.auth.init.isLogged;

const extra = (_state, position: AvailablePositions) => position;

export const getBannerListByPosition = createSelector(
  [allBanners, extra, isLogged],
  (banners, position, logged) => ({
    ...banners[position],
    list:
      banners[position]?.list
        .filter((item) => isShowBanner(item.auth, logged))
        .sort((a, b) => b.weight - a.weight) || [],
  })
);
