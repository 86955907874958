import React, { FC } from 'react';
import classNames from 'classnames';
import { useAppSelector } from '@box/redux/hooks';
import { currentGameSelectors } from '@box/redux/games';

import { SmallLoader } from '../../baseComponents';
import { useOpenGames } from '../../hooks';

import styles from './MenuSearch.module.scss';
import { SearchListItemProps } from './MenuSearch.types';

export const SearchListItem: FC<SearchListItemProps> = ({ item, onClose }) => {
  const { openGame, loadingId } = useOpenGames();
  const gameLoadingId = useAppSelector(currentGameSelectors.loadedId);

  const isLoading = gameLoadingId === loadingId;

  const onClick = () => {
    openGame({ id: item.id, hasDemo: item.has_demo, withRedirect: true });
    onClose();
  };

  return (
    <li
      className={classNames(styles.listItem, {
        [styles['listItem--loading']]: isLoading,
      })}
    >
      <div onClick={onClick} className={styles.listItemLink} aria-hidden='true'>
        {item.name}
      </div>
      <SmallLoader isActive={isLoading} />
    </li>
  );
};
