import React, { FC } from 'react';
import classNames from 'classnames';

import { setChatOpen, supportSelectors } from '@box/redux/support';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';

import { Props } from './Badge.types';

import styles from './badge.module.scss';

import { ReactComponent as ChatIcon } from './assets/images/chat.svg';

export const Badge: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();

  const isChatOpen = useAppSelector(supportSelectors.isOpen);
  const unreadCount = useAppSelector(supportSelectors.unreadMessagesCount);

  const open = () => dispatch(setChatOpen(!isChatOpen));

  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.badge, className, {
        [styles.badge_hidden]: isChatOpen,
        [styles.badge_mobile]: isMobile,
      })}
      onClick={open}
      aria-hidden='true'
    >
      {unreadCount ? (
        <div className={styles['unread-counter']}>+{unreadCount}</div>
      ) : null}
      <ChatIcon />
    </div>
  );
};
