import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { userSelectors } from '@box/redux/user';
import { emailResend } from '@box/redux/email';

import { getPureErrorMessage } from '../../utils';
import { AppThunkApi } from '../../types';
import {
  openInfoPopup,
  hideModal,
  openModal,
  thunkWithConfirm,
  openErrorPopup,
} from '../../app';

import { addWithdrawal } from './actions';
import {
  FetchWithdrawalRequestsPayload,
  FetchWithdrawalRequestsReturn,
  GETWithdrawalRequests,
  POSTWithdrawalRequests,
  WithdrawalRequestPayload,
  WithdrawalRequestReturn,
} from './types';
import { getOrCreateWallet } from './_getOrCreateWallet';

export const fetchWithdrawalRequests = createAsyncThunk<
  FetchWithdrawalRequestsReturn,
  FetchWithdrawalRequestsPayload,
  AppThunkApi
>(
  'finance/fetchWithdrawalRequests',
  async (payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    const subtypeIn = ['withdrawal', 'withdrawal_refund'];

    const { userId, sort, sortby, page, type } = payload;

    try {
      const { data, status } = await api.get<GETWithdrawalRequests>(
        `/userdatas/${userId}/withdrawal-requests`,
        { params: { sort, sortby, page, status: type, subtypeIn } }
      );

      if (status === 200) {
        return {
          list: data.data,
          currentPage: data.meta.current_page,
          lastPage: data.meta.last_page,
        };
      }

      return rejectWithValue('error');
    } catch (e) {
      return rejectWithValue('error');
    }
  }
);

export const withdrawalRequest = createAsyncThunk<
  WithdrawalRequestReturn,
  WithdrawalRequestPayload,
  AppThunkApi
>(
  'finance/withdrawalRequest',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api, isJune } = extra;

    const isWithdrawal = userSelectors.isWithdrawal(getState());

    const {
      userId,
      amount,
      walletId,
      name,
      fieldValues,
      methodId,
      merchantId,
      confirm,
      amountError,
      currencyCode,
      minDep,
    } = payload;

    try {
      const { error, withdrawalWalletId } = await getOrCreateWallet({
        userId,
        name,
        fieldValues,
        methodId,
        merchantId,
        api,
        confirm,
      });

      if (error) {
        return rejectWithValue(error);
      }

      const { data, status } = await api.post<POSTWithdrawalRequests>(
        `/userdatas/${userId}/withdrawal-requests`,
        {
          amount,
          wallet_id: walletId,
          withdrawal_wallet_id: withdrawalWalletId,
          confirm,
        },
        {
          params: {
            append: 'success_create_message',
          },
        }
      );

      if (status === 201) {
        const successMessage =
          data.success_create_message ||
          i18next.t('withdrawalRequest.success_submit_text');

        dispatch(hideModal('funds'));
        dispatch(
          openInfoPopup({
            text: successMessage,
            type: 'success',
          })
        );

        dispatch(
          addWithdrawal({
            withdrawal: data,
            lists: ['withdrawal-waiting', 'withdrawal'],
          })
        );

        return data;
      }

      if (status === 400) {
        if (data?.errors?.email_verified) {
          if (isJune) {
            dispatch(
              thunkWithConfirm({
                title: i18next.t('deposit.email_error.title'),
                text: i18next.t('deposit.email_error.text'),
                yes: i18next.t('deposit.email_error.button'),
                onlyYes: true,
                callback: () => {
                  dispatch(emailResend());
                },
              })
            );
          } else {
            dispatch(
              thunkWithConfirm({
                title: i18next.t('user_actions.email_verify'),
                text: i18next.t('settings.email_not_verified'),
                yes: i18next.t('settings.send_mail_again'),
                no: i18next.t('bonuses.cancel'),
                callback: () => {
                  dispatch(emailResend());
                },
              })
            );
          }

          return;
        }
        if (data?.errors?.confirm) {
          if (!isWithdrawal) {
            dispatch(openModal('to-settings'));
          } else {
            dispatch(
              thunkWithConfirm({
                title: i18next.t('verified.confirm.title'),
                text: data.errors.confirm[0],
                yes: i18next.t('verified.confirm.accept'),
                no: i18next.t('verified.confirm.reject'),
                callback: () => {
                  if (amountError) {
                    const minSum = Math.floor((minDep / 74) * 100);
                    dispatch(
                      openErrorPopup({
                        title: i18next.t('withdrawalRequest.min_dep.title'),
                        text: i18next.t('withdrawalRequest.min_dep.text', {
                          amount,
                          currency: currencyCode,
                          minDep,
                          value: Math.floor(minSum * 75) / 100,
                          minSum,
                        }),
                      })
                    );
                  } else {
                    dispatch(
                      withdrawalRequest({
                        userId,
                        name,
                        fieldValues,
                        methodId,
                        merchantId,
                        amount,
                        walletId,
                        confirm: 1,
                      })
                    );
                  }
                },
              })
            );
          }
          return rejectWithValue('');
        }
        return rejectWithValue(getPureErrorMessage(data));
      }

      return rejectWithValue(getPureErrorMessage(data));
    } catch (e) {
      return rejectWithValue('error');
    }
  }
);
