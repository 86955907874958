import React from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPopup } from '../../../baseComponents';
import { useToggleActiveElement } from '../../../hooks';

export const VerifyBadge = () => {
  const { t } = useTranslation();

  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  return (
    <div
      className='settings-verify-badge'
      onClick={toggleOpen}
      aria-hidden='true'
      ref={elementRef}
    >
      <span />
      <DetailPopup className='settings-verify-badge__info' isOpen={isOpen}>
        {t('settings.success_verified')}
      </DetailPopup>
    </div>
  );
};
