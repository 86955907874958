import { createSlice } from '@reduxjs/toolkit';

import { thunkWithGlobalLoader } from './thunks';

const initialState = {
  isActive: false,
};

export const slice = createSlice({
  name: 'globalLoader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkWithGlobalLoader.pending, (state) => {
        state.isActive = true;
      })
      .addCase(thunkWithGlobalLoader.fulfilled, (state) => {
        state.isActive = false;
      })
      .addCase(thunkWithGlobalLoader.rejected, (state) => {
        state.isActive = false;
      });
  },
});
