import React, { useEffect, useState, FC } from 'react';
import reactDom from 'react-dom';

import { useTranslation } from 'react-i18next';

import { closeInfoPopup, infoPopupSelectors } from '@box/redux/app';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';
import { Popup as PopupUi, LightPopup } from '@box/ui';

import { Props } from './Popup.types';

import { ReactComponent as SuccessSvg } from './assets/images/success.svg';
import { ReactComponent as ErrorSvg } from './assets/images/error.svg';
import { ReactComponent as ChatSvg } from './assets/images/chat.svg';

export const Element: FC<Props> = ({
  text,
  type,
  onCloseHandler,
  isCurrent,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 500);
  }, []);

  const handleClick = () => {
    setOpen(false);
    setTimeout(() => {
      onCloseHandler();
    }, 500);
  };

  const whichIcon = (type) => {
    switch (type) {
      case 'chat':
        return ChatSvg;
      case 'error':
        return ErrorSvg;
      default:
        return SuccessSvg;
    }
  };

  useEffect(() => {
    let timeOutOpen: NodeJS.Timeout;
    let timeOutDispatch: NodeJS.Timeout;

    if (isCurrent) {
      timeOutOpen = setTimeout(() => setOpen(false), 9500);
      timeOutDispatch = setTimeout(() => onCloseHandler(), 10000);
    }

    return () => {
      clearTimeout(timeOutOpen);
      clearTimeout(timeOutDispatch);
    };
  }, [isCurrent]);

  if (isJune) {
    return (
      <LightPopup
        isOpen={isOpen}
        text={text}
        onClose={handleClick}
        icon={whichIcon(type)}
        buttonText={t('common.ok')}
      />
    );
  }
  return reactDom.createPortal(
    <PopupUi
      isOpen={isOpen}
      text={text}
      isSecondary={!isCurrent}
      type={type}
      onClose={handleClick}
      icon={whichIcon(type)}
    />,
    document.body
  );
};

export const Popup = () => {
  const dispatch = useAppDispatch();
  const popupList = useAppSelector(infoPopupSelectors.popupList);

  const onCloseHandler = () => {
    dispatch(closeInfoPopup());
    const lastPopup = popupList[popupList.length - 1];
    if (lastPopup?.callback) {
      lastPopup.callback();
    }
  };

  return (
    <>
      {popupList.map((item, index) => {
        return (
          <Element
            key={index}
            isCurrent={item.id === popupList[popupList.length - 1].id}
            text={item.text}
            type={item.type}
            onCloseHandler={onCloseHandler}
          />
        );
      })}
    </>
  );
};
