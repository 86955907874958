// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__container{height:40px;margin:0 0 12px;position:relative;width:100%}.\\------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__container .\\------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__input{border:none;border-radius:4px;box-sizing:border-box;font-size:14px;height:40px;outline:none;padding:0 0 0 12px;width:100%}.\\------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__container svg{position:absolute;right:16px;top:9px}", "",{"version":3,"sources":["webpack://./../../shared/components/FaqsSearch/FaqsInput/FaqsInput.module.scss"],"names":[],"mappings":"AACA,2EAEE,WAAA,CAEA,eAAA,CADA,iBAAA,CAFA,UAGF,CAEE,kJAME,WAAA,CAHA,iBAAA,CACA,qBAAA,CAIA,cAAA,CANA,WAAA,CAGA,YAAA,CAEA,kBAAA,CANA,UAOJ,CAGE,+EACE,iBAAA,CAEA,UAAA,CADA,OAAJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.container {\n  width: 100%;\n  height: 40px;\n  position: relative;\n  margin: 0 0 12px 0;\n\n  .input {\n    width: 100%;\n    height: 40px;\n    border-radius: 4px;\n    box-sizing: border-box;\n    outline: none;\n    border: none;\n    padding: 0 0 0 12px;\n    font-size: 14px;\n  }\n\n  svg {\n    position: absolute;\n    top: 9px;\n    right: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__container",
	"input": "------shared-components-FaqsSearch-FaqsInput-FaqsInput-module__input"
};
module.exports = ___CSS_LOADER_EXPORT___;
