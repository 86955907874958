import React, { FC } from 'react';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import styles from './Window.module.scss';
import { ReactComponent as JuneSvg } from './assets/images/june.svg';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';
import {
  BackTitleProps,
  ContainerProps,
  HeadProps,
  WindowComponentProps,
} from './Window.types';

const Container: FC<ContainerProps> = ({
  children,
  className,
  size = 'md',
  fullHeight,
  backgroundColor = 'white',
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        styles[`container--${size}`],
        styles[`container_backgroundColor_${backgroundColor}`],
        { [styles.container_fullHeight]: fullHeight }
      )}
      {...props}
    >
      {children}
    </div>
  );
};

const Head: FC<HeadProps> = ({
  children,
  className,
  color,
  isSvg,
  ...props
}) => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  return (
    <div
      className={classNames(
        styles.head,
        { [styles[`head_color_${color}`]]: color && color !== 'default' },
        { [styles.head_desktop]: isDesktop },
        className
      )}
      {...props}
    >
      {isSvg && <JuneSvg />}
      {children}
    </div>
  );
};

const Title: FC<WindowComponentProps> = ({ children, className, ...props }) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <h3
      className={classNames(
        styles.title,
        { [styles.title_light]: isJune },
        className
      )}
      {...props}
    >
      {children}
    </h3>
  );
};

const BackTitle: FC<BackTitleProps> = ({ children, onClick }) => {
  return (
    <button className={styles.backTitle} type='button' onClick={onClick}>
      <div className={styles.svg}>
        <ArrowSvg />
      </div>
      <p className={styles.backText}>{children}</p>
    </button>
  );
};

const Close: FC<WindowComponentProps> = ({ className, ...props }) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(
        styles.close,
        { [styles.close_light]: isJune },
        className
      )}
      {...props}
      aria-hidden='true'
    />
  );
};

const Body: FC<WindowComponentProps> = ({ children, className, ...props }) => (
  <div className={classNames(styles.body, className)} {...props}>
    {children}
  </div>
);

const Footer: FC<WindowComponentProps> = ({
  children,
  className,
  ...props
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(
        styles.footer,
        { [styles.footer_june]: isJune },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export const Window = {
  Container,
  Head,
  Title,
  Close,
  Body,
  Footer,
  BackTitle,
};
