import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { refreshBalance } from '@box/redux/user/balance/thunks';
import { userInitSelectors } from '@box/redux/authentication';

export const useAuthWithInterval = () => {
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  useEffect(() => {
    const interval = setInterval(() => {
      if (isLogged) {
        dispatch(refreshBalance());
      }
      // Баланс будет обновляться раз в 5 минут
    }, 300000);
    return () => clearInterval(interval);
  }, [isLogged]);
};
