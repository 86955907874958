import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './Tab.types';

import styles from './tab.module.scss';

export const Tab: FC<Props> = ({ onClick, children, index, activeTab }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const handleClick = () => {
    onClick(index);
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(styles.button, {
        [styles.button_active]: activeTab === index,
        [styles.button_mobile]: isMobile,
      })}
    >
      {children}
    </button>
  );
};
