import { createSlice } from '@reduxjs/toolkit';

import { setVersion, setVersionOutdated } from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  currentVersion: null,
  isVersionOutdated: false,
};

export const slice = createSlice({
  name: 'app/version',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setVersion, (state, { payload }) => {
        state.currentVersion = payload;
      })
      .addCase(setVersionOutdated, (state, { payload }) => {
        state.isVersionOutdated = payload;
      });
  },
});
