import { useMemo } from 'react';

export const useTransaction = ({ list }) => {
  const { status } = list;

  const statusClass = useMemo(() => {
    if (status === 'approved' || status === 'completed') {
      return 'completed';
    } else if (status === 'declined' || status === 'cancelled') {
      return 'declined';
    } else {
      return 'waiting';
    }
  }, [status]);

  return {
    statusClass,
  };
};
