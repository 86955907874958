import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './LineLoader.types';

import styles from './lineLoader.module.scss';

export const LineLoader: FC<Props> = ({ color = 'white' }) => {
  return (
    <div className={classNames(styles.loader, styles[`loader_color_${color}`])}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
