import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import decemberStyles from '../Base/december.module.scss';
import baseStyles from '../Base/baseInput.module.scss';
import { BaseInputPorps } from '../Base';
import { getInput } from '../getInput';

type MaskedInputProps = NumberFormatProps & BaseInputPorps;

const maskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (props, ref) => <NumberFormat getInputRef={ref} {...props} />
);

export const MaskedInput = getInput(baseStyles, maskedInput);
export const DecemberMaskedInput = getInput(decemberStyles, maskedInput);
