/* eslint-disable max-len */
import { FaqsItemProps } from './FaqsItem';
import { ReactComponent as PayTmSvg } from './assets/images/paytm.svg';
import { ReactComponent as UpiSvg } from './assets/images/upi.svg';
import { ReactComponent as PhonePeSvg } from './assets/images/phonePe.svg';
import { ReactComponent as NetbankingSvg } from './assets/images/netbanking.svg';
import { ReactComponent as VisaSvg } from './assets/images/visa.svg';

import Image1 from './assets/images/image1.png';

export const faqsList: FaqsItemProps[] = [
  {
    icon: PayTmSvg,
    title: 'PAY TM',
    value: 'pay_tm',
    text: [`faqs.pay_tm.1`],
    image: {
      link: Image1,
      alt: 'paid',
    },
    link: 'https://drive.google.com/file/d/11HQRczdMiL7rYMIV2mUjnTkH5nG4yOXf/view?usp=sharing',
  },
  {
    icon: UpiSvg,
    title: 'Upi',
    value: 'upi',
    text: ['faqs.upi.1', `faqs.upi.2`, 'faqs.upi.3'],
  },
  {
    icon: PhonePeSvg,
    title: 'PhonePe',
    value: 'phonepe',
    text: [`faqs.phonepe.1`, 'faqs.phonepe.2'],
    link: 'https://drive.google.com/file/d/1tDbeN4Ab5AUwHNxU9ovK2-wZAqThLAsk/view?usp=sharing',
  },
  {
    icon: NetbankingSvg,
    title: 'Netbanking',
    value: 'netbanking',
  },
  {
    icon: VisaSvg,
    value: 'visa_mastercard',
  },
];
