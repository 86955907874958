import React from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';
import { BackArrowButtonProps } from './BackArrowButton.types';

import { ReactComponent as ArrowSvg } from '../../public/svg/arrows/back.svg';

import styles from './BackArrowButton.module.scss';

export const BackArrowButton: React.FC<BackArrowButtonProps> = ({
  text,
  className,
  onClick,
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <button
      onClick={onClick}
      className={classNames(styles.back, className, {
        [styles.isDecember]: !isJune,
      })}
      type='button'
    >
      <ArrowSvg />
      <p className={styles.text}>{text}</p>
    </button>
  );
};
