import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useApp, useReloadApp } from '@box/shared/hooks';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { appVersionsSelectors, setVersionOutdated } from '@box/redux/app';
import { Button, LightButton } from '@box/ui';

import { Props } from './newVersion.types';

import styles from './newVersion.module.scss';

export const NewVersion: FC<Props> = ({ isFooter }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { reloadHandler } = useReloadApp();

  const isAppOutdated = useAppSelector(appVersionsSelectors.isVersionOutdated);

  const {
    userEnv: { isMobile, host },
  } = useApp();

  const handleClose = () => {
    dispatch(setVersionOutdated(false));
  };

  if (isAppOutdated) {
    return (
      <div
        className={classNames(styles.newVersion, {
          [styles.newVersion_june]: host === 'june',
          [styles.newVersion_mobile]: isMobile,
          [styles.newVersion_footer]: isFooter,
        })}
      >
        <p className={styles.label}>{t('version.new_version_available')}</p>
        {host === 'june' ? (
          <LightButton onClick={reloadHandler}>
            {t('version.click_to_update_the_app')}
          </LightButton>
        ) : (
          <Button
            className={styles.button}
            type='button'
            onClick={reloadHandler}
          >
            {t('version.click_to_update_the_app')}
          </Button>
        )}

        <button type='button' onClick={handleClose} className={styles.close}>
          <div /> <div />
        </button>
      </div>
    );
  }

  return null;
};
