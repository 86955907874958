import {
  withCatalogState,
  withFilters as withGamesFilters,
  withInfinityScroll as withGamesInfinityScroll,
  withState as withGamesListState,
} from './GameList.state';

export {
  withCatalogState,
  withGamesFilters,
  withGamesInfinityScroll,
  withGamesListState,
};
