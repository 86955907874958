import React, { FC } from 'react';
import { globalLoaderSelectors } from '@box/redux/app';
import { useAppSelector } from '@box/redux/hooks';

import { Loading } from '../Loading';

export const GlobalLoader: FC<{
  active?: boolean;
  isJune?: boolean;
  isFailed?: boolean;
}> = ({ active, isJune, isFailed }) => {
  const isActive = useAppSelector(globalLoaderSelectors.active);

  if (!(isActive || active)) return null;

  return <Loading isFailed={isFailed} isJune={isJune} hideSpinner={false} />;
};
