import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { InnerModal, Modal } from '@box/ui';
import { useAppDispatch } from '@box/redux/hooks';
import { closeBonusDetail } from '@box/redux/bonuses';
import { useApp } from '@box/shared/hooks';

import { Main } from './Main';
import { Instruction } from './Instruction';

import { Props } from './BonusModal.types';

import styles from './bonusModal.module.scss';
import { ReactComponent as CloseSvg } from './assets/images/close.svg';

export const BonusModal: FC<Props> = ({
  name,
  className,
  getting_params,
  condition,
  id,
  wager_params,
  isAbsolute,
  isOpen,
}) => {
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const {
    userEnv: { isMobile },
  } = useApp();

  const { t } = useTranslation();

  const handleClick = () => {
    params.delete('bonus');
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleClose = () => {
    dispatch(closeBonusDetail());
  };

  if (isAbsolute) {
    return (
      <Modal close={handleClose} isOpen={isOpen}>
        <div
          className={classNames(styles.container, styles.container_absolute, {
            [styles.container_mobile]: isMobile,
          })}
        >
          <div className={styles.header}>
            <button
              type='button'
              onClick={handleClose}
              className={styles.close}
            >
              <CloseSvg />
            </button>
          </div>
          <Main
            name={name}
            getting_params={getting_params}
            id={id}
            wager={wager_params?.wager}
          />
          <Instruction
            percent={getting_params[0]?.percent}
            condition={condition}
          />
        </div>
      </Modal>
    );
  }

  return (
    <InnerModal
      onBackClick={handleClick}
      onClose={handleClick}
      back={t('bonuses.back_to_promotions')}
      className={className}
    >
      <div className={styles.container}>
        <Main
          name={name}
          getting_params={getting_params}
          id={id}
          wager={wager_params?.wager}
        />
        <Instruction
          percent={getting_params[0]?.percent}
          condition={condition}
        />
      </div>
    </InnerModal>
  );
};
