import { createSlice } from '@reduxjs/toolkit';

import { Language } from '../../types';

import { changeLang, initLang } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {} as Language;

export const slice = createSlice({
  name: 'app/lang',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeLang.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      })

      .addCase(initLang.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      });
  },
});
