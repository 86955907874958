// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Payments-ui-Title-title-module__title{color:#8888a6;font-size:12px;font-weight:400;letter-spacing:-.03em;line-height:1.5;margin:0}", "",{"version":3,"sources":["webpack://./../../shared/components/Payments/ui/Title/title.module.scss"],"names":[],"mappings":"AACA,gEAEE,aAAA,CACA,cAAA,CAGA,eAAA,CALA,qBAAA,CAGA,eAAA,CACA,QACF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.title {\n  letter-spacing: -0.03em;\n  color: #8888A6;\n  font-size: 12px;\n  line-height: 1.5;\n  margin: 0;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "------shared-components-Payments-ui-Title-title-module__title"
};
module.exports = ___CSS_LOADER_EXPORT___;
