import React from 'react';
import classNames from 'classnames';
import reactDom from 'react-dom';
import { useLockBodyScroll } from 'react-use';
import isFunction from 'lodash/isFunction';

import './errorPopup.scss';
import { ReactComponent as HeadIcon } from './assets/head_icon.svg';
import { Button } from '..';

export const ErrorPopup = ({
  className,
  isOpen,
  title,
  children,
  close,
  buttonText,
  buttonClick,
}) => {
  useLockBodyScroll(!!isOpen);

  const onButtonClick = () => {
    if (isFunction(buttonClick)) {
      buttonClick();

      return;
    }

    if (isFunction(close)) {
      close();
    }
  };

  return reactDom.createPortal(
    <div
      className={classNames('error-popup', className, {
        'error-popup--open': isOpen,
      })}
    >
      <div
        className='error-popup__overlay'
        onClick={close}
        aria-hidden='true'
      />
      <div className='error-popup__content'>
        <div className='error-popup__head'>
          <div className='error-popup__icon' onClick={close} aria-hidden='true'>
            <HeadIcon />
          </div>
          <span className='error-popup__title'>{title}</span>
        </div>
        <div className='error-popup__body'>
          {children}
          {buttonText && (
            <div className='error-popup__bottom'>
              <Button
                className='error-popup__button'
                color='secondary'
                hoverType='opacity'
                onClick={onButtonClick}
                aria-hidden='true'
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};
