import React, { useMemo, FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { jackpotSelectors } from '@box/redux/app';
import { commonSelectors } from '@box/redux/common';
import { useApp } from '@box/shared/hooks';

import { Props } from './Jackpot.types';

import styles from './jackpot.module.scss';

export const Jackpot: FC<Props> = ({ children }) => {
  const jackpot = useSelector(jackpotSelectors.amount);
  const currencyId = useSelector(jackpotSelectors.currencyId);
  const symbol = useSelector(commonSelectors.currencySymbolById(currencyId));

  const {
    userEnv: { isDesktop },
  } = useApp();

  const numbersArray = useMemo(() => {
    const array = [...jackpot.toString()].map(String);
    array.unshift(symbol);
    return array.reverse();
  }, [jackpot, symbol]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_desktop]: isDesktop,
      })}
    >
      <div className={styles.counter}>
        {numbersArray.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(styles.count, {
                [styles.count_decor]: (index + 1) % 3 === 0,
              })}
            >
              {item}
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};
