import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { useAuthTokens } from '../../hooks';
import { openInfoPopup } from '../../app';
import { AppThunkApi } from '../../types';
import { getPureErrorMessage } from '../../utils';

import { POSTPasswordUpdate, UpdatePasswordPayload } from './types';

export const updatePassword = createAsyncThunk<
  void,
  UpdatePasswordPayload,
  AppThunkApi
>(
  'auth/password/update',
  async (payload, { extra, rejectWithValue, dispatch }) => {
    const { api } = extra;

    try {
      const { setAccessToken } = useAuthTokens();

      const { data, status } = await api.post<POSTPasswordUpdate>(
        '/password/update',
        payload
      );

      if (status === 200) {
        setAccessToken(data.access_token);

        dispatch(
          openInfoPopup({
            text: i18next.t('settings.success_submit_text'),
            type: 'success',
          })
        );

        return;
      }

      const error = getPureErrorMessage(data.errors);

      return rejectWithValue(error);
    } catch (err) {
      return rejectWithValue('Error');
    }
  }
);
