import React, { FC } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, CircleTimer, ImageSet } from '@box/ui';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { userSelectors } from '@box/redux/user';
import { useIsGeo, useIsAuthorized, useApp } from '@box/shared/hooks';

import styles from './BonusTimer.module.scss';

import BonusPng from './assets/images/bonus.png';
import BonusWebp from './assets/images/bonus.webp';
import BonusAvif from './assets/images/bonus.avif';

export const BonusTimer: FC = () => {
  const user = useAppSelector(userSelectors.user);
  const dispatch = useAppDispatch();

  const {
    userEnv: { isMobile },
  } = useApp();

  const { t } = useTranslation();

  const { isLogged } = useIsAuthorized();
  const { isShow } = useIsGeo('UZ');

  const userCreatedAt = Date.parse(user?.created_at);
  const currentDate = Date.now();
  const milliseconds = 1800000 - (currentDate - userCreatedAt);

  const handleClick = () => {
    dispatch(openModal('deposit'));
  };

  if (milliseconds < 0) {
    return null;
  }

  if (!isShow || !isLogged) {
    return null;
  }

  return (
    <div
      className={classNames(styles['bonus-timer'], {
        [styles['bonus-timer_mobile']]: isMobile,
      })}
    >
      <Button
        color='secondary'
        borderRadius={isMobile ? 'medium' : 'large'}
        className={styles.button}
        hover='opacity'
        onClick={handleClick}
      >
        {t('bonus_timer.button')}
      </Button>
      <div className={styles.row}>
        <div className={styles['image-wrapper']}>
          <ImageSet
            className={styles.image}
            src={BonusPng}
            srcWebp={BonusWebp}
            srcAvif={BonusAvif}
            alt={t('bonuses.bonus')}
          />
        </div>
        <div className={styles.info}>
          <p className={styles.text}>
            <Trans i18nKey='bonus_timer.text' components={{ span: <span /> }} />
          </p>
          <CircleTimer
            className={styles.timer}
            size={!isMobile ? 78 : 41}
            milliseconds={milliseconds}
            fontSize={isMobile ? 'small' : 'medium'}
          />
        </div>
      </div>
    </div>
  );
};
