import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useToggleActiveElement } from '@box/shared/hooks';

import { CustomLink } from '@box/ui';
import styles from './MenuDropdown.module.scss';
import { ReactComponent as PointsIcon } from './assets/images/points.svg';
import { MenuDropdownProps } from './MenuDropdown.types';

export const MenuDropdown: FC = ({
  className,
  items,
  onLinkClick,
}: MenuDropdownProps) => {
  const { t } = useTranslation();

  const { isOpen, toggle, elementRef } = useToggleActiveElement({
    hideWhenClickOutside: true,
  });

  return (
    <div
      className={classNames(styles.container, className, {
        [styles['container--active']]: isOpen,
      })}
      onClick={toggle}
      aria-hidden='true'
      ref={elementRef}
    >
      <div className={styles.badge}>
        <div className={styles.badgeText}>
          <p>{t('navigation.more_button')}</p>
          <PointsIcon />
        </div>
      </div>
      <div className={styles.menu}>
        {items.map((item, index) => (
          <li
            className={styles.menuItem}
            aria-hidden='true'
            key={index}
            onClick={() => onLinkClick(index)}
          >
            <CustomLink navigationMenu {...item} />
          </li>
        ))}
      </div>
    </div>
  );
};
