import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './Radio.types';

import styles from './radio.module.scss';

export const Radio: FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      value,
      id,
      fill,
      isSmall,
      label,
      icon: Icon,
      iconPosition = 'left',
      textPadding,
      radioSize = 'medium',
      align = 'center',
      transparent,

      ...props
    },
    ref
  ) => {
    const {
      userEnv: { isDesktop },
    } = useApp();

    return (
      <label
        htmlFor={id}
        className={classNames(
          styles.radio,
          styles[`radio_size_${radioSize}`],
          { [styles.radio_desktop]: isDesktop },
          className
        )}
      >
        <input
          id={id}
          className={styles.input}
          type='radio'
          value={value}
          ref={ref}
          {...props}
        />
        <span
          className={classNames(styles.label, styles[`label_align_${align}`], {
            [styles.label_fill]: fill,
            [styles.label_small]: isSmall,
            [styles.label_transparent]: transparent,
          })}
        >
          <div className={styles.wrapper} />
          {/* Нужен для плавного перехода в градиент */}
          <div
            className={classNames(styles.wrapper, styles.wrapper_gradient)}
          />
          {/* <SVGIcon /> */}
          {/* Нужен для плавного перехода из градиента */}
          <div className={styles.content}>
            {iconPosition === 'left' && Icon && <Icon />}
            <p
              className={classNames(styles.text, {
                [styles.text_padding]: textPadding,
                [styles.text_margin_left]: Icon,
                [styles.text_margin_right]: iconPosition === 'right' && Icon,
              })}
            >
              {label}
            </p>
            {iconPosition === 'right' && Icon && <Icon />}
          </div>
        </span>
      </label>
    );
  }
);
