import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunk } from '../../types';

export const thunkWithGlobalLoader: AppThunk<
  any,
  AppThunk<any, any> | AppThunk<any, any>[]
> = createAsyncThunk(
  'app/globalLoader/wrapper',
  async (payload, { dispatch, rejectWithValue }) => {
    const thunk = payload;

    try {
      if (Array.isArray(thunk)) {
        await Promise.all(thunk.map((item) => dispatch(item)));
      } else {
        await dispatch(thunk());
      }

      return {};
    } catch (e) {
      return rejectWithValue('');
    }
  }
);
