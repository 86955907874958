import { useEffect, useState } from 'react';

export const AnimationDelayWrapper = ({
  isOpen,
  delayTime = 500,
  children,
}) => {
  const [render, setIsRender] = useState(false);

  useEffect(() => {
    let timeout;

    if (isOpen && !render) {
      setIsRender(true);
    } else if (!isOpen && render) {
      timeout = setTimeout(() => {
        setIsRender(isOpen);
      }, delayTime);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  return render && children;
};
