import React, { useEffect } from 'react';
import { useAppSelector } from '@box/redux/hooks';
import { withdrawalMerchantsSelectors } from '@box/redux/finance';

import { merchantIcons } from '../../../utils/merchants';
import { MerchantTabs as DefaultMerchantTabs } from '../../MerchantTabs/MerchantTabs';

const merchantTabsState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { onTabClick, activeTab } = props;

    const merchants = useAppSelector(withdrawalMerchantsSelectors.list);

    useEffect(() => {
      if (!activeTab && merchants.length) {
        onTabClick(merchants[0]);
      }
    }, [activeTab, merchants]);

    const list = merchants.map((item) => ({
      id: item.id,
      name: item.name,
      icon: merchantIcons[item.alias] || merchantIcons.defaultIcon,
      onClick: () => onTabClick(item),
    }));

    return <Component {...props} list={list} />;
  };

  return wrapper;
};

export const MerchantTabs = merchantTabsState(DefaultMerchantTabs);
