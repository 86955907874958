import React, { FC } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames';
import { Collapsed } from '@box/shared/baseComponents';
import { useApp } from '@box/shared/hooks';

import { Props } from './Dropdown.types';

import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';
import styles from './dropdown.module.scss';

export const Dropdown: FC<Props> = ({ className, title, children }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const [isOpen, toggleOpen] = useToggle(false);
  return (
    <div
      className={classNames(
        styles.dropdown,
        { [styles.dropdown_mobile]: isMobile },
        className
      )}
    >
      <button
        onClick={toggleOpen}
        type='button'
        className={classNames(styles.head, { [styles.head_show]: isOpen })}
      >
        {title}
        <ArrowSvg />
      </button>
      <Collapsed isOpen={isOpen}>
        <p className={styles.text}>{children}</p>
      </Collapsed>
    </div>
  );
};
