import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle, useWindowSize } from 'react-use';

import { Collapsed } from '../Collapsed';

import {
  ArrayValueProps,
  RowProps,
  RowsListProps,
  SimpleValueProps,
} from './RowsList.types';

import './rowsList.scss';

const ArrayValue: FC<ArrayValueProps> = ({ values }) => {
  const { width } = useWindowSize();
  const listRef = useRef<HTMLDivElement>();
  const collapsedRef = useRef<HTMLDivElement>();
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [isOpen, toggleIsOpen] = useToggle(false);

  const mitCollapseHeight = useMemo(
    () => listRef.current?.scrollHeight,
    [listRef.current, width]
  );

  useEffect(() => {
    setIsToggleActive(
      !!(collapsedRef.current.clientHeight < listRef.current.clientHeight)
    );
  }, [listRef.current, width]);

  return (
    <div className='rows-list-array-value'>
      <Collapsed
        isOpen={isOpen}
        style={{ minHeight: mitCollapseHeight }}
        ref={collapsedRef}
      >
        <div className='rows-list-array-value__list' ref={listRef}>
          {values.map((item, idx) => (
            <div
              className='rows-list-array-value__item'
              onClick={item.onClick}
              aria-hidden='true'
              key={idx}
            >
              {item.name}
            </div>
          ))}
        </div>
      </Collapsed>
      {isToggleActive && (
        <div
          className={classNames('rows-list-array-value__toggle', {
            'rows-list-array-value__toggle--open': isOpen,
          })}
          onClick={toggleIsOpen}
          aria-hidden='true'
        />
      )}
    </div>
  );
};

const SimpleValue: FC<SimpleValueProps> = ({ value }) => (
  <span className='rows-list-simple-value'>{value}</span>
);

const Row: FC<RowProps> = ({ label, children }) => (
  <div className='rows-list__item'>
    <span className='rows-list__item-label'>{label}</span>
    <div className='rows-list__item-content'>{children}</div>
  </div>
);

export const RowsList: FC<RowsListProps> = React.forwardRef<
  HTMLDivElement,
  RowsListProps
>(({ className, values, properties, children, isContentBefore }, ref) => {
  const { t } = useTranslation();

  const list = useMemo(
    () =>
      properties.reduce(
        (acc, item) =>
          values[item.field]
            ? [...acc, { label: t(item.label), value: values[item.field] }]
            : acc,
        []
      ),
    [values]
  );

  const getRowValue = (item, key) => {
    if (Array.isArray(item.value)) {
      if (!item.value.length) return null;

      return (
        <Row label={item.label} key={key}>
          <ArrayValue values={item.value} />
        </Row>
      );
    }

    return (
      <Row label={item.label} key={key}>
        <SimpleValue value={item.value} />
      </Row>
    );
  };

  return (
    <div className={classNames('rows-list', className)} ref={ref}>
      {isContentBefore && children}
      {list.map((item, idx) => getRowValue(item, idx))}
      {!isContentBefore && children}
    </div>
  );
});
