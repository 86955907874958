import { ReactComponent as PhonePe } from './assets/phone_pe.svg';
import { ReactComponent as Paytm } from './assets/paytm.svg';
import { ReactComponent as Upi } from './assets/upi.svg';
import { ReactComponent as CreditCard } from './assets/credit_card.svg';
import { ReactComponent as Paykassma } from './assets/paykassma.svg';
import { ReactComponent as Cryptopay } from './assets/cryptopay.svg';
import { ReactComponent as Visa } from './assets/visa.svg';

export const icons = {
  'phone-pe': PhonePe,
  paytm: Paytm,
  upi: Upi,
  genesispay: CreditCard,
  paykassma: Paykassma,
  cryptopay: Cryptopay,
  defaultIcon: CreditCard,
  'visa-mc-paystudio': Visa,
  'visa-mc-paypound': Visa,
};
