import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { getPureErrorMessage } from '@box/redux/utils';

import { AppThunkApi, UserRolesNames } from '../../types';
import { hideModal, openModal, setModalProps } from '../../app';

import { userSelectors, setUserInfo, updateRoles } from '../info';

import {
  InitDocumentsReturn,
  GetUserDocuments,
  GetUserDocumentTypes,
  UpdateUserPayload,
  UpdateUserReturn,
  PatchUserData,
  CreateUserDocumentPayload,
  CreateUserDocumentReturn,
  PostCreateUserDocument,
  UpdateUserDocumentReturn,
  UpdateUserDocumentPayload,
  PostUpdateUserDocument,
} from './types';

export const initDocuments = createAsyncThunk<
  InitDocumentsReturn,
  void,
  AppThunkApi
>(
  'user/documents/fetch',
  async (_payload, { extra, rejectWithValue, getState }) => {
    const { api } = extra;

    try {
      const userId = userSelectors.id(getState());

      const { data: types, status: typesStatus } =
        await api.get<GetUserDocumentTypes>('/documents/types');

      const { data: documents, status: documentsStatus } =
        await api.get<GetUserDocuments>(`/userdatas/${userId}/documents`);

      if (typesStatus === 200 && documentsStatus === 200) {
        return {
          types: types.data,
          currentDocument: documents.data[0],
        };
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

export const updateUserAfterDocument = createAsyncThunk<
  UpdateUserReturn,
  UpdateUserPayload,
  AppThunkApi
>(
  'user/documents/updateUser',
  async (payload, { extra, rejectWithValue, dispatch }) => {
    const { api } = extra;

    const { userId, userData } = payload;

    try {
      const { data, status } = await api.patch<PatchUserData>(
        `/userdatas/${userId}`,
        {
          ...userData,
          with: 'permissions,roles,roles.permissions',
        }
      );

      if (status === 200) {
        if (
          data.roles.find(
            (item) => item.name === UserRolesNames.pending_verification
          )
        ) {
          dispatch(
            setModalProps({
              id: 'info',
              props: {
                title: i18next.t('settings.verification'),
                text: i18next.t('settings.documents_success'),
              },
            })
          );

          dispatch(openModal('info'));
        }

        dispatch(updateRoles(data.roles));
        dispatch(setUserInfo(data));

        dispatch(hideModal('settings'));

        return data;
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

export const createUserDocument = createAsyncThunk<
  CreateUserDocumentReturn,
  CreateUserDocumentPayload,
  AppThunkApi
>(
  'user/documents/create',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api } = extra;

    const userId = userSelectors.id(getState());

    const { values, userData } = payload;

    try {
      const { data, status } = await api.post<PostCreateUserDocument>(
        `/userdatas/${userId}/documents?with=type`,
        values
      );

      if (status === 201) {
        await dispatch(updateUserAfterDocument({ userId, userData }));

        return data;
      }

      return rejectWithValue(data?.errors);
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);

export const updateUserDocument = createAsyncThunk<
  UpdateUserDocumentReturn,
  UpdateUserDocumentPayload,
  AppThunkApi
>(
  'user/documents/update',
  async (payload, { dispatch, extra, rejectWithValue, getState }) => {
    const { api } = extra;

    const userId = userSelectors.id(getState());

    const { id, values, userData } = payload;

    try {
      const { data, status } = await api.post<PostUpdateUserDocument>(
        `/userdatas/${userId}/documents/${id}?_method=PATCH`,
        values
      );

      if (status === 200) {
        await dispatch(updateUserAfterDocument({ userId, userData }));

        return data;
      }
      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);
