// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-TopUp-PaymentFrame-PaymentFrame-module__paymentFrame{align-items:center;box-sizing:border-box;display:flex;flex-direction:column;justify-content:center;padding:10px;width:100%}.\\------shared-components-Modals-TopUp-PaymentFrame-PaymentFrame-module__paymentFrame iframe{border:none;max-width:100%}.\\------shared-components-Modals-TopUp-PaymentFrame-PaymentFrame-module__back{align-self:flex-start;margin-bottom:12px}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/TopUp/PaymentFrame/PaymentFrame.module.scss"],"names":[],"mappings":"AACA,sFAME,kBAAA,CAJA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,YAAA,CANA,UAMF,CAEE,6FAEE,WAAA,CADA,cACJ,CAIA,8EAEE,qBAAA,CADA,kBAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.paymentFrame {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n\n  iframe {\n    max-width: 100%;\n    border: none;\n  }\n}\n\n.back {\n  margin-bottom: 12px;\n  align-self: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentFrame": "------shared-components-Modals-TopUp-PaymentFrame-PaymentFrame-module__paymentFrame",
	"back": "------shared-components-Modals-TopUp-PaymentFrame-PaymentFrame-module__back"
};
module.exports = ___CSS_LOADER_EXPORT___;
