import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: withdrawalMerchantsReset } = actions;

export * as withdrawalMerchantsSelectors from './selectors';
export * from './thunks';

export default reducer;
