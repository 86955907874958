import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from '@box/shared/hooks';
import { useTransaction } from './useTransaction';

import styles from './Transaction.module.scss';

export const Transaction = ({ list }) => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  const { date, payable_id, currencySymbol, amount, type, merchant } = list;

  const { statusClass } = useTransaction({ list });

  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.transaction, {
        [styles.transaction_desktop]: isDesktop,
        [styles.transaction_payout]: type !== 'payment',
      })}
    >
      {!isDesktop ? (
        <>
          <div className={styles.upper}>
            <div className={styles.upperLeft}>
              <div className={styles.mobileSum}>
                <div className={styles.mobileAmount}>
                  {amount} {currencySymbol}
                </div>
                <div className={styles.mobileId}>
                  <div>{t('detalization.transaction')}</div>
                  <div>№{payable_id}</div>
                </div>
              </div>
            </div>
            <div className={styles.upperRight}>
              <div className={styles.mobileDate}>
                <div className={styles.mobileDate_date}>{date.date}</div>
                <div className={styles.mobileDate_time}>{date.time}</div>
              </div>
            </div>
          </div>
          <div className={styles.divide} />
          <div className={styles.lower}>
            <div className={styles.mobileMethod}>
              <div
                className={classNames(styles.mobileMethod_heading, {
                  [styles.mobileMethod_heading_withdrawal]: type !== 'payment',
                })}
              >
                {t('detalization.payment_method')}
              </div>
              <div className={styles.mobileMethod_method}>{merchant}</div>
            </div>
            <div className={styles.mobileStatus}>
              <div className={styles[statusClass]}>
                {t(`detalization.${statusClass}`)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.date}>
            {date.date} <span className={styles.time}>{date.time}</span>
          </div>
          <div className={styles.id}>№{payable_id}</div>
          <div className={styles.sum}>
            {amount} {currencySymbol}
          </div>
          <div>{merchant}</div>
          <div className={styles[statusClass]}>
            {t(`detalization.${statusClass}`)}
          </div>
        </>
      )}
    </div>
  );
};
