// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Bonuses-Bonuses-module__modal__body{max-width:750px}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Bonuses/Bonuses.module.scss"],"names":[],"mappings":"AACA,qEACE,eAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.modal__body {\n  max-width: 750px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__body": "------shared-components-Modals-Bonuses-Bonuses-module__modal__body"
};
module.exports = ___CSS_LOADER_EXPORT___;
