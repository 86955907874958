export const languagesFlags = {
  English: 'GB',
  Bengali: 'IN',
  Russian: 'RU',
  Azerbaijanian: 'AZ',
  Swahili: 'TZ',
  Turkish: 'TR',
  Uzbek: 'UZ',
  Italian: 'IT',
  Portugal: 'PT',
  Spanish: 'ES',
  Romania: 'RO',
  Norway: 'NO',
  Polish: 'PL',
  Vietnam: 'VI',
};
