import { RootState } from '@box/redux/types';
import { createSelector } from '@reduxjs/toolkit';

export const tasksList = (state: RootState) => state.loyalty.tasks.list;
export const tasksListOnlyUncompleted = createSelector([tasksList], (list) =>
  list.filter((item) => !item.isDone)
);
export const tasksListOnlyCompleted = createSelector([tasksList], (list) =>
  list.filter((item) => item.isDone)
);
