import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationItem, LightNavigationItem } from '@box/ui';
import { useAppSelector } from '@box/redux/hooks';
import { userInitSelectors } from '@box/redux/authentication';

import { Aviator } from '../../components';
import { useApp } from '../useApp';

import { ReactComponent as HomeSvg } from './assets/images/home.svg';
import { ReactComponent as GamesSvg } from './assets/images/games.svg';
import { ReactComponent as CasinoSvg } from './assets/images/casino.svg';
import { ReactComponent as LiveGamesSvg } from './assets/images/live-games.svg';
import { ReactComponent as BonusesSvg } from './assets/images/bonuses.svg';

// import { ReactComponent as CasesSvg } from './assets/images/cases.svg';
// import { ReactComponent as LeadersSvg } from './assets/images/leaders.svg';

type useNavLinksType = () => NavigationItem[];

type UseLightNavLinks = () => LightNavigationItem[];

export const useNavLinks: useNavLinksType = () => {
  const { t } = useTranslation();

  const { route } = useApp();

  return [
    {
      label: t('navigation.main'),
      to: route('main'),
      excludedActive: [route('autoReg'), route('autoReg.v2')],
    },
    {
      label: t('navigation.casino'),
      to: route('casino'),
      excludedActive: [route('casino.list', { id: 'live' })],
    },
    {
      label: t('navigation.live_games'),
      to: route('casino.list', { id: 'live' }),
    },
    { label: t('navigation.games'), to: route('games') },
    { component: Aviator, to: 'aviator' },
    {
      label: t('navigation.tournaments'),
      to: route('tournaments.all'),
    },
    { label: t('navigation.bonus'), to: route('bonuses.list') },
  ];
};

export const useLightNavLinks: UseLightNavLinks = () => {
  const { t } = useTranslation();

  const { route } = useApp();

  const isLogged = useAppSelector(userInitSelectors.isLogged);

  return [
    {
      label: t('navigation.home'),
      to: route('main'),
      icon: HomeSvg,
      excludedActive: [route('autoReg'), route('autoReg.v2')],
    },
    {
      component: () => <Aviator className='' isFullHeight withIcon withName />,
      to: 'aviator',
    },
    {
      label: t('navigation.games'),
      to: route('games'),
      icon: GamesSvg,
    },
    {
      label: t('navigation.casino'),
      to: route('casino'),
      excludedActive: [route('casino.list', { id: 'live' })],
      icon: CasinoSvg,
    },
    {
      label: t('navigation.live_games'),
      to: route('casino.list', { id: 'live' }),
      icon: LiveGamesSvg,
    },
    {
      label: t('navigation.bonus'),
      to: isLogged ? route('bonuses.list') : route('bonusesList'),
      icon: BonusesSvg,
    },
    // {
    //   label: t('navigation.cases'),
    //   to: '/cases',
    //   icon: CasesSvg,
    // },
    // {
    //   label: t('navigation.leaders'),
    //   to: '/leaders',
    //   icon: LeadersSvg,
    // },
  ];
};
