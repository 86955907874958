import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApp } from '@box/shared/hooks';

import { Icon } from '../Icon';

import './casinoMobileLayout.scss';

export const CasinoMobileLayout = ({
  icon,
  title,
  children,
  defaultBackLink = '/casino',
  className,
}) => {
  const { t } = useTranslation();
  const { state: routerState } = useLocation();
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(
        'casino-mobile-layout',
        { 'casino-mobile-layout_june': isJune },
        className
      )}
    >
      <div className='casino-mobile-layout__info'>
        {icon && (
          <Icon className='casino-mobile-layout__info-icon' icon={icon} />
        )}
        <div className='casino-mobile-layout__info-name'>{title}</div>
        <Link
          className='casino-mobile-layout__back'
          to={routerState?.backLink || defaultBackLink}
        >
          {t('common.back')}
        </Link>
      </div>
      <div className='casino-mobile-layout__content'>{children}</div>
    </div>
  );
};

CasinoMobileLayout.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.any]),
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  defaultBackLink: PropTypes.string,
};

CasinoMobileLayout.defaultProps = {
  icon: null,
  className: '',
  defaultBackLink: '/casino',
};
