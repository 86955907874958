import { createSlice } from '@reduxjs/toolkit';
import { UserRates } from '../../types';

import { fetchRates } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  error: '',
  loading: false,
  rates: [] as UserRates[],
  isReady: false,
};

export const slice = createSlice({
  name: 'rates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRates.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rates = payload;
        state.isReady = true;
      })
      .addCase(fetchRates.rejected, (state, { payload }) => {
        state.error = payload as string;
      });
  },
});
