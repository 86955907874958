import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Props } from './BonusCard.types';

import styles from './bonusCard.module.scss';
import { BonusIcon } from './BonusIcon';
import { Status } from './Status';
import { ProgressBar } from './ProgressBar';

export const BonusCard: FC<Props> = ({
  minSum,
  maxBonus,
  wager,
  status,
  bonusName,
  amount,
  neededAmount,
  percent,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.card, className)}>
      <div className={styles.up}>
        <div className={styles.main}>
          <BonusIcon className={styles.icon} status={status} />
          <div className={styles.info}>
            <h3 className={styles.name}>{bonusName}</h3>
            <Status status={status} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.box}>
            <p className={styles.text}>{t('bonuses.wager')}:</p>
            <p className={styles.value}>{wager}</p>
          </div>
          <div className={styles.box}>
            <p className={styles.text}>{t('bonuses.min_dep')}:</p>
            <p className={styles.value}>{minSum}</p>
          </div>
          <div className={styles.box}>
            <p className={styles.text}>{t('bonuses.max_bonus')}:</p>
            <p className={styles.value}>{maxBonus}</p>
          </div>
        </div>
      </div>
      {status === 'activated' && (
        <ProgressBar
          amount={amount}
          neededAmount={neededAmount}
          percent={percent}
          status={status}
        />
      )}
    </div>
  );
};
