import React, { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LightButton } from '@box/ui';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { balanceSelectors } from '@box/redux/user';
import { openModal } from '@box/redux/app';
import { useApp, useNavigateToDeposit } from '@box/shared/hooks';

import type { Props } from './TopLine.types';
import styles from './topline.module.scss';
import { ReactComponent as Logo } from './assets/images/logo.svg';
import { ReactComponent as CasinoSvg } from './assets/images/casino.svg';
import { ReactComponent as AccountIcon } from './assets/images/account.svg';
import { ReactComponent as DropsIcon } from './assets/images/drops.svg';
import { ReactComponent as PlusIcon } from './assets/images/plus.svg';
import { Menu } from '../Menu';

export const TopLine: FC<Props> = ({ menuHide, withoutNavigation }) => {
  const { t } = useTranslation();

  const {
    route,
    userEnv: { isDesktop },
  } = useApp();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currencySymbol = useAppSelector(balanceSelectors.currencySymbol);
  const balance = useAppSelector(balanceSelectors.balanceValue);
  const bonusBalance = useAppSelector(balanceSelectors.bonusBalance);
  const location = useLocation();

  const isLogged = useAppSelector(userInitSelectors.isLogged);

  const { openDeposit } = useNavigateToDeposit();

  const openTopUp = () => {
    openDeposit();
  };

  const onOpenMenu = () => {
    navigate(route('profile.my_profile'));
  };

  const handleLoginClick = () => {
    dispatch(openModal('login'));
  };

  const handleRegisterClick = () => {
    dispatch(openModal('register'));
  };
  return (
    <div
      className={classNames(styles.topline, {
        [styles.topline_desktop]: isDesktop,
      })}
    >
      <div className={isDesktop ? styles.navigation : undefined}>
        <Link
          className={styles.logo}
          to={withoutNavigation ? location.pathname : '/'}
        >
          <div className={styles.box}>
            <Logo />
          </div>
          <div className={styles.casinoBox}>
            <CasinoSvg />
          </div>
        </Link>
        {isDesktop && !menuHide && <Menu />}
      </div>
      <div
        className={classNames(styles.buttons, { [styles.logged]: isLogged })}
      >
        {isLogged ? (
          <>
            {isDesktop ? (
              <div className={styles.balance}>
                <div className={styles.balanceInfo}>
                  <span>{t('navigation.balance')}</span>
                  <span className={styles.count}>
                    {currencySymbol}
                    {balance > 0 ? Math.floor(balance * 100) / 100 : 0}
                  </span>
                </div>
                <div className={styles.balanceInfo}>
                  <span>{t('user_actions.bonuses')}</span>
                  <span className={styles.count}>
                    {currencySymbol}
                    {bonusBalance > 0
                      ? Math.floor(bonusBalance * 100) / 100
                      : 0}
                  </span>
                </div>

                <LightButton
                  onClick={openTopUp}
                  className={styles.topUpButton}
                  color='green'
                >
                  <PlusIcon />
                  {t('user_actions.topUp')}
                </LightButton>
              </div>
            ) : (
              <div className={styles.balance}>
                <LightButton
                  onClick={openTopUp}
                  className={styles.topUpButton}
                  color='green'
                >
                  {t('user_actions.topUp')}
                </LightButton>
                <div className={styles.balanceInfo}>
                  <span className={styles.count}>
                    {currencySymbol}
                    {balance}
                  </span>
                  <span>+{t('user_actions.bonuses')}</span>
                </div>
                {/* <div className={styles.drops}>
                  <DropsIcon />
                </div> */}
              </div>
            )}
            <LightButton onClick={onOpenMenu} className={styles.accountButton}>
              <AccountIcon />
              {isDesktop && (
                <span>
                  {' '}
                  {t('account.account_name')} <DropsIcon />
                </span>
              )}
            </LightButton>
          </>
        ) : (
          <>
            <LightButton onClick={handleLoginClick} className={styles.button}>
              {t('account.sign_in')}
            </LightButton>
            <LightButton
              onClick={handleRegisterClick}
              className={styles.button}
              color='green'
            >
              {t('account.sign_up')}
            </LightButton>
          </>
        )}
      </div>
    </div>
  );
};
