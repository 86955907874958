// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-baseComponents-Plate-plate-module__plate{display:grid;width:100%}.\\------shared-baseComponents-Plate-plate-module__plates-with-collapsed{box-sizing:border-box;display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../../shared/baseComponents/Plate/plate.module.scss"],"names":[],"mappings":"AACA,wDACE,YAAA,CACA,UAAF,CAGA,wEACE,qBAAA,CACA,YAAA,CACA,qBAAF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.plate {\n  display: grid;\n  width: 100%;\n}\n\n.plates-with-collapsed {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plate": "------shared-baseComponents-Plate-plate-module__plate",
	"plates-with-collapsed": "------shared-baseComponents-Plate-plate-module__plates-with-collapsed"
};
module.exports = ___CSS_LOADER_EXPORT___;
