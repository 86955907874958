import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { BlockWithLoading } from '../BlockWithLoading';

import './decoratedButton.scss';

export const DecoratedButton = ({
  children,
  onClick,
  className,
  small,
  loading,
}) => {
  return (
    <button
      className={classNames('decorated-button', className, {
        'decorated-button--small': !!small,
        'decorated-button--loading': loading,
      })}
      type='button'
      onClick={onClick}
      disabled={loading}
    >
      {loading && <BlockWithLoading loading />}
      <span className='decorated-button__text'>{children}</span>
    </button>
  );
};

DecoratedButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  small: PropTypes.bool,
  loading: PropTypes.bool,
};

DecoratedButton.defaultProps = {
  onClick: () => null,
  className: '',
  small: false,
  loading: false,
};
