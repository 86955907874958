import { createSlice } from '@reduxjs/toolkit';

import { requestStatuses } from '../../utils/constants';

import { fetchGameHistory, addGameToHistory } from './thunks';
import { GamesHistoryInitialState } from './types';

const initialState: GamesHistoryInitialState = {
  list: [],
  loading: false,
  status: requestStatuses.notStarted,
};

export const slice = createSlice({
  name: 'gamesHistory',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGameHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.list = payload;
        state.status = requestStatuses.init;
      })
      .addCase(fetchGameHistory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addGameToHistory.fulfilled, (state, { payload }) => {
        state.list = payload as any;
      });
  },
});
