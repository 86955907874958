import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: withdrawalRequestsReset } = actions;

export * from './actions';
export * as withdrawalRequestsSelectors from './selectors';
export * from './thunks';

export default reducer;
