import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';
import {
  FetchWithdrawalMerchantsReturn,
  GETWithdrawalMerchants,
} from './types';

export const fetchWithdrawalMerchants = createAsyncThunk<
  FetchWithdrawalMerchantsReturn,
  void,
  AppThunkApi
>(
  'finance/fetchWithdrawalMerchants',
  async (_payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data, status } = await api.get<GETWithdrawalMerchants>(
        `fin/withdrawal-methods`
      );

      if (status === 200) {
        return data.data;
      }

      return rejectWithValue('error');
    } catch (e) {
      return rejectWithValue('error');
    }
  }
);
