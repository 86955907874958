import { useAppSelector } from '@box/redux/hooks';

import { commonSelectors } from '@box/redux/common';

export const useIsGeo = (geo: string) => {
  const clientData = useAppSelector(commonSelectors.clientData);
  const isShow = clientData?.country?.code === geo;

  return { isShow: !!isShow };
};
