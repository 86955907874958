import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Line.types';

import styles from './line.module.scss';

export const Line: FC<Props> = ({ className }) => {
  return <div className={classNames(styles.line, className)} />;
};
