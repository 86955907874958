/* eslint-disable max-len */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const DefaultProvider1 = ({ className }) => {
  const id = useMemo(() => Math.trunc(Math.random() * 10000), []);

  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.23862 1.11628C5.02146 -0.372094 6.97854 -0.372092 7.76138 1.11628L11.7245 8.65117C12.5073 10.1395 11.5288 12 9.9631 12H2.0369C0.471227 12 -0.507314 10.1395 0.275521 8.65116L4.23862 1.11628Z'
        fill={`url(#paint0_linear_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1='0'
          y1='0'
          x2='7.5185'
          y2='11.5079'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF7A00' />
          <stop offset='0.890625' stopColor='#8200D1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

DefaultProvider1.propTypes = {
  className: PropTypes.string,
};

DefaultProvider1.defaultProps = {
  className: '',
};

export const DefaultProvider2 = ({ className }) => {
  const id = useMemo(() => Math.trunc(Math.random() * 10000), []);

  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.23862 1.11628C5.02146 -0.372094 6.97854 -0.372092 7.76138 1.11628L11.7245 8.65117C12.5073 10.1395 11.5288 12 9.9631 12H2.0369C0.471227 12 -0.507314 10.1395 0.275521 8.65116L4.23862 1.11628Z'
        fill={`url(#paint0_linear_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1='0'
          y1='0'
          x2='7.5185'
          y2='11.5079'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.890625' stopColor='#00C5D1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

DefaultProvider2.propTypes = {
  className: PropTypes.string,
};

DefaultProvider2.defaultProps = {
  className: '',
};

export const DefaultProvider3 = ({ className }) => {
  const id = useMemo(() => Math.trunc(Math.random() * 10000), []);

  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.23862 1.11628C5.02146 -0.372094 6.97854 -0.372092 7.76138 1.11628L11.7245 8.65117C12.5073 10.1395 11.5288 12 9.9631 12H2.0369C0.471227 12 -0.507314 10.1395 0.275521 8.65116L4.23862 1.11628Z'
        fill={`url(#paint0_linear_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1='0'
          y1='0'
          x2='7.5185'
          y2='11.5079'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF00B8' />
          <stop offset='0.890625' stopColor='#FAFF00' />
        </linearGradient>
      </defs>
    </svg>
  );
};

DefaultProvider3.propTypes = {
  className: PropTypes.string,
};

DefaultProvider3.defaultProps = {
  className: '',
};

export const DefaultProvider4 = ({ className }) => {
  const id = useMemo(() => Math.trunc(Math.random() * 10000), []);

  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.23862 1.11628C5.02146 -0.372094 6.97854 -0.372092 7.76138 1.11628L11.7245 8.65117C12.5073 10.1395 11.5288 12 9.9631 12H2.0369C0.471227 12 -0.507314 10.1395 0.275521 8.65116L4.23862 1.11628Z'
        fill={`url(#paint0_linear_${id})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_${id}`}
          x1='0'
          y1='0'
          x2='7.5185'
          y2='11.5079'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00F0FF' />
          <stop offset='0.890625' stopColor='#000AFF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

DefaultProvider4.propTypes = {
  className: PropTypes.string,
};

DefaultProvider4.defaultProps = {
  className: '',
};
