import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { LightButton } from '@box/ui';
import { FlexibleFields, Amount } from '@box/shared/components/Payments';

import { BonusJune } from '../BonusJune';

import { DepositBannerProps } from '../../states/DepositBanner.types';

import styles from './banner.module.scss';

export const Banner: FC<DepositBannerProps> = ({
  fields,
  onSubmit,
  amount,
  percent,
  minBonuses,
  bonusesDefault,
  currencySymbol,
  submitLoading,
  error,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, watch } = useFormContext();

  const watchAmount = watch('amount');

  const valueBonuses = useMemo(() => {
    if (watchAmount !== undefined) {
      return watchAmount;
    }
    return bonusesDefault || null;
  }, [bonusesDefault, watchAmount]);

  return (
    <>
      <div className={styles.banner}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.column}>
            <p className={styles.text}>{t('deposit.banner_text')}</p>
            <div
              className={classNames(styles.row, {
                [styles.row_column]:
                  !(valueBonuses >= Number(minBonuses)) ||
                  String(valueBonuses).length >= 4,
              })}
            >
              <Amount className={styles.amount} isBanner amount={amount} />
              <BonusJune
                className={styles.bonus}
                currencySymbol={currencySymbol}
                valueBonuses={valueBonuses}
                minBonuses={Number(minBonuses)}
                percent={String(percent)}
                isBanner
              />
            </div>
            {fields && <FlexibleFields isBanner fields={fields} />}
          </div>
          <div className={styles.button}>
            <LightButton
              loading={submitLoading}
              disabled={submitLoading}
              fixHeight
              type='submit'
            >
              {t('deposit.submit')}
            </LightButton>
          </div>
        </form>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </>
  );
};
