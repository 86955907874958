import { slice } from './slice';

export const { reducer, actions } = slice;

export const { reset: resetGamesHistory } = actions;

export * from './thunks';
export * as gameHistorySelectors from './selectors';

export default reducer;
