import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ImageSet } from '@box/ui';

import { Props } from './MobileCard.types';

import styles from './mobileCard.module.scss';
import FirstIcon from './assets/images/1.png';
import FirstIconWebp from './assets/images/1.webp';
import FirstIconAvif from './assets/images/1.avif';
import SecondIcon from './assets/images/2.png';
import SecondIconWebp from './assets/images/2.webp';
import SecondIconAvif from './assets/images/2.avif';
import ThirdIcon from './assets/images/3.png';
import ThirdIconWebp from './assets/images/3.webp';
import ThirdIconAvif from './assets/images/3.avif';
import FourthIcon from './assets/images/4.png';
import FourthIconWebp from './assets/images/4.webp';
import FourthIconAvif from './assets/images/4.avif';

export const MobileCard: FC<Props> = ({
  level,
  name,
  rating,
  rate,
  wager,
  icon,
}) => {
  const { t } = useTranslation();

  const iconUrl = () => {
    switch (icon) {
      case '1':
        return {
          png: FirstIcon,
          webp: FirstIconWebp,
          avif: FirstIconAvif,
        };
      case '2':
        return {
          png: SecondIcon,
          webp: SecondIconWebp,
          avif: SecondIconAvif,
        };
      case '3':
        return {
          png: ThirdIcon,
          webp: ThirdIconWebp,
          avif: ThirdIconAvif,
        };
      default:
        return {
          png: FourthIcon,
          webp: FourthIconWebp,
          avif: FourthIconAvif,
        };
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.info}>
        <div className={styles.column}>
          <div className={classNames(styles.row, styles.row_start)}>
            <p className={styles.level}>{level}</p>
            <p className={styles.name}>{name}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.label}>{t('loyalty.user.need_rating')}:</p>
            <p className={styles.value}>
              <span>{rating}</span>
            </p>
          </div>
        </div>
        <div className={styles.column}>
          <div>
            <p className={styles.label}>{t('loyalty.chart.exchange_rate')}:</p>
            <p className={styles.value}>
              <span>{rate}</span>
            </p>
          </div>
          <div>
            <p className={styles.label}>{t('loyalty.wager')}:</p>
            <p className={styles.value}>
              <span>{wager}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={styles.image}>
        <ImageSet
          src={iconUrl().png}
          srcWebp={iconUrl().webp}
          srcAvif={iconUrl().avif}
          alt={name}
        />
      </div>
    </div>
  );
};
