import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserTransactions,
  withdrawalRequestsSelectors,
  transactionsSelectors,
  walletsSelectors,
  fetchWithdrawalRequests,
} from '@box/redux/finance';
import { commonSelectors } from '@box/redux/common';
import { getStatus } from '@box/shared/utils';

import { useDateFormat } from '../../../../hooks';

export const withDetailParams = (Component) => {
  const wrapper = ({ ...props }) => {
    const { listId, requestMeta, userId, withdrawalRequestMeta } = props;
    const { getDetalizationDate } = useDateFormat();

    const dispatch = useDispatch();
    const currencies = useSelector(commonSelectors.currencies);
    const currentTransactions = useSelector((state) =>
      transactionsSelectors.listById(state, listId)
    );
    const currentWithdrawalRequests = useSelector((state) =>
      withdrawalRequestsSelectors.listById(state, withdrawalRequestMeta.id)
    );
    const wallets = useSelector(walletsSelectors.list);

    let transactionsList = currentTransactions?.list || [];

    const getCurrencySymbol = (name) =>
      currencies.find((item) => item.code === name)?.symbol;

    const getWalletName = (walletId) =>
      wallets.find((item) => item.id === walletId)?.name;

    const withdrawalsList =
      currentWithdrawalRequests?.list?.map((item) => ({
        date: getDetalizationDate(item.created_at),
        amount: item.amount,
        currencySymbol: getCurrencySymbol(getWalletName(item.wallet_id)),
        walletName: getWalletName(item.wallet_id),
        status: getStatus(item),
        isWithdrawal: true,
      })) || [];

    transactionsList = transactionsList
      .map((item) =>
        +item.amountFloat
          ? {
              date: getDetalizationDate(item.created_at),
              amount: parseFloat(+item.amountFloat).toFixed(2),
              currencySymbol: getCurrencySymbol(getWalletName(item.wallet_id)),
              walletName: item.wallet.name,
              status: getStatus(item),
              type: item?.meta?.type,
            }
          : null
      )
      .filter(Boolean);

    useEffect(() => {
      if (!currentTransactions?.ready) {
        dispatch(
          fetchUserTransactions({
            id: listId,
            userId,
            ...requestMeta,
          })
        );
      }

      if (!currentWithdrawalRequests?.ready) {
        dispatch(
          fetchWithdrawalRequests({
            ...withdrawalRequestMeta,
            userId,
          })
        );
      }
    }, []);

    let list =
      withdrawalsList.length >= 1
        ? [...withdrawalsList.slice(0, 2), ...transactionsList]
        : [...withdrawalsList, ...transactionsList];

    list = list.length <= 4 ? list : list.slice(0, 4);

    return (
      <Component
        {...props}
        list={list}
        loading={currentTransactions?.loading}
      />
    );
  };

  return wrapper;
};
