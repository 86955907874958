import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkApi } from '@box/redux/types';

import {
  FetchSlotGamesPayload,
  FetchSlotGamesReturn,
  GetSlotGames,
} from './types';

export const fetchSlotGames = createAsyncThunk<
  FetchSlotGamesReturn,
  FetchSlotGamesPayload,
  AppThunkApi
>('slots/games/fetchSlotGames', async (payload, { extra, rejectWithValue }) => {
  const { api, isMobile, slotUrl } = extra;

  const { position, page, perPage, search, listId } = payload;

  try {
    const { data, status } = await api.get<GetSlotGames>('/v1/games', {
      params: {
        page,
        per_page: perPage,
        catalog: position,
        is_mobile: isMobile ? 1 : 0,
        search,
      },
      baseURL: slotUrl,
    });

    if (status === 200) {
      return {
        listId,
        list: data.data,
        meta: data.meta,
      };
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue('Error');
  }
});
