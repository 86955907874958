import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeFrame } from '@box/redux/finance';
import { BackArrowButton } from '@box/ui';

import styles from './PaymentFrame.module.scss';
import { PaymentFrameProps } from './PaymentFrame.types';

export const PaymentFrame: FC<PaymentFrameProps> = ({ src, width, height }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (src) {
        dispatch(removeFrame());
      }
    };
  }, []);

  const onBack = () => {
    dispatch(removeFrame());
  };

  return (
    <div className={styles.paymentFrame}>
      <BackArrowButton
        className={styles.back}
        text={t('deposit.back')}
        onClick={onBack}
      />
      <iframe src={src} width={width} height={height} title='payment' />
    </div>
  );
};
