import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './Step.types';

import styles from './step.module.scss';

export const Step: FC<Props> = ({
  className,
  counter,
  title,
  text,
  description,
  image,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(
        styles.step,
        { [styles.step_mobile]: isMobile },
        { [styles.step_reverse]: !isMobile && counter % 2 === 0 },
        className
      )}
    >
      <div className={styles.column}>
        <div className={styles.head}>
          <p className={styles.counter}>{counter}</p>
          <p className={styles.title}>{title}</p>
        </div>
        <p className={styles.text}>{text}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <img className={styles.image} src={image} alt={title} />
    </div>
  );
};
