import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';

import { FetchRatesReturn, GETPartnerRates } from './types';

export const fetchRates = createAsyncThunk<FetchRatesReturn, void, AppThunkApi>(
  'rates/fetchRates',
  async (_paylaod, { extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data, status } = await api.get<GETPartnerRates>('/partner/rates');

      if (status === 200) {
        return data.data;
      } else if (status === 500) {
        return rejectWithValue('ServerError');
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);
