import { useEffect } from 'react';
import { useAppDispatch } from '@box/redux/hooks';
import { emailVerify } from '@box/redux/email';

export const useEmailVerifyEffect = ({ user_id, hash }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user_id && hash) {
      dispatch(emailVerify({ user_id, hash }));
    }
  }, [user_id, hash]);
};
