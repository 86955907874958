import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import { RootState } from '../../types';
import { requestStatuses } from '../../utils/constants';

export const all = (state: RootState) => state.finance.paymentMethods;
export const list = (state: RootState) => all(state).list;
export const isInit = (state: RootState) =>
  all(state).status === requestStatuses.init;
export const selectedMethod = (state: RootState) => all(state).selectedMethod;
export const sortedByCategories = createSelector([list], (list) => {
  const categories = uniqBy(
    list.reduce((acc, item) => {
      return [...acc, ...item.categories];
    }, []),
    'alias'
  );

  return categories.map((item) => {
    const payments = list.filter((payment) =>
      payment.categories.find(
        (merchantCategory) => item.alias === merchantCategory.alias
      )
    );

    return {
      title: item.title,
      list: payments,
    };
  });
});
