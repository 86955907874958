import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPureErrorMessage } from '@box/redux/utils';

import { AppThunkApi } from '../../types';

import {
  GETRoles,
  InitUserPayload,
  InitUserReturn,
  RefetchRolesReturn,
} from './types';
import { updateRoles } from './actions';

export const initUser = createAsyncThunk<
  InitUserReturn,
  InitUserPayload,
  AppThunkApi
>('user/init', async (payload, thunkAPI) => {
  const { isLogged, user } = payload;

  try {
    if (!isLogged) {
      return thunkAPI.rejectWithValue('No auth');
    }

    return user;
  } catch (e) {
    return thunkAPI.rejectWithValue('Error');
  }
});

export const refetchRoles = createAsyncThunk<
  RefetchRolesReturn,
  void,
  AppThunkApi
>(
  'user/refetchRoles',
  async (_payload, { extra, rejectWithValue, dispatch }) => {
    const { api } = extra;
    try {
      const { data, status } = await api.get<GETRoles>('/me', {
        params: {
          with: 'roles',
        },
      });

      if (status === 200) {
        dispatch(updateRoles(data.roles));
        return data;
      }

      return rejectWithValue(getPureErrorMessage(data));
    } catch (err) {
      return rejectWithValue('error');
    }
  }
);
