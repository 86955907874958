import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BonusDepositDetail } from '@box/shared/components';
import { LightButton, ImageSet } from '@box/ui';

import { DepositBannerProps } from '../../states/DepositBanner.types';

import styles from './bottomPlate.module.scss';
import VectorPNG from './assets/images/vector.png';
import VectorWebp from './assets/images/vector.webp';
import VectorAvif from './assets/images/vector.avif';

export const BottomPlate: FC<DepositBannerProps> = ({
  defaultAmount,
  percent,
  currencySymbol,
  handleClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.bottomPlate}>
      <div className={styles.box}>
        <div className={styles.row}>
          <div className={styles.amount}>
            {defaultAmount} {currencySymbol}
          </div>
          <p className={styles.text}>
            +{Math.floor(defaultAmount * percent) / 100}
            {currencySymbol}
          </p>
          <ImageSet
            className={styles.img}
            src={VectorPNG}
            srcWebp={VectorWebp}
            srcAvif={VectorAvif}
            alt='Decor'
          />
        </div>
        <BonusDepositDetail openUp />
      </div>
      <LightButton className={styles.button} onClick={handleClick}>
        {t('deposit.submit')}
      </LightButton>
    </div>
  );
};
