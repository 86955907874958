import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { DepositFields } from '@box/redux';

import { Controller } from '../../ui';

import { Props } from './Amount.types';

export const Amount: FC<Props> = ({
  amount,
  currencySymbol,
  label,
  className,
}) => {
  const { t } = useTranslation();
  const { formState, register } = useFormContext<DepositFields>();
  const { errors } = formState;

  return (
    <Controller
      {...register('amount', {
        required: { value: true, message: t('fields.payment_rules.required') },
        min: {
          value: amount.min,
          message: t('fields.payment_rules.minSum', { sum: amount.min }),
        },
        max: {
          value: amount.max,
          message: t('fields.payment_rules.maxSum', { sum: amount.max }),
        },
      })}
      type='number'
      placeholder={`${amount.min} ${currencySymbol}`}
      errorMessage={errors?.amount?.message}
      className={className}
      label={label}
      labelAlign='left'
    />
  );
};
