import React, { useEffect, useMemo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  paymentMethodsSelectors,
  fetchPaymentMethods,
  paymentSelectors,
  userPayment,
} from '@box/redux/finance';
import { paymentIntension } from '@box/shared/utils';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import { Field, DepositFields } from '@box/redux';
import { userInitSelectors } from '@box/redux/authentication';
import { userSelectors } from '@box/redux/user';

import { DepositBannerProps } from './DepositBanner.types';

export const depositBannerState = (Component: FC<DepositBannerProps>) => {
  const wrapper = () => {
    const methods = useForm<DepositFields>();
    // TODO: Перенести логику на сторону бэка
    const paymentId = 30;

    const { watch, setValue } = methods;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isPaymentsInit = useAppSelector(paymentMethodsSelectors.isInit);
    const isLogged = useAppSelector(userInitSelectors.isLogged);
    const payments = useAppSelector(paymentMethodsSelectors.list);
    const currency = useAppSelector(userSelectors.currency);
    const loading = useAppSelector(paymentSelectors.loading);
    const requestError = useAppSelector(paymentSelectors.error);
    const clientData = useAppSelector(commonSelectors.clientData);
    const userInfo = useAppSelector(userSelectors.user);
    const currentPayment = payments.find((item) => item.id === paymentId);
    const userId = useAppSelector(userSelectors.id);
    const watchAmount = watch('amount');

    const defaultBonus =
      clientData?.country?.payment_method_params?.dep_button2_amount;

    const onSubmit: SubmitHandler<DepositFields> = (data) => {
      const value = {
        userId,
        amount: data.amount,
        currencyCode: currency.code,
        paymentMethodId: currentPayment?.id,
        merchantId: currentPayment?.merchant.id,
        fields: {
          ...data,
          hosted: false,
        },
      };

      paymentIntension({
        userId,
        paymentPayload: value,
      });

      dispatch(
        userPayment({
          userId,
          ...value,
        })
      );
    };

    const handleClick = () => {
      navigate('/');
      window.scrollTo(0, 0);
    };

    const fields = useMemo(() => {
      let fields = [] as Field[];
      if (currentPayment) {
        currentPayment?.fields?.forEach((item) => {
          fields.push(item);
        });
      }
      return fields;
    }, [currentPayment]);

    const amount = () => {
      const min = currentPayment?.limits?.min;
      if (defaultBonus) {
        return defaultBonus;
      } else if (min) {
        return min;
      }
      return 0;
    };

    const percent = userInfo?.deposit_bonus?.bonus?.getting_params[0]?.percent;

    useEffect(() => {
      if (!isPaymentsInit && isLogged) {
        dispatch(fetchPaymentMethods());
      }
    }, [isLogged]);

    useEffect(() => {
      if (clientData) {
        if (typeof watchAmount === 'undefined') {
          setValue('amount', defaultBonus);
        }
      }
    }, [clientData, watchAmount]);

    return (
      <FormProvider {...methods}>
        <Component
          currencySymbol={currency?.symbol}
          defaultAmount={amount()}
          currentPayment={currentPayment}
          percent={Number(percent) || 0}
          bonusesDefault={defaultBonus}
          handleClick={handleClick}
          fields={fields}
          submitLoading={loading}
          error={requestError}
          amount={{
            min: currentPayment?.limits?.min,
            max: currentPayment?.limits?.max,
          }}
          onSubmit={onSubmit}
          minBonuses={userInfo?.deposit_bonus?.bonus?.getting_params[0]?.min}
        />
      </FormProvider>
    );
  };

  return wrapper;
};
