import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';
import { ImageSet } from '@box/ui';

import { Props } from './Bottom.types';

import styles from './bottom.module.scss';
import PayFirst from './assets/images/pay_first.png';
import PayFirstWebp from './assets/images/pay_first.webp';
import PaySecond from './assets/images/pay_second.png';
import PaySecondWebp from './assets/images/pay_second.webp';
import PayThird from './assets/images/pay_third.png';
import PayThirdWebp from './assets/images/pay_third.webp';
import { ReactComponent as LogoSvg } from './assets/images/logo.svg';
import { ReactComponent as LockSvg } from './assets/images/lock.svg';

export const Bottom: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const { route } = useApp();

  return (
    <div className={classNames(styles.bottom, className)}>
      <div className={styles.policy}>
        <LockSvg />
        <div className={styles.policyText}>
          {t('deposit.policy.policy_text')}{' '}
          <Link to={route('politics.terms_and_conditions')}>
            {t('deposit.policy.terms')}
          </Link>{' '}
          {t('deposit.policy.end')}{' '}
          <Link to={route('politics.privacy')}>
            {t('deposit.policy.privacy')}
          </Link>
          .
        </div>
      </div>
      <div className={styles.payments}>
        <div className={styles.bottomImages}>
          <LogoSvg />
          <ImageSet src={PayFirst} srcWebp={PayFirstWebp} alt='pay_first' />
          <ImageSet src={PaySecond} srcWebp={PaySecondWebp} alt='pay_second' />
          <ImageSet src={PayThird} srcWebp={PayThirdWebp} alt='pay_third' />
        </div>
        <span>{t('deposit.policy.address')}</span>
      </div>
    </div>
  );
};
