import { BonusTypes } from './bonuses';

export type Currency = {
  id: number;
  code: string;
  name: string;
  symbol: string;
};

export type Country = {
  id: number;
  code: string;
  name: string;
  phone_code: number;
};

// Banner
export type Banner = {
  title: string;
  description: string;
  position: BannerPotions;
  auth: BannerAuthStatuses;
  url?: string;
  weight: number;
  click_action: string;
  bonus?: unknown;
  images: {
    desktop: string;
    mobile: string;
  };
  buttons: BannerButton[] | [];
};

type BannerButton = {
  id: number;
  text: string;
  color: string;
  type: BannerButtonType;
};

export type BannersWithPosition = {
  id: number;
  name: string;
  alias: string;
  banners: Banner[];
};

export enum BannerButtonType {
  LINK = 'link',
  GET_BONUS = 'get_bonus',
  OPEN_BONUS = 'open_bonus',
}

export enum BannerAuthStatuses {
  ALL = 'all',
  AUTH = 'auth',
  GUEST = 'guest',
}

export enum BannerPotions {
  MAIN = 'main_slider',
  CASINO = 'casino_slider',
  SECONDARY = 'secondary',
  TOP_UP_MODAL = 'topup_modal',
}

// ClientData
export type ClientData = {
  country: {
    code: string;
    default_currency_code: string;
    default_language_code: string;
    payment_method_params: {
      enabled: boolean;
      dep_button1_amount: number;
      dep_button2_amount: number;
      dep_button3_amount: number;
      dep_button4_amount: number;
      beatify_form_for_methods: number[];
    };
  };
  ip: string;
  jackpot_id: number;
  partner_mirror: string;
};

export type Provider = {
  id: number;
  name: string;
  alias: string;
  games_count: number;
  icon: string;
};

export type Category = {
  id: number;
  name: string;
  alias: string;
  games_count: number;
  icon: unknown;
};

export type BonusTypesNames = Record<BonusTypes, string>;

export type Language = {
  id: number;
  name: string;
  code: string;
  native_name: string;
};
