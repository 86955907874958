import { createSlice } from '@reduxjs/toolkit';
import { generateUUID } from '@box/shared/utils/generateUUID';

import { closeInfoPopup, openInfoPopup } from './actions';
import { InitialState } from './types';

export const initialState: InitialState = {
  popupList: [],
};

export const slice = createSlice({
  name: 'app/infoPopup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openInfoPopup, (state, { payload }) => {
        const id = generateUUID();
        state.popupList = [...state.popupList, { ...payload, id }];
      })
      .addCase(closeInfoPopup, (state) => {
        state.popupList = [
          ...state.popupList.slice(0, state.popupList.length - 1),
        ];
      });
  },
});
