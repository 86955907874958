import { createSelector } from '@reduxjs/toolkit';
import { RootState, UserWallet } from '../../types';

export const all = (state: RootState) => state.finance.wallets;
export const list = (state: RootState) => all(state).list;

const userCurrency = (state: RootState) => state.user.info?.currency;

export const current = createSelector(
  [list, userCurrency],
  (wallets, currency) =>
    wallets.find((item) => item.name === currency?.code) || ({} as UserWallet)
);

export const bonus = createSelector(
  [list],
  (wallets) =>
    wallets.find((item) => item.slug === 'BONUS') || ({} as UserWallet)
);
