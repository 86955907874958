/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './NavigationButton.types';

import styles from './navigationButton.module.scss';

export const NavigationButton: FC<Props> = ({
  label,
  icon: Icon,
  searchParams,
  value,
  isBase,
  className,
  disabled,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const [params, setParams] = useSearchParams();

  const currentParam = params.get(searchParams);

  const handleSetParams = () => {
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleClick = () => {
    if (isBase) {
      params.delete(searchParams);
      handleSetParams();
    } else {
      params.append(searchParams, value);
      handleSetParams();
    }
  };

  const isActive = () => {
    if (isBase) {
      return currentParam === null;
    }
    return currentParam === value;
  };

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={handleClick}
      className={classNames(
        styles.button,
        {
          [styles.button_active]: isActive(),
          [styles.button_mobile]: isMobile,
          [styles.button_disabled]: disabled,
        },
        className
      )}
    >
      <>
        <Icon />
        <p className={styles.label}>{label}</p>
        <div className={styles.decor} />
      </>
    </button>
  );
};
