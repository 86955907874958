import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';
import classNames from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  fetchLogin,
  loginSelectors,
  registerSelectors,
  setIsButtonDisabled,
} from '@box/redux/authentication';
import { hideModal, openModal } from '@box/redux/app';
import { DecemberInput, DecemberPassword, Text, Window } from '@box/ui';
import { commonSelectors } from '@box/redux/common';
import {
  getCountryIdByICode,
  getCurrencyIdByCode,
} from '@box/shared/utils/selectOptions';

import { Control, Button } from '../../../baseComponents';
import { prepareUsername } from '../../../utils/user';
import { passwordRegex, validatePhone } from '../../../utils/validation';

import { Modal } from '../../Modal/Modal';

import './loginModal.scss';

export const schema = z.object({
  username: z
    .string()
    .email('fields.email_format_error')
    .or(z.string().refine(validatePhone, 'fields.phone_format_error')),
  password: z
    .string()
    .min(6, 'fields.password_length_error')
    .max(30, 'fields.password_max_length_error')
    .regex(passwordRegex, 'fields.invalid_field'),
});

export const LoginModal = () => {
  const { t } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: zodResolver(schema),
  });

  const loading = useSelector(loginSelectors.loading);
  const registerLoading = useAppSelector(registerSelectors.loading);
  const error = useSelector(loginSelectors.error);
  const isUserNotFound = useSelector(loginSelectors.isUserNotFound);
  const currencies = useSelector(commonSelectors.currencies);
  const clientData = useSelector(commonSelectors.clientData);
  const countries = useSelector(commonSelectors.countries);
  const isButtonDisabled = useAppSelector(loginSelectors.isButtonDisabled);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(setIsButtonDisabled(true));
    data.username = prepareUsername(data.username);
    dispatch(
      fetchLogin({
        currency_id: getCurrencyIdByCode(
          currencies,
          clientData?.country?.default_currency_code
        ),
        country_id: getCountryIdByICode(countries, clientData?.country?.code),
        ...data,
      })
    );
  };

  const modalOnHide = () => reset();

  const openRecovery = () => {
    dispatch(hideModal('login'));
    dispatch(openModal('recovery'));
  };

  const openRegister = () => {
    dispatch(hideModal('login'));
    dispatch(openModal('register'));
  };

  return (
    <Modal
      id='login'
      className='login'
      title={t('login_modal.title')}
      onClose={modalOnHide}
      size='xs'
    >
      <div className={classNames('login__body')}>
        <span className='login__body-title'>{t('login_modal.choice')}</span>
        <form
          className={classNames('login__form', {
            login__form_mobile: isMobile,
          })}
          onSubmit={handleSubmit(onSubmit)}
        >
          <DecemberInput
            placeholder={t('login_modal.login_field')}
            color='outlined'
            className='login__form-input'
            error={t(errors.username?.message)}
            {...register('username')}
          />
          <DecemberPassword
            placeholder={t('login_modal.password')}
            color='outlined'
            className='login__form-input'
            error={t(errors.password?.message)}
            {...register('password')}
          />
          <div className='login__form-controls'>
            <button
              className='login__form-forgot'
              type='button'
              onClick={openRecovery}
            >
              {t('login_modal.forgot_pass')}
            </button>
            <Button
              className='login__form-submit'
              type='submit'
              color='secondary'
              disabled={loading || registerLoading || isButtonDisabled}
              loading={loading || registerLoading}
            >
              {t('login_modal.submit')}
            </Button>
          </div>
        </form>
        {!isUserNotFound && <span className='login__form-error'>{error}</span>}
      </div>
      <Window.Footer className='modal-footer'>
        <Text size='xs' color='gray'>
          {t('login_modal.no_acc')}
        </Text>
        <button
          type='button'
          onClick={openRegister}
          className='modal-footer-link'
        >
          {t('login_modal.register')}
        </button>
      </Window.Footer>
    </Modal>
  );
};
