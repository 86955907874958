import { FormSelect } from '@box/shared/baseComponents';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '@box/redux/hooks';

import { commonSelectors } from '@box/redux/common';

import { Inputs } from '../PersonalData.types';

export const CountryField = memo(() => {
  const { t } = useTranslation();

  const countries = useAppSelector(commonSelectors.settingsCountries);
  const {
    control,
    formState: { errors },
  } = useFormContext<Inputs>();

  if (countries) {
    return (
      <FormSelect
        name='country_id'
        control={control as any}
        options={countries}
        onChange={(val, field) => field.onChange(val.value)}
        label={t('settings.country')}
        disabled
        error={t(errors.country_id?.message)}
        errorMessage={t(errors.country_id?.message)}
        isMyProfile
      />
    );
  }
  return null;
});
