import React, { useState, FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import { setChatOpen } from '@box/redux/support';
import { openImageViewer } from '@box/redux/app';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import { ImageSet } from '@box/ui';

import { useApp } from '../../hooks';
import { BlockWithLoading, LinkIcon } from '../../baseComponents';
import { ChatForm } from '../ChatForm';
import { MessageButton } from './MessageButton';

import { MessageItemProps, SupportChatProps } from './SupportChat.types';

import './supportChat.scss';

import Avatar from './assets/images/avatar.jpg';
import AvatarWebp from './assets/images/avatar.webp';
import AvatarAvif from './assets/images/avatar.avif';

const MessageItem: FC<MessageItemProps> = ({
  isSelf,
  text,
  image,
  date,
  buttons,
  isShowUz,
}) => {
  const dispatch = useAppDispatch();

  const [openButton, setOpenButton] = useState(null);

  const openImage = () => {
    dispatch(openImageViewer(image));
    dispatch(setChatOpen(false));
  };

  return (
    <div
      className={classNames('message-item', {
        'message-item--self': isSelf,
      })}
    >
      <div className='message-item__inner'>
        {!isSelf && (
          <div className='message-item__icon'>
            {isShowUz ? (
              <ImageSet
                src={Avatar}
                srcWebp={AvatarWebp}
                srcAvif={AvatarAvif}
                alt='Manager'
              />
            ) : (
              <LinkIcon name='logo' />
            )}
          </div>
        )}
        <div className='message-item__content'>
          {(text || image) && (
            <div className='message-item__text'>
              {text && <ReactMarkdown>{text}</ReactMarkdown>}

              {image && (
                <img
                  className='message-item__image'
                  onClick={openImage}
                  src={image}
                  alt='picture from messages'
                  aria-hidden='true'
                />
              )}
            </div>
          )}

          <div className='message-item__date'>{date}</div>
          {buttons?.map((button, index) => (
            <MessageButton
              key={index}
              id={index}
              text={button.params.text}
              buttonText={button.name}
              openButton={openButton}
              setOpenButton={setOpenButton}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const SupportChat: FC<SupportChatProps> = ({
  messages,
  onSendMessage,
  close,
  sendLoading,
  messagesLoading,
  isOpen,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  // еще думаю как сделать лучше
  const clientData = useAppSelector(commonSelectors.clientData);
  const isShowUz = clientData?.country?.code === 'UZ';

  return createPortal(
    <div
      className={classNames('support-chat', {
        'support-chat--mobile': isMobile,
        'support-chat_open': isOpen,
      })}
    >
      <div className='support-chat__inner'>
        <div className='support-chat__head'>
          <div className='support-chat__icon'>
            {isShowUz ? (
              <ImageSet
                src={Avatar}
                srcWebp={AvatarWebp}
                srcAvif={AvatarAvif}
                alt='Manager'
              />
            ) : (
              <LinkIcon name='logo' />
            )}
          </div>
          <div className='support-chat__head-info'>
            <span className='support-chat__name'>Smart Assistant</span>
            <span className='support-chat__status'>online</span>
          </div>
          <div
            className='support-chat__close'
            onClick={close}
            aria-hidden='true'
          />
        </div>
        <div className='support-chat__body'>
          <BlockWithLoading loading={messagesLoading}>
            <div className='support-chat__messages'>
              {[...messages].reverse().map((item, idx) => (
                <MessageItem
                  isShowUz={isShowUz}
                  {...item}
                  buttons={item.buttons}
                  key={idx}
                />
              ))}
            </div>
          </BlockWithLoading>
        </div>
        <div className='support-chat__bottom'>
          <ChatForm
            loading={sendLoading || messagesLoading}
            sendMessage={onSendMessage}
            disabled={sendLoading || messagesLoading}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};
