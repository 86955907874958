/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PhotosProps } from './Photos.types';

import styles from './photos.module.scss';
import { Upload } from './Upload/Upload';

export const Photos: React.FC<PhotosProps> = React.memo(({ disabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.photos}>
      <Upload
        name='scan'
        label={t('settings.document_photo')}
        disabled={disabled}
        first
      />
      <Upload
        name='photo'
        label={t('settings.document_selfie')}
        disabled={disabled}
        first={false}
      />
    </div>
  );
});
