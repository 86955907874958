import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { bonusesSelectors, fetchBonuses } from '@box/redux/bonuses';
import { userBonusesSelectors } from '@box/redux/user/bonuses';
import { useAppSelector } from '@box/redux/hooks';

export const useBonusesListState = () => {
  const dispatch = useDispatch();

  const loading = useAppSelector(bonusesSelectors.loading);
  const isInit = useAppSelector(bonusesSelectors.init);
  const userBonusesLoading = useAppSelector(userBonusesSelectors.loading);
  let list = useAppSelector(bonusesSelectors.list);

  useEffectOnce(() => {
    if (!isInit && !loading) {
      dispatch(fetchBonuses());
    }
  });
  return { list, loading, userBonusesLoading };
};
