import axios from 'axios';

import { appConfig } from './config';
import { getToken, getCrmToken } from './utils';

const api = axios.create({
  baseURL: `${appConfig.mainApi}/v1`,
  validateStatus: (status: number) => {
    return status >= 200 && status < 501;
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const crmToken = getCrmToken();
    const lang = localStorage.getItem('lang') || 'en';
    const originReferer = localStorage.getItem('Origin-Referer');

    if (config.baseURL === appConfig.crmApi) {
      if (crmToken) {
        config.headers.Authorization = `Bearer ${crmToken}`;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    config.headers['cache-control'] = 'no-cache';
    config.headers['X-Lang'] = lang;
    config.headers['Origin-Referer'] = originReferer;
    return config;
  },
  (e) => Promise.reject(e)
);

api.interceptors.response.use(
  async (res) => {
    if (appConfig.env === 'development') {
      // eslint-disable-next-line no-console
      console.info(
        '🚀',
        res.status,
        res.config.method.toUpperCase(),
        res.config.url,
        res
      );
    }

    return res;
  },
  (err) => Promise.reject(err)
);

export { api };
