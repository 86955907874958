import React from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { ImageSet } from '@box/ui';

import styles from './lightPayments.module.scss';

import Payment1 from './assets/images/1.png';
import Payment1Webp from './assets/images/1.webp';
import Payment2 from './assets/images/2.png';
import Payment2Webp from './assets/images/2.webp';
import Payment3 from './assets/images/3.png';
import Payment3Webp from './assets/images/3.webp';
import Payment4 from './assets/images/4.png';
import Payment4Webp from './assets/images/4.webp';
import Payment5 from './assets/images/5.png';
import Payment5Webp from './assets/images/5.webp';
import Payment6 from './assets/images/6.png';
import Payment6Webp from './assets/images/6.webp';
import Payment7 from './assets/images/7.png';
import Payment7Webp from './assets/images/7.webp';
import Payment8 from './assets/images/8.png';
import Payment8Webp from './assets/images/8.webp';
import Payment9 from './assets/images/9.png';
import Payment9Webp from './assets/images/9.webp';
import Payment10 from './assets/images/10.png';
import Payment10Webp from './assets/images/10.webp';
import Payment11 from './assets/images/11.png';
import Payment11Webp from './assets/images/11.webp';
import Payment12 from './assets/images/12.png';
import Payment12Webp from './assets/images/12.webp';

const payments = [
  {
    png: Payment1,
    webp: Payment1Webp,
  },
  {
    png: Payment2,
    webp: Payment2Webp,
  },
  {
    png: Payment3,
    webp: Payment3Webp,
  },
  {
    png: Payment4,
    webp: Payment4Webp,
  },
  {
    png: Payment5,
    webp: Payment5Webp,
  },
  {
    png: Payment6,
    webp: Payment6Webp,
  },
  {
    png: Payment7,
    webp: Payment7Webp,
  },
  {
    png: Payment8,
    webp: Payment8Webp,
  },
  {
    png: Payment9,
    webp: Payment9Webp,
  },
  {
    png: Payment10,
    webp: Payment10Webp,
  },
  {
    png: Payment11,
    webp: Payment11Webp,
  },
  {
    png: Payment12,
    webp: Payment12Webp,
  },
];

export const LightPayments = () => {
  const {
    userEnv: { isDesktop },
  } = useApp();

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {payments.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(styles.item, {
                [styles.item_desktop]: isDesktop,
              })}
            >
              <ImageSet
                src={item.png}
                srcWebp={item.webp}
                key={index}
                alt='Payment'
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
