import { createAsyncThunk } from '@reduxjs/toolkit';

import { getPureErrorMessage } from '../../utils';
import { AppThunkApi } from '../../types';

import {
  POSTTransaction,
  UserPaymentsPayload,
  UserPaymentsReturn,
} from './types';

export const userPayment = createAsyncThunk<
  UserPaymentsReturn,
  UserPaymentsPayload,
  AppThunkApi
>('payment/userPayment', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  const { amount, currencyCode, merchantId, fields, userId, paymentMethodId } =
    payload;

  try {
    const { data, status } = await api.post<POSTTransaction>(
      `/userdatas/${userId}/transactions`,
      {
        amount,
        currency: currencyCode,
        type: 'payment',
        meta: {
          return_url: window.location.href,
          payment_method_id: paymentMethodId,
          merchant_id: merchantId,
          payload: { ...fields },
        },
      }
    );

    if (status === 201) {
      if (!data.meta.external_object.result.success) {
        const { message, description } = data.meta.external_object.result.error;

        return rejectWithValue(message || description);
      }

      return data;
    }

    const error = getPureErrorMessage(data.errors);
    return rejectWithValue(error);
  } catch (err) {
    return rejectWithValue('Error');
  }
});
