import React, { FC } from 'react';
import classNames from 'classnames';
import { LightBonusItem as BonusItem } from '@box/shared/components';

import { useApp } from '@box/shared/hooks';
import { Props } from './lightBonusesList.types';

import styles from './lightBonusesList.module.scss';

export const LightBonusesList: FC<Props> = ({
  list,
  title,
  counter,
  no_title,
  type,
  commonList,
}) => {
  const {
    userEnv: { isDesktop, isMobile },
  } = useApp();

  const handleBonusItemReturn = () => {
    if (type === 'all' && commonList.length) {
      return (
        <>
          {commonList.map((element) =>
            element.list.map((item, index) => (
              <BonusItem
                listType={element.type}
                className={classNames(styles.item, {
                  [styles.item_desktop]: isDesktop,
                })}
                key={index}
                {...item}
              />
            ))
          )}
        </>
      );
    }

    return (
      <>
        {list.map((item, index) => {
          return (
            <BonusItem
              className={classNames(styles.item, {
                [styles.item_desktop]: isDesktop,
              })}
              key={index}
              bonusId={item.id}
              {...item}
            />
          );
        })}
      </>
    );
  };
  return (
    <div
      className={classNames(styles.list, {
        [styles.list_desktop]: isDesktop,
      })}
    >
      {!no_title && (
        <div className={styles.row}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.counter}>{counter}</div>
        </div>
      )}
      <div
        className={classNames(styles.container, {
          [styles.container_mobile]: isMobile,
        })}
      >
        {handleBonusItemReturn()}
      </div>
    </div>
  );
};
