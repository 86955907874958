/* eslint-disable max-len */
import appConfig from '../config';

export const getImageUrl = (
  id: string,
  type:
    | 'defaultDesktop'
    | 'jackpotDesktop'
    | 'defaultMobile'
    | 'providerMobile'
    | 'winMobile'
) => {
  return `${appConfig.image_url}/${appConfig.image_hash}/game/${id}/${type}`;
};
