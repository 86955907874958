import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideModal, setRedirects } from '@box/redux/app';

import { AnimationDelayWrapper } from '../../baseComponents';

export const withReduxState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { id, onHide } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { show, redirects } = useSelector((state) => state.app.modals);

    const redirectsOnHide = Object.keys(redirects || {});

    const isOpen = show.includes(id);

    useEffect(() => {
      if (!isOpen && redirectsOnHide.includes(id)) {
        navigate(redirects[id]);
        dispatch(setRedirects({}));
      }
    }, [show, redirects, isOpen]);

    const close = () => {
      dispatch(hideModal(id));
      if (onHide) onHide();

      if (redirectsOnHide.includes(id)) {
        // Если id текущего модального окна, есть в списке
        // редиректов, то при закрытии произойдёт редирект
        // на указанный url
        navigate(redirects[id]);
        dispatch(setRedirects({}));
      }
    };

    return (
      <AnimationDelayWrapper isOpen={isOpen}>
        <Component {...props} close={close} isOpen={isOpen} />
      </AnimationDelayWrapper>
    );
  };

  return wrapper;
};
