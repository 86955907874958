import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp, useToggleActiveElement } from '@box/shared/hooks';
import { DetailPopup } from '@box/shared/baseComponents';

import { Props } from './DetailInfo.types';

import styles from './detailInfo.module.scss';

export const DetailInfo: FC<Props> = ({
  className,
  label,
  text,
  isButton,
  onClick,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const {
    toggle: toggleOpen,
    isOpen,
    elementRef,
  } = useToggleActiveElement({ hideWhenClickOutside: true });

  return (
    <div
      onClick={isButton ? onClick : toggleOpen}
      aria-hidden='true'
      ref={elementRef}
      className={classNames(
        styles.detail,
        { [styles.detail_mobile]: isMobile },
        className
      )}
    >
      <div className={styles.open}>
        <div className={styles.decor}>?</div>
        <p className={styles.text}>{label}</p>
      </div>
      {!isButton && text && (
        <DetailPopup isLoyalty className={styles.popup} isOpen={isOpen}>
          {text}
        </DetailPopup>
      )}
    </div>
  );
};
