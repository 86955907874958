import React, { FC } from 'react';
import classNames from 'classnames';
import { Loader } from '@box/ui/src/Loader';

import { Props } from './Button.types';

import styles from './button.module.scss';

export const Button: FC<Props> = ({ className, disabled, label, isShadow }) => {
  return (
    <button
      disabled={disabled}
      className={classNames(
        styles.button,
        {
          [styles.button_disabled]: disabled,
          [styles.button_shadow]: isShadow,
        },
        className
      )}
      type='submit'
    >
      {disabled ? <Loader /> : label}
    </button>
  );
};
