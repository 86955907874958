import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types';

export const lists = (state: RootState) => state.finance.transactions;
export const listId = (_state: RootState, id: string) => id;

export const listById = createSelector([lists, listId], (state, id) => {
  if (state) {
    return {
      ...state[id],
    };
  }
  return false;
});
