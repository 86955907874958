export const requestStatuses = {
  init: 'init',
  notStarted: 'not started',
};

export const bonusStatuses = {
  created: 'created',
  ended: 'ended',
  activated: 'activated',
  spins_used: 'spins_used',
};

export const bannersPositions = {
  MAIN_SLIDER: 'main_slider',
  CASINO_SLIDER: 'casino_slider',
  TOP_UP: 'topup_modal',
  SECONDARY: 'secondary',
};

export const bannersButtonsType = {
  REDIRECT: 'link',
  GET_BONUS: 'get_bonus',
  OPEN_BONUS: 'open_bonus',
};

export const casinoPageGamesPerRow = 5;
