import { createSlice } from '@reduxjs/toolkit';

import { updatePassword } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  loading: false,
  error: '',
};

export const slice = createSlice({
  name: 'passwordUpdate',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});
