import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';
import styles from './popup.module.scss';
import { Props } from './Popup.types';

export const Popup: FC<Props> = ({
  isOpen,
  type,
  text,
  onClose,
  icon: Icon,
  isSecondary,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.popup, styles[`popup_color_${type}`], {
        [styles.mobile]: isMobile,
        [styles['popup--open']]: isOpen,
        [styles.popup_secondary]: isSecondary,
      })}
    >
      <div className={styles.inner}>
        <button onClick={onClose} className={styles.close}>
          <Icon />
        </button>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};
