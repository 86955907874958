import React, { FC } from 'react';

import { useForm, FormProvider } from 'react-hook-form';

import classNames from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppSelector } from '@box/redux/hooks';
import { userSelectors } from '@box/redux/user';
import { personalDataSchema } from '@box/shared/utils/validation';
import { useApp } from '@box/shared/hooks';

import { VerifyStatus } from '../VerifyStatus';

import { Inputs, Props } from './PersonalData.types';

import styles from './personalData.module.scss';
import { EmailVerified } from './EmailVerified';
import { PersonalDataForm } from './PersonalData.form';

export const PersonalData: FC<Props> = ({ isMainContent = false }) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const isVerify = useAppSelector(userSelectors.isVerify);
  const isVerifyPending = useAppSelector(userSelectors.isPendingVerification);
  const isVerifyDenied = useAppSelector(userSelectors.isVerifyDenied);
  const isVerifyNotStarted = useAppSelector(userSelectors.isVerifyNotStarted);
  const isEmailVerified = useAppSelector(userSelectors.isEmailVerified);

  const formMethods = useForm<Inputs>({
    resolver: zodResolver(personalDataSchema),
  });

  return (
    <FormProvider {...formMethods}>
      <div
        className={classNames(styles.container, {
          [styles.container_mobile]: isMobile,
          [styles.container_desktop]: !isMobile,
          [styles.container_main]: isMainContent,
        })}
      >
        {!isEmailVerified && <EmailVerified className={styles.verified} />}
        <VerifyStatus
          isVerify={isVerify}
          isVerifyDenied={isVerifyDenied}
          isVerifyNotStarted={isVerifyNotStarted}
          isVerifyPending={isVerifyPending}
        />
        <PersonalDataForm />
      </div>
    </FormProvider>
  );
};
