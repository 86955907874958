import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const queries: Record<string, string> = useMemo(
    () =>
      search
        .split('?')
        .join('')
        .split('&')
        .reduce(
          (acc, query) =>
            query.split('=')[0]
              ? {
                  ...acc,
                  [query.split('=')[0]]: query.split('=')[1],
                }
              : acc,
          {}
        ),
    [search]
  );

  const setParam = ({ name, value }) => {
    const currentQueries = queries;
    currentQueries[name] = value;
    const emptyFilters = Object.values(queries).map((item) => !item && true);

    const searchString = !emptyFilters[0]
      ? Object.keys(currentQueries)
          .reduce((acc, query) => {
            if (name === query) {
              return acc.length > 0
                ? [...acc, `&${query}=${currentQueries[query]}`]
                : currentQueries[query]
                ? [
                    ...acc,
                    currentQueries[query] !== ''
                      ? `?${query}=${currentQueries[query]}`
                      : '',
                  ]
                : [...acc];
            }

            return acc.length > 0
              ? [...acc, `&${query}=${currentQueries[query]}`]
              : [
                  ...acc,
                  currentQueries[query] !== ''
                    ? `?${query}=${currentQueries[query]}`
                    : '',
                ];
          }, [])
          .join('')
      : '';

    return navigate({
      pathname,
      search: searchString,
    });
  };

  const pushWithQueries = (url: string) => navigate(`${url}${search}`);

  return {
    queries,
    setParam,
    pushWithQueries,
  };
};
