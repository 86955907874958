import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useAppDispatch } from '@box/redux/hooks';
import { hideModal, openModal } from '@box/redux/app';

import { useApp, useDisabledByPath } from '@box/shared/hooks';

import { Modal } from '../../Modal/Modal';
import Tabs from '../../Tabs/Tabs';
import { Quick } from './Quick';
// import { ViaPhone } from './ViaPhone';

import Bonus1Icon from './assets/bonus_1.svg';
import Bonus2Icon from './assets/bonus_2.svg';
import Bonus3Icon from './assets/bonus_3.svg';

import styles from './Register.module.scss';

export const RegisterModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const disabled = useDisabledByPath({ path: '/aviator' });

  const { route } = useApp();

  const openLogin = () => {
    dispatch(hideModal('register'));
    dispatch(openModal('login'));
  };

  const handleUserAgreement = () => {
    dispatch(hideModal('register'));
    navigate(route('politics.user_agreement'));
  };

  const openUserAgreement = () => {
    disabled(handleUserAgreement);
  };

  const tabsData = useMemo(
    () => [
      { title: t('modal_reg.quick.title'), component: Quick },
      // { title: t('modal_reg.via_phone.title'), component: ViaPhone },
    ],
    []
  );

  return (
    <Modal
      size='smplus'
      id='register'
      title={t('modal_reg.title')}
      className={styles.register}
    >
      <Tabs tabs={tabsData} />
      <div className={classNames(styles.footer)}>
        <span>{t('modal_reg.have_acc')}</span>
        <span
          className={styles.footer_link}
          onClick={openLogin}
          aria-hidden='true'
        >
          {t('modal_reg.login')}
        </span>
      </div>
      <div className={styles.bonus}>
        <div className={styles.bonus_item}>
          <img
            src={Bonus1Icon}
            className={styles.bonus_icon}
            alt='Private promotions and bonuses'
          />
          <span className={styles.bonus_title}>{t('modal_reg.bonuses.1')}</span>
        </div>
        <div className={styles.bonus_item}>
          <img
            src={Bonus2Icon}
            className={styles.bonus_icon}
            alt='Cashbacks and prizes'
          />
          <span className={styles.bonus_title}>{t('modal_reg.bonuses.2')}</span>
        </div>
        <div className={styles.bonus_item}>
          <img
            src={Bonus3Icon}
            className={styles.bonus_icon}
            alt='Best tournaments'
          />
          <span className={styles.bonus_title}>{t('modal_reg.bonuses.3')}</span>
        </div>
      </div>
      <div className={styles.policy}>
        {t('modal_reg.policy.start')}{' '}
        <span onClick={openUserAgreement} aria-hidden='true'>
          {t('modal_reg.policy.link')}
        </span>
        {t('modal_reg.policy.end')}
      </div>
    </Modal>
  );
};
