// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Games-games-module__games{margin-top:16px;padding-bottom:66px}.\\------shared-components-Games-games-module__games_june{padding:0 0 16px}", "",{"version":3,"sources":["webpack://./../../shared/components/Games/games.module.scss"],"names":[],"mappings":"AACA,oDACE,eAAA,CACA,mBAAF,CAEE,yDACE,gBAAJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.games {\n  margin-top: 16px;\n  padding-bottom: 66px;\n\n  &_june {\n    padding: 0 0 16px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"games": "------shared-components-Games-games-module__games",
	"games_june": "------shared-components-Games-games-module__games_june"
};
module.exports = ___CSS_LOADER_EXPORT___;
