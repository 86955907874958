import { createSlice } from '@reduxjs/toolkit';

import { requestStatuses } from '../../utils/constants';
import { UserDocument } from '../../types';

import {
  initDocuments,
  createUserDocument,
  updateUserDocument,
} from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  status: requestStatuses.notStarted,
  types: [],
  currentDocument: {} as UserDocument,
  loading: false,
  error: '',
};

export const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initDocuments.fulfilled, (state, { payload }) => {
        state.types = payload.types;
        state.currentDocument = payload.currentDocument;

        state.status = requestStatuses.init;
      })

      .addCase(createUserDocument.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(createUserDocument.fulfilled, (state, { payload }) => {
        state.currentDocument = payload;
        state.loading = false;
      })
      .addCase(createUserDocument.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(updateUserDocument.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(updateUserDocument.fulfilled, (state, { payload }) => {
        state.currentDocument = payload;
        state.loading = false;
      })
      .addCase(updateUserDocument.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});
