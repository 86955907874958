import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateToDeposit } from '@box/shared/hooks';
import { userInitSelectors } from '@box/redux/authentication';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';

import { ReactComponent as Background } from '@box/shared/icons/valor_logo_back.svg';
import styles from './EmptyBonusesList.module.scss';

export const EmptyBonusesList = ({ no_button }: { no_button?: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector(userInitSelectors.isLogged);
  const { openDeposit } = useNavigateToDeposit();

  const openLoginModal = () => {
    dispatch(openModal('login'));
  };
  const handleOpenDepositModal = () => openDeposit();

  return (
    <div className={styles.container}>
      <Background />
      <span className={styles.text}>{t('bonuses.empty')}</span>
      {!no_button && (
        <button
          onClick={isLogged ? handleOpenDepositModal : openLoginModal}
          type='button'
          className={styles.empty_button}
        >
          {t('detalization.no_payouts.button')}
        </button>
      )}
    </div>
  );
};
