import React, { useState } from 'react';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';

import './tabs.scss';
import TabsItem from './TabsItem/TabsItem';

const Tabs = ({ tabs = [] }) => {
  const [active, setActive] = useState(0);

  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div className={classNames('tabs', { tabs_june: isJune })}>
      <div className='tabs__head'>
        {tabs.map((item, index) => (
          <button
            className={classNames('tabs__head-item', {
              'tabs__head-item--active': active === index,
            })}
            type='button'
            onClick={() => setActive(index)}
            key={index}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className='tabs__content'>
        <TabsItem active={active} component={tabs[active].component} />
      </div>
    </div>
  );
};

export default React.memo(Tabs);
