import React, { lazy, Suspense } from 'react';
import Loading from '@box/shared/components/Loading/Loading';

import { userEnvironment } from '../userEnvironment';

const Layout = lazy(() => import('@box/layout'));

export const LayoutApp = () => {
  return (
    <Suspense
      fallback={<Loading isFailed={false} hideSpinner isJune={false} />}
    >
      <Layout userEnv={userEnvironment} />
    </Suspense>
  );
};
