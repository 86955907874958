import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ImageSet } from '@box/ui';

import styles from './Warning.module.scss';

import { ReactComponent as AgeIcon } from './assets/age.svg';
import { ReactComponent as StopIcon } from './assets/stop.svg';

import Logo1 from './assets/logo_1.png';
import Logo1Webp from './assets/logo_1.webp';
import Logo2 from './assets/logo_2.png';
import Logo2Webp from './assets/logo_2.webp';
import Logo3 from './assets/logo_3.png';
import Logo3Webp from './assets/logo_3.webp';

export const Warning: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.warning, className)}>
      <div className={styles.row}>
        <AgeIcon />
        <ImageSet src={Logo1} srcWebp={Logo1Webp} alt='logo' />
        <ImageSet src={Logo2} srcWebp={Logo2Webp} alt='logo' />
      </div>
      <div className={styles.row}>
        <ImageSet src={Logo3} srcWebp={Logo3Webp} alt='logo' />
      </div>
      <div className={styles.row}>
        <span>{t('footer.warning')}</span>
        <StopIcon />
      </div>
    </div>
  );
};
