import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import isRegExp from 'lodash/isRegExp';

export const useValidate = () => {
  const { t } = useTranslation();
  const getCustomValidate = (rules) => (v) => {
    if (rules.email) {
      if (!v.match(/^\S+@\S+$/)) return t('fields.payment_rules.email_format');
    }

    return true;
  };

  const getRulesObj = (rules) =>
    Object.keys(rules).reduce((acc, item) => {
      const key = camelCase(item);
      let value = rules[item];

      if (key === 'pattern' && value) {
        const regexp = new RegExp(value.substr(1).slice(0, -1));
        value = isRegExp(regexp) ? regexp : null;
      }

      return value
        ? {
            ...acc,
            [key]: {
              value,
              message: t(`fields.payment_rules.${key}`, {
                extra: value,
              }),
            },
          }
        : acc;
    }, {});

  return {
    customEmailValidate: getCustomValidate,
    rulesForObject: getRulesObj,
  };
};
