import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { AppThunkApi } from '../types';
import {
  InitChatReturn,
  InitChatPayload,
  GETChat,
  SendMessageReturn,
  SendMessagePayload,
  POSTMessage,
  FetchMessageReturn,
  FetchMessagePayload,
  GETMessage,
  ResetUnreadMessagesCounterReturn,
  ResetUnreadMessagesCounterPayload,
  PUTMessagesCounter,
} from './types';

export const initChat = createAsyncThunk<
  InitChatReturn,
  InitChatPayload,
  AppThunkApi
>('support/initChat', async (payload, { extra, rejectWithValue }) => {
  const { api, crmUrl } = extra;

  const { chatId, userId } = payload;

  const config = {
    baseURL: crmUrl,
  };

  try {
    const { data, status } = await api.get<GETChat>(
      `v1/users/${userId}/chats/${chatId}/messages?with[]=sender&with[]=files&sort_by_asc=created_at&all=1`,
      config
    );

    if (status === 200 || status === 201) {
      return data;
    }

    Sentry.configureScope((scope) =>
      scope
        .setUser({ chatId, userId })
        .setExtra('store', crmUrl)
        .setExtra('status', status)
    );

    Sentry.captureException(data);

    return rejectWithValue('Error');
  } catch (e) {
    Sentry.configureScope((scope) =>
      scope.setUser({ chatId, userId }).setExtra('store', crmUrl)
    );
    Sentry.captureException(e);
    return rejectWithValue('Error');
  }
});

export const sendMessage = createAsyncThunk<
  SendMessageReturn,
  SendMessagePayload,
  AppThunkApi
>('support/sendMessage', async (payload, { extra, rejectWithValue }) => {
  const { api, crmUrl } = extra;

  const { chatId, userId, text, files } = payload;

  const config = {
    baseURL: crmUrl,
  };

  try {
    const { data, status } = await api.post<POSTMessage>(
      `v1/users/${userId}/chats/${chatId}/messages`,
      {
        chat_id: chatId,
        user_id: userId,
        text,
        files,
      },
      config
    );

    if (status === 201 || status === 200) {
      return data;
    }

    Sentry.configureScope((scope) =>
      scope
        .setUser({ chatId, userId, text })
        .setExtra('store', crmUrl)
        .setExtra('status', status)
    );
    Sentry.captureException(data);

    return rejectWithValue('Error');
  } catch (e) {
    Sentry.configureScope((scope) =>
      scope.setUser({ chatId, userId, text }).setExtra('store', crmUrl)
    );
    Sentry.captureException(e);
    return rejectWithValue('Error');
  }
});

export const fetchMessage = createAsyncThunk<
  FetchMessageReturn,
  FetchMessagePayload,
  AppThunkApi
>('support/fetchMessage', async (payload, { extra, rejectWithValue }) => {
  const { api, crmUrl } = extra;

  const { chatId, userId, messageId } = payload;

  const config = {
    baseURL: crmUrl,
  };

  try {
    const { data, status } = await api.get<GETMessage>(
      `v1/users/${userId}/chats/${chatId}/messages/${messageId}?with[]=sender&with[]=files`,
      config
    );

    if (status === 200 || status === 201) {
      return data;
    }

    Sentry.configureScope((scope) =>
      scope
        .setUser({ chatId, userId })
        .setExtra('store', crmUrl)
        .setExtra('status', status)
    );

    Sentry.captureException(data);

    return rejectWithValue('Error');
  } catch (e) {
    Sentry.configureScope((scope) =>
      scope.setUser({ chatId, userId, messageId }).setExtra('store', crmUrl)
    );
    Sentry.captureException(e);
    return rejectWithValue('Error');
  }
});

export const resetUnreadMessagesCounter = createAsyncThunk<
  ResetUnreadMessagesCounterReturn,
  ResetUnreadMessagesCounterPayload,
  AppThunkApi
>(
  'support/resetUnreadMessagesCounter',
  async (payload, { extra, rejectWithValue }) => {
    const { api, crmUrl } = extra;

    const { chatId, userId } = payload;

    const config = {
      baseURL: crmUrl,
    };

    const body = {
      pivot: {
        stat: {
          unread_messages_count: 0,
        },
      },
    };

    try {
      const { data, status } = await api.put<PUTMessagesCounter>(
        `v1/users/${userId}/chats/${chatId}`,
        body,
        config
      );

      if (status === 200) {
        return data;
      }

      Sentry.configureScope((scope) =>
        scope
          .setUser({ chatId, userId })
          .setExtra('store', crmUrl)
          .setExtra('status', status)
      );

      Sentry.captureException(data);

      return rejectWithValue('Error');
    } catch (e) {
      Sentry.configureScope((scope) =>
        scope.setUser({ chatId, userId }).setExtra('store', crmUrl)
      );
      Sentry.captureException(e);
      return rejectWithValue('Error');
    }
  }
);
