import React, { FC, useState } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { useToggleActiveElement, useApp } from '@box/shared/hooks';

import { Label as LabelComponent } from './Label';
import { Message as MessageComponent } from './Message';
import { Collapsed } from '../Collapsed';

import { HiddenInputProps, Props } from './Control.types';

import { ReactComponent as HideIcon } from './assets/images/hide.svg';
import { ReactComponent as ShowIcon } from './assets/images/show.svg';

import './control.scss';
import { DetailPopup } from '../DetailPopup';

const HiddenInput: FC<HiddenInputProps> = ({
  children,
  openElement: OpenElement,
}) => {
  const [isOpen, setOpen] = useState(false);
  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div
      className={classNames('control__hidden-input', {
        'control__hidden-input_close_mobile': !isOpen && isMobile,
        'control__hidden-input_close': !isOpen,
      })}
    >
      <Collapsed isOpen={!isOpen}>
        <OpenElement onClick={handleClick} />
      </Collapsed>
      <Collapsed isOpen={isOpen}>{children}</Collapsed>
    </div>
  );
};

export const Control: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      error,
      errorMessage,
      size,
      message,
      as,
      label,
      icon,
      children,
      inputIcon,
      closeIcon,
      color,
      className,
      format,
      mask,
      type,
      id,
      defaultValue,
      placeholder,
      isCentered,
      isHidden,
      openElement,
      withPopup,
      popupText,
      isPasswordWithHidden,
      ...props
    },
    ref
  ) => {
    const {
      userEnv: { isJune, isMobile },
    } = useApp();

    const classes = classNames(
      {
        control: true,
        [`control--${size}`]: size,
        [`control--${color}`]: color,
        control_june: isJune,

        'control--error': error,
        'control--success': message,
        'control--with-icon': inputIcon,
        'control--center': isCentered,
        control_mobile: isMobile,
      },
      className || []
    );

    const {
      toggle: toggleOpen,
      isOpen,
      elementRef,
    } = useToggleActiveElement({ hideWhenClickOutside: true });

    type = type || 'text';
    id = id || Math.random().toString(36).substr(2, 9);
    const tag = as || 'input';

    const Label = label ? (
      withPopup ? (
        <div className='control__popup-row'>
          <LabelComponent id={id} label={label} icon={icon} />
          <div
            className='funds-detail'
            onClick={toggleOpen}
            aria-hidden='true'
            ref={elementRef}
          >
            <span>?</span>
            <DetailPopup
              className='funds-detail-info'
              isOpen={isOpen}
              position='bottom-start'
            >
              {popupText}
            </DetailPopup>
          </div>
        </div>
      ) : (
        <LabelComponent id={id} label={label} icon={icon} />
      )
    ) : (
      ''
    );

    const Password = () => {
      const [isShow, setShow] = useState(false);

      const handleShowClick = () => {
        setShow((prevVar) => !prevVar);
      };

      return (
        <div className='control-box'>
          {React.createElement(
            tag,
            {
              ...props,
              placeholder,
              id,
              type: isShow ? 'text' : 'password',
              ref,
              key: 1,
            },
            children
          )}
          <button
            type='button'
            onClick={handleShowClick}
            className='control-button'
          >
            {isShow ? <ShowIcon /> : <HideIcon />}
          </button>
        </div>
      );
    };

    const Component = mask ? (
      format ? (
        <NumberFormat
          id={id}
          format={format + mask}
          allowEmptyFormatting
          defaultValue={defaultValue}
          mask='_'
          getInputRef={ref}
          placeholder={placeholder}
          {...props}
          {...(type === ('tel' || 'password' || 'text') && { type })}
        />
      ) : (
        <NumberFormat
          {...props}
          format={mask}
          type='text'
          defaultValue={defaultValue}
          placeholder={placeholder}
          id={id}
          getInputRef={ref}
        />
      )
    ) : isPasswordWithHidden ? (
      <Password />
    ) : (
      React.createElement(
        tag,
        { ...props, placeholder, id, type, ref, key: 1 },
        children
      )
    );
    const InputIcon = React.createElement(
      'span',
      { className: 'input-icon' },
      inputIcon
    );

    const CloseIcon = React.createElement(
      'span',
      { className: 'input-icon--right' },
      closeIcon
    );

    message = errorMessage || message;

    const Message = message ? (
      <MessageComponent
        className={classNames('control__message', {
          'control__message--error': !!error,
        })}
        message={message}
      />
    ) : (
      ''
    );

    const content =
      type === 'checkbox' || type === 'radio'
        ? [Component, Label]
        : [Label, Component, Message, InputIcon, CloseIcon];

    if (isHidden) {
      return (
        <HiddenInput openElement={openElement}>
          {React.createElement('div', { className: classes }, ...content)}
        </HiddenInput>
      );
    }

    return React.createElement('div', { className: classes }, ...content);
  }
);
