import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkApi } from '../../types';
import { FetchPaymentMethodsReturn, GETPaymentMethods } from './types';

export const fetchPaymentMethods = createAsyncThunk<
  FetchPaymentMethodsReturn,
  void,
  AppThunkApi
>(
  'finance/fetchPaymentMethods',
  async (_payload, { extra, rejectWithValue }) => {
    const { api, isMobile } = extra;

    try {
      const { status, data } = await api.get<GETPaymentMethods>(
        `/fin/payment-methods?isMobile=${isMobile}`
      );

      if (status === 200) {
        return data.data || [];
      }

      return rejectWithValue('Error');
    } catch (e) {
      return rejectWithValue('Error');
    }
  }
);
