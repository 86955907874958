import React from 'react';
import { hideModal, resetModalProps } from '@box/redux/app';
import { LightButton, Text } from '@box/ui';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { useApp } from '@box/shared/hooks';
import { Button } from '../../../baseComponents';
import { Modal } from '../../Modal/Modal';

import { ReactComponent as CloseSvg } from './assets/images/close.svg';
import { InfoProps } from './Info.types';
import styles from './Info.module.scss';

export const InfoModal: React.FC<InfoProps> = ({ onHide, id = 'info' }) => {
  const props = useAppSelector((state) => state.app.modals.props);
  const dispatch = useAppDispatch();
  const currentProps = props[id];
  const {
    userEnv: { isJune },
  } = useApp();

  const handleHide = () => {
    if (onHide) onHide();

    dispatch(resetModalProps({ id }));
  };

  const handleClose = () => {
    dispatch(hideModal(id));

    setTimeout(() => {
      if (onHide) onHide();
    }, 1000); // Чтобы текст не пропадал до закрытия окна
  };

  return (
    <Modal
      className={styles.info}
      id={id}
      onClose={handleHide}
      title={currentProps?.title}
      size='xs'
      headWithSvg={isJune}
      headColor={isJune ? 'yellow' : 'default'}
      customHead={isJune}
      heightAuto={isJune}
    >
      {isJune ? (
        <div className={styles.info_june}>
          <div className={styles.info_june__header}>
            <p className={styles.info_june__title}>{currentProps?.title}</p>
            <button
              type='button'
              onClick={handleClose}
              className={styles.info_june__close}
            >
              <CloseSvg />
            </button>
          </div>
          <div className={styles.info_june__box}>
            <p className={styles.info_june__text}>{currentProps?.text}</p>
            <LightButton
              color='green'
              className={styles.info_june__button}
              onClick={handleClose}
            >
              OK
            </LightButton>
          </div>
        </div>
      ) : (
        <div className={styles.info__body}>
          <Text className={styles.info__text} color='black' size='sm' as='span'>
            {currentProps?.text}
          </Text>
          <Button
            color='secondary'
            className={styles.info__button}
            onClick={handleClose}
          >
            Ок
          </Button>
        </div>
      )}
    </Modal>
  );
};
