import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import {
  forgotPassword,
  passwordRecoverySelectors,
  recoveryReset,
} from '@box/redux/authentication';
import { hideModal, openModal } from '@box/redux/app';
import { Text } from '@box/ui';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Form } from '../../../baseComponents/Form/Form';
import { Modal } from '../../Modal/Modal';

import { RecoveryContinueModal } from '../RecoveryContinue';
import { InfoModal } from '../Info';

import styles from './recovery.module.scss';
import { ReactComponent as DecorSvg } from './assets/images/decor.svg';

export const RecoveryModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(passwordRecoverySelectors.loading);
  const error = useAppSelector(passwordRecoverySelectors.error);

  const {
    userEnv: { isJune, isDesktop },
  } = useApp();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(
      forgotPassword({ email: data.email, successText: t('recovery.success') })
    );
  };

  const handleBack = () => {
    dispatch(hideModal('recovery'));
    dispatch(openModal('login'));
  };

  const fields = [
    {
      name: 'email',
      placeholder: t('fields.email'),
      type: 'email',
      label: isJune ? t('fields.email') : '',
      rules: {
        required: t('fields.required'),
      },
    },
  ];

  const onHide = () => {
    reset();
    dispatch(recoveryReset());
  };

  return (
    <>
      <Modal
        id='recovery'
        className={styles.recovery}
        title={t('recovery.title')}
        onClose={onHide}
        size={isDesktop ? 'lg' : 'xs'}
        headWithSvg={isJune}
        headColor={isJune ? 'yellow' : 'default'}
        heightAuto={isJune && isDesktop}
      >
        <div
          className={classNames(styles.body, {
            [styles.body_june]: isJune,
          })}
        >
          <Text className={styles.text} as='p' size='sm' color='black'>
            {isJune ? t('recovery.textValor') : t('recovery.text')}
          </Text>
          <Form
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
            register={register}
            fields={fields}
            formError={error}
            buttonColor={isJune ? 'yellow' : ''}
            className={classNames(styles['recovery-continue'], {
              [styles['recovery-continue_desktop']]: isDesktop,
            })}
          />
        </div>
        {isJune ? (
          <div className={styles.footer}>
            <button onClick={handleBack} type='button' className={styles.link}>
              <DecorSvg />
              {t('recovery.cancel_recovery_and_return')}
            </button>
          </div>
        ) : (
          <div className='modal-footer modal-footer--left'>
            <span
              className='modal-footer-link'
              onClick={handleBack}
              aria-hidden='true'
            >
              {t('recovery.back')}
            </span>
            <span> {t('recovery.cancel')}</span>
          </div>
        )}
      </Modal>
      <InfoModal
        onHide={() => dispatch(openModal('login'))}
        id='info--recovery'
      />
      <RecoveryContinueModal />
    </>
  );
};
