import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserTransactions,
  transactionsSelectors,
} from '@box/redux/finance';
import { commonSelectors } from '@box/redux/common';
import { getStatus } from '@box/shared/utils';

import { useDateFormat } from '../../../../hooks';

export const withState = (Component) => {
  const wrapper = ({ listId, showItems, requestMeta, userId, ...props }) => {
    const dispatch = useDispatch();
    const currencies = useSelector(commonSelectors.currencies);
    const currentList = useSelector((state) =>
      transactionsSelectors.listById(state, listId)
    );

    const { getDetalizationDate } = useDateFormat();

    let list = currentList.list || [];

    const getCurrencySymbol = (name) =>
      currencies.find((item) => item.code === name)?.symbol;

    useEffect(() => {
      if (!currentList.ready) {
        dispatch(
          fetchUserTransactions({
            id: listId,
            userId,
            ...requestMeta,
          })
        );
      }
    }, []);

    list = list
      .map((item) =>
        +item.amountFloat
          ? {
              date: getDetalizationDate(item.created_at),
              amount: parseFloat(+item.amountFloat).toFixed(2),
              currencySymbol: getCurrencySymbol(item.wallet.name),
              walletName: item.wallet.name,
              status: getStatus(item),
              type: item?.meta?.type,
            }
          : null
      )
      .filter(Boolean);

    list = showItems ? list.slice(0, showItems) : list;

    return <Component {...props} loading={!currentList?.ready} list={list} />;
  };

  return wrapper;
};
