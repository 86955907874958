import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Text } from '@box/ui';

import { Button, BlockWithLoading } from '../../../../baseComponents';

import { Show } from '../Show';

import { ReactComponent as SumLogo } from '../assets/sum-logo.svg';

export const DetalizationListContainer = ({
  list,
  title,
  nextPage,
  loading,
  isNextPageAvailable,
  pagingLoading,
}) => {
  const { t } = useTranslation();

  const getLabel = (isWithdrawal, type) => {
    if (isWithdrawal) {
      return t('detalization.withdrawal');
    }
    if (type === 'withdrawal_refund') {
      return t('bonuses.withdrawal_refund');
    }
    if (type === 'bonus') {
      return t('bonuses.bonus');
    }
    if (type === 'promocode') {
      return t('bonuses.bonus');
    }
    return t('detalization.deposit');
  };

  return (
    <div className='detalization-content'>
      <BlockWithLoading
        className='detalization-content__loader'
        loading={loading}
      >
        {title && <h6>{title}</h6>}
        {!list.length && !loading ? (
          <div className='detalization-no-info'>
            <Text color='black' weight='bold' size={28}>
              {t('common.no_data')}
            </Text>
          </div>
        ) : (
          <div className='detalization-list'>
            {list.map((item, index) => {
              return (
                <div
                  className={classNames('detalization-list__item', {
                    'detalization-list__item--pending':
                      item.status === 'pending',
                    'detalization-list__item--approved':
                      item.status === 'approved',
                    'detalization-list__item--declined':
                      item.status === 'declined',
                  })}
                  key={index}
                >
                  <div className='detalization-list__item-left'>
                    <div className='detalization-list__item-column'>
                      <span className='detalization-list__item-wallet'>
                        {item.walletName}
                      </span>
                      <span className='detalization-list__item-date'>
                        {getLabel(item.isWithdrawal, item?.type)}
                      </span>
                    </div>

                    {item.status === 'approved' && item.isWithdrawal && (
                      <Show className='detalization-list__item-show' />
                    )}
                  </div>
                  <div className='detalization-list__item-right'>
                    <span className='detalization-list__item-date'>
                      {item.date}
                    </span>
                    <span className='detalization-list__item-sum'>
                      {item.amount}{' '}
                      {item?.withdrawal_amount && item?.confirm
                        ? `(${item.withdrawal_amount})`
                        : ''}
                      {item.currencySymbol ? (
                        <span>{item.currencySymbol}</span>
                      ) : (
                        <SumLogo />
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
            {isNextPageAvailable && (
              <Button
                className='detalization-list__button'
                loading={pagingLoading}
                onClick={nextPage}
                color='secondary'
              >
                {t('common.show_more')}
              </Button>
            )}
          </div>
        )}
      </BlockWithLoading>
    </div>
  );
};
