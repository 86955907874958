// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__fields{width:100%}.\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__fields .\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__field+.\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__field{margin-top:12px}.\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__phone{position:relative}.\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__phone input[name=custom_phone]{padding-left:40px}.\\------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__phone:after{content:\"+91\";left:3%;position:absolute;top:calc(50% + 1.3px)}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Deposits/Components/FlexibleFields/FlexibleFields.module.scss"],"names":[],"mappings":"AACA,kGACE,UAAF,CAGI,oSACE,eADN,CAMA,iGAKE,iBAPF,CAGE,0HACE,iBADJ,CAME,uGAEE,aAAA,CADA,OAAA,CAEA,iBAAA,CACA,qBAJJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.fields {\n  width: 100%;\n\n  .field {\n    & + .field {\n      margin-top: 12px;\n    }\n  }\n}\n\n.phone {\n  input[name='custom_phone'] {\n    padding-left: 40px;\n  }\n\n  position: relative;\n\n  &::after {\n    left: 3%;\n    content: '+91';\n    position: absolute;\n    top: calc(50% + 1.3px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": "------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__fields",
	"field": "------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__field",
	"phone": "------shared-components-Modals-Deposits-Components-FlexibleFields-FlexibleFields-module__phone"
};
module.exports = ___CSS_LOADER_EXPORT___;
