import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { copyTextToClipboard } from '@box/shared/utils';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { fetchPartnerUrl, streamsSelectors } from '@box/redux/partner/streams';

import { Loader } from '@box/ui/src/Loader';

import { Modal } from '../../Modal';

import styles from './referral.module.scss';

import { ReactComponent as CopiedSvg } from './assets/copied.svg';

export const Referral = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const dispatch = useAppDispatch();

  const partnerUrl = useAppSelector(streamsSelectors.url);
  const loading = useAppSelector(streamsSelectors.loading);

  const handleClick = () => {
    dispatch(openModal('invitations'));
  };

  const handleCopyText = () => {
    if (partnerUrl) {
      copyTextToClipboard(partnerUrl);
      setCopied(true);
    }
  };

  const handleGetUrl = () => {
    dispatch(fetchPartnerUrl());
  };

  return (
    <Modal title={t('referral.title')} dark id='referral'>
      <div className={styles.container}>
        <div className={styles.box}>
          <h4 className={styles.title}>{t('referral.invite')}</h4>
          <p className={styles.text}>{t('referral.receive_cash')}</p>
          <button onClick={handleClick} type='button' className={styles.link}>
            {t('referral.invitations')}
          </button>
        </div>
        {partnerUrl && (
          <div className={styles.box}>
            <h4 className={styles.title}>{t('referral.share_code')}</h4>
            <p className={styles.text}>{t('referral.your_code')}</p>
            <div className={styles.ref}>
              <p className={styles.refText}>{partnerUrl.slice(8)}</p>
            </div>
          </div>
        )}
        {partnerUrl ? (
          <button
            onClick={handleCopyText}
            type='button'
            className={classNames(styles.button, {
              [styles.button_copied]: copied,
            })}
          >
            <CopiedSvg /> <span>{t('referral.copy_link')}</span>
          </button>
        ) : (
          <button
            onClick={handleGetUrl}
            type='button'
            disabled={loading}
            className={styles.button}
          >
            {loading ? <Loader /> : t('referral.get_referral_link')}
          </button>
        )}
      </div>
    </Modal>
  );
};
