import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  BonusModal,
  LightBonusesList as List,
  EmptyBonusesList,
  Header,
  Promocode,
} from '@box/shared/components';

import { BlockWithLoading } from '@box/shared/baseComponents';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { bonusesSelectors, fetchBonuses } from '@box/redux/bonuses';
import { initUserBonuses, userBonusesSelectors } from '@box/redux/user';
import { bonusStatuses } from '@box/redux/utils';

import type { CommonListProps } from '@box/shared/components/LightBonusesList/lightBonusesList.types';

import styles from './BonusesList.module.scss';

export const JuneBonusesList = ({ no_title }: { no_title?: boolean }) => {
  const [params] = useSearchParams();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(bonusesSelectors.loading);
  const isInit = useAppSelector(bonusesSelectors.init);

  let list = useAppSelector(bonusesSelectors.list);

  let activeBonuses = useAppSelector((state) =>
    userBonusesSelectors.filteredByStatuses(state, [
      bonusStatuses.activated,
      bonusStatuses.spins_used,
    ])
  );

  let availableBonuses = useAppSelector((state) =>
    userBonusesSelectors.filteredByStatuses(state, [bonusStatuses.created])
  );

  let completedBonuses = useAppSelector((state) =>
    userBonusesSelectors.filteredByStatuses(state, [bonusStatuses.ended])
  );

  useEffectOnce(() => {
    if (!isInit && !loading) {
      dispatch(fetchBonuses());
      dispatch(initUserBonuses());
    }
  });

  const bonusParam = params.get('bonus');

  const activeBonus = useMemo(() => {
    return list.find((item) => item.type === bonusParam);
  }, [list, bonusParam]);

  const commonList: CommonListProps[] = [
    { type: 'available', list: availableBonuses },
    { type: 'active', list: activeBonuses },
    { type: 'completed', list: completedBonuses },
  ];

  const handleCommonBonusListReturn = () => {
    if (
      activeBonuses.length ||
      availableBonuses.length ||
      completedBonuses.length
    ) {
      return <List type='all' commonList={commonList} no_title={no_title} />;
    }
    if (list.length) {
      return <List list={list} no_title={no_title} />;
    }

    return <EmptyBonusesList no_button />;
  };

  const tabComponents = [
    <BlockWithLoading loading={loading}>
      <Promocode label={t('promocodes.promocodes')} />
      {list.length ? (
        <>
          <List
            type='active'
            list={activeBonuses}
            title={t('bonuses.active')}
            counter={activeBonuses.length}
            no_title={no_title}
          />
          <List
            type='available'
            list={availableBonuses}
            title={t('bonuses.available')}
            counter={availableBonuses.length}
            no_title={no_title}
          />
        </>
      ) : null}
    </BlockWithLoading>,
    <BlockWithLoading loading={loading}>
      {list.length ? (
        <List
          type='completed'
          list={completedBonuses}
          title={t('bonuses.completed')}
          counter={completedBonuses.length}
          no_title={no_title}
        />
      ) : (
        <EmptyBonusesList />
      )}
    </BlockWithLoading>,
  ];

  return (
    <div className={no_title ? styles.container : undefined}>
      {!no_title && (
        <Header activeBonus={activeBonus} tabsComponents={tabComponents} />
      )}
      {activeBonus && <BonusModal {...activeBonus} />}
      {no_title && (
        <BlockWithLoading loading={loading}>
          {handleCommonBonusListReturn()}
        </BlockWithLoading>
      )}
    </div>
  );
};
