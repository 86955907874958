import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@box/redux/hooks';
import { openModal } from '@box/redux/app';
import { initUserBonuses } from '@box/redux/user';

import { AppContext } from '../../contexts';
import { BonusWalletsProps } from './BonusWallets.types';

import styles from './BonusWallets.module.scss';

export const BonusWallets: React.FC<BonusWalletsProps> = ({
  className,
  bonuses,
  summary,
  bonusBalance,
  symbol,
}) => {
  const { t } = useTranslation();

  const { route } = useContext(AppContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (bonusBalance === 0) {
      navigate(route('bonuses.list'));
    } else {
      dispatch(initUserBonuses());
      dispatch(openModal('bonuses'));
    }
  };

  return (
    <div className={classNames(styles['bonus-wallets'], className)}>
      <div className={styles['bonus-wallets__content']}>
        <div className={styles['bonus-wallets__list']}>
          <div
            className={classNames(
              styles['bonus-wallets__list-item'],
              styles['bonus-wallets__list-item--all']
            )}
          >
            <span className={styles['bonus-wallets__list-item-label']}>
              {t('common.casino')}
            </span>
            <span className={styles['bonus-wallets__list-item-value']}>
              {summary}
            </span>
          </div>
          {bonuses.map((item, idx) => (
            <div className={styles['bonus-wallets__list-item']} key={idx}>
              <span className={styles['bonus-wallets__list-item-label']}>
                {item.label}
              </span>
              <span className={styles['bonus-wallets__list-item-value']}>
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className={styles['bonus-wallets__bottom']}>
          <div className={styles['bonus-wallets__bottom-text']}>
            <div className={styles['bonus-wallets__bottom-text-icon']} />
            {bonusBalance !== 0
              ? t('navigation.bonus_balance_transferred')
              : t('navigation.receive_bonus', {
                  symbol,
                })}
          </div>
          <button
            type='button'
            className={styles['bonus-wallets__tell-more']}
            onClick={handleClick}
          >
            {t('navigation.consult')}
          </button>
        </div>
      </div>
    </div>
  );
};
