import React, { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useApp } from '@box/shared/hooks';
import { ReactComponent as Empty } from '@box/shared/icons/empty.svg';

import { Transaction } from '../Transaction';

import { PayoutsHistoryProps } from './PayoutsHistory.types';

import styles from './PayoutsHistory.module.scss';
import { Pagination } from '../Pagination';

export const PayoutsHistory: FC<PayoutsHistoryProps> = ({
  payoutList,
  detalizationList,
  loading,
  isReady,
  currentPage,
  lastPage,
  showNextPage,
  showPreviousPage,
  changePageNumber,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const hasTransactions = payoutList && payoutList.length > 0;

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.container_desktop]: isDesktop,
          [styles.container_shadowsDisabled]: hasTransactions,
          [styles.container_empty]: !hasTransactions,
        })}
      >
        {isDesktop && hasTransactions && (
          <div className={styles.headers}>
            {detalizationList.map((detalizationItem, index) => (
              <div key={detalizationItem} className={styles.item}>
                {/* Временно пока не получаем методы */}
                {index !== 3 ? t(detalizationItem) : ''}
              </div>
            ))}
          </div>
        )}
        <BlockWithLoading loading={!isReady || loading}>
          {!hasTransactions && !loading ? (
            <div
              className={classNames(styles.empty, {
                [styles.empty_desktop]: isDesktop,
              })}
            >
              <Empty />
              <span>{t('detalization.no_payouts.title')}</span>
              <span>{t('detalization.no_payouts.subtitle')}</span>
              <NavLink
                to='/casino'
                color='green'
                className={styles.empty_button}
              >
                {t('detalization.no_payouts.button')}
              </NavLink>
            </div>
          ) : (
            <>
              {payoutList.map((elem, index) => (
                <Transaction list={elem} key={index} />
              ))}
            </>
          )}
        </BlockWithLoading>
      </div>
      {hasTransactions && (
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          loading={loading}
          showNextPage={showNextPage}
          showPreviousPage={showPreviousPage}
          changePageNumber={changePageNumber}
        />
      )}
    </>
  );
};
