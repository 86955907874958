import React from 'react';
import PropTypes from 'prop-types';

import { icons } from '../../utils/linkIcons';

import { Icon } from '../Icon';

export const LinkIcon = ({ className, name }) => {
  const Component = icons[name];

  return Component ? (
    <Component className={className} />
  ) : (
    <Icon icon={name} className={className} />
  );
};

LinkIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LinkIcon.defaultProps = {
  className: '',
  name: '',
};
