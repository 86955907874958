import React, { FC, ReactElement } from 'react';
import { useEffectOnce } from 'react-use';
import { Helmet } from 'react-helmet';

import { useDescription, useDocumentTitle } from '../../hooks';
import { IPageProps } from '../../types';

interface IPageComponentProps extends IPageProps {
  children: ReactElement;
}

export const Page: FC<IPageComponentProps> = React.memo(
  ({ children, title, scrollToTop = false, description }) => {
    const { title: fixedTitle } = useDocumentTitle(title);

    const { description: fixedDescription } = useDescription(description);

    useEffectOnce(() => {
      if (scrollToTop) {
        window.scrollTo({ top: 0 });
      }
    });

    return (
      <>
        <Helmet>
          <title>{fixedTitle}</title>
          <meta name='description' content={fixedDescription} />
        </Helmet>
        {children}
      </>
    );
  }
);
