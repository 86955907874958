import React, { FC } from 'react';
import classNames from 'classnames';

import { useBreakPoints } from '../../hooks/useBreakPoints';

import { PlateProps } from './types';

import styles from './plate.module.scss';

export const Plate: FC<PlateProps> = ({
  children,
  className,
  perRow = 0,
  breakPoints,
  spacing = 4,
  style,
  defaultPerRow = 0,
}) => {
  const itemFromBreakPoint = useBreakPoints(breakPoints);

  return (
    <div
      className={classNames(styles.plate, className)}
      style={{
        gridTemplateColumns: `repeat(${
          perRow ||
          itemFromBreakPoint ||
          defaultPerRow ||
          React.Children.toArray(children).length
        }, 1fr)`,
        gridGap: spacing,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
