import { FieldsList } from '../fields';

export type UserDocument = {
  id: number;
  user_id: number;
  scan: string;
  photo: string;
  type_id: number;
  fields_values: Record<string, string | number>;
  created_at: string;
  updated_at: string;
  user: unknown;
  type: UserDocumentType;
};

export type UserDocumentType = {
  id: number;
  name: string;
  alias: string;
  fields: FieldsList;
  created_at: string;
  updated_at: string;
};

export type UserDocumentTypesList = UserDocumentType[];
