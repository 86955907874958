import isEqual from 'lodash/isEqual';
import forIn from 'lodash/forIn';
import get from 'lodash/get';

interface ApiDocument extends FormData {
  type: string;
  scan: File;
  photo: File;
  fields_values: Record<string, string | number | boolean>;
}

export type PrepareDocuments = (
  data: { document_type: string; scan: string; photo: string },
  currentDocument
) => ApiDocument;

export const prepareDocuments: PrepareDocuments = (data, currentDocument) => {
  const formData = new FormData() as ApiDocument;

  const fields = {};

  forIn(data, (value, key) => {
    if (key.includes('document__')) {
      const name = key.replace('document__', '');

      fields[name] = value;
    }
  });

  if (!isEqual(data.document_type, get(currentDocument, 'type.alias'))) {
    formData.append('type', data.document_type);
  }

  if (!isEqual(data.scan, get(currentDocument, 'scan'))) {
    formData.append('scan', data.scan);
  }

  if (!isEqual(data.photo, get(currentDocument, 'photo'))) {
    formData.append('photo', data.photo);
  }

  formData.append('fields_values', JSON.stringify(fields));

  return formData;
};
