import React, { useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffectOnce } from 'react-use';
import {
  fetchGames,
  catalogsSelectors,
  fetchCatalog,
  resetList,
} from '@box/redux/games';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { BlockWithLoading } from '../../baseComponents';
import { useCurrentGameList } from '../../hooks/useCurrentGameList';
import { useGamesPagination } from '../../hooks/useGamesPagination';

export const withState = (Component) => {
  const wrapper = ({
    listId,
    perPage,
    category,
    provider,
    search,
    viewCountElements,
    sortBy,
    notFoundText,
    ...props
  }) => {
    const dispatch = useAppDispatch();
    const currentList = useCurrentGameList(listId);
    let list = viewCountElements
      ? currentList.data?.slice(
          0,
          viewCountElements *
            (currentList?.meta?.current_page
              ? currentList.meta.current_page
              : 1)
        )
      : currentList.data;

    useEffect(() => {
      dispatch(resetList(listId));
      dispatch(
        fetchGames({
          list: listId,
          perPage,
          category,
          provider,
          search,
          sortBy,
        })
      );
    }, [listId, search]);

    list = currentList?.meta?.last_page === 1 ? currentList.data : list;

    if (!!notFoundText && !!list && !list.length && !currentList.loading) {
      return <div className='games-list-not-found'>{notFoundText}</div>;
    }

    if (!list) return null;

    return (
      <Component list={list} loading={!currentList.initialized} {...props} />
    );
  };

  return wrapper;
};

export const withFilters = (Component) => {
  const wrapper = ({ ...props }) => {
    const { listId, perPage } = props;

    const dispatch = useAppDispatch();
    const currentList = useCurrentGameList(listId);
    const filtersRef = useRef({});
    const { filters, loading, initialized } = currentList;

    const areFiltersEqual = useMemo(
      () =>
        JSON.stringify(filtersRef.current) === JSON.stringify(filters || {}),
      [filters, filtersRef.current]
    );

    useEffect(() => {
      if (initialized && !loading && !areFiltersEqual) {
        dispatch(fetchGames({ list: listId, perPage, ...filters }));
        filtersRef.current = filters;
      }
    }, [loading, filters, initialized]);

    return <Component {...props} />;
  };

  return wrapper;
};

export const withInfinityScroll = (Component) => {
  const wrapper = ({ ...props }) => {
    const { listId, perPage, category, provider, search } = props;

    const list = useCurrentGameList(listId);
    const { loadNextPage, isPagingAvailable } = useGamesPagination({
      id: listId,
      perPage,
    });

    const next = () => {
      loadNextPage({ category, provider, search });
    };

    return (
      <InfiniteScroll
        dataLength={list?.data?.length || 0}
        next={next}
        hasMore={isPagingAvailable}
        loader={<BlockWithLoading loading />}
      >
        <Component {...props} />
      </InfiniteScroll>
    );
  };

  return wrapper;
};

export const withCatalogState = (Component) => {
  const wrapper = ({ ...props }) => {
    const { alias, maxCount } = props;

    const dispatch = useAppDispatch();
    const { list, ready, loading } = useAppSelector((state) =>
      catalogsSelectors.catalogByAlias(state, alias)
    );

    useEffectOnce(() => {
      if (!ready && !loading) {
        dispatch(fetchCatalog({ alias }));
      }
    });

    return (
      <Component
        {...props}
        list={maxCount ? list.slice(0, maxCount) : list}
        loading={!ready}
      />
    );
  };

  return wrapper;
};
