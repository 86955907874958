// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Withdrawal-Components-Payments-payments-module__tabs{align-items:stretch;display:flex;flex-wrap:wrap;gap:4px;margin-top:24px}.\\------shared-components-Withdrawal-Components-Payments-payments-module__tabs_mobile{flex-wrap:nowrap;overflow-x:scroll;overflow-y:hidden}.\\------shared-components-Withdrawal-Components-Payments-payments-module__tabs_mobile::-webkit-scrollbar{height:0}", "",{"version":3,"sources":["webpack://./../../shared/components/Withdrawal/Components/Payments/payments.module.scss"],"names":[],"mappings":"AACA,+EACE,mBAAA,CACA,YAAA,CACA,cAAA,CACA,OAAA,CACA,eAAF,CAEE,sFAGE,gBAAA,CAFA,iBAAA,CACA,iBACJ,CAEI,yGACE,QAAN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.tabs {\n  align-items: stretch;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4px;\n  margin-top: 24px;\n\n  &_mobile {\n    overflow-x: scroll;\n    overflow-y: hidden;\n    flex-wrap: nowrap;\n\n    &::-webkit-scrollbar {\n      height: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "------shared-components-Withdrawal-Components-Payments-payments-module__tabs",
	"tabs_mobile": "------shared-components-Withdrawal-Components-Payments-payments-module__tabs_mobile"
};
module.exports = ___CSS_LOADER_EXPORT___;
