import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useApp } from '@box/shared/hooks';
import { userSelectors } from '@box/redux/user';

import { Modal } from '../../Modal';
import Tabs from '../../Tabs/Tabs';

import './settings.scss';
import { PasswordChange } from './PasswordChange';
import { UserInfo } from './UserInfo';
import { VerifyBadge } from './VerifyBadge';

export const SettingsModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    userEnv: { isMobile },
  } = useApp();

  const isUserVerify = useSelector(userSelectors.isVerify);

  const tabs = useMemo(
    () => [
      {
        title: t('settings.personal_data'),
        component: UserInfo,
      },
      {
        title: t('settings.secure'),
        component: PasswordChange,
      },
    ],
    [language]
  );

  return (
    <Modal
      id='settings'
      className={classNames('settings', { settings_mobile: isMobile })}
      headExtraComponent={isUserVerify && <VerifyBadge />}
      title={t('settings.title')}
    >
      <div className='settings__body'>
        <Tabs tabs={tabs} />
      </div>
    </Modal>
  );
};
