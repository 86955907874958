import { createSlice } from '@reduxjs/toolkit';

import {
  setChatOpen,
  resetUnread,
  setChatId,
  setUnreadMessageCounter,
  setChatReady,
  setUploadError,
} from './actions';
import { sendMessage, initChat, fetchMessage } from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  isOpen: false,
  sendLoading: false,
  chat: {
    id: null,
    messages: [],
    unread_messages_count: 0,
  },
  ready: false,
  error: '',
};

export const slice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setChatOpen, (state, { payload }) => {
        state.isOpen = payload;
      })
      .addCase(sendMessage.pending, (state) => {
        state.sendLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state, { payload }) => {
        state.sendLoading = false;
        state.chat.messages = state.chat.messages.find(
          (item) => item.id === payload.id
        )
          ? [...state.chat.messages]
          : [...state.chat.messages, payload];
      })
      .addCase(sendMessage.rejected, (state) => {
        state.sendLoading = false;
      })
      .addCase(initChat.fulfilled, (state, { payload }) => {
        state.chat.messages = payload;
        state.ready = true;
      })
      .addCase(setChatId, (state, { payload }) => {
        state.chat.id = payload;
      })
      .addCase(resetUnread, (state) => {
        state.chat.unread_messages_count = 0;
      })
      .addCase(fetchMessage.pending, (state) => {
        state.sendLoading = true;
      })
      .addCase(fetchMessage.fulfilled, (state, { payload }) => {
        state.sendLoading = false;
        state.chat.messages = state.chat.messages.find(
          (item) => item.id === payload.id
        )
          ? [...state.chat.messages]
          : [...state.chat.messages, payload];
      })
      .addCase(fetchMessage.rejected, (state) => {
        state.sendLoading = false;
      })
      .addCase(setUnreadMessageCounter, (state, { payload }) => {
        state.chat.unread_messages_count = payload;
      })
      .addCase(setChatReady, (state, { payload }) => {
        state.ready = payload;
      })
      .addCase(setUploadError, (state, { payload }) => {
        state.error = payload;
      });
  },
});
