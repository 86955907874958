import React from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

// LM-2758: скрыть соц сети
// import { juneSocialLinks } from '@box/shared/utils';
// import { LightSocialLink } from '@box/ui';

import styles from './socials.module.scss';
// LM-2758: скрыть соц сети
// import { ReactComponent as TelegramIcon } from './assets/telegram.svg';
// import { ReactComponent as InstagramIcon } from './assets/instagram.svg';
// import { ReactComponent as FacebookIcon } from './assets/facebook.svg';
// import { ReactComponent as TwitterIcon } from './assets/twitter.svg';
import { ReactComponent as LogoIcon } from './assets/logo.svg';

export const Socials = () => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  // LM-2758: скрыть соц сети
  // const socials = [
  //   {
  //     icon: TelegramIcon,
  //     link: juneSocialLinks.telegram,
  //   },
  //   { icon: InstagramIcon, link: juneSocialLinks.instagram },
  //   { icon: FacebookIcon, link: juneSocialLinks.facebook },
  //   { icon: TwitterIcon, link: juneSocialLinks.twitter },
  // ];

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_desktop]: isDesktop,
      })}
    >
      {isDesktop && (
        <div className={styles.desktop_content}>
          <div className={styles.logo}>
            <LogoIcon />
          </div>

          <p className={styles.copyright}>
            © 2021 - {currentYear}. ValorCasino.{' '}
            {t('footer.menu.rights_reserved')}
          </p>

          <p className={styles.adress}>
            Bettor IO N.V., Registration number: 157065, <br /> Curacao, Abraham
            de Veerstraat 9
          </p>
        </div>
      )}
      {/* LM-2758: скрыть соц сети */}
      {/* {socials.map((item, index) => {
        const Icon = item.icon;
        return (
          <LightSocialLink
            className={isDesktop ? styles.icon : undefined}
            key={index}
            href={item.link}
          >
            <Icon />
          </LightSocialLink>
        );
      })} */}
    </div>
  );
};
