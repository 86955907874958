import { slice } from './slice';

const { reducer, actions } = slice;

export const { reset: resetUserDocuments } = actions;

export * from './thunks';
export * as documentsSelectors from './selectors';

export default reducer;
