import { requestStatuses } from '../../utils/constants';

import { RootState, UserDocument } from '../../types';

const all = (state: RootState) => state.user.documents;
export const init = (state: RootState) =>
  all(state).status === requestStatuses.init;
export const types = (state: RootState) => all(state).types;
export const loading = (state: RootState) => all(state).loading;
export const current = (state: RootState) =>
  all(state).currentDocument || ({} as UserDocument);
export const error = (state: RootState) => all(state).error;
