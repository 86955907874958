import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import { thunkWithConfirm } from '@box/redux/app';
import {
  deleteUserBonus,
  userBonusesSelectors,
  balanceSelectors,
} from '@box/redux/user';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { ListStateProps, WithListWrapperProps } from './List.types';

export const withListState = <Props,>(
  Component: React.FC<Props & ListStateProps>
) => {
  const wrapper = ({ statuses, id, ...props }: WithListWrapperProps<Props>) => {
    const { t } = useTranslation();

    const [deleted, setDeleted] = useState(false);

    const dispatch = useAppDispatch();

    const list = useAppSelector((state) =>
      userBonusesSelectors.filteredByStatuses(state, statuses)
    );

    const symbol = useAppSelector(balanceSelectors.currencySymbol);
    const loading = useAppSelector(userBonusesSelectors.loading);

    const listMemo = useMemo(
      () =>
        sortBy(
          list.map((item) => ({
            ...item,
            onDelete: () => {
              dispatch(
                thunkWithConfirm({
                  title: t('common.bonus.title'),
                  text: t('common.bonus.cancel'),
                  yes: t('common.bonus.yes'),
                  no: t('common.bonus.no'),
                  callback: () => {
                    dispatch(deleteUserBonus({ bonusId: item.bonus_id }));
                    setDeleted(true);
                  },
                  onUnConfirm: () => {
                    setDeleted(false);
                  },
                })
              );
            },
          })),
          [(item) => Number(item?.getting_params[0]?.percent)]
        ),
      [size(list)]
    );

    return (
      <Component
        {...(props as Props)}
        values={listMemo}
        loading={loading}
        id={id}
        symbol={symbol}
        deleted={deleted}
        setDeleted={setDeleted}
        pagingLoading={loading}
      />
    );
  };

  return wrapper;
};
