import { createAction } from '@reduxjs/toolkit';

import {
  InitBalancePayload,
  SetActiveWalletBalancePayload,
  SetBonusBalancePayload,
} from './types';

export const initBalance =
  createAction<InitBalancePayload>('user/balance/init');
export const setActiveWalletBalance =
  createAction<SetActiveWalletBalancePayload>(
    'user/balance/setActiveWalletBalance'
  );
export const setBonusBalance = createAction<SetBonusBalancePayload>(
  'user/balance/setBonusBalance'
);
export const resetBalances = createAction('user/balance/resetBalances');
