import { createSlice } from '@reduxjs/toolkit';

import { forgotPassword, resetPassword } from './thunks';

const initialState = {
  loading: false,
  error: '',
};

export const slice = createSlice({
  name: 'passwordRecovery',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
      });
  },
});
