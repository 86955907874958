import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { commonSelectors } from '@box/redux/common';
import {
  fetchWithdrawalRequests,
  walletsSelectors,
  withdrawalRequestsSelectors,
} from '@box/redux/finance';
import { useDateFormat } from '@box/shared/hooks';
import { getStatus } from '@box/shared/utils';

import { Props, PayoutsHistoryProps } from './PayoutsHistory.types';

export const withState = (Component: FC<PayoutsHistoryProps>) => {
  const wrapper = ({ listId, userId }: Props) => {
    const dispatch = useAppDispatch();

    const currencies = useAppSelector(commonSelectors.currencies);

    const { list, ready, currentPage, lastPage, loading } = useAppSelector(
      (state) => withdrawalRequestsSelectors.listById(state, listId)
    );

    const wallets = useAppSelector(walletsSelectors.list);

    const { getLightDetalizationDate } = useDateFormat();

    const getCurrencySymbol = (name) =>
      currencies.find((item) => item.code === name)?.symbol;

    const getWalletName = (walletId) =>
      wallets.find((item) => item.id === walletId)?.name;

    useEffect(() => {
      dispatch(
        fetchWithdrawalRequests({
          id: listId,
          userId,
          page: currentPage || 1,
          sort: 'desc',
          sortby: 'created_at',
        })
      );
    }, []);

    const nextPage = () => {
      dispatch(
        fetchWithdrawalRequests({
          id: listId,
          userId,
          page: currentPage + 1,
          sort: 'desc',
          sortby: 'created_at',
        })
      );
    };

    const previousPage = () => {
      dispatch(
        fetchWithdrawalRequests({
          id: listId,
          userId,
          page: currentPage - 1,
          sort: 'desc',
          sortby: 'created_at',
        })
      );
    };

    const changePageNumber = (pageNumber) => {
      dispatch(
        fetchWithdrawalRequests({
          id: listId,
          userId,
          page: pageNumber,
          sort: 'desc',
          sortby: 'created_at',
        })
      );
    };

    const resultList =
      list?.map((item) => {
        return {
          date: getLightDetalizationDate(item.created_at),
          amount: `${parseFloat(item.amount).toFixed(2)} ${
            item?.withdrawal_amount && item?.confirm
              ? `(${item.withdrawal_amount})`
              : ''
          }`,
          currencySymbol: getCurrencySymbol(getWalletName(item.wallet_id)),
          status: getStatus(item),
          method: item?.meta?.payload?.method,
          merchant: item.meta?.external_object?.merchant?.name,
          payable_id: item.id,
          isWithdrawal: true,
        };
      }) || [];

    const detalization = {
      date: 'detalization.date',
      transaction: 'detalization.transaction',
      amount: 'detalization.amount',
      payment_method: 'detalization.payment_method',
      status: 'detalization.status',
    };

    return (
      <Component
        payoutList={resultList}
        detalizationList={Object.values(detalization)}
        loading={loading}
        isReady={ready}
        currentPage={currentPage}
        lastPage={lastPage}
        showNextPage={nextPage}
        showPreviousPage={previousPage}
        changePageNumber={changePageNumber}
      />
    );
  };

  return wrapper;
};
