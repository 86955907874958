import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { AppThunkApi } from '@box/redux/types';

import { RefreshBalanceReturn, GETBalance } from './types';

export const refreshBalance = createAsyncThunk<
  RefreshBalanceReturn,
  void,
  AppThunkApi
>(
  'user/balance/refreshBalance',
  async (_payload, { extra, rejectWithValue }) => {
    const { api } = extra;

    try {
      const { data, status } = await api.get<GETBalance>('/me', {
        params: {
          with: 'wallets',
        },
      });

      if (status === 200) {
        const bonus = data.wallets.find((item) => item.slug === 'BONUS');
        const activeWallet = data.wallets.find((item) => item.slug !== 'BONUS');

        return {
          currencyWallet: activeWallet.balance_float,
          bonusWallet: bonus.balance_float,
        };
      }

      if (status === 401 || status === 403) {
        return rejectWithValue(i18next.t('requestErrors.no_authorization'));
      }

      return rejectWithValue('Error');
    } catch (err) {
      return rejectWithValue(i18next.t('requestErrors.no_authorization'));
    }
  }
);
