import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useApp } from '@box/shared/hooks';
import { Text } from '@box/ui';

import { Form, Payments } from './Components';

import { Props } from './Withdrawal.types';

import styles from './withdrawal.module.scss';

export const Withdrawal: FC<Props> = ({
  payments,
  selectedMethod,
  setSelectedMethod,
  paymentsLoading,
  currencySymbol,
  onSubmit,
  fields,
  submitLoading,
  error,
  available,
  isDepositWagered,
  commissionPercent,
  blocked,
  onBlockedBlur,
  onBlockedChange,
  inputWithRateError,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  useEffect(() => {
    if (payments) {
      setSelectedMethod(payments[0]);
    }
  }, [payments]);
  return (
    <div
      className={classNames(styles.container, {
        [styles.container_loading]: paymentsLoading,
        [styles.container_desktop]: !isMobile,
      })}
    >
      <BlockWithLoading loading={paymentsLoading}>
        {!isMobile && (
          <Text
            className={styles.withdrawal_title}
            size='xxxl'
            weight='800'
            color='violet'
            as='span'
          >
            {t('withdrawalRequest.funds')}
          </Text>
        )}
        {payments && payments.length > 0 ? (
          <>
            <div className={styles.withdrawal_options}>
              <Text
                size={isMobile ? 'lg' : 'xxl'}
                weight='800'
                color='violet'
                as='span'
              >
                {t('withdrawalRequest.method')}
              </Text>
              <Payments
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                payments={payments}
              />
            </div>
            <Form
              onSubmit={onSubmit}
              amount={{
                min: selectedMethod?.limits.min,
                max: selectedMethod?.limits.max,
              }}
              currency={currencySymbol}
              isOpen={!!selectedMethod}
              fields={fields}
              submitLoading={submitLoading}
              amountLabel={t('top_up.sum')}
              error={error}
              commissionPercent={commissionPercent}
              available={available}
              isDepositWagered={isDepositWagered}
              blocked={blocked}
              onBlockedBlur={onBlockedBlur}
              onBlockedChange={onBlockedChange}
              inputWithRateError={inputWithRateError}
            />
          </>
        ) : (
          <p className={styles.nodata}>{t('common.no_data')}</p>
        )}
      </BlockWithLoading>
    </div>
  );
};
