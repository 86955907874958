import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appConfig } from '@box/shared/config';

import { FaqsItem } from './FaqsItem';

import { faqsList } from './Faqs.data';

import styles from './Faqs.module.scss';
import { FaqsSearch } from '../FaqsSearch';

const company = appConfig.companyName;

export const Faqs = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();

  const paramValue = params.get('faqs');

  const inputParamValue = params.get('faqsSearch');

  const faqsListFiltered = useMemo(
    () =>
      paramValue
        ? faqsList.filter((item) => t(item.value, { company }) === paramValue)
        : faqsList,
    [faqsList, paramValue]
  );

  const faqsListFilteredInput = useMemo(
    () =>
      inputParamValue
        ? faqsList.filter((item) =>
            t(item.value, { company })?.includes(inputParamValue)
          )
        : faqsList,
    [faqsList, inputParamValue]
  );

  return (
    <div className={styles.container}>
      <FaqsSearch />
      <div className={styles.main}>
        <ul className={styles.list}>
          {!paramValue
            ? faqsListFilteredInput.map((item, index) => (
                <li key={index} className={styles.item}>
                  <FaqsItem {...item} />
                </li>
              ))
            : faqsListFiltered.map((item, index) => (
                <li key={index} className={styles.item}>
                  <FaqsItem {...item} />
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
};
