import { appConfig } from '../config';

export const currentUrl = () => {
  const { host } = window.location;

  const mirrors = {
    [appConfig.mirror1]: appConfig.mirror1,
    [appConfig.mirror2]: appConfig.mirror2,
    [appConfig.mirror3]: appConfig.mirror3,
  };

  return mirrors[host] || appConfig.main_url;
};
