import { socialLinks } from './constants';

export type GetTelegramByCountryCode = (string) => string;

export const getTelegramByCountryCode: GetTelegramByCountryCode = (
  countryCode
) => {
  switch (countryCode) {
    case 'UZ':
      return socialLinks.telegramUZ;
    case 'BR':
      return socialLinks.telegramBR;
    case 'EN':
      return socialLinks.telegramEN;
    case 'IN':
      return socialLinks.telegramIN;
    default:
      return socialLinks.telegram;
  }
};
