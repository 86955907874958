// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Deposits-ui-Label-label-module__label{font-size:12px;line-height:135%}.\\------shared-components-Modals-Deposits-ui-Label-label-module__label_color_default{color:#9d8daf}.\\------shared-components-Modals-Deposits-ui-Label-label-module__label_color_gray{color:#b9b5be}.\\------shared-components-Modals-Deposits-ui-Label-label-module__label_align_left{text-align:left}.\\------shared-components-Modals-Deposits-ui-Label-label-module__label_align_center{text-align:center}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Deposits/ui/Label/label.module.scss"],"names":[],"mappings":"AACA,uEACE,cAAA,CACA,gBAAF,CAII,qFACE,aAFN,CAKI,kFACE,aAHN,CAQI,kFACE,eANN,CASI,oFACE,iBAPN","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.label {\n  font-size: 12px;\n  line-height: 135%;\n  \n\n  &_color {\n    &_default {\n      color: $color-amethyst-smoke;\n    }\n\n    &_gray {\n      color: $color-french-gray;\n    }\n  }\n\n  &_align {\n    &_left {\n      text-align: left;\n    }\n\n    &_center {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "------shared-components-Modals-Deposits-ui-Label-label-module__label",
	"label_color_default": "------shared-components-Modals-Deposits-ui-Label-label-module__label_color_default",
	"label_color_gray": "------shared-components-Modals-Deposits-ui-Label-label-module__label_color_gray",
	"label_align_left": "------shared-components-Modals-Deposits-ui-Label-label-module__label_align_left",
	"label_align_center": "------shared-components-Modals-Deposits-ui-Label-label-module__label_align_center"
};
module.exports = ___CSS_LOADER_EXPORT___;
