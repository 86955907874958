import React, { FC } from 'react';
import classNames from 'classnames';

import { Props } from './Title.types';

import styles from './title.module.scss';

export const Title: FC<Props> = ({ children, className }) => {
  return <h3 className={classNames(styles.title, className)}>{children}</h3>;
};
