import { RootState } from '../../types';

export const all = (state: RootState) => state.app.confirm;
export const isOpen = (state: RootState) => all(state).isOpen;
export const title = (state: RootState) => all(state).title;
export const text = (state: RootState) => all(state).text;
export const extraText = (state: RootState) => all(state).extraText;
export const callback = (state: RootState) => all(state).callback;
export const unConfirm = (state: RootState) => all(state).onUnConfirm;
export const yes = (state: RootState) => all(state).yes;
export const no = (state: RootState) => all(state).no;
export const onlyYes = (state: RootState) => all(state).onlyYes;
