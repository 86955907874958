import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  setIsButtonDisabled,
  userRegistration,
} from '@box/redux/authentication';
import { commonSelectors } from '@box/redux/common';
import { preparePhone } from '@box/shared/utils';
import { passwordRegex, validatePhone } from '@box/shared/utils/validation';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';

import { useApp } from '@box/shared/hooks';
import {
  createCurrenciesOptions,
  getCountryIdByICode,
  getCurrencyIdByCode,
} from '../../../../utils/selectOptions';
import { OpenElement } from './OpenElement';

import { QuickFormFields } from './Quick.types';
import styles from './Quick.module.scss';

const schema = z.object(
  {
    country_id: z.number(),
    currency_id: z.number(),
    email: z.string().email({
      message: 'fields.email_format_error',
    }),
    password: z
      .string()
      .min(6, { message: 'fields.password_length_error' })
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
  },
  {
    required_error: 'fields.required',
  }
);

const schemaJune = z.object(
  {
    country_id: z.number(),
    currency_id: z.number(),
    email: z.string().email({
      message: 'fields.email_format_error',
    }),
    password: z
      .string()
      .min(6, { message: 'fields.password_length_error' })
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
  },
  {
    required_error: 'fields.required',
  }
);

const juneWithPhone = schemaJune.extend({
  phone: z
    .string({
      required_error: 'fields.required',
    })
    .refine(validatePhone, 'fields.phone_format_error'),
});

export const useQuickFields = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const countries = useAppSelector(commonSelectors.countries);
  const currencies = useAppSelector(commonSelectors.currencies);
  const clientData = useAppSelector(commonSelectors.clientData);

  const isBangladesh = clientData?.country?.code === 'BD';

  const {
    userEnv: { isJune },
  } = useApp();

  const form = useForm<QuickFormFields>({
    resolver: zodResolver(
      isJune ? (isBangladesh ? schemaJune : juneWithPhone) : schema
    ),
  });

  const { control, setValue } = form;

  const currencyOptions = useMemo(
    () => createCurrenciesOptions(currencies),
    [currencies]
  );

  useEffect(() => {
    if (clientData) {
      setValue(
        'currency_id',
        getCurrencyIdByCode(
          currencies,
          clientData?.country?.default_currency_code
        )
      );
      setValue(
        'country_id',
        getCountryIdByICode(countries, clientData?.country?.code)
      );
    }
  }, [clientData, currencies, countries]);

  const onSubmit = (data: QuickFormFields) => {
    if (isJune && !isBangladesh) {
      data.phone = preparePhone(data.phone);
    }
    dispatch(setIsButtonDisabled(true));
    dispatch(userRegistration({ ...data }));
  };

  const fields = [
    {
      name: 'currency_id',
      options: currencyOptions,
      onChange: (val, field) => field.onChange(val.value),
      placeholder: t('fields.select'),
      element: 'select',
      control,
      label: isJune ? t('currency.title') : '',
      className: styles.control,
    },
    {
      name: 'email',
      placeholder: t('fields.email'),
      type: 'email',
      className: styles.control,
      label: isJune ? t('fields.email') : '',
    },
    {
      name: 'password',
      placeholder: t('fields.password'),
      type: 'password',
      className: styles.control,
      label: isJune ? t('fields.password') : '',
      element: 'password',
    },
  ];

  const juneFields = [
    ...fields,
    {
      name: 'promocode',
      placeholder: '. . .',
      type: 'text',
      rules: {
        required: false,
      },
      className: styles.control,
      label: isJune ? t('fields.promocode_title') : '',
      isHidden: true,
      rednerOpenElement: (open) => (
        <OpenElement withMargin={isBangladesh} onClick={open} />
      ),
      element: 'hiddenInput',
    },
  ];

  return {
    form,
    fields,
    juneFields,
    onSubmit,
    isBangladesh,
  };
};
