import { RootState } from '../types';

export const all = (state: RootState) => state.support;
export const isOpen = (state: RootState) => all(state).isOpen;
export const messages = (state: RootState) => all(state).chat.messages || [];
export const chat = (state: RootState) => all(state).chat || {};
export const chatId = (state: RootState) => all(state).chat.id;
export const sendLoading = (state: RootState) => all(state).sendLoading;
export const messagesLoading = (state: RootState) => !all(state).ready;
export const unreadMessagesCount = (state: RootState) =>
  all(state).chat.unread_messages_count;
