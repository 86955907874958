import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DecemberHiddenInput,
  DecemberInput,
  DecemberPassword,
  HiddenInput,
  Input,
  LightButton,
  Password,
} from '@box/ui';

import { useApp } from '@box/shared/hooks';
import Button from '../Button/Button';
import { FormSelect } from '../FormSelect';

import './form.scss';

const elements = {
  input: Input,
  select: FormSelect,
  password: Password,
  hiddenInput: HiddenInput,
};

const decemberElements = {
  input: DecemberInput,
  select: FormSelect,
  password: DecemberPassword,
  hiddenInput: DecemberHiddenInput,
};

export const Form = ({
  register,
  errors,
  fields,
  onSubmit,
  className,
  loading,
  loginLoading,
  formError,
  submitText,
  buttonColor = 'green',
  children,
  isButtonDisabled,
}) => {
  const { t } = useTranslation();
  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  return (
    <form
      className={classNames('form', { form_mobile: isMobile }, className)}
      onSubmit={onSubmit}
    >
      {children}
      {fields.map((item) => {
        const el = isJune ? elements : decemberElements;
        const Element = el[item.element] || el.input;
        return (
          <Element
            className={classNames('form__control', item.className, {
              'form__control--hidden': item.hidden,
            })}
            {...register(item.name, item.rules)}
            {...item}
            error={t(errors[item.name]?.message)}
            color='outlined'
            defaultValue={item.hidden ? item.default_value : ''}
            key={item.name}
            isHidden={item.isHidden}
            openElement={item.openElement}
          />
        );
      })}
      {formError && <span className='form__form-error'>{t(formError)}</span>}
      {isJune ? (
        <LightButton
          disabled={loading || isButtonDisabled || loginLoading}
          loading={loading || loginLoading}
          type='submit'
          fixHeight
          className='form__submit_june'
          color={buttonColor}
        >
          {submitText || t('common.submit')}
        </LightButton>
      ) : (
        <Button
          className='form__submit'
          loading={loading}
          color='secondary'
          type='submit'
        >
          {submitText || t('common.submit')}
        </Button>
      )}
    </form>
  );
};
