import React, { FC } from 'react';
import classNames from 'classnames';

import { LightSocialLinkProps } from './SocialLink.types';

import styles from './sociallink.module.scss';

export const LightSocialLink: FC<LightSocialLinkProps> = ({
  className,
  children,
  href,
}) => {
  return (
    <button
      type='button'
      onClick={() => window.open(href, '_blank', 'noreferrer')}
      className={classNames(styles.social, className)}
    >
      {children}
    </button>
  );
};
