import classNames from 'classnames';
import React from 'react';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import { setChatOpen, supportSelectors } from '@box/redux/support';

import styles from './lightchatbadge.module.scss';
import { ReactComponent as MessageSvg } from './assets/images/message.svg';

export const LightChatBadge = () => {
  const dispatch = useAppDispatch();

  const isChatOpen = useAppSelector(supportSelectors.isOpen);
  const unreadCount = useAppSelector(supportSelectors.unreadMessagesCount);

  const handleBadgeClick = () => {
    dispatch(setChatOpen(true));
  };
  return (
    <div
      onClick={handleBadgeClick}
      role='button'
      onKeyPress={handleBadgeClick}
      tabIndex={0}
      className={classNames(styles.badge, {
        [styles.badge_open]: unreadCount && unreadCount > 0 && !isChatOpen,
      })}
    >
      <MessageSvg />
      <p className={styles.counter}>+{unreadCount}</p>
    </div>
  );
};
