import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { useApp } from '@box/shared/hooks';
import { ReactComponent as Empty } from '@box/shared/icons/empty.svg';

import { Transaction } from '../Transaction';

import { TransactionHistoryProps } from './DepositHistory.types';

import styles from './DepositHistory.module.scss';
import { Pagination } from '../Pagination';

export const DepositHistory = ({
  depositList,
  detalizationList,
  loading,
  isReady,
  currentPage,
  lastPage,
  openDepositModal,
  showNextPage,
  showPreviousPage,
  changePageNumber,
}: TransactionHistoryProps) => {
  const { t } = useTranslation();

  const {
    userEnv: { isDesktop },
  } = useApp();

  const hasTransactions = depositList && depositList.length > 0;

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.container_desktop]: isDesktop,
          [styles.container_shadowsDisabled]: hasTransactions,
          [styles.container_empty]: !hasTransactions,
        })}
      >
        {isDesktop && hasTransactions && (
          <div className={styles.headers}>
            {detalizationList.map((detalizationItem) => (
              <div key={detalizationItem} className={styles.item}>
                {t(detalizationItem)}
              </div>
            ))}
          </div>
        )}
        <BlockWithLoading loading={!isReady || loading}>
          {!hasTransactions && isReady ? (
            <div
              className={classNames(styles.empty, {
                [styles.empty_desktop]: isDesktop,
              })}
            >
              <Empty />
              <span>{t('detalization.no_deposits.title')}</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('detalization.no_deposits.subtitle'),
                }}
              />
              <button
                onClick={openDepositModal}
                type='button'
                className={styles.empty_button}
              >
                {t('detalization.no_deposits.button')}
              </button>
            </div>
          ) : (
            depositList.map((elem, index) => (
              <Transaction list={elem} key={index} />
            ))
          )}
          {isDesktop && depositList.length && !isReady ? (
            <div className={styles.footer} />
          ) : null}
        </BlockWithLoading>
      </div>
      {hasTransactions && (
        <Pagination
          currentPage={currentPage}
          lastPage={lastPage}
          loading={loading}
          showNextPage={showNextPage}
          showPreviousPage={showPreviousPage}
          changePageNumber={changePageNumber}
        />
      )}
    </>
  );
};
