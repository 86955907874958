/* eslint-disable no-restricted-globals */
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';

export const getStatParams = () => {
  let search = location.search.substring(1);
  let params = false;
  let subs = {};

  if (search) {
    params = JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  }

  forIn(params, (value, key) => {
    if (key.match(/^sub\d+/gm)) {
      if (!isEmpty(value)) {
        subs[key] = value;
        delete params[key];
      } else {
        delete params[key];
      }
    }
  });

  if (Object.keys(subs).length > 0) {
    params.subs = subs;
  }

  return params;
};
