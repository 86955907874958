type UseBonusesCount = (type: string) => { count: number };

export const useBonusesCount: UseBonusesCount = (type) => {
  const count = (type) => {
    if (type) {
      switch (type) {
        case '1st_dep_bonuses':
          return 0;
        case '2nd_dep_bonuses':
          return 1;
        case '3rd_dep_bonuses':
          return 2;
        case '4th_dep_bonuses':
          return 3;
        default:
          return 0;
      }
    }
    return 0;
  };

  return { count: count(type) };
};
