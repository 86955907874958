import { createReducer } from '@reduxjs/toolkit';

import { setStats } from './actions';
import { StatsInitialState } from './types';

const initialState = {} as StatsInitialState;

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setStats, (_state, { payload }) => ({
    ...payload,
  }));
});
