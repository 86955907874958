import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BonusDepositDetail } from '@box/shared/components';
import { ImageSet } from '@box/ui';

import { Props } from './Bonuses.types';

import styles from './bonuses.module.scss';
import Gift from './assets/images/gift_small.png';
import GiftWebp from './assets/images/gift_small.webp';

export const Bonuses: FC<Props> = ({
  min,
  value,
  percent,
  className,
  minBonuses,
  defaultValue,
  currencySymbol,
}) => {
  const valueBonuses = useMemo(() => {
    if (value !== undefined) {
      return value;
    }
    return defaultValue;
  }, [defaultValue, value]);

  const { t } = useTranslation();
  return (
    <div className={classNames(styles.bonus, className)}>
      {valueBonuses >= Number(minBonuses) ? (
        <div className={styles.info}>
          <span>
            +
            {valueBonuses ? (Number(valueBonuses) * Number(percent)) / 100 : 10}{' '}
            {currencySymbol}
          </span>
          <div className={styles.box}>
            <ImageSet src={Gift} srcWebp={GiftWebp} alt='gift' />
            {t('bonuses.bonus')}
          </div>
        </div>
      ) : (
        <div className={styles.close}>
          <div className={styles.row}>
            <p className={styles.title}>{t('bonuses.not_available')}</p>
            <p className={styles.emoji}>🙁</p>
          </div>
          <p className={styles.subtitle}>
            {t('deposit.from')} {minBonuses} {currencySymbol}
          </p>
          <p className={styles.subtitle}>{t('deposit.bonus_message')}</p>
        </div>
      )}
      <BonusDepositDetail />
    </div>
  );
};
