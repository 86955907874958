import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useAppSelector } from '@box/redux/hooks';
import { balanceSelectors, userSelectors } from '@box/redux/user';
import { useApp } from '@box/shared/hooks';

import { Circle } from './Circle';
import { Status } from './Status';
import { ProgressBar } from './ProgressBar';

import { Props } from './ActiveBonus.types';

import styles from './activeBonus.module.scss';
import { ReactComponent as ArrowSvg } from './assets/images/arrow.svg';

export const ActiveBonus: FC<Props> = ({
  amount,
  total,
  status,
  name,
  description,
  progression_percentage,
  wager,
}) => {
  const [params, setParams] = useSearchParams();

  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  const symbol = useAppSelector(balanceSelectors.currencySymbol);

  const { t } = useTranslation();

  const handleClick = () => {
    params.delete('active-bonus');
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const deposit = useMemo(() => {
    if (amount && typeof amount === 'number') {
      return Math.round(amount * 10) / 10;
    }
    return 0;
  }, [amount]);

  const needed = useMemo(() => {
    if (status === 'activated') {
      if (
        total &&
        typeof total === 'number' &&
        amount &&
        typeof amount === 'number'
      ) {
        return total - amount >= 0 ? Math.round((total - amount) * 10) / 10 : 0;
      }
      return 0;
    } else {
      if (amount && typeof amount === 'number') {
        return Math.round(amount * 10) / 10;
      }
      return 0;
    }
  }, [amount, total, status]);

  const bonusSum = useMemo(() => {
    if (status === 'activated') {
      if (total && typeof total === 'number') {
        return Math.round(total * 10) / 10;
      }
      return 0;
    } else {
      if (amount && typeof amount === 'number') {
        return Math.round(amount * 10) / 10;
      }
      return 0;
    }
  }, [total]);

  const percent = useMemo(() => {
    if (progression_percentage) {
      return Number(progression_percentage);
    }
    return 0;
  }, [progression_percentage]);

  const get = useMemo(() => {
    if (wager && total) {
      return Math.round(total / wager);
    }
    return 0;
  }, [total, wager]);

  const currency = useAppSelector(userSelectors.currency);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_mobile]: isMobile,
        [styles.container_june]: isJune,
      })}
    >
      <button onClick={handleClick} type='button' className={styles.header}>
        <ArrowSvg />
        <p className={styles.text}>{t('bonuses.all')}</p>
      </button>
      <div className={styles.main}>
        <div className={styles.progression}>
          <Circle progression_percentage={percent} />
          <div className={classNames(styles.box, styles.box_active)}>
            <p className={styles.label}>
              {t('bonuses.your_deposit')}: <span>{deposit}</span>
            </p>
          </div>
          <div className={styles.box}>
            <p className={styles.label}>
              {t('bonuses.sum_of_bonuses_received')}: <span>{bonusSum}</span>
            </p>
          </div>
          <div className={styles.box}>
            <p className={styles.label}>
              {t('bonuses.you_get')}:{' '}
              <span>
                {get} {symbol}
              </span>
            </p>
          </div>
          <div className={styles.box}>
            <p className={styles.label}>
              {t('bonuses.able_transfer')}: <span>{needed}</span>
            </p>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.up}>
            <Status status={status} />
            <h3 className={styles.title}>{name}</h3>
            {!isMobile && <p className={styles.text}>{description}</p>}
          </div>
          <ProgressBar
            progression_percentage={percent}
            deposit={deposit}
            needed={needed}
            currency={currency?.code}
          />
          {isMobile && <p className={styles.text}>{description}</p>}
        </div>
      </div>
    </div>
  );
};
