import React, { FC } from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import Select, {
  SelectInstance,
  SingleValueProps,
  OptionProps,
  components,
} from 'react-select';
import { Flag } from '@box/ui';

import { OptionInterface, Props } from './SelectWithFlag.types';

import './selectWithFlag.scss';

const SingleValue: FC<SingleValueProps> = ({ children, ...props }) => {
  const string = children.toLocaleString();
  return (
    <components.SingleValue {...props}>
      <div className='form-select__flag'>
        <Flag id={string} />
      </div>
      {string}
    </components.SingleValue>
  );
};

const Option: FC<OptionProps> = ({ children, ...props }) => {
  const string = children.toLocaleString();
  return (
    <components.Option {...props}>
      <div className='form-select__flag'>
        <Flag id={string} />
      </div>
      {string}
    </components.Option>
  );
};

export const SelectWithFlag: FC<Props> = React.forwardRef<
  SelectInstance<OptionInterface>,
  Props
>(
  (
    {
      name,
      control,
      options,
      placeholder,
      onChange,
      className,
      rules,
      label,
      defaultValue,
      menuPlacement,
      error,
      errorMessage,
      disabled,
    },
    ref
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <div className={className}>
              {label && <span className='form-select-label'>{label}</span>}
              <Select
                ref={ref}
                className={classNames('form-select', {
                  'form-select--error': !!error,
                })}
                classNamePrefix='form-select'
                placeholder={placeholder}
                onChange={(v: OptionInterface) => onChange(v, field)}
                options={options}
                isSearchable={false}
                menuPlacement={menuPlacement}
                value={
                  options.find((item) => item.value === field.value) || null
                }
                isDisabled={disabled}
                components={{ SingleValue, Option }}
              />
              {errorMessage && (
                <span className='form-select-error'>{errorMessage}</span>
              )}
            </div>
          );
        }}
      />
    );
  }
);
