import { useTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import isRegExp from 'lodash/isRegExp';
import { z } from 'zod';

export const useValidate = () => {
  const { t } = useTranslation();
  const getCustomValidate = (rules, mask) => (v) => {
    if (rules.email) {
      const result = z.string().email().safeParse(v);
      if (!result.success) return t('fields.payment_rules.email_format');
    }

    if (mask) {
      if (v.includes(' ')) return t('fields.invalid_field');
    }

    return true;
  };

  const getRulesObj = (rules) =>
    Object.keys(rules).reduce((acc, item) => {
      const key = camelCase(item);
      let value = rules[item];

      if (key === 'pattern' && value) {
        const regexp = new RegExp(value.substr(1).slice(0, -1));
        value = isRegExp(regexp) ? regexp : null;
      }

      if (key === 'blockedMax') {
        return value
          ? {
              ...acc,
              max: {
                value,
                message: t('withdrawalRequest.amount_error'),
              },
            }
          : acc;
      }

      return value
        ? {
            ...acc,
            [key]: {
              value,
              message: t(`fields.payment_rules.${key}`, {
                extra: value,
              }),
            },
          }
        : acc;
    }, {});

  return {
    customValidate: getCustomValidate,
    rulesForObject: getRulesObj,
  };
};
