import { createSlice } from '@reduxjs/toolkit';

import { BannersInitialState, BannersListStore } from './types';
import { fetchBannersByPosition } from './thunks';

const defaultList: BannersListStore = {
  loading: true,
  init: false,
  list: [],
};

export const slice = createSlice({
  name: 'banners',
  initialState: {} as BannersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannersByPosition.pending, (state, payload) => {
        const { position } = payload.meta.arg;

        const list = state[position] || defaultList;

        state[position] = list;
      })
      .addCase(fetchBannersByPosition.fulfilled, (state, payload) => {
        const { position } = payload.meta.arg;

        const list = state[position];

        list.list = payload.payload.list;
        list.loading = false;
        list.init = true;

        state[position] = list;
      });
  },
});
