import { createSlice } from '@reduxjs/toolkit';

import { fetchInitChatsUser } from './thunks';
import { InitialState } from './types';
import { setUserId } from './actions';

const initialState: InitialState = {
  loading: false,
  error: '',
  userId: null,
};

export const slice = createSlice({
  name: 'init',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitChatsUser.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchInitChatsUser.fulfilled, (state, { payload }) => {
        state.loading = true;
        state.userId = payload;
      })
      .addCase(fetchInitChatsUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(setUserId, (state, { payload }) => {
        state.userId = payload;
      });
  },
});
