import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useApp } from '@box/shared/hooks';

import { ErrorPopup } from '../../ErrorPopup';

import './defaultErrorPopup.scss';
import { LightErrorPopup } from '../../LightErrorPopup';

export const DefaultErrorPopup = ({ title, text, id }) => {
  const { t } = useTranslation();
  const {
    userEnv: { isJune },
  } = useApp();

  if (isJune) {
    return (
      <LightErrorPopup
        className='error-light-popup'
        id={id}
        title={title}
        buttonText={t('common.ok')}
        timered
      >
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          className='error-popup__text'
        />
      </LightErrorPopup>
    );
  }

  return (
    <ErrorPopup
      className='error-popup'
      id={id}
      title={title}
      buttonText={t('common.ok')}
    >
      <p
        dangerouslySetInnerHTML={{ __html: text }}
        className='error-popup__text'
      />
    </ErrorPopup>
  );
};

DefaultErrorPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string,
};

DefaultErrorPopup.defaultProps = {
  title: '',
  text: '',
  id: '',
};
