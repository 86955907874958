import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLang } from '@box/shared/utils';
import i18next from 'i18next';

import { AppThunkApi } from '../../types';
import { userInitSelectors } from '../../authentication';

import { userSelectors } from '../../user/info';
import { fetchCategories } from '../../common';

import {
  ChangeLangPayload,
  ChangeLangReturn,
  PatchUserDataPayload,
  PatchUserDataReturn,
} from './types';
import { ready } from './selectors';

export const changeLang = createAsyncThunk<
  ChangeLangReturn,
  ChangeLangPayload,
  AppThunkApi
>('app/lang/set', async (payload, { rejectWithValue, getState, dispatch }) => {
  const isLogged = userInitSelectors.isLogged(getState());
  const userId = userSelectors.id(getState());

  try {
    i18next.changeLanguage(payload.code);
    setLang(payload.code);

    if (isLogged) {
      dispatch(patchUserData({ userId, data: { language_id: payload.id } }));
    }

    dispatch(fetchCategories({ lang: payload.code }));

    return payload;
  } catch (e) {
    return rejectWithValue('Error');
  }
});

export const initLang = createAsyncThunk<
  ChangeLangReturn,
  ChangeLangPayload,
  AppThunkApi
>('app/lang/init', async (payload, { getState, rejectWithValue }) => {
  const isAlreadyReady = ready(getState());

  if (!isAlreadyReady) {
    i18next.changeLanguage(payload.code);
    setLang(payload.code);

    return payload;
  }

  return rejectWithValue('');
});

export const patchUserData = createAsyncThunk<
  PatchUserDataReturn,
  PatchUserDataPayload,
  AppThunkApi
>('app/userdata/patch', async (payload, { extra, rejectWithValue }) => {
  const { api } = extra;

  try {
    const { data, status } = await api.patch(
      `/userdatas/${payload.userId}`,
      payload.data
    );

    if (status === 200) {
      return data;
    }

    return rejectWithValue('Error');
  } catch (e) {
    return rejectWithValue(e.message || 'Error');
  }
});
