import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const validateMonth = (
  month: string | number,
  year: string | number
) => {
  return dayjs(`${month}/${year}`, 'MM/YY').isAfter(dayjs().add(1, 'month'));
};

export const validateYear = (year: string | number) => {
  const currentYear = dayjs();
  const date = dayjs(year, 'YY');
  return (
    date.isAfter(currentYear.subtract(1, 'year')) &&
    date.isBefore(currentYear.add(18, 'year'))
  );
};
