import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { changePasswordScheme } from '@box/shared/utils/validation';
import { useAppDispatch, useAppSelector } from '@box/redux/hooks';
import {
  passwordUpdateSelectors,
  updatePassword,
  passwordUpdateReset,
} from '@box/redux/authentication';
import { LightButton, Text, Input } from '@box/ui';
import { useApp } from '@box/shared/hooks';

import { Inputs } from './Secure.types';

import { ReactComponent as HideSvg } from './assets/images/hide.svg';
import { ReactComponent as ShowSvg } from './assets/images/show.svg';

import styles from './secure.module.scss';

export const Secure = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    userEnv: { isMobile },
  } = useApp();

  const [showPassword, setShowPassword] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'all',
    resolver: zodResolver(changePasswordScheme),
  });

  const error = useAppSelector(passwordUpdateSelectors.error);
  const loading = useAppSelector(passwordUpdateSelectors.loading);
  const oldPassword = watch('old_password');
  const password = watch('new_password');
  const passwordConfirm = watch('password_confirmation');

  const handleClick = (id) => {
    if (showPassword.includes(id)) {
      setShowPassword(showPassword.filter((item) => item !== id));
    } else {
      setShowPassword([...showPassword, id]);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    dispatch(updatePassword(data));
  };

  useEffect(() => {
    return () => {
      dispatch(passwordUpdateReset());
    };
  }, []);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_desktop]: !isMobile,
      })}
    >
      <div className={styles.secure_content}>
        <Text
          as='span'
          size={isMobile ? 'lg' : 'xxl'}
          weight='800'
          color='violet'
          className={styles.title}
        >
          {t('password_change.change')}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.box}>
            <Input
              placeholder={t('settings.old_pass')}
              type={showPassword.includes('1') ? 'text' : 'password'}
              label={t('settings.old_pass')}
              className={styles.control}
              error={t(errors.old_password?.message)}
              {...register('old_password')}
            />
            <button
              className={classNames(styles.show, {
                [styles.show_active]: showPassword.includes('1'),
              })}
              onClick={() => {
                handleClick('1');
              }}
              type='button'
            >
              {showPassword.includes('1') ? <ShowSvg /> : <HideSvg />}
            </button>
          </div>
          <div className={styles.box}>
            <Input
              placeholder={t('settings.new_pass')}
              type={showPassword.includes('2') ? 'text' : 'password'}
              label={t('settings.new_pass')}
              className={styles.control}
              error={t(errors.new_password?.message)}
              {...register('new_password')}
            />
            <button
              className={classNames(styles.show, {
                [styles.show_active]: showPassword.includes('2'),
              })}
              onClick={() => {
                handleClick('2');
              }}
              type='button'
            >
              {showPassword.includes('2') ? <ShowSvg /> : <HideSvg />}
            </button>
          </div>
          <div className={styles.box}>
            <Input
              placeholder={t('settings.confirm_pass')}
              type={showPassword.includes('3') ? 'text' : 'password'}
              label={t('settings.confirm_pass')}
              className={styles.control}
              error={t(errors.password_confirmation?.message)}
              {...register('password_confirmation')}
            />
            <button
              className={classNames(styles.show, {
                [styles.show_active]: showPassword.includes('3'),
              })}
              onClick={() => {
                handleClick('3');
              }}
              type='button'
            >
              {showPassword.includes('3') ? <ShowSvg /> : <HideSvg />}
            </button>
          </div>
          <LightButton
            disabled={
              !!errors.new_password ||
              !!errors.old_password ||
              !!errors.password_confirmation ||
              !oldPassword ||
              !passwordConfirm ||
              !password ||
              loading
            }
            loading={loading}
            className={styles.submit}
            type='submit'
            color='yellow'
            fixHeight
          >
            {t('common.submit')}
          </LightButton>
        </form>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
};
