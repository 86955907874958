import React from 'react';
import { createPortal } from 'react-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import classNames from 'classnames';
import { useLockBodyScroll } from 'react-use';
import { useApp } from '@box/shared/hooks';

import './imageViewer.scss';

export const ImageViewer = ({ image, isOpen, close, isZoomActive }) => {
  useLockBodyScroll(!!isOpen);

  const {
    userEnv: { isJune },
  } = useApp();

  return createPortal(
    <div
      className={classNames(
        'image-viewer',
        { 'image-viewer_june': isJune },
        { 'image-viewer--open': isOpen }
      )}
    >
      <div
        className='image-viewer__overlay'
        onClick={close}
        aria-hidden='true'
      />
      <div className='image-viewer__close' onClick={close} aria-hidden='true' />
      <div className='image-viewer__content'>
        <TransformWrapper
          disabled={!isZoomActive}
          doubleClick={{ disabled: true }}
          maxScale={3}
          maxPositionX={500}
          maxPositionY={500}
          centerZoomedOut
        >
          <TransformComponent>
            <img src={image} alt='test' />
          </TransformComponent>
        </TransformWrapper>
      </div>
    </div>,
    document.body
  );
};
