import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { InnerModal, Modal } from '@box/ui';
import { closeBonusDetail } from '@box/redux/bonuses';
import { useAppDispatch } from '@box/redux/hooks';
import { useApp } from '@box/shared/hooks';

import { Main } from './Main';
import { Instruction } from './Instruction';

import { Props } from './BonusModal.types';

import styles from './bonusModal.module.scss';
import { ReactComponent as CloseSvg } from './assets/images/close.svg';

export const DecemberBonusModal: FC<Props> = ({
  name,
  img,
  className,
  bonusId,
  description,
  getting_params,
  wager_params,
  isAbsolute,
  isOpen,
}) => {
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const {
    userEnv: { isMobile },
  } = useApp();

  const { t } = useTranslation();

  const handleClick = () => {
    params.delete('bonus');
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleClose = () => {
    dispatch(closeBonusDetail());
  };

  const additionals = useMemo(() => {
    if (getting_params && getting_params.length > 0 && wager_params) {
      return {
        wager: wager_params?.wager,
        max: getting_params[0]?.max,
        min: getting_params[0]?.min,
        percent: getting_params[0]?.percent,
      };
    }
    return false;
  }, [getting_params, wager_params]);

  if (isAbsolute) {
    return (
      <Modal close={handleClose} isOpen={isOpen}>
        <div
          className={classNames(styles.container, styles.container_absolute, {
            [styles.container_mobile]: isMobile,
          })}
        >
          <div className={styles.header}>
            <button
              type='button'
              onClick={handleClose}
              className={styles.close}
            >
              <CloseSvg />
            </button>
          </div>
          <Main
            description={description}
            bonusId={bonusId}
            name={name}
            image={img}
          />
          {additionals && <Instruction {...additionals} />}
        </div>
      </Modal>
    );
  }

  return (
    <InnerModal
      onBackClick={handleClick}
      onClose={handleClick}
      back={t('bonuses.back_to_promotions')}
      className={className}
    >
      <div className={styles.container}>
        <Main
          description={description}
          bonusId={bonusId}
          name={name}
          image={img}
        />
        {additionals && <Instruction {...additionals} />}
      </div>
    </InnerModal>
  );
};
