import { createSlice } from '@reduxjs/toolkit';

import { requestStatuses } from '../../utils/constants';

import { initWallets } from './actions';
import { WalletsInitialState } from './types';

const initialState: WalletsInitialState = {
  list: [],
  status: requestStatuses.notStarted,
};

export const slice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(initWallets, (state, { payload }) => {
      state.list = payload;
      state.status = requestStatuses.init;
    });
  },
});
