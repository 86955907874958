// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal{z-index:100}.\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal .\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal__body{max-width:400px}.\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal__text{margin:12px}.\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal__buttons{display:flex;flex-wrap:wrap;gap:12px;padding:12px}.\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal__button{flex:1;font-weight:700}.\\------shared-components-Modals-ConfirmModal-confirmModal-module__modal__button-june{flex:1;font-size:14px;height:38px}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/ConfirmModal/confirmModal.module.scss"],"names":[],"mappings":"AACA,yEACE,WAAF,CAEE,wJACE,eAAJ,CAGE,+EACE,WADJ,CAIE,kFAGE,YAAA,CACA,cAAA,CAFA,QAAA,CADA,YACJ,CAKE,iFACE,MAAA,CACA,eAHJ,CAME,sFACE,MAAA,CAEA,cAAA,CADA,WAHJ","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.modal {\n  z-index: $layout-popup;\n\n  .modal__body {\n    max-width: 400px;\n  }\n\n  &__text {\n    margin: 12px;\n  }\n\n  &__buttons {\n    padding: 12px;\n    gap: 12px;\n    display: flex;\n    flex-wrap: wrap;\n  }\n\n  &__button {\n    flex: 1;\n    font-weight: 700;\n  }\n\n  &__button-june {\n    flex: 1;\n    height: 38px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal",
	"modal__body": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal__body",
	"modal__text": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal__text",
	"modal__buttons": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal__buttons",
	"modal__button": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal__button",
	"modal__button-june": "------shared-components-Modals-ConfirmModal-confirmModal-module__modal__button-june"
};
module.exports = ___CSS_LOADER_EXPORT___;
