import React, { useState } from 'react';
import classNames from 'classnames';
import { useSwiper } from 'swiper/react';
import { useApp } from '@box/shared/hooks';

import styles from './slideButtons.module.scss';

import { ReactComponent as JuneArrowSvg } from './assets/arrow_june.svg';
import { ReactComponent as ArrowSvg } from './assets/arrow.svg';

export const SlideButtons = () => {
  const swiper = useSwiper();

  const [isBeginning, setBeginning] = useState(true);
  const [isEnd, setEnd] = useState(false);

  const handleClick = (isNext: boolean) => {
    setBeginning(swiper.isBeginning);
    setEnd(swiper.isEnd);

    if (isNext) {
      swiper.slideNext();
    } else {
      swiper.slidePrev();
    }
  };

  const {
    userEnv: { isJune },
  } = useApp();

  return (
    <div
      className={classNames(styles.buttons, { [styles.buttons_june]: isJune })}
    >
      <button
        className={classNames(styles.button, {
          [styles.button_hidden]: isBeginning,
        })}
        type='button'
        disabled={isBeginning}
        onClick={() => handleClick(false)}
      >
        {isJune ? <JuneArrowSvg /> : <ArrowSvg />}
      </button>
      <button
        className={classNames(
          styles.button,
          { [styles.button_hidden]: isEnd },
          styles.button_next
        )}
        type='button'
        disabled={isEnd}
        onClick={() => handleClick(true)}
      >
        {isJune ? <JuneArrowSvg /> : <ArrowSvg />}
      </button>
    </div>
  );
};
