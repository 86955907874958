import { withState } from './DetalizationList.state';
import { withPagination } from './DetalizationList.pagination';
import { DetalizationListContainer } from './DitalizationList.container';
import { withWithdrawalList } from './WithdrawalList';
import { withDetailParams } from './DetailParams';

export const DetalizationList = withState(DetalizationListContainer);
export const PaginateDetalizationList = withPagination(DetalizationList);
export const WithdrawalList = withWithdrawalList(DetalizationListContainer);
export const DetailParams = withDetailParams(DetalizationListContainer);
