import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { FormProvider } from 'react-hook-form';
import { useApp, useDeposit } from '@box/shared/hooks';
import { Text } from '@box/ui';

import { PaymentFrame } from '../Modals/TopUp/PaymentFrame';
import { Payments, Form } from './Components';

import { Props } from './Deposit.types';

import styles from './deposit.module.scss';

export const Deposit: FC<Props> = ({ isModal }) => {
  const { t } = useTranslation();

  const {
    userEnv: { isMobile },
  } = useApp();

  const {
    methods,
    isIn,
    payments,
    isBeatifyCardForm,
    selectedMethod,
    setSelectedMethod,
    loading,
    onSubmit,
    bonuses,
    currencySymbol,
    defaultsEnabled,
    requestError,
    fields,
    qr,
    frame,
    paymentLoading,
  } = useDeposit();

  return (
    <FormProvider {...methods}>
      <div
        className={classNames(styles.container, {
          [styles.container_loading]: paymentLoading,
          [styles.container_mobile]: isMobile,
          [styles.container_modal]: isModal,
        })}
      >
        {!frame && (
          <BlockWithLoading loading={paymentLoading}>
            {payments && payments.length > 0 ? (
              <>
                {!isMobile && !isModal && (
                  <Text
                    className={styles.deposit_title}
                    size='xxxl'
                    weight='800'
                    color='violet'
                    as='span'
                  >
                    {t('detalization.deposit')}
                  </Text>
                )}
                <div className={styles.deposit_options}>
                  <Text
                    size={isMobile ? 'lg' : 'xxl'}
                    weight='800'
                    color='violet'
                    as='span'
                  >
                    {t('detalization.deposit_method')}
                  </Text>
                  <Payments
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    payments={payments}
                    tabsSkittles={isIn}
                    isModal={isModal}
                  />
                </div>
                <Form
                  onSubmit={onSubmit}
                  isOpen={!!selectedMethod}
                  bonuses={bonuses}
                  amount={{
                    min: selectedMethod?.limits?.min,
                    max: selectedMethod?.limits?.max,
                  }}
                  currencySymbol={currencySymbol}
                  defaultsEnabled={defaultsEnabled}
                  submitLoading={loading}
                  fields={fields}
                  isBeatifyCardForm={isBeatifyCardForm}
                  requestError={requestError}
                  amountLabel={t('top_up.sum')}
                  isModal={isModal}
                  qrOpen={!!qr}
                  qr={qr}
                />
              </>
            ) : (
              <p className={styles.nodata}>{t('common.no_data')}</p>
            )}
          </BlockWithLoading>
        )}
        {frame && <PaymentFrame {...frame} />}
      </div>
    </FormProvider>
  );
};
