import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { useApp } from '@box/shared/hooks';

import {
  getCategoryIcon,
  getJuneCategoryIcon,
} from '../../utils/icons/categories';
import { getProviderIcon } from '../../utils/icons/providers';

export const Icon = ({ icon, className }) => {
  const {
    userEnv: { isJune },
  } = useApp();
  let Component;

  if (isFunction(icon)) {
    Component = icon;

    return <Component className={className} />;
  }

  if (typeof icon === 'object') {
    if (icon?.type === 'provider') {
      Component = getProviderIcon(icon);
    } else if (icon?.type === 'category') {
      if (isJune) {
        Component = getJuneCategoryIcon(icon);
      } else {
        Component = getCategoryIcon(icon);
      }
    }
  }

  if (typeof icon === 'string') {
    Component = React.memo(() => (
      <img className={className} src={icon} alt='' />
    ));
  }

  return Component ? <Component className={className} /> : null;
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
};

Icon.defaultProps = {
  icon: null,
  className: '',
};
