// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__infoFaq{border-bottom:2px solid #e9e2f1;padding:16px 0;text-align:center}.\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__infoFaq .\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__left img,.\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__infoFaq .\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__left source{transform:scaleX(-1)}.\\------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__link{background:none;border:none;color:#4776f6;cursor:pointer;-webkit-text-decoration:underline;text-decoration:underline}", "",{"version":3,"sources":["webpack://./../../shared/components/Modals/Deposits/Components/InfoFaq/InfoFaq.module.scss"],"names":[],"mappings":"AACA,qFACE,+BAAA,CAEA,cAAA,CADA,iBACF,CAOI,yVACE,oBAFN,CAOA,kFAEE,eAAA,CADA,WAAA,CAGA,aAAA,CADA,cAAA,CAEA,iCAAA,CAAA,yBAJF","sourcesContent":["@import \"/opt/buildhome/repo/packages/ui/src/styles/globals.scss\";\n.infoFaq {\n  border-bottom: 2px solid #e9e2f1;\n  text-align: center;\n  padding: 16px 0;\n\n  .left {\n    img {\n      transform: scale(-1, 1);\n    }\n\n    source {\n      transform: scale(-1, 1);\n    }\n  }\n}\n\n.link {\n  border: none;\n  background: none;\n  cursor: pointer;\n  color: #4776f6;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoFaq": "------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__infoFaq",
	"left": "------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__left",
	"link": "------shared-components-Modals-Deposits-Components-InfoFaq-InfoFaq-module__link"
};
module.exports = ___CSS_LOADER_EXPORT___;
