import React, { FC } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Navigation.module.scss';
import { INavigationProps, NavigationItem } from './Navigation.types';

const CustomLink: FC<NavigationItem> = ({
  label,
  excludedActive,
  component: Component,
  ...props
}) => {
  const { pathname } = useLocation();

  const getClassNames = ({ isActive }: { isActive: boolean }): string => {
    const match = excludedActive?.find((item) => matchPath(item, pathname));

    return classNames(styles.navigationLink, {
      [styles.navigationActiveLink]: !match && isActive,
    });
  };

  return Component ? (
    <div className={getClassNames({ isActive: false })}>
      <Component />
    </div>
  ) : (
    <NavLink className={getClassNames} {...props}>
      {label}
    </NavLink>
  );
};

export const Navigation: FC<INavigationProps> = ({
  className,
  items,
  size = 'medium',
}) => {
  return (
    <nav
      className={classNames(className, styles.navigation, {
        [styles['navigation--medium']]: size === 'medium',
        [styles['navigation--small']]: size === 'small',
      })}
    >
      <ul className={styles.navigationList}>
        {items.map((item, idx) => (
          <li className={styles.navigationItem} aria-hidden='true' key={idx}>
            <CustomLink {...item} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
