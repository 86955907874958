import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import z from 'zod';
import {
  passwordRecoverySelectors,
  recoveryReset,
  resetPassword,
} from '@box/redux/authentication';
import { Text } from '@box/ui';
import { passwordRegex } from '@box/shared/utils/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useApp } from '@box/shared/hooks';

import { Form } from '../../../baseComponents/Form/Form';

import { Modal } from '../../Modal/Modal';

import './recoveryContinue.scss';

const required = { required_error: 'fields.required' };
const schema = z
  .object({
    email: z.string(required).email('fields.email_format_error'),
    password: z
      .string(required)
      .min(6, 'fields.password_length_error')
      .max(30, 'fields.password_max_length_error')
      .regex(passwordRegex, 'fields.invalid_field'),
    password_confirmation: z.string(required),
    token: z.string(required),
  })
  .refine((v) => v.password === v.password_confirmation, {
    message: 'fields.password_confirmation',
    path: ['password_confirmation'],
  });

export const RecoveryContinueModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(passwordRecoverySelectors.loading);
  const error = useSelector(passwordRecoverySelectors.error);

  const {
    userEnv: { isJune, isDesktop },
  } = useApp();

  const {
    register,
    handleSubmit,
    formState: { errors },

    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => dispatch(resetPassword(data));

  const fields = [
    {
      name: 'email',
      placeholder: t('fields.email'),
      type: 'email',
      label: isJune ? t('fields.email') : '',
    },
    {
      name: 'password',
      placeholder: t('fields.password'),
      type: 'password',
      label: isJune ? t('fields.password') : '',
    },
    {
      name: 'password_confirmation',
      type: 'password',
      placeholder: t('fields.password_confirmation'),
      label: isJune ? t('fields.password_confirmation') : '',
    },
    {
      name: 'token',
      placeholder: t('fields.token'),
      type: 'text',
      label: isJune ? t('fields.token') : '',
    },
  ];

  const onClose = () => {
    reset();
    dispatch(recoveryReset());
  };

  return (
    <Modal
      id='recovery-continue'
      className='recovery-continue'
      title={t('recovery_continue.title')}
      size={isDesktop ? 'lg' : 'xs'}
      onClose={onClose}
      headColor={isJune ? 'yellow' : 'default'}
      headWithSvg={isJune}
      heightAuto={isJune && isDesktop}
    >
      <div
        className={classNames('recovery-continue__body', {
          'recovery-continue__body_june': isJune,
        })}
      >
        <Text
          className={classNames('recovery__text', {
            'recovery__text-desktop': isDesktop,
          })}
          size='sm'
          color='black'
          as='p'
        >
          {t('recovery_continue.text')}
        </Text>
        <Form
          buttonColor={isJune ? 'yellow' : ''}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
          register={register}
          fields={fields}
          formError={error}
          className={classNames('recovery-continue', {
            'recovery-continue-desktop': isDesktop,
          })}
        />
      </div>
    </Modal>
  );
};
