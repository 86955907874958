import classNames from 'classnames';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { usePhoneFormats, useApp } from '@box/shared/hooks';

import { getPhoneWithoutCode } from '../../utils/user';
import { phoneValidator } from '../../utils/validation';

import './phoneInput.scss';

const PhoneInput = ({
  className,
  code,
  control,
  error,
  rules = {},
  disabled,
  isInvestments,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  const { validate, ...restRules } = rules;

  const { mask, placeholder, minValue } = usePhoneFormats(code);

  return (
    <Controller
      control={control}
      name='phone'
      rules={{
        ...restRules,
        validate: {
          phoneValidator: (v) => phoneValidator(code, v, minValue),
          ...validate,
        },
      }}
      render={({ field }) => (
        <div
          className={classNames('phone-input', className, {
            'phone-input--error': !!error,
            'phone-input_june': isJune,
            'phone-input_investments': isInvestments,
          })}
        >
          <NumberFormat
            className={classNames('phone-input__input', {
              'phone-input__input_mobile': isMobile,
              'phone-input__input_june': isJune,
              'phone-input__input_investments': isInvestments,
            })}
            format={mask}
            value={
              field.value?.length >= minValue
                ? getPhoneWithoutCode(code, field.value)
                : field.value
            }
            type='text'
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            getInputRef={field.ref}
            disabled={disabled}
            isAllowed={() => true}
            placeholder={placeholder}
          />
          {error && <span className='phone-input__error'>{t(error)}</span>}
        </div>
      )}
    />
  );
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  code: PropTypes.number,
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
};

PhoneInput.defaultProps = {
  className: '',
  code: 1,
  error: '',
};

export default PhoneInput;
