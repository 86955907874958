export const getUpdateData = (user, newData) => {
  return Object.keys(newData).reduce((acc, item) => {
    if (
      user[item] !== undefined &&
      JSON.stringify(user[item]) !== JSON.stringify(newData[item])
    ) {
      return {
        ...acc,
        [item]: newData[item],
      };
    }

    return acc;
  }, {});
};
