import { UserData } from './user';

export type UploadedFile = {
  id: number;
  name: string;
  path: string;
  preview: string;
  type: string;
};

export interface MessageImage extends UploadedFile {
  type: 'image';
}

export interface Message {
  id: number;
  chat_id: number;
  user_id: number;
  created_at: string;
  text: string;
  image: string;
  date: string;
  isSelf: boolean;
  viewed_at: string;
  data: {
    text: string;
  };
  files?: MessageImage[];
  read_by: number[];
  status: string;
  sender: UserData;
  buttons: {
    params: {
      text: string;
    };
    name: string;
  }[];
}

export type MessageList = Message[];

export interface AdminChatUserData extends UserData {
  created_at: string;
  deleted_at: string;
  is_online: boolean;
  last_client: {
    date: string;
    ip: string;
    lang: string;
    referer: string;
    useragent: string;
  };
  pivot: {
    chat_id: number;
    last_viewed_at: number;
    userdata_id: number;
    viewed_messages_count: number;
  };
  system_params: {
    telegram_id: string;
  };
  updated_at: string;
  verification_status_message: string;
}
