import React, { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { useAppDispatch } from '@box/redux/hooks';
import { openImageViewer } from '@box/redux/app';
import { setChatOpen } from '@box/redux/support';

import { MessageButton } from '../../MessageButton';

import { Props } from './MessageItem.types';

import styles from './messageItem.module.scss';

export const MessageItem: FC<Props> = ({
  isSelf,
  text,
  image,
  date,
  buttons,
}) => {
  const dispatch = useAppDispatch();

  const [openButton, setOpenButton] = useState(null);

  const openImage = () => {
    dispatch(openImageViewer(image));
    dispatch(setChatOpen(false));
  };

  return (
    <div
      className={classNames(styles.message, { [styles.message_self]: isSelf })}
    >
      <div className={styles.up}>
        <p className={styles.name}>{!isSelf ? 'Smart Assistant' : 'You'}</p>
        <p className={styles.date}>{date}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.text}>
          {text && <ReactMarkdown>{text}</ReactMarkdown>}
          {image && (
            <img
              className={styles.image}
              onClick={openImage}
              src={image}
              alt='picture from messages'
              aria-hidden='true'
            />
          )}
        </div>
        {buttons?.map((button, index) => (
          <MessageButton
            key={index}
            id={index}
            text={button.params.text}
            buttonText={button.name}
            openButton={openButton}
            setOpenButton={setOpenButton}
          />
        ))}
      </div>
    </div>
  );
};
