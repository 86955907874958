import React, { useEffect } from 'react';
import classNames from 'classnames';
import reactDom from 'react-dom';
import isFunction from 'lodash/isFunction';
import { LightButton } from '@box/ui';

import './lightErrorPopup.scss';
import { ReactComponent as HeadIcon } from './assets/head_icon.svg';

export const LightErrorPopup = ({
  className,
  isOpen,
  title,
  children,
  close,
  buttonText,
  buttonClick,
  timered,
}) => {
  const onButtonClick = () => {
    if (isFunction(buttonClick)) {
      buttonClick();

      return;
    }

    if (isFunction(close)) {
      close();
    }
  };

  useEffect(() => {
    if (timered) {
      const timeout = setTimeout(() => {
        close();
        clearTimeout(timeout);
      }, 10000);
    }
  }, []);

  return reactDom.createPortal(
    <div
      className={classNames('error-light-popup', className, {
        'error-light-popup--open': isOpen,
      })}
    >
      <div className='error-light-popup__content'>
        <div className='error-light-popup__head'>
          <div className='error-light-popup__icon'>
            <HeadIcon />
          </div>
          <span className='error-light-popup__title'>{title}</span>
        </div>
        <div className='error-light-popup__body'>
          {children}
          {buttonText && (
            <div className='error-light-popup__bottom'>
              <LightButton
                className='error-light-popup__button'
                color='yellow'
                onClick={onButtonClick}
              >
                {buttonText}
              </LightButton>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};
