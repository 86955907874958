import React, { FC } from 'react';
import classNames from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import MaskInput from 'react-number-format';
import { z } from 'zod';
import { Collapsed } from '@box/shared/baseComponents';
import {
  validateMonth as monthValidate,
  validateYear as yearValidate,
} from '@box/shared/utils/validation';
import { useTranslation } from 'react-i18next';
import { DepositFields } from '@box/redux';

import { Props, InputProps } from './Card.types';

import styles from './Card.module.scss';

const Input: FC<InputProps> = ({
  className,
  name,
  mask,
  label,
  rules,
  ...props
}) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    control,
    name,
    rules,
  });

  const { error } = fieldState;

  return (
    <label
      className={classNames(styles.input, className, {
        [styles.inputError]: !!error,
        [styles.inputWithValue]: !!field.value?.trim(),
      })}
      htmlFor={name}
    >
      {mask ? (
        <MaskInput id={name} format={mask} {...field} {...(props as any)} />
      ) : (
        <input id={name} {...field} {...props} />
      )}
      <span className={styles.label}>{label}</span>
    </label>
  );
};

export const Card: FC<Props> = ({ isOpen }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<DepositFields>();

  const lengthValidate = (length: number, name: string) => (v: string) => {
    const result = z.string().trim().min(length).safeParse(v);

    return result.success ? true : t('deposit.card.correctly', { name });
  };

  const validateMonth = (v: string) => {
    const year = getValues('exp_year');

    if (!monthValidate(v, year)) return t('deposit.card.currency_mouth');

    return true;
  };

  const validateYear = (v: string) => {
    if (!yearValidate(v)) return t('deposit.card.correctly_year');
    return true;
  };

  return (
    <Collapsed isOpen={isOpen}>
      <div className={styles.card}>
        <Input
          className={styles.cardNumber}
          name='pan'
          // TODO: add translation
          label={t('deposit.card.card_number')}
          mask='#### #### #### ####'
          rules={{
            required: true,
            validate: lengthValidate(16, 'card number'),
          }}
        />
        <div className={styles.bottom}>
          <Input
            name='exp_month'
            label={t('deposit.card.MM')}
            mask='##'
            rules={{
              required: true,
              validate: {
                length: lengthValidate(2, 'mouth'),
                format: validateMonth,
              },
            }}
          />
          <Input
            name='exp_year'
            label={t('deposit.card.YY')}
            placeholder='YY'
            mask='##'
            rules={{
              required: true,
              validate: {
                length: lengthValidate(2, 'year'),
                format: validateYear,
              },
            }}
          />
          <Input
            className={styles.cvc}
            name='cvc'
            type='password'
            label={t('deposit.card.CVC')}
            mask='###'
            rules={{
              required: true,
              validate: lengthValidate(3, 'cvc'),
            }}
          />
        </div>
        <Input
          className={styles.holder}
          name='holder'
          label={t('deposit.card.card_holder')}
          rules={{
            required: true,
            validate: lengthValidate(5, 'Name'),
          }}
        />
      </div>
    </Collapsed>
  );
};
