import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { currentGameSelectors } from '@box/redux/games';

import { SmallLoader } from '../../baseComponents';
import { useApp, useOpenGames } from '../../hooks';
import { icons } from '../../utils/linkIcons';

import styles from './Aviator.module.scss';
import { Props } from './Aviator.types';

export const Aviator: FC<Props> = ({
  className,
  withIcon,
  withName = true,
  text,
  isFullHeight,
}) => {
  const {
    userEnv: { isMobile, host },
  } = useApp();

  const loadedId = useSelector(currentGameSelectors.loadedId);
  const isButtonDisabled = useMatch({
    path: '/casino/current/aviator',
    end: false,
  });

  const { openGame, loadingId } = useOpenGames();

  const Logo = icons.aviatorLogo;
  const Name = icons.aviatorName;

  const isLoading = loadingId === loadedId;

  const onClick = () => {
    if (!isButtonDisabled) {
      openGame({ id: 'aviator', hasDemo: true, withRedirect: true });
    }
  };

  const isWithName =
    isMobile && host === 'june' ? withName && !isLoading : withName;

  return (
    <div
      className={classNames(styles.aviator, className, {
        [styles['aviator--loading']]: isLoading,
        [styles.aviator_fullHeight]: isFullHeight,
        [styles.aviator_mobile]: isMobile && host === 'june' && !isLoading,
      })}
      onClick={onClick}
      aria-hidden='true'
    >
      <SmallLoader className={styles.loader} isActive={isLoading} />
      {withIcon && <Logo className={styles.logo} />}
      {isWithName && <Name className={styles.name} />}
      <span className='aviator__text'>{text}</span>
    </div>
  );
};
