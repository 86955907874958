import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Collapsed } from '@box/shared/baseComponents';
import { useTranslation } from 'react-i18next';
import { LightButton } from '@box/ui';
import { useAppSelector } from '@box/redux/hooks';
import { loyaltyChartSelectors } from '@box/redux/loyalty';
import { useApp } from '@box/shared/hooks';

import { DetailInfo } from '../../DetailInfo';
import { Chart } from './Chart';
import { Header } from './Header';
import { MobileChart } from './MobileChart';

import styles from './coinsChart.module.scss';

export const CoinsChart: FC = () => {
  const points = useAppSelector(loyaltyChartSelectors.points);
  const exchangeRate = useAppSelector(loyaltyChartSelectors.exchangeRate);
  const historyList = useAppSelector(loyaltyChartSelectors.historyList);

  const {
    userEnv: { isMobile },
  } = useApp();

  const { t } = useTranslation();

  const [isHistoryHidden, setHistoryHidden] = useState(false);

  const handleToggleHidden = () => {
    setHistoryHidden((prevVar) => !prevVar);
  };

  return (
    <div
      className={classNames(styles.coins, { [styles.coins_mobile]: isMobile })}
    >
      <h3 className={styles.title}>{t('loyalty.chart.title')}</h3>
      <div className={styles.row}>
        <p className={styles.subtitle}>{t('loyalty.chart.subtitle')}</p>
        <DetailInfo
          className={styles.info}
          label={t('loyalty.chart.accumulation_rules')}
          text={t('loyalty.chart.accumulation_rules')}
        />
      </div>
      <div className={styles.chart}>
        <Header
          handleToggleHidden={handleToggleHidden}
          isHistoryHidden={isHistoryHidden}
          points={points}
          exchangeRate={exchangeRate}
        />
        <Collapsed isOpen={!isHistoryHidden}>
          {isMobile ? (
            <MobileChart historyList={historyList} />
          ) : (
            <Chart historyList={historyList} />
          )}
        </Collapsed>
      </div>
      {isMobile && (
        <LightButton className={styles.button}>
          {t('loyalty.chart.start_earning')}
        </LightButton>
      )}
    </div>
  );
};
