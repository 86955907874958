import React, { FC } from 'react';
import Select, {
  SingleValueProps,
  OptionProps,
  components,
} from 'react-select';
import { langSelectors, changeLang } from '@box/redux/app/lang';
import { useAppSelector, useAppDispatch } from '@box/redux/hooks';
import { commonSelectors } from '@box/redux/common';
import { thunkWithGlobalLoader } from '@box/redux/app';
import { Flag } from '@box/ui';

import classNames from 'classnames';
import { useApp } from '../../hooks';

import { languagesFlags } from './LanguageDropdown.data';

import './languageDropdown.scss';

const SingleValue: FC<SingleValueProps> = ({ children, ...props }) => {
  const string = children.toLocaleString();
  return (
    <components.SingleValue {...props}>
      <div className='language-dropdown_flag'>
        <Flag id={languagesFlags[string]} />
      </div>
      {string}
    </components.SingleValue>
  );
};

const Option: FC<OptionProps> = ({ children, ...props }) => {
  const string = children.toLocaleString();
  return (
    <components.Option {...props}>
      <div className='language-dropdown_flag'>
        <Flag id={languagesFlags[string]} />
      </div>
      <p className='language-dropdown_text'>{string}</p>
    </components.Option>
  );
};
const DropdownIndicator = (props) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator
      className={classNames('language-dropdown_arrow', {
        'language-dropdown_arrow--open': selectProps.menuIsOpen,
      })}
      {...props}
    />
  );
};
const customStyles = {
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused || isSelected ? '#FDA700 !important' : '#fff',
      color: isFocused || isSelected ? '#fff !important' : '#202040',
      '&:hover': {
        backgroundColor: '#FDA700 !important',
        color: '#fff !important',
      },
    };
  },
  control: () => ({
    border: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  singleValue: () => ({
    paddingLeft: '4px',
    color: '#202040 !important',
  }),
  valueContainer: () => ({
    display: 'flex !important',
    alignItems: 'center',
  }),
  menuList: () => ({
    maxHeight: '160px',
    borderRadius: '8px !important',
    backgroundColor: '#fff !important',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#FDA700',
    },
  }),
};

export const LanguageDropdown = ({ june = false }) => {
  const dispatch = useAppDispatch();

  const currentLang = useAppSelector(langSelectors.current);
  const languages = useAppSelector(commonSelectors.languages);

  const { availableLanguages } = useApp();

  const onChangeLang = (v: { label: string; value: string }) => {
    const language = languages.find((item) => item.code === v.value);
    dispatch(thunkWithGlobalLoader(changeLang.bind(null, language)));
  };

  return (
    <Select
      menuPlacement='top'
      styles={june && customStyles}
      className='language-dropdown'
      classNamePrefix='language-dropdown'
      value={availableLanguages.find((item) => item.value === currentLang.code)}
      options={availableLanguages}
      isSearchable={false}
      onChange={onChangeLang}
      components={{ SingleValue, Option, DropdownIndicator }}
    />
  );
};
