import React, { FC } from 'react';
import classNames from 'classnames';

import { useApp } from '@box/shared/hooks';
import { Props } from './DetailPopup.types';

import styles from './detailPopup.module.scss';

export const DetailPopup: FC<Props> = ({
  className,
  isOpen,
  children,
  isLoyalty,
  openUp,
  position = 'bottom',
}) => {
  const {
    userEnv: { isJune },
  } = useApp();

  return (
    isOpen && (
      <div
        className={classNames(
          styles.popup,
          styles[`position-${position}`],
          { [styles.popup_june]: isJune },
          { [styles.popup_loyalty]: isLoyalty },
          { [styles.popup_openUp]: openUp },
          className
        )}
      >
        {children}
      </div>
    )
  );
};
