import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BlockWithLoading } from '@box/shared/baseComponents';
import { PaymentQR } from '@box/shared/components';
import { useDeposit } from '@box/shared/hooks';

import { Modal } from '../../Modal';
import { PaymentFrame } from '../TopUp/PaymentFrame';
import { Bottom, Payments, Form, InfoFaq } from './Components';

export const DepositModal = () => {
  const { t } = useTranslation();

  const {
    methods,
    isIn,
    payments,
    isBeatifyCardForm,
    paymentLoading,
    selectedMethod,
    setSelectedMethod,
    loading,
    onSubmit,
    bonuses,
    currencySymbol,
    defaultsEnabled,
    requestError,
    fields,
    qr,
    frame,
  } = useDeposit();

  useEffect(() => {
    if (payments) {
      setSelectedMethod(payments[0]);
    }
  }, [payments]);

  return (
    <Modal id='deposit' title={t('deposit.title')}>
      <FormProvider {...methods}>
        <BlockWithLoading
          className='currencies-loader'
          loading={paymentLoading}
        >
          {!qr && !frame && (
            <>
              {isIn && <InfoFaq />}
              {payments && (
                <Payments
                  selectedMethod={selectedMethod}
                  setSelectedMethod={setSelectedMethod}
                  payments={payments}
                  tabsSkittles={isIn}
                />
              )}
              <Form
                onSubmit={onSubmit}
                isOpen={!!selectedMethod}
                bonuses={bonuses}
                amount={{
                  min: selectedMethod?.limits?.min,
                  max: selectedMethod?.limits?.max,
                }}
                currencySymbol={currencySymbol}
                defaultsEnabled={defaultsEnabled}
                submitLoading={loading}
                fields={fields}
                isBeatifyCardForm={isBeatifyCardForm}
                requestError={requestError}
                amountLabel={t('top_up.sum')}
              />
              <Bottom />
            </>
          )}
          {qr && <PaymentQR />}
          {frame && <PaymentFrame {...frame} />}
        </BlockWithLoading>
      </FormProvider>
    </Modal>
  );
};
