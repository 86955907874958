import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useScrollAndResize = () => {
  const [scrollPosition, setScrollPosition] = useState({
    x: window.pageXOffset,
    y: window.pageYOffset,
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition({
        x: window.pageXOffset,
        y: window.pageYOffset,
      });
    };

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const throttledHandleScroll = throttle(handleScroll, 100); // 100 миллисекунд задержки
    const throttledHandleResize = throttle(handleResize, 200); // 200 миллисекунд задержки

    window.addEventListener('scroll', throttledHandleScroll);
    window.addEventListener('resize', throttledHandleResize);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, []);

  return {
    scrollPosition,
    windowSize,
    isMobile: windowSize.width < 930,
  };
};
