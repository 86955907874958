import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '@box/shared/hooks';

import { ReactComponent as AddIcon } from '../assets/images/add.svg';
import { OpenElementProps } from './OpenElement.types';

import styles from './OpenElement.module.scss';

export const OpenElement: React.FC<OpenElementProps> = ({
  onClick,
  withMargin,
}) => {
  const { t } = useTranslation();

  const {
    userEnv: { isJune, isMobile },
  } = useApp();

  return (
    <div
      className={classNames(styles.element, {
        [styles.element_desktop]: !isMobile,
        [styles.element_margin]: withMargin,
      })}
      onClick={onClick}
      tabIndex={0}
      role='button'
      aria-hidden='true'
    >
      <AddIcon />
      <p
        className={classNames(styles.text, {
          [styles.june]: isJune,
        })}
      >
        {t('promocodes.add_promo_code')}
      </p>
    </div>
  );
};
