import { createSlice } from '@reduxjs/toolkit';

import { InitialState, Task } from './types';

const initialState: InitialState = {
  list: [
    {
      id: 1,
      points: 2,
      text: 'loyalty.tasksList.1',
      icon: 'profile',
      progressType: 'empty',
      isDone: true,
    },
    {
      id: 2,
      points: 2,
      text: 'loyalty.tasksList.2',
      icon: 'email',
      progressType: 'empty',
      isDone: true,
    },
    {
      id: 3,
      points: 3,
      text: 'loyalty.tasksList.3',
      progress: 66,
      icon: 'game3',
      progressType: 'bar',
    },
    {
      id: 4,
      points: 5,
      text: 'loyalty.tasksList.4',
      progress: 40,
      icon: 'game5',
      progressType: 'bar',
      date: 1687294800000,
    },
    {
      id: 5,
      points: 10,
      text: 'loyalty.tasksList.5',
      progress: 20,
      icon: 'game10',
      progressType: 'bar',
    },
    {
      id: 6,
      points: 20,
      text: 'loyalty.tasksList.6',
      progress: 50,
      icon: 'dep2',
      progressType: 'bar',
    },
    {
      id: 7,
      points: 30,
      text: 'loyalty.tasksList.7',
      progress: 33,
      icon: 'dep3',
      progressType: 'bar',
    },
    {
      id: 8,
      points: 40,
      text: 'loyalty.tasksList.8',
      progress: 25,
      icon: 'dep4',
      progressType: 'bar',
    },
    {
      id: 9,
      points: 30,
      text: 'loyalty.tasksList.9',
      progress: 100,
      icon: 'money100',
      isDone: true,
      progressType: 'bar',
    },
    {
      id: 10,
      points: 40,
      text: 'loyalty.tasksList.10',
      progress: 40,
      icon: 'money500',
      progressType: 'bar',
    },
    {
      id: 11,
      points: 100,
      text: 'loyalty.tasksList.11',
      progress: 20,
      icon: 'money1000',
      progressType: 'bar',
    },
    {
      id: 12,
      points: 200,
      text: 'loyalty.tasksList.12',
      progress: 10,
      icon: 'money2000',
      progressType: 'bar',
    },
    {
      id: 13,
      points: 500,
      text: 'loyalty.tasksList.13',
      progress: 4,
      icon: 'money5000',
      progressType: 'bar',
    },
    {
      id: 14,
      points: 50,
      text: 'loyalty.tasksList.14',
      progressType: 'app',
      icon: 'mobileApp',
    },
    {
      id: 15,
      points: 50,
      text: 'loyalty.tasksList.15',
      progressType: 'support',
      icon: 'support',
    },
    {
      id: 16,
      points: 50,
      text: 'loyalty.tasksList.16',
      progressType: 'empty',
      icon: 'fund',
    },
    {
      id: 17,
      points: 100,
      text: 'loyalty.tasksList.17',
      progressType: 'bar',
      progress: 50,
      icon: 'bet100',
    },
    {
      id: 18,
      points: 200,
      text: 'loyalty.tasksList.18',
      progressType: 'bar',
      progress: 25,
      icon: 'bet200',
    },
    {
      id: 19,
      points: 300,
      text: 'loyalty.tasksList.19',
      progressType: 'bar',
      progress: 16,
      icon: 'bet300',
    },
    {
      id: 200,
      points: 400,
      text: 'loyalty.tasksList.20',
      progressType: 'bar',
      progress: 12,
      icon: 'bet400',
    },
    {
      id: 200,
      points: 500,
      text: 'loyalty.tasksList.21',
      progressType: 'bar',
      progress: 10,
      icon: 'bet500',
    },
  ] as Task[],
};

export const slice = createSlice({
  name: 'loyalty/tasks',
  initialState,
  reducers: {},
});
