import { RootState } from '../../types';

export const all = (state: RootState) => state.auth.login;
export const loading = (state: RootState) => all(state).loading;
export const error = (state: RootState) => all(state).error;
export const isSuccess = (state: RootState) => state.auth.login.isSuccess;
export const isUserNotFound = (state: RootState) =>
  state.auth.login.isUserNotFound;
export const isButtonDisabled = (state: RootState) =>
  state.auth.login.isButtonDisabled;
