import isString from 'lodash/isString';

import { appConfig } from '../config';

const main = `https://static.${window.location.hostname}`;

const servicePaths = {
  main: appConfig.mainStorage ? appConfig.mainStorage : main,
  crm: appConfig.crmStorage,
};

type GetStoragePath = (
  url: string,
  service: keyof typeof servicePaths
) => string;

export const getStoragePath: GetStoragePath = (url, service) => {
  const path = servicePaths[service];

  if (!isString(url)) return '';

  if (
    /(http(s?)):\/\//i.test(url) ||
    url.startsWith(servicePaths.main) ||
    url.startsWith(servicePaths.crm)
  )
    return url;

  if (url.startsWith('/')) return `${path}${url}`;

  return `${path}/${url}`;
};

export const getFinRedirect = (url) =>
  url.replace('/transactions', appConfig.finTransactions);
