import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { Props } from './Tabs.types';

import styles from './tabs.module.scss';

export const Tabs: FC<Props> = ({ className }) => {
  const [params, setParams] = useSearchParams();

  const tabValue = params.get('funds');

  const handleSetParams = () => {
    setParams({
      ...Object.fromEntries(new URLSearchParams(params)),
    });
  };

  const handleDepositTab = () => {
    if (tabValue) {
      params.delete('funds');
      handleSetParams();
    }
  };

  const handleWithdrawalTab = () => {
    if (!tabValue) {
      params.append('funds', 'withdrawal');
      handleSetParams();
    }
  };

  return (
    <div className={classNames(styles.tabs, className)}>
      <button
        className={classNames(styles.tab, { [styles.tab_active]: !tabValue })}
        type='button'
        onClick={handleDepositTab}
      >
        Depozit
      </button>
      <button
        className={classNames(styles.tab, { [styles.tab_active]: tabValue })}
        type='button'
        onClick={handleWithdrawalTab}
      >
        Mablag’ chiqarish
      </button>
    </div>
  );
};
