import { useEffect } from 'react';

export const useLockScroll = (lock) => {
  const targetStyle = document.querySelector('body').style;

  useEffect(() => {
    if (lock) {
      targetStyle.overflow = 'hidden';
      targetStyle.position = 'relative';
      targetStyle.height = '100%';
    }
    return () => {
      targetStyle.overflow = 'auto';
      targetStyle.height = 'auto';
    };
  }, [lock]);
};
