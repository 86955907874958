import React, { FC } from 'react';
import classNames from 'classnames';
import { appConfig } from '@box/shared/config';

import { Props } from './Payment.types';

import styles from './payment.module.scss';

export const Payment: FC<Props> = ({
  className,
  children,
  item,
  onClick,
  selectedItem,
  label,
}) => {
  const handleClick = () => {
    onClick(item);
  };
  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(styles.button, className)}
    >
      <div
        className={classNames(styles.payment, {
          [styles.payment_active]: selectedItem?.id === item?.id,
        })}
      >
        {children}
      </div>
      {label && appConfig.env === 'development' && (
        <p className={styles.label}>{label}</p>
      )}
    </button>
  );
};
