import React, { useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { openImageViewer } from '@box/redux/app';
import { documentsSelectors } from '@box/redux/user';
import { getStoragePath } from '@box/shared/utils';
import { useAppSelector } from '@box/redux/hooks';
import { Text } from '@box/ui';

import { ReactComponent as CloseIcon } from '../assets/images/close.svg';

import styles from '../photos.module.scss';
import { PhotoError } from '../PhotoError/PhotoError';
import { UploadProps } from './Upload.types';

export const Upload: React.FC<UploadProps> = ({
  name,
  label,
  disabled,
  first,
}) => {
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [canDownload, setCanDownload] = useState(true);
  const inputRef = useRef(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [preview, setPreview] = useState('');

  const currentDocument = useSelector(documentsSelectors.current);
  const errors = useAppSelector(documentsSelectors.error);
  const photoError = useMemo(() => {
    if (typeof errors !== 'string' && errors?.photo) {
      return errors.photo[0];
    }
    return '';
  }, [errors]);

  const scanError = useMemo(() => {
    if (typeof errors !== 'string' && errors?.scan) {
      return errors.scan[0];
    }
    return '';
  }, [errors]);

  const { control, watch } = useFormContext();
  const profileStore = watch(name);

  useEffect(() => {
    if (profileStore) {
      setCanDownload(false);
    } else {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setCanDownload(true);
    }
  }, [profileStore]);

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: { required: `${t('fields.required')}` },
  });

  useEffect(() => {
    if (!isEmpty(currentDocument)) {
      setPreview(getStoragePath(currentDocument[name], 'main'));
    }
  }, [currentDocument]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.value;
    const fileSize = Math.ceil(Number(file?.size) * 0.001);
    /* eslint-disable */
    const updateFileName = fileName.replace(/^.*[\/\\]| \(\d+\)\.\w+$/g, '');
    /* eslint-enable */
    if (e.target.files[0]) {
      setFileName(updateFileName);
      setFileSize(fileSize);
      setPreview(URL.createObjectURL(file));
      onChange(file);
      setCanDownload(false);
    }
  };

  const openPreview = () => {
    if (!isError) {
      dispatch(openImageViewer(preview));
    }
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className={styles.inputs}>
      <div className={styles.head}>
        <Text size='xs' as='span' className={styles.name}>
          {label}
        </Text>
      </div>
      {!disabled && (
        <label
          className={classNames(styles.upload, profileStore && styles.uploaded)}
          htmlFor={name}
        >
          {profileStore ? (
            <>
              <div
                className={styles.uploaded_info}
                role='presentation'
                onClick={openPreview}
                onKeyDown={openPreview}
              >
                <span>{fileName}</span>
                <span>
                  {fileSize}
                  {t('common.document_weight')}
                </span>
              </div>
              <CloseIcon onClick={() => setCanDownload(true)} />
            </>
          ) : (
            <span>{t('common.upload')}</span>
          )}
          <input
            id={name}
            type='file'
            ref={inputRef}
            onClick={(e) => !canDownload && e.preventDefault()}
            onChange={onFileChange}
            accept='image/png, image/jpg, image/jpeg'
          />
        </label>
      )}
      {disabled && (
        <div
          className={styles.input_preview}
          onClick={openPreview}
          aria-hidden='true'
        >
          {(loading || isError) && <PhotoError label={label} />}
          <img
            src={preview}
            alt={label}
            className={classNames(styles.img, {
              [styles.img_loading]: loading || isError,
            })}
            onLoad={handleImageLoad}
            onError={() => setError(true)}
          />
        </div>
      )}
      {error && <span className={styles.error}>{t(error.message)}</span>}
      {first && scanError && <span className={styles.error}>{scanError}</span>}
      {!first && photoError && (
        <span className={styles.error}>{photoError}</span>
      )}
    </div>
  );
};
