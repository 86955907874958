import dayjs from 'dayjs';
import 'dayjs/locale/bn';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';

export const useDateFormat = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const format = (date, formatStr) =>
    dayjs(date).locale(language).format(formatStr);

  const getDetalizationDate = (date) => {
    const timeFormat = language === 'en' ? '(hh:mm A)' : '(HH:mm)';

    return format(date, `MMMM, DD.YYYY ${timeFormat}`);
  };
  const getLightDetalizationDate = (date) => {
    const timeFormat = language === 'en' ? 'hh:mm A' : 'HH:mm';

    return {
      fullDate: format(date, `MM.DD.YYYY ${timeFormat}`),
      date: format(date, `MM.DD.YYYY`),
      time: format(date, `${timeFormat}`),
    };
  };

  const supportChatMessageDate = (date) => format(date, 'DD-MM-YYYY');

  return {
    format,
    getDetalizationDate,
    supportChatMessageDate,
    getLightDetalizationDate,
  };
};
