import React, { FC } from 'react';
import classNames from 'classnames';

import { Loader } from '../Loader';
import styles from './Button.module.scss';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  bold?: boolean;
  color?: 'primary' | 'secondary' | 'transparent';
  textColor?: 'white' | 'secondary' | 'black';
  hover?: 'outline' | 'opacity';
  size?: 'small' | 'medium' | 'large' | 'x-large';
  loading?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
}

export const Button: FC<IButtonProps> = ({
  children,
  className,
  bold,
  color = 'primary',
  textColor = 'white',
  hover,
  size = 'medium',
  loading,
  disabled,
  uppercase,
  ...props
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        className,
        styles[`button--${color}`],
        styles[`button--hover-${hover}`],
        styles[`button--${size}`],
        {
          [styles['button--bold']]: bold,
          [styles['button--loading']]: loading,
        }
      )}
      disabled={disabled || loading}
      {...props}
    >
      <Loader className={styles.loader} />
      <span
        className={classNames(styles.text, styles[`text_color_${textColor}`], {
          [styles.text_uppercase]: uppercase,
        })}
      >
        {children}
      </span>
    </button>
  );
};
