import React, { FC } from 'react';
import classNames from 'classnames';
import { useApp } from '@box/shared/hooks';

import { Props } from './checkboxWithState.types';

import styles from './checkboxWithState.module.scss';

export const CheckboxWithState: FC<Props> = ({
  label,
  setValue,
  isActive,
  className,
}) => {
  const {
    userEnv: { isMobile },
  } = useApp();

  const handleClick = () => {
    setValue((prevVar) => !prevVar);
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        styles.checkbox,
        { [styles.checkbox_active]: isActive },
        { [styles.checkbox_mobile]: isMobile },
        className
      )}
    >
      <div className={styles.decor} />
      <p className={styles.label}>{label}</p>
    </button>
  );
};
